import React from "react";
import ArrowBack from "./Icons/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackArrow = ({ mt, marginLeft, marginRight }, props) => {
  const navigate = useNavigate();
  const marginStyle = {
    marginTop: mt,
    marginLeft: marginLeft || "29px",
    marginRight: marginRight || "29px",
    ...props,
  };

  const goBack = () => navigate(-1);

  return (
    <div className="flex " style={marginStyle} onClick={goBack}>
      <div className="flex items-center cursor-pointer">
        <ArrowBack />
        <span>Back</span>
      </div>
    </div>
  );
};

export default BackArrow;
