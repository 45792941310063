import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { showErrorModal } from "../../reducers/messageSlice";
import { fetchUserProfile } from "./getProfile";
import { API_UPDATE_INVESTOR_PROFILE } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const updateInvestorProfile = createAsyncThunk(
  "updateInvestorProfile/update",
  async ({ payload, cb, setSubmitting }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_UPDATE_INVESTOR_PROFILE,
        data: payload,
      });

      // dispatch(fetchUserProfile());

      cb();

      mixpanel.track("Success", {
        type: "Profile",
        subtype: "update-investor-profile",
      });
      // return { ...payload.formValues, id_photo_URL: data?.data?.id_photo };
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Profile",
        subtype: "update-investor-profile",
        message: msg,
      });
      dispatch(showErrorModal(msg || "An error has occurred"));
      setSubmitting(false);
    }
  }
);
