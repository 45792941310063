import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_REGISTER } from "../../../config/api";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const registerUsers = createAsyncThunk(
  "users/register",
  async ({ data, navigate }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_REGISTER,
        data,
      });
      const accessToken = response?.data?.data?.access_token;
      const user = response?.data?.data?.user;

      localStorage.setItem("accessToken", accessToken);

      navigate("/verification", {
        state: { email: user?.email },
      });
      mixpanel.track("Portal-Signup", {});
      mixpanel.identify(user?.email);
      mixpanel.register({ email: user?.email });
      mixpanel.people.set({
        // $first_name: response.data?.user?.name,
        // $last_name: response.data?.user?.fullName?.split(" ")[1],
        $name: user?.name,
        $email: user?.email,
        $created: new Date(),
      });

      mixpanel.people.set({ "First login date": new Date() });

      // return user;
    } catch (error) {
      toast.error(
        error?.response?.data?.errors?.email || "Error, please try again"
      );

      const message = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "signup",
        message: message,
      });

      throw error;
    }
  }
);
