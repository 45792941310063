import React from "react";
import { useLocation } from "react-router-dom";

const HomeIcon = () => {
  const pathname = useLocation().pathname;

  return (
    <div>
      <img
        src={"/Assets/home.png"}
        alt="Home Icon"
        style={{
          width: "20px",
          height: "20px",
          fill: pathname === "/" ? "#159AA8" : "#fff",
        }}
      />
    </div>
  );
};

export default HomeIcon;
