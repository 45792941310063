import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  countries,
  genderOptions,
  idTypesOptions,
  relationshipOptions,
} from "../../config/data";
import { BsQuestionCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CustomInput from "../inputs/CustomInput";
import SelectField from "../inputs/SelectField";
import PhoneInputField from "../inputs/PhoneInputField";
import CustomButton from "../buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { showErrorModal } from "../../store/reducers/messageSlice";
import { updateUserProfile } from "../../store/actions/profile/updateProfile";
import { convertBirthDate } from "../../config/utils";
import HalDatePicker from "../DatePicker";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import dayjs from "dayjs";
import { profileDetailsSchema } from "../../helper/validationSchemas";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import mixpanel from "mixpanel-browser";

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);
  const profileFileInputRef = useRef(null);

  const [selectedRelationship, setSelectedReltionship] = useState("");
  const [myImage, setMyImage] = useState(null);
  const [myProfile, setMyProfile] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(null);
  const [myImagePreview, setMyImagePreview] = useState(null);

  const { userProfileData } = useSelector((state) => state?.userProfile);

  const { user, profile, pending } = useSelector((state) => state.onboarding);

  const loading = useSelector((state) => state?.userProfile?.pending);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleProfileClick = () => {
    profileFileInputRef.current.click();
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const clearIconStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const form = useFormik({
    initialValues: {
      first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
      last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
      address: profile?.address || "",
      nok_first_name: profile?.nok_first_name || "",
      nok_last_name: profile?.nok_last_name || "",
      nok_phone: profile?.nok_phone || "",
      nok_email: profile?.nok_email || "",
      nok_relationship: profile?.nok_relationship || "",
      country: profile?.country
        ? {
            value: profile?.country,
            label: profile?.country,
          }
        : "",
      state: userProfileData?.state || "",
      nationality: userProfileData?.nationality || "",
      city: userProfileData?.city || "",
      postal_code: userProfileData?.postal_code || "",
      gender: userProfileData?.gender || "",
      country_of_residence: userProfileData?.country_of_residence || {
        value: "Nigeria",
        label: "Nigeria",
      },
      phoneCode: "+234",
      email: user?.email,

      phone: userProfileData?.phone || user?.phone,
      birth_date: userProfileData?.birth_date || null,
      profession: userProfileData?.profession || "",
      linked_in_profile: userProfileData?.linked_in_profile || "",
      referral: userProfileData?.referral || "",
      id_number: userProfileData?.id_number || "",
      id_type: userProfileData?.id_type || "",
      id_Photo: "",
      id_Photo_URL: null,
      learn: "",
    },
    validationSchema: profileDetailsSchema,
  });

  useEffect(() => {
    if (profile?.first_name) {
      form.setValues({
        first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
        last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
        address: profile?.address || "",
        email: user?.email || "",
        nok_first_name: profile?.nok_first_name || "",
        nok_last_name: profile?.nok_last_name || "",
        nok_phone: profile?.nok_phone || "",
        nok_email: profile?.nok_email || "",
        nok_relationship: profile?.nok_relationship
          ? {
              value: profile?.nok_relationship,
              label: profile?.nok_relationship,
            }
          : null,
        country: profile?.country
          ? {
              value: profile?.country,
              label: profile?.country,
            }
          : null,
        state: profile?.state
          ? {
              value: profile?.state,
              label: profile?.state,
            }
          : null,
        city: profile?.city || "",
        postal_code: profile?.postal_code || "",

        gender: profile?.gender
          ? genderOptions?.find(
              (i) => i.value?.toLowerCase() === profile?.gender?.toLowerCase()
            )
          : null,
        country_of_residence: profile?.country_of_residence
          ? countries?.find(
              (country) => country?.value === profile?.country_of_residence
            )
          : null,
        phoneCode: "+234",
        phone: profile?.phone,
        birth_date: profile?.birth_date ? dayjs(profile?.birth_date) : null,
        profession: profile?.profession || "",
        linked_in_profile: profile?.linked_in_profile || "",

        // referee: userProfileData?.referee || "",
        // learn: userProfileData?.learn || "",
        id_type: profile?.id_type
          ? idTypesOptions?.find((item) => item?.value === profile?.id_type)
          : null,
        nationality: profile?.nationality
          ? {
              value: profile?.nationality,
              label: profile?.nationality,
            }
          : null,
        id_number: profile?.id_number || "",

        id_Photo: "",
        id_Photo_URL: profile?.id_photo ? profile?.id_photo : null,
        profile_pic_URL: profile?.id_photo ? profile?.profile_pic : null,
      });
    }
  }, [user, profile]);

  const handleRemoveProfileImage = () => {
    setProfilePreview(null);
    form.setFieldValue("profile_pic_URL", null);
    setMyProfile(null);
  };
  const handleRemoveImage = () => {
    setMyImagePreview(null);
    form.setFieldValue("id_Photo_URL", null);
    setMyImage(null);
  };

  const handleImageChange = async (e, type) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];
    if (!/(gif|jpe?g|png|jpg|svg|jpeg|pdf)$/i.test(convertedFiles.type)) {
      dispatch(
        showErrorModal(
          "Invalid file type. Only PNG, jpg, GIF, JPEG, SVG, and PDF files are accepted."
        )
      );
      return;
    }

    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 1.5) {
      dispatch(showErrorModal("File size is too big (max. 1.5MB)"));
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);

    if (type === "profilePicture") {
      setMyProfile(convertedFiles);
      fileReader.addEventListener("load", (result) => {
        setProfilePreview(result.target.result);
      });
    } else {
      setMyImage(convertedFiles);
      fileReader.addEventListener("load", (result) => {
        setMyImagePreview(result.target.result);
      });
    }
  };

  const handleSubmitUserDetails = () => {
    const formData = new FormData();
    const birthDate = new Date(form.values.dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    if (age < 18) {
      dispatch(showErrorModal("You must be 18 years or older to proceed."));
      return;
    } else if (!user?.hasCompletedProfile) {
      navigate("/onboarding");
      dispatch(showErrorModal("Please complete your profile first"));
      return;
    }

    if (myImage) {
      formData.append("id_photo", myImage);
    }
    if (myProfile) {
      formData.append("profile_pic", myProfile);
    }

    const formattedBirthDate = convertBirthDate(
      new Date(form.values.birth_date)
    );

    formData.append("first_name", form.values?.first_name);
    formData.append("last_name", form.values?.last_name);
    formData.append("address", form.values?.address);
    formData.append("country", form.values.country.label);
    formData.append("state", form.values.state.label);
    formData.append("city", form.values.city);
    formData.append("postal_code", form.values.postal_code);
    formData.append("gender", form.values?.gender?.value?.toLowerCase());
    formData.append(
      "country_of_residence",
      form.values?.country_of_residence?.value
    );
    formData.append("phone", form.values.phone);
    formData.append("birth_date", formattedBirthDate);
    formData.append("profession", form.values.profession);
    formData.append("linked_in_profile", form.values.linked_in_profile);
    formData.append("id_type", form.values?.id_type?.value);
    // formData.append("nationality", form.values?.nationality?.value);
    formData.append("id_number", form.values.id_number);
    formData.append("nok_first_name", form.values?.nok_first_name);
    formData.append("nok_last_name", form.values?.nok_last_name);
    formData.append("nok_phone", form.values.nok_phone);
    formData.append("nok_email", form.values.nok_email);
    formData.append("nok_relationship", form.values.nok_relationship?.label);

    dispatch(
      updateUserProfile({
        data: formData,
        navigate,
        formValues: form.values,
        from: "settings",
        cb: () => {
          setShowMessageModal(true);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(fetchUserProfile());
    // const clean = setInterval(() => {
    //   dispatch(fetchUserProfile());
    // }, 60000);
    // return () => {
    //   clearInterval(clean);
    // };
  }, []);

  const handleRelationshipChange = (event) => {
    setSelectedReltionship(event.target.value);
  };

  const handleClosModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Profile", {
        user: user,
      });
    }
  }, [user]);
  return (
    <div className="w-full">
      <div className="mb-8">
        <div className="flex  flex-col lg:flex-row justify-between">
          <div>
            <h1 className="font-[600] text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">
              Profile
            </h1>
            <p className="text-base font-[400] text-[#475467}">
              Update your personal details.
            </p>
          </div>

          <div className="opacity-0">
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="100px"
              height="45px"
              padding="10px"
              margin="5px 5px"
              color="#000"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Save"
              width="100px"
              height="45px"
              padding="10px"
              margin="15px 0"
              color="#fff"
              loading={loading}
              disabled={
                (!myImagePreview && !form.values?.id_Photo_URL) ||
                loading ||
                !form.isValid ||
                !form.dirty
              }
              onClick={handleSubmitUserDetails}
            />
          </div>
        </div>
      </div>
      {/* <div className="mb-20">
        <h1 className=" text-[18px] leading-[28px] font-[600] mb-1">
          Personal Info
        </h1>
        <p className="text-base font-[400] text-[#475467}">
          Update your photo and personal here.
        </p>
      </div>
      {/* <div className="mb-10 w-full flex flex-col lg:flex-row justify-between ">
        <div>
          <h1 className=" text-[14px] leading-[28px] font-[600] mb-1">
            Your Photo
          </h1>
          <p className="text-base font-[400] text-[#475467}">
            This will be displayed on your profile.
          </p>
        </div>
        <div className="flex w-full lg:w-[512px] lg:pr-8 flex-col mt-3 lg:mt-0 lg:flex-row items-center ">
          <img
            src="/Assets/newAvatar.png"
            alt=""
            className="w-12 h-12  lg:mb-16 lg:mr-4"
          />
          <div
            className="w-full"
            style={{
              height: "126px",
              border: "1px solid #EAECF0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
              textAlign: "center",
              lineHeight: 1.8,
              padding: "2px  10px",
              marginTop: "10px",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, "profilePicture")}
              hidden
              ref={profileFileInputRef}
            />
            {profilePreview || form.values?.profile_pic_URL ? (
              <>
                <IconButton
                  onClick={handleRemoveProfileImage}
                  style={clearIconStyle}
                >
                  <ClearIcon />
                </IconButton>
                <img
                  src={profilePreview || form.values?.profile_pic_URL}
                  alt="Preview"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                  }}
                />
              </>
            ) : (
              <div>
                <p className="text-sm mt-4">
                  <div className="flex items-center justify-center">
                    <img
                      src="/Assets/upload.png"
                      alt="Personal icon"
                      className="w-12 h-12 mr-4 "
                    />
                  </div>
                  <span
                    className="text-[#159AA8] mt-2"
                    onClick={handleProfileClick}
                  >
                    Click to upload{" "}
                  </span>
                 </p>
                <p className="text-sm">SVG, PNG, JPG, or GIF (max. 2MB)</p>
              </div>
            )}
          </div>
        </div>
      </div> */}
      <div className="flex lg:pr-8 justify-between flex-col lg:flex-row py-3 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] leading-[20px] font-[600]">First Name</h4>

        <div className="w-full lg:w-[512px]">
          <CustomInput
            width="100%"
            disabled={profile?.BVN || user?.bvn_verified || pending}
            placeholder={"First name here"}
            borderColor={profile?.BVN || user?.bvn_verified ? "#D0D5DD" : null}
            pt="8px"
            form={form}
            id="first_name"
          />
          {profile?.BVN || user?.bvn_verified ? (
            <BsQuestionCircle
              onClick={() => setShowModal(true)}
              className="cursor-pointer mx-2 my-1 "
              size={"14px"}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex lg:pr-8  flex-col lg:flex-row justify-between py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] leading-[20px] font-[600]">Last Name</h4>
        <div className="w-full lg:w-[512px]">
          <CustomInput
            width="100%"
            disabled={profile?.BVN || user?.bvn_verified || pending}
            form={form}
            id="last_name"
            placeholder={"Last name here"}
            borderColor={profile?.BVN || user?.bvn_verified ? "#D0D5DD" : null}
            pt="8px"
          />
          {profile?.BVN || user?.bvn_verified ? (
            <BsQuestionCircle
              onClick={() => setShowModal(true)}
              className="cursor-pointer mx-2 my-1 "
              size={"14px"}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex flex-col lg:pr-8 lg:flex-row justify-between py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] leading-[20px] font-[600]">
          Email Address
        </h4>
        <div className="w-full lg:w-[512px]">
          <CustomInput
            disabled
            width="100%"
            placeholder={"Your email here"}
            form={form}
            id="email"
            borderColor={profile?.BVN || user?.bvn_verified ? "#D0D5DD" : null}
            pt="8px"
          />
          {/* {profile?.BVN || user?.bvn_verified ? ( */}
          <BsQuestionCircle
            onClick={() => setShowModal(true)}
            className="cursor-pointer mx-2 my-1 "
            color="rgba(29,53,81,1)"
            size={"14px"}
          />
          {/* ) : (
            <></>
          )} */}
        </div>
      </div>
      <div className="flex flex-col lg:pr-8 lg:flex-row justify-between  py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] leading-[20px] flex-1 font-[600]">
          Country of Residence
        </h4>
        <div className="w-full lg:w-[512px]">
          <SelectField
            label="Choose country"
            id="country_of_residence"
            options={countries}
            form={form}
            sx={{ flex: 1, height: "52px" }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:pr-8 lg:flex-row justify-between py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
          Phone Number
        </h4>
        <div className="w-full lg:w-[512px]">
          <PhoneInputField
            disabled={profile?.BVN || user?.bvn_verified || pending}
            width={"100%"}
            height={"50px"}
            name={"phone"}
            form={form}
            id={"phone"}
          />
          {profile?.BVN || user?.bvn_verified ? (
            <BsQuestionCircle
              onClick={() => setShowModal(true)}
              className="cursor-pointer mx-2 my-1 "
              size={"14px"}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:pr-8 lg:flex-row justify-between  py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] leading-[20px] flex-1 font-[600]">
          Gender
        </h4>
        <div className="w-full lg:w-[512px]">
          <SelectField
            label="Gender"
            id="gender"
            form={form}
            options={genderOptions || []}
            sx={{ flex: 1, height: "52px" }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:pr-8 lg:flex-row justify-between py-5 border-t border-t-[#EAECF0] ">
        <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
          Date of Birth
        </h4>
        <div className="w-full lg:w-[512px]">
          <HalDatePicker
            height={50}
            id={"birth_date"}
            form={form}
            disabled={true}
            // form={form}

            rightImage
          />
        </div>
      </div>

      <div className="mt-20  w-full">
        <h1 className="font-[600] text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">
          Next of Kin Details
        </h1>
        <p className="text-base font-[400] mb-8 text-[#475467}">
          Update your next of kin details.
        </p>

        <div className="flex w-full justify-between lg:pr-8 flex-col lg:flex-row py-5 border-t border-t-[#EAECF0] ">
          <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
            Name
          </h4>

          <div className="flex w-full lg:w-[512px] gap-3 ">
            <div className="w-full ">
              <CustomInput
                width={"100%"}
                placeholder={"First name"}
                borderColor="#D0D5DD"
                form={form}
                id="nok_first_name"
              />
            </div>
            <div className="w-full">
              <CustomInput
                form={form}
                id="nok_last_name"
                width={"100%"}
                style={{
                  flex: 1,
                }}
                placeholder={"Last name"}
                borderColor="#D0D5DD"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between lg:pr-8 flex-col lg:flex-row py-5 border-t border-t-[#EAECF0] ">
          <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
            Email Address
          </h4>
          <div className="w-full  lg:w-[512px]">
            <CustomInput
              width="100%"
              height="8px"
              placeholder={"Next of Kin Email"}
              borderColor="#D0D5DD"
              form={form}
              id="nok_email"
              pt="8px"
              mt="10px"
            />
          </div>
        </div>

        <div className="flex w-full justify-between lg:pr-8 flex-col lg:flex-row py-5 border-t border-t-[#EAECF0] ">
          <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
            Phone Number
          </h4>
          <div className="w-full lg:w-[512px]">
            <PhoneInputField
              width={"100%"}
              height={"44px"}
              name={"nok_phone"}
              form={form}
              id={"nok_phone"}
            />
          </div>
        </div>

        <div className="flex w-full justify-between lg:pr-8 flex-col lg:flex-row py-5 border-t border-t-[#EAECF0] ">
          <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
            Relationship
          </h4>
          <div className="w-full lg:w-[512px]">
            <SelectField
              id="nok_relationship"
              label="Choose Relationship"
              form={form}
              value={selectedRelationship}
              options={relationshipOptions}
              sx={{ width: "100%", height: "52px" }}
            />
          </div>
        </div>

        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          <div className="mt-12 mb-5 border-b border-b-[#EAECF0]">
            {/* <div className="mt-12 mb-5 "> */}
            <div className="flex justify-between mb-4 gap-4 flex-col sm:flex-row">
              <div>
                <h1 className="font-[600] text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">
                  Identification
                </h1>
              </div>
            </div>
          </div>

          {!pending && (
            <>
              <div className="flex gap-2 w-[90%] md:w-[320px] justify-between items-center mt-1 mb-5">
                <h1 className="font-[600] text-base text-[#101828]">
                  Email Verification
                </h1>

                <div className="flex justify-start items-start mt-1 ">
                  {user?.email_verified ? (
                    <div className="bg-[#ECFDF3] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <img
                        src="/Assets/markIcon.png"
                        alt=""
                        className="w-2 h-2 mr-2"
                      />
                      <h4 className=" text-[#067647]  font-[500] text-[12px] leading-[18px]    ">
                        verified
                      </h4>
                    </div>
                  ) : (
                    <div className="bg-[#FEF3F2] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <h4 className=" text-[#B42318] font-[500] text-[12px] leading-[18px]    ">
                        Pending
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-2 w-[90%] md:w-[320px] justify-between items-center mt-1 mb-5">
                <h1 className="font-[600] text-base text-[#101828]">
                  BVN Verification
                </h1>

                <div className="flex justify-start items-start mt-1 ">
                  {profile?.BVN || user?.bvn_verified ? (
                    <div className="bg-[#ECFDF3] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <img
                        src="/Assets/markIcon.png"
                        alt=""
                        className="w-2 h-2 mr-2"
                      />
                      <h4 className=" text-[#067647]  font-[500] text-[12px] leading-[18px]    ">
                        verified
                      </h4>
                    </div>
                  ) : (
                    <div className="bg-[#FEF3F2] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <h4 className=" text-[#B42318] font-[500] text-[12px] leading-[18px]    ">
                        Pending
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-1 mb-5 flex gap-2 w-[90%] md:w-[320px] justify-between items-center">
                <h1 className="font-[600] text-base text-[#101828]">
                  Document Verification
                </h1>
                <div className="flex justify-start items-start mt-1 ">
                  {user?.document_verified === false ? (
                    <div className="bg-[#FEF3F2] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <h4 className=" text-[#B42318] font-[500] text-[12px] leading-[18px]    ">
                        Pending
                      </h4>
                    </div>
                  ) : (
                    <div className="bg-[#ECFDF3] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <img
                        src="/Assets/markIcon.png"
                        alt=""
                        className="w-2 h-2 mr-2"
                      />
                      <h4 className=" text-[#067647]  font-[500] text-[12px] leading-[18px]    ">
                        verified
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-2 w-[90%] md:w-[320px] justify-between items-center mt-1 mb-8">
                <h1 className="font-[600] text-base text-[#101828]">
                  Membership Verification
                </h1>

                <div className="flex justify-start items-start mt-1 ">
                  {user?.selectedPlan?.toLowerCase() === "platinum" ? (
                    <div className="bg-[#ECFDF3] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <img
                        src="/Assets/markIcon.png"
                        alt=""
                        className="w-2 h-2 mr-2"
                      />
                      <h4 className=" text-[#067647]  font-[500] text-[12px] leading-[18px]    ">
                        Approved
                      </h4>
                    </div>
                  ) : user?.selectedPlan?.toLowerCase() === "free" &&
                    user?.free_approved_plan === "approved" ? (
                    <div className="bg-[#ECFDF3] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <img
                        src="/Assets/markIcon.png"
                        alt=""
                        className="w-2 h-2 mr-2"
                      />
                      <h4 className=" text-[#067647]  font-[500] text-[12px] leading-[18px]    ">
                        Approved
                      </h4>
                    </div>
                  ) : user?.selectedPlan?.toLowerCase() === "free" &&
                    user?.free_approved_plan === "rejected" ? (
                    <div className="bg-[#FEF3F2] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <h4 className=" text-[#B42318] font-[500] text-[12px] leading-[18px]    ">
                        Rejected
                      </h4>
                    </div>
                  ) : (
                    <div className="bg-[#FEF3F2] flex justify-center items-center pt-[2px] pb-1 px-2  rounded-full ">
                      <h4 className=" text-[#B42318] font-[500] text-[12px] leading-[18px]    ">
                        Pending
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {profile && !profile?.id_photo && (
            <div className="flex w-full justify-between lg:pr-8 flex-col lg:flex-row py-5  ">
              <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
                Document type
              </h4>
              <div w-full>
                <div className="w-full lg:w-[512px]">
                  <SelectField
                    label="Choose ID type"
                    id="id_type"
                    form={form}
                    options={idTypesOptions}
                    sx={{ width: "100%", height: "52px" }}
                  />
                </div>
                <div className="w-full  mt-2 mb-1 ">
                  <CustomInput
                    fullWidth
                    type="number"
                    height="10px"
                    form={form}
                    id="id_number"
                    placeholder={"Enter your uploaded Id number"}
                    borderColor="#D0D5DD"
                  />
                </div>
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "250px",
                      border: "1px solid #EAECF0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      textAlign: "center",
                      lineHeight: 1.8,
                      marginTop: "10px",
                      padding: "10px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <input
                      type="file"
                      accept=".png, .jpg, .gif, .jpeg, .svg"
                      onChange={handleImageChange}
                      hidden
                      ref={fileInputRef}
                    />
                    {myImagePreview || form.values?.id_Photo_URL ? (
                      <>
                        <IconButton
                          onClick={handleRemoveImage}
                          style={clearIconStyle}
                        >
                          <ClearIcon />
                        </IconButton>
                        <img
                          src={myImagePreview || form.values?.id_Photo_URL}
                          alt="Preview"
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            height: "100%",
                          }}
                        />
                      </>
                    ) : (
                      <div
                        className="w-full flex-col h-full flex justify-center items-center"
                        onClick={handleDivClick}
                      >
                        <p className="text-sm mt-4">
                          <div className="flex items-center justify-center">
                            <img
                              src="/Assets/upload.png"
                              alt="Personal icon"
                              className="w-12 h-12 mr-4 "
                            />
                          </div>
                          <span className="text-[#159AA8] mt-2">
                            Click to upload{" "}
                          </span>
                          {/* or drag and drop */}
                        </p>
                        <p className="text-sm">
                          SVG, PNG, JPG, or GIF (max. 1.5MB)
                        </p>
                      </div>
                    )}
                  </div>
                  {!myImagePreview && !form.values?.id_Photo_URL && (
                    <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
                      File upload is required
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
          <div className="flex gap-3 mb-14 pr-4 w-full items-center justify-end">
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="100px"
              height="45px"
              padding="10px"
              margin="5px 5px"
              color="#000"
              //  onClick={onClose}
            />
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Save"
              width="100px"
              height="45px"
              padding="10px"
              margin="15px 0"
              color="#fff"
              loading={loading}
              disabled={
                (!myImagePreview && !form.values?.id_Photo_URL) ||
                loading ||
                !form.isValid ||
                !form.dirty
              }
              onClick={handleSubmitUserDetails}
            />
          </div>
        </div>
      </div>
      <ResponseMessageModal
        onClose={handleCloseMessageModal}
        open={showMessageModal}
        type={1}
        mainText={"Profile updated successfully"}
        // subText={
        // }
        handleContinue={handleCloseMessageModal}
      />
      <ResponseMessageModal
        open={showModal}
        onClose={handleClosModal}
        handleContinue={handleClosModal}
        type={0}
        btnTitle={"Ok"}
        mainText={""}
        subText={
          "To change your personal information, send an email with the new information and the reason for the change to support@halvestco.com using your registered email address."
        }
      />
    </div>
  );
}

export default Profile;
