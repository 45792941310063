import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_RESET_PASSWORD } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ data, cb, setSubmitting }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_RESET_PASSWORD,
        data,
      });

      mixpanel.track("Success", {
        type: "onboarding",
        subtype: "change-password",
      });
      cb();
      return response;
    } catch (error) {
      const msg = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "change-password",
        message: msg,
      });

      setSubmitting(false);
      toast.error(msg);
      throw error;
    }
  }
);
