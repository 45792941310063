import Axios from "axios";

export const Client = async (params) => {
  const token = localStorage.getItem("accessToken");
  const {
    path,
    method,
    type,
    data,
    queryParams,
    contentType = "application/json",
  } = params;

  const baseURL =
    process.env.REACT_APP_APP_ENV === "staging"
      ? process.env.REACT_APP_STAGING_BASE_URL
      : process.env.REACT_APP_PRODUCTION_BASE_URL;

  const headers = {
    ...(token && {
      Authorization: `Bearer ${token}`,
    }),
    "Content-Type": contentType,
    Accept: "application/json",
  };

  let url = type === "full-path" ? path : `${baseURL}api/v1/${path}`;

  const axiosConfig = {
    method: method,
    url: url,
    timeout: 20000,
    headers: headers,
    params: queryParams,
    responseType: "json",
  };

  if (method.toUpperCase() !== "GET") {
    axiosConfig.data =
      contentType === "multipart/form-data" ? data : JSON.stringify(data);
  }

  try {
    const response = await Axios(axiosConfig);
    return response;
  } catch (error) {
    // error?.response?.status === 403 ||
    if (
      error?.response?.status === 401 ||
      error.response.data.message === "Authorization Token not found" ||
      error.response.data.message === "Token is Expired" ||
      error.response.data.message === "Token is Invalid"
    ) {
      if (localStorage.getItem("accessToken")) {
        localStorage.removeItem("accessToken");
      }
      // window.location = `/?message=${error.response.data.message}`;
      window.location = `/`;
    } else {
      throw error;
    }
  }
};
