import { closeModal } from "../store/reducers/messageSlice";

export const actions = (dispatch, cb) => [
  {
    msg: "The profile data provided does not match the BVN verification data. Please review and ensure all provided information is accurate.",
    call: "Go to Profile",
    action: () => {
      dispatch(closeModal());
      cb();
    },
    navigateTo: "/settings/profile",
  },
  {
    msg: "Insufficient balance, click here to fund your wallet.",
    call: "Go to Wallet",
    action: () => {
      dispatch(closeModal());
      cb();
    },
    navigateTo: "/wallet",
  },
  // {
  //   msg: "The provided BVN is already registered with another account. Please verify the BVN and try again, or contact support for assistance.",
  //   call: "Go to Profile",
  // },
];
