import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { NumericFormat } from "react-number-format";
export const CustomInputWithLabel = ({
  containerMarginTop,
  label,
  Required,
  ...props
}) => {
  return (
    <div
      className="w-full "
      style={{
        marginBottom: containerMarginTop || "20px",
      }}
    >
      <h4 className="font-[500] text-sm mb-[2px] leading-5">
        {label}
        {Required ? "*" : ""}{" "}
      </h4>
      <CustomInput {...props} />
    </div>
  );
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, prefix, ...other } = props;

  return (
    <NumericFormat
      {...other}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={prefix}
    />
  );
});

const CustomInput = ({
  label,
  icon,
  type,
  iconPosition,
  form,
  id,
  autoFocus,
  prefix,
  height,
  width,
  secured,
  mr,
  selfValue,
  ml,
  p,
  customType,
  placeholder,
  borderColor,
  onChange,
  mt,
  textInputCustomStyles,
  bg,
  noBorder,
  variant,
  fullWidth,
  customTextStyle,
  specialType,
  ...props
}) => {
  const [show, setShow] = useState(type !== "password" && !secured);

  const handleChange = (e) => {
    form?.setFieldValue(id, e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <TextField
        label={label}
        name={id}
        id={id}
        autoFocus={autoFocus}
        placeholder={placeholder}
        // customPrefix={customPrefix || null}
        // value={values.numberformat}
        // onChange={handleChange}
        value={selfValue || form?.values[id]}
        onChange={(e) => (onChange ? onChange(e) : handleChange(e))}
        // error={form?.errors[id] ? true : false}
        // helperText={form?.errors[id] || ""}
        // type={"text" "password"}
        {...props}
        type={
          show && (type === "password" || secured)
            ? "text"
            : secured
            ? "password"
            : type
        }
        fullWidth={fullWidth}
        sx={{
          "& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
            // fontFamily: "TTI",
            fontFamily: "Inter",
            color: "#101828",
            fontWeight: "500",
          },
          "& .MuiInputBase-input": {
            // backgroundColor: "red"
            // border: "none",
            // borderWidth: 13,
            // borderRadius: "12px",
            // height: "120px",
          },
          "& .css-10botns-MuiInputBase-input-MuiFilledInput-input": {
            // paddingTop: "12px",
            // paddingBottom: "12px",
          },
          "& .css-1pnmrwp-MuiTypography-root":
            typeof icon === "string"
              ? {
                  // fontFamily: "TTI",
                  fontFamily: "Inter",
                  color: "#101828",
                  fontWeight: "500",
                  marginTop: "3px",
                }
              : null,
          "& input": {
            width: iconPosition === "right" ? "auto" : width,

            border: bg && "none",
            outline: bg ? "none" : "initial",
            "&:focus": {
              borderColor: "transparent",
            },
            paddingTop: "12px",
            paddingBottom: "12px",
            ...textInputCustomStyles,
          },
          "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {},

          outline: bg ? "none" : "initial",
          border: "none",
        }}
        variant={
          specialType === "support"
            ? "filled"
            : icon || secured || type === "password"
            ? "standard"
            : variant || "filled"
        }
        inputProps={{ prefix: prefix }}
        InputProps={{
          inputComponent: customType === "amount" ? NumericFormatCustom : null,
          disableUnderline: true,
          startAdornment: iconPosition === "left" && (
            <InputAdornment
              position="start"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              {icon}
            </InputAdornment>
          ),
          endAdornment:
            iconPosition === "right" ? (
              <InputAdornment
                position="end"
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                {icon}
              </InputAdornment>
            ) : type === "password" || secured ? (
              <InputAdornment
                position="end"
                sx={{
                  height: 20,
                  width: 20,
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                {show ? (
                  <PiEyeSlash
                    className="cursor-pointer"
                    onClick={() => setShow(false)}
                    size={20}
                  />
                ) : (
                  <PiEyeLight
                    className="cursor-pointer"
                    onClick={() => setShow(true)}
                    size={20}
                  />
                )}
              </InputAdornment>
            ) : null,
          style: {
            borderRadius: "8px",
            padding: icon || secured || type === "password" ? "0px 12px" : null,
            overflow: "hidden",
            border: noBorder ? null : "1px solid #D0D5DD",
            backgroundColor:
              icon || secured || type === "password"
                ? "transparent"
                : bg || "transparent",
          },
        }}
      />
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
          {form?.errors[id]}
        </div>
      )}
    </div>
  );
};

export default CustomInput;
