import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_GET_paymentSchedule } from "../../../config/api";
import mixpanel from "mixpanel-browser";
export const getPaymentSchedule = createAsyncThunk(
  "portfolio/getpaymentSchedule",
  async ({ currency, product_id }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_paymentSchedule}${currency}&product_id=${product_id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "portfolio",
        subtype: "get-payment-schedule",
        message: msg,
      });
      toast.error(msg);
      throw error;
    }
  }
);
