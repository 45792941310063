import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResponseMessageModal from "./ResponseMessageModal";
import { useCookies } from "react-cookie";
import mixpanel from "mixpanel-browser";

const Reminder = ({ showReminder, setShowReminder }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.onboarding);
  const { profile, loading } = useSelector((state) => state.onboarding);

  const now = new Date();
  const nextDay = new Date(now.setDate(now.getDate() + 1));

  const [_, setCookie] = useCookies(["reminder"]);

  return (
    <ResponseMessageModal
      onClose={() => {
        setCookie(
          `${user?.email}`,
          { reminded: true },
          {
            expires: nextDay,
            secure: true,
          }
        );
        mixpanel.track("New-User-Closed-Reminder", {
          user: user,
        });
        setShowReminder(false);
      }}
      open={showReminder}
      type={0}
      closeIcon={true}
      // closeButtonTitle={"Later"}
      btnTitle={"Complete Your Profile"}
      mainText={"Complete Your Profile"}
      subText={
        "Complete your profile today and have access to various Investment opportunities."
      }
      handleContinue={() => {
        setCookie(
          `${user?.email}`,
          { reminded: true },
          {
            expires: nextDay,
            secure: true,
          }
        );

        mixpanel.track("New-User-Continued-Onboarding-From-Reminder", {
          user: user,
        });

        if (profile?.id_number && !profile?.investorProfile?.risk_tolerance) {
          navigate("/onboarding/investor-profile");
        } else if (profile?.investorProfile?.risk_tolerance) {
          navigate("/onboarding/membership");
        } else {
          navigate("/onboarding");
        }
      }}
    />
  );
};

export default Reminder;
