import React from "react";
import CustomButton from "../buttons/Button";

const NoData = ({ title, noImage, subtitle, image, cta, py }) => {
  return (
    <div
      style={{ paddingTop: py, paddingBottom: py }}
      className="flex items-center justify-center  flex-col h-[35vh] my-2  font-semibold"
    >
      {noImage ? (
        <></>
      ) : (
        <img
          src={`/Assets/${image}`}
          alt=""
          className="w-[102px] object-contain h-[102px]"
        />
      )}
      <h1 className="mb-2 text-center">{title}</h1>
      <span className="text-center max-w-[80%] ">{subtitle}</span>
    </div>
  );
};

export default NoData;
