import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  API_USER_BANK_DETAILS,
  API_USER_USD_BANK_DETAILS,
} from "../../../config/api";

export const getUserBanks = createAsyncThunk(
  "userbanks/get",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: API_USER_BANK_DETAILS,
      });
      const { data: USDData } = await Client({
        method: "GET",
        path: API_USER_USD_BANK_DETAILS,
      });

      return { NGR: data?.data, USD: USDData?.data };
    } catch (error) {
      // toast.error(error?.response?.data?.message || "An error has occurred");
      payload.setSubmitting(false);
    }
  }
);
