import React, { useEffect, useMemo, useState } from "react";
import NoData from "../cards/NoData";
import { useDispatch, useSelector } from "react-redux";
import { getPayouts } from "../../store/actions/payouts/getPayouts";
import useCurrency from "../../hooks/useCurrency";
import Loader from "../sharedLayouts/Loader";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { groupbydate } from "../../helper/groupbydate";

function UpcomingPayments() {
  const dispatch = useDispatch();
  const [showAcc, setShowAcc] = useState(0);
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const [_currency, getAmount] = useCurrency();
  const [viewAll, setViewAll] = useState(false);

  const { payOuts, pending } = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(getPayouts(currency));
    // const clean = setInterval(() => {
    //   dispatch(getPayouts(currency));
    // }, 60000);
    // return () => {
    //   clearInterval(clean);
    // };
  }, [currency]);

  const sortedPayouts = useMemo(
    () =>
      payOuts
        ? groupbydate({
            data: payOuts,
            dateKey: "payment_date",
          })
        : [],
    [payOuts]
  );

  const grouped = useMemo(() => {
    return sortedPayouts
      ? [...sortedPayouts]?.slice(0, viewAll ? sortedPayouts?.length : 15)
      : [];
  }, [sortedPayouts, viewAll]);

  return (
    <div
      className="w-full min-h-[720px] 2xl:w-[500px] border border-border-color-1 p-5 rounded-xl"
      style={{ minHeight: "100%" }}
    >
      <div className="flex justify-between ">
        <h1 className="font-[600] text-[#101828] text-[18px] mb-8">
          Upcoming Payments
        </h1>
        {payOuts?.length > 15 ? (
          <h3
            onClick={() => setViewAll((prev) => !prev)}
            className="font-[600] cursor-pointer text-[#475467] text-[16px] leading-5 mb-8"
          >
            {viewAll ? "View Less" : "View More"}
          </h3>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full">
        {pending ? (
          <div className="w-full justify-center items-center flex min-h-[300px]">
            <Loader />
          </div>
        ) : !payOuts || payOuts?.length < 1 ? (
          <NoData
            title={`No Upcoming ${
              _currency === "$" ? "USD" : "Naira"
            } Payments`}
            subtitle="There is currently no upcoming payment, please check back later"
            image="notransaction.png"
          />
        ) : (
          <>
            {grouped.map((item, ind) => {
              return (
                <div className={`     `}>
                  <div
                    onClick={() => {
                      setShowAcc((prev) => (prev === ind ? null : ind));
                    }}
                    className="flex border-b border-b-solid border-b-gray-30  py-2 w-full cursor-pointer items-center justify-between"
                  >
                    <div className="flex  items-center gap-4">
                      <img
                        src="/Assets/upcoming.png"
                        alt=""
                        className="w-10 h-10"
                      />
                      <div className="flex flex-col">
                        <h3 className="font-[500] text-[#101828] text-sm leading-6">
                          {`${item?.month} ${item?.year} Payments`}
                        </h3>
                      </div>
                    </div>
                    <div>
                      {showAcc === ind ? (
                        <HiOutlineChevronUp className="cursor-pointer" />
                      ) : (
                        <HiOutlineChevronDown className="cursor-pointer " />
                      )}
                    </div>
                  </div>
                  {showAcc === ind ? (
                    item.groups
                      .slice(0, 4)
                      ?.map(
                        (
                          {
                            amount,
                            currency,
                            payment_date,
                            investment_product_name,
                            monthly_payout,
                          },
                          index
                        ) => (
                          <div
                            className={`flex   px-5 py-3 ${
                              sortedPayouts?.length - 1 === index
                                ? "border-none"
                                : "border-b border-b-solid border-b-gray-30"
                            }`}
                          >
                            <div className="flex w-full items-center justify-between">
                              <div className="flex  items-center gap-3">
                                <div className="flex gap-[6px] flex-col">
                                  <h3 className="font-[500] text-[#101828] text-base ">
                                    {investment_product_name}
                                  </h3>
                                  <h4 className="font-[400] text-[#475467] text-sm ">
                                    Due {payment_date}
                                  </h4>
                                </div>
                              </div>
                              <h3 className="font-[500] text-[#101828] text-sm ">
                                {monthly_payout && _currency}
                                {monthly_payout && getAmount(monthly_payout, 2)}
                              </h3>
                            </div>
                          </div>
                        )
                      )
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
            {/* {sortedPayouts?.map(
              (
                {
                  amount,
                  currency,
                  payment_date,
                  investment_product_name,
                  monthly_payout,
                },
                index
              ) => (
                <div
                  className={`flex  my-2 px-5 py-4 ${
                    sortedPayouts?.length - 1 === index
                      ? "border-none"
                      : "border-b border-b-solid border-b-gray-30"
                  }`}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex  items-center gap-3">
                      <img
                        src="/Assets/upcoming.png"
                        alt=""
                        className="w-10 h-10"
                      />
                      <div className="flex flex-col">
                        <h3 className="font-[500] text-[#101828] text-base leading-6">
                          {investment_product_name}
                        </h3>
                        <h4 className="font-[400] text-[#475467] text-sm leading-5">
                          Due {payment_date}
                        </h4>
                      </div>
                    </div>
                    <h3 className="font-[500] text-[#101828] text-sm leading-5">
                      {monthly_payout && _currency}
                      {monthly_payout && getAmount(monthly_payout, 2)}
                    </h3>
                  </div>
                </div>
              )
            )} */}
          </>
        )}
      </div>
    </div>
  );
}

export default UpcomingPayments;
