import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Client } from "../../../client";
import { API_GOOGLE_REGISTER } from "../../../config/api";
import mixpanel from "mixpanel-browser";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const registerUsersWithGoogle = createAsyncThunk(
  "users/googleRegister",
  async ({ code }, { rejectWithValue }) => {
    try {
      const tokenResponse = await fetch(
        process.env.REACT_APP_GOOGLE_EXCHANGE_TOKEN,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
            grant_type: "authorization_code",
            code: code,
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
          }),
        }
      );

      const tokenData = await tokenResponse.json();

      if (!tokenResponse.ok) {
        throw new Error(
          tokenData.error || "Failed to exchange the authorization code."
        );
      }

      const response = await Client({
        method: "POST",
        path: API_GOOGLE_REGISTER,
        queryParams: { accessToken: tokenData.access_token },
      });

      const { accessToken, user } = response.data;
      localStorage.setItem("accessToken", accessToken);
      toast.success("Registration successful!");

      mixpanel.identify(user?.email);
      mixpanel.register({ email: user?.email });
      mixpanel.people.set({
        // $first_name: response.data?.user?.name,
        // $last_name: response.data?.user?.fullName?.split(" ")[1],
        $name: user?.name,
        $email: user?.email,
        $created: new Date(),
      });
      mixpanel.track("Portal-Signup-Google", {});
      mixpanel.people.set({ "First login date": new Date() });

      return user;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Registration with Google failed";

      const message = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "signup-google",
        message: message,
      });

      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
