import React, { useEffect } from "react";
import OpportunityCard from "../cards/OpportunityCard";
import CustomButton from "../buttons/Button";
import { useState } from "react";
import CustomPagination from "../pagination/CustomPagination";
import Loader from "../sharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolio } from "../../store/actions/portfolio/getportfolio";
import { useNavigate } from "react-router-dom";
import NoData from "../cards/NoData";
import useCurrency from "../../hooks/useCurrency";

function InvestmentOpportunities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("all");
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const [_currency, getAmount] = useCurrency();

  const { portfolio: opportunitiesData, pending: loading } = useSelector(
    (state) => state.portfolio
  );

  // const [setFilterCriteria] = useState({
  //   searchText: "",
  //   isOpen: "all",
  //   riskProfile: "all",
  // });

  // const filterOpportunities = () => {
  //   const filteredOpportunities = opportunitiesData?.filter((opportunity) => {
  //     const isOpenMatch =
  //       filterCriteria.isOpen === "all" ||
  //       opportunity.header.isOpen === filterCriteria.isOpen;

  //     const riskProfileMatch =
  //       filterCriteria.riskProfile === "all" ||
  //       opportunity.riskProfile === filterCriteria.riskProfile;

  //     const searchTextMatch =
  //       opportunity.title &&
  //       opportunity.title
  //         .toLowerCase()
  //         .includes(filterCriteria.searchText.toLowerCase());

  //     return isOpenMatch && riskProfileMatch && searchTextMatch;
  //   });

  //   return filteredOpportunities;
  // };

  // const updateFilterCriteria = (criteria, buttonKey) => {
  //   setFilterCriteria({ ...filterCriteria, ...criteria });
  //   setActiveButton(buttonKey);
  // };

  const pageCount = 1; //Math.ceil(opportunitiesData.length / 3);
  const [currentPage, setCurrentPage] = useState(1);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const handleFilter = () => {
  //   setFilterCriteria({ searchText: "", isOpen: "all", riskProfile: "all" });
  //   setActiveButton("all");
  // };

  return (
    <div className="w-full">
      <div
        style={{}}
        className="flex w-full justify-between items-center gap-y-3 flex-wrap mb-5 "
      >
        <div className="flex w-full flex-wrap gap-x-1 lg:gap-x-0 gap-y-2">
          <CustomButton
            variant="outlined"
            maxWidth={"60%"}
            customColor={activeButton === "all" ? "#159AA8" : "#fff"}
            children="All my Investments"
            width="180px"
            height="40px"
            padding="10px"
            className={
              "!border  lg:border-none lg:!rounded-tr-none lg:!rounded-br-none !rounded-lg"
            }
            borderColor={"#D0D5DD"}
            onClick={() => setActiveButton("all")}
            color={activeButton === "all" ? "#fff" : "#000"}
          />
          <CustomButton
            variant="outlined"
            customColor={activeButton === "open" ? "#159AA8" : "#fff"}
            children="Active Investments"
            width="180px"
            height="40px"
            padding="10px"
            custumStyle={{}}
            className={
              "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-tr-none lg:!rounded-bl-none lg:!rounded-br-none !rounded-lg"
            }
            onClick={() => setActiveButton("open")}
            color={activeButton === "open" ? "#fff" : "#000"}
            borderColor={"#D0D5DD"}
            // margin={"0px 10px"}
          />
          <CustomButton
            variant="outlined"
            customColor={activeButton === "paid out" ? "#159AA8" : "#fff"}
            children="Fully Paid Out Investments"
            width="250px"
            height="40px"
            padding="10px"
            custumStyle={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            className={
              "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
            }
            onClick={() => setActiveButton("paid out")}
            color={activeButton === "paid out" ? "#fff" : "#000"}
            borderColor={"#D0D5DD"}
          />
        </div>
      </div>

      {loading ? (
        <div className="w-full justify-center items-center flex min-h-[300px]">
          <Loader />
        </div>
      ) : opportunitiesData?.filter((item) => {
          if (activeButton === "all") {
            return true;
          } else if (activeButton === "open") {
            return !item?.paymentSchedules?.expected_date?.every(
              (paymentSchedule) => paymentSchedule?.status === "Paid"
            );
          } else if (activeButton === "paid out") {
            return item?.paymentSchedules?.expected_date?.every(
              (paymentSchedule) => paymentSchedule?.status === "Paid"
            );
          }
        })?.length < 1 ? (
        <div className="p-10">
          <NoData
            title={`No ${_currency === "$" ? "USD" : "Naira"} Investment Found`}
            subtitle="There are currently no investments in your portfolio."
            image="notransaction.png"
          />
        </div>
      ) : (
        <>
          <div className="grid min-h-[350px] grid-cols-1 md:grid-cols-3 gap-6">
            {opportunitiesData
              ?.filter((item) => {
                if (activeButton === "all") {
                  return true;
                } else if (activeButton === "open") {
                  return !item?.paymentSchedules?.expected_date?.every(
                    (paymentSchedule) => paymentSchedule?.status === "Paid"
                  );
                } else if (activeButton === "paid out") {
                  return item?.paymentSchedules?.expected_date?.every(
                    (paymentSchedule) => paymentSchedule?.status === "Paid"
                  );
                }
              })
              //  ?.slice((currentPage - 1) * 3, currentPage * 3)
              .map((opportunity) => (
                <OpportunityCard
                  hanldeClick={() => {
                    navigate("/portfolio/details", {
                      state: { opportunity },
                    });
                  }}
                  portfolio
                  key={opportunity.id}
                  opportunity={opportunity?.investment_product}
                />
              ))}
          </div>
          <div className="flex justify-between mt-10">
            <div className="mb-10 flex justify-center items-center w-full">
              <CustomPagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InvestmentOpportunities;
