import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/buttons/Button";
import { useFormik } from "formik";
import { signUpForTealValidationSchema } from "../../helper/validationSchemas";
import { CustomInputWithLabel } from "../../components/inputs/CustomInput";
import SEO from "../../config/Seo";
import TealAuth from "../../components/containers/TealAuth";
import PhoneInputField from "../../components/inputs/PhoneInputField";
import { registerTealUser } from "../../store/actions/onboarding/registerTealUser";

const RegisterTeal = () => {
  const dispatch = useDispatch();

  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgreementChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const handleRegister = (values, { setSubmitting }) => {
    dispatch(
      registerTealUser({
        payload: { ...values, phone_number: values?.phone },
        // payload: {},
        setSubmitting,
        errorCallBack: () => {},
      })
    );
  };

  const form = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phoneCode: "+234",
      phone_number: "",
    },
    onSubmit: handleRegister,
    validationSchema: signUpForTealValidationSchema,
  });

  return (
    <>
      <SEO
        title={"Join the Waitlist!"}
        description={
          "Let your money work harder for you With Halvest Savings & Ethical Investment opportunities."
        }
        name={"Halvest"}
        type={"article"}
      />
      <TealAuth>
        <div className="h-screen w-[100%]  flex flex-col">
          <div className="py-8  w-[100%]   m-auto laptop:w-[65%] px-8   flex-1 flex items-center justify-center ">
            <div className="w-full  min-h-[498px] flex flex-col ">
              <h2 className="  font-[600] w-full text-4xl text-text-color-1  leading-[44px] mt-8 mb-2   tracking-[1%]">
                Join the Waitlist
              </h2>

              <h4 className="  text-[#475467] text-[18px] tracking-loose font-[500] w-[99%] mb-8">
                Let your money work harder for you With Halvest Savings &
                Ethical Investment opportunities.
              </h4>

              <CustomInputWithLabel
                label={"First Name"}
                Required
                width="360px"
                height="18px"
                placeholder={"Your First Name"}
                borderColor="#D0D5DD"
                form={form}
                id="first_name"
              />
              <CustomInputWithLabel
                label={"Last Name"}
                Required
                width="360px"
                height="18px"
                placeholder={"Your Last Name"}
                borderColor="#D0D5DD"
                form={form}
                id="last_name"
              />

              <CustomInputWithLabel
                label={"Email"}
                Required
                width="360px"
                height="18px"
                placeholder={"Enter your email"}
                borderColor="#D0D5DD"
                form={form}
                id="email"
              />

              <h4 className="font-[500] text-sm leading-5">Phone Number*</h4>
              <div className="w-full mb-5 flex flex-col md:flex-row mt-1  justify-between ">
                <div className="flex flex-1 md:flex-auto md:w-[605px]">
                  <PhoneInputField
                    fullWidth
                    name={"phone"}
                    form={form}
                    id={"phone_number"}
                  />
                </div>
              </div>

              <div className="mt-2 mb-3 w-full ">
                <div className=" w-[95%] " id="terms">
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="checkbox"
                      type="checkbox"
                      checked={isAgreed}
                      onChange={handleAgreementChange}
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "16px",
                        marginRight: "8px",
                      }}
                    />
                    <h4 className="font-[500] text-sm text-[#344054] cursor-pointer ">
                      I have read and I agree to the{" "}
                      <a
                        rel="noreferrer"
                        href={"https://halvestco.com/Legal/Terms.docx.pdf"}
                        // download={`HALVEST TERMS`}
                        target="_blank"
                        className="text-[#159AA8]  text-sm "
                      >
                        Terms
                      </a>{" "}
                      &{" "}
                      <a
                        rel="noreferrer"
                        href={
                          "https://halvestco.com/Legal/Privacy%20Policy.docx.pdf"
                        }
                        // download={`HALVEST PRIVACY POLICY`}
                        target="_blank"
                        className="text-[#159AA8]  text-sm "
                      >
                        Privacy Policy
                      </a>
                    </h4>
                  </label>
                </div>
              </div>
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                loading={form.isSubmitting}
                width="100%"
                height="48px"
                padding="10px"
                margin="15px 0px"
                color="#fff"
                disabled={
                  !isAgreed ||
                  form.isSubmitting ||
                  !form.isValid ||
                  !form?.dirty
                }
                onClick={form.handleSubmit}
              >
                Join the waitlist now
              </CustomButton>
            </div>
          </div>
          <div className="flex justify-between items-center mx-4 py-7 ">
            <span>&copy; Halvest2024</span>
            <span className="flex items-center">
              <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
              support@halvestco.com
            </span>
          </div>
        </div>
      </TealAuth>
    </>
  );
};

export default RegisterTeal;
