import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutUser } from "../../reducers/onboardingSlice";

export const logout = createAsyncThunk(
  "users/logout",
  async (data, { dispatch }) => {
    localStorage.removeItem("accessToken");
    dispatch(logoutUser());
  }
);
