import React from "react";
import CustomButton from "../../components/buttons/Button";
import AuthVerify from "../../components/containers/AuthVerify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.onboarding.pending);

  return (
    <AuthVerify>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          widthL: "100%",
        }}
      >
        <div className="w-full max-w-[360px] px-6  m-auto laptop:w-[65%]">
          <div className="flex items-center flex-col ">
            <img
              width={56}
              height={56}
              src="/Assets/verify-success-icon.png"
              alt=""
            />
            <h1 className="mb-3 mt-5 font-[600] text-[#101828] text-[30px]">
              Password Changed
            </h1>
            <h6 className="w-[90%] font-[400] text-[#475467]  text-base  leading-6 text-center">
              Your password has been changed successfully, please login with
              your new password.
            </h6>
          </div>
          <div className="text-center">
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Back to Login"
              width="100%"
              height="40px"
              padding="10px"
              loading={loading}
              margin="32px 0px"
              color="#fff"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    </AuthVerify>
  );
};

export default ResetPasswordSuccess;
