import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_REGISTER_TEAL } from "../../../config/api";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const registerTealUser = createAsyncThunk(
  "users/registerTealUser",
  async ({ payload, setSubmitting, errorCallBack }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_REGISTER_TEAL,
        data: payload,
      });

      mixpanel.track("App-Joined-Waitlist", {});
      mixpanel.identify(data?.email);
      mixpanel.register({ email: data?.email });
      mixpanel.people.set({
        $name: `${data?.first_name} ${data?.last_name}`,
        $email: data?.email,
        $created: new Date(),
      });

      setSubmitting(false);
      toast.success(
        data?.message || data?.data?.message || "Error, please try again"
      );
    } catch (error) {
      const message = proccessErrorMessage(error);

      setSubmitting(false);
      toast.error(message || "Error, please try again");

      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "join-waitlist",
        message: message,
      });

      throw error;
    }
  }
);
