import React, { useCallback, useEffect } from "react";
import SelectField from "../inputs/SelectField";
import { useState } from "react";
import {
  investmentOptions,
  currencyOptions,
  statmentTypes,
} from "../../config/data";
import CustomButton from "../buttons/Button";
import HalDatePicker from "../DatePicker";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { accountStaementSchema } from "../../helper/validationSchemas";
import { generateAccountStatement } from "../../store/actions/statement/generateAccountStatement";
import ConfirmModal from "../modal/ConfirmModal";
import { showErrorModal } from "../../store/reducers/messageSlice";
import { useNavigate } from "react-router-dom";
import CreatePinModal from "../modal/CreatePinModal";
import { generateInvestmentStatement } from "../../store/actions/statement/generateInvestmentStatement";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";

function AccountStatement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statementType, setStatementType] = useState();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState("");
  const _currentDate = new Date();
  const _7daysAgo = new Date(_currentDate - 1000 * 60 * 60 * 24 * 7);

  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const closeCreatePinModal = () => {
    setIsPinModalOpen(false);
  };

  const user = useSelector((state) => state.onboarding?.user);
  const handleStatementChange = (event) => {
    setSelectedStatement(event.target.value);
  };

  const onSubmit = useCallback(() => {
    if (!user?.hasSetPin) {
      setIsPinModalOpen(true);
    } else if (user?.hasSetPin) {
      setIsConfirmModalOpen(true);
    }
  }, [user]);

  const form = useFormik({
    initialValues: {
      currency: "",
      statementType: {
        value: "Account Statement",
        label: "Account Statement",
      },
      start_date: dayjs(
        new Date(
          _7daysAgo?.getFullYear(),
          _7daysAgo?.getMonth(),
          _7daysAgo?.getDate()
        )
      ),
      end_date: dayjs(
        new Date(
          _currentDate?.getFullYear(),
          _currentDate.getMonth(),
          _currentDate.getDate()
        )
      ),
    },
    validationSchema: accountStaementSchema,
    onSubmit,
  });

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirm = useCallback(
    (pin) => {
      setSubmitting(true);
      const { currency, start_date, end_date, statementType } = form.values;
      const data = {
        currency: currency?.value,
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        transaction_pin: pin,
      };

      if (statementType?.value === "Account Statement") {
        dispatch(
          generateAccountStatement({
            data,
            setSubmitting,
            cb: () => {
              setSubmitting(false);
            },
          })
        );
      } else if (statementType?.value === "Investment Statement") {
        dispatch(
          generateInvestmentStatement({
            data,
            setSubmitting,
            cb: () => {
              setSubmitting(false);
            },
          })
        );
      }
    },
    [form.values]
  );

  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Account-Statement", {
        user: user,
      });
    }
  }, [user]);

  return (
    <div>
      <div className="mb-20 ">
        <div className="mb-4 pb-6 border-b border-b-[#EAECF0] px-1">
          <h1 className="font-[600] text-2xl lg:text-[30px] lg:leading-[38px] mb-1 text-[#101828]">
            Request Statement
          </h1>
          <p className="text-sm lg:text-base mt-2 font-[400] text-[#475467}">
            {`Your statement will be forwarded to your email address
            ${user?.email?.slice(0, 1)}***@*****.com in few minutes.`}
          </p>
        </div>

        <div className="w-full px-3 mt-2 flex flex-col lg:flex-row justify-between   mb-10">
          <label className="font-semibold ">Statement Type</label>
          <div className="flex w-full lg:w-[650px]">
            <div className="flex max-w-full w-[300px]">
              <SelectField
                id="statementType"
                options={statmentTypes}
                form={form}
                sx={{ width: "100%", height: "52px" }}
              />
            </div>
          </div>
        </div>
        <div className="w-full px-3 mt-2 flex flex-col lg:flex-row justify-between   mb-10">
          <label className="font-semibold ">Currency</label>
          <div className="flex w-full lg:w-[650px]">
            <div className="flex max-w-full w-[300px]">
              <SelectField
                id="currency"
                options={currencyOptions}
                form={form}
                sx={{ width: "100%", height: "52px" }}
              />
            </div>
          </div>
        </div>
        {/* {form?.values?.statementType?.value === "Account Statement" ? ( */}
        <div className="flex px-3  flex-col lg:flex-row justify-between">
          <label className="font-semibold">Date</label>
          <div className="flex items-center flex-row gap-3 w-full lg:w-[650px]">
            <HalDatePicker
              id={"start_date"}
              height={50}
              disabled={true}
              form={form}
              label="Date business started"
              rightImage
            />

            <AiOutlineMinus className="" />
            <HalDatePicker
              id={"end_date"}
              height={50}
              disabled={true}
              form={form}
              label="Date business started"
              rightImage
            />
          </div>
        </div>
        {/* ) : (
          <></>
        )} */}
        <div className="flex px-3  items-center w-full justify-end mt-5">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Request Statement"
            width="200px"
            height="45px"
            disabled={!form.isValid || !form.dirty || submitting}
            padding="10px"
            margin="5px 0"
            color="#fff"
            loading={submitting}
            onClick={form?.handleSubmit}
          />
        </div>
      </div>
      <CreatePinModal open={isPinModalOpen} onClose={closeCreatePinModal} />
      <ConfirmModal
        title={""}
        subTitle={"Please enter your 4 digit transaction pin"}
        open={isConfirmModalOpen}
        onConfirm={handleConfirm}
        onClose={closeConfirmModal}
      />
    </div>
  );
}

export default AccountStatement;
