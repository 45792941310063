import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import {
  API_GET_ALL_PACKAGES,
  API_SUBSCRIBE_PACKAGE,
} from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";
import { logout } from "../user/logout";
import mixpanel from "mixpanel-browser";

export const getPackages = createAsyncThunk(
  "packages/getPackages",
  async (data, { dispatch }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_ALL_PACKAGES,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "subscription",
        subtype: "get-packages",
        message: msg,
      });
      if (
        error?.response?.data?.message === "Token is Invalid" ||
        error?.response?.data?.message === "Authentication required."
      ) {
        dispatch(logout());
      }

      throw error;
    }
  }
);
export const subscribe = createAsyncThunk(
  "packages/postPackages",
  async ({ data: payload, cb }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_SUBSCRIBE_PACKAGE}${payload?.id}`,
      });
      cb(data);
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "subscription",
        subtype: "subscribe",
        message: msg,
      });
      dispatch(
        showErrorModal(
          msg || error?.response?.data?.message || "An error has occurred"
        )
      );
      throw error;
    }
  }
);
