import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CustomButton from "../buttons/Button";
import { useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

function ConfirmModal({ open, title, subTitle, onClose, onConfirm }) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleVerify = (_otp) => {
    onConfirm(_otp);
    setOtp("");
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 500,
          maxWidth: "90%",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className="flex flex-col items-center">
          <img width={48} height={48} src="/Assets/input-pin.png" alt="" />

          <div className="flex flex-col">
            <span className="text-[18px] font-[600] text-center my-3">
              {title || "Confirm Transaction"}
            </span>
            <span className="text-center font-[400] text-[12px] text-[#475467]">
              {subTitle ||
                "Please enter your 4 digit transaction pin to confirm your transaction"}
            </span>
          </div>

          <div>
            <OTPInput
              value={otp}
              onChange={setOtp}
              inputType="password"
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              placeholder="----"
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                marginTop: "34px",
              }}
              inputStyle={{
                width: "50px",
                height: "50px",
                border: "1.5px solid #159AA8",
                borderRadius: "10px",
                fontWeight: "bold",
                color: "#159AA8",
                fontSize: "20px",
                marginLeft: "6px",
                marginRight: "6px",
              }}
            />
            <span className="opacity-0 text-center ml-[5px] font-[400] text-[12px] text-[#475467]">
              Forgot Pin?{" "}
            </span>
            <h4 onClick={() => navigate("/settings/security")}>
              <span className="opacity-0 text-center font-[400] text-[12px] text-[#475467]">
                Click to reset
              </span>
            </h4>
          </div>
        </div>

        <div className="mt-[30px] flex items-center">
          <CustomButton
            variant="outlined"
            customColor="#fff"
            children="Cancel"
            width="150px"
            height="40px"
            padding="10px"
            color="#000"
            onClick={onClose}
          />
          <div className="ml-[15px] w-full">
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Verify"
              disabled={otp?.length < 4}
              width="100%"
              height="40px"
              padding="10px"
              color="#fff"
              onClick={() => {
                handleVerify(otp);
              }}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
