import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CustomButton from "../buttons/Button";
import ConfirmModal from "./ConfirmModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { submitInvestment } from "../../store/actions/investment/submitinvestment";
import { useParams, useNavigate } from "react-router";
import CustomInput from "../inputs/CustomInput";
import { showErrorModal } from "../../store/reducers/messageSlice";
import { getBalance } from "../../store/actions/dashboard/manageFunds";
import CreatePinModal from "./CreatePinModal";
import mixpanel from "mixpanel-browser";

const InterestModal = ({
  open,
  onClose,
  submitInterest,
  openInvestmentModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.onboarding?.user);
  const [amount, setAmount] = useState("");
  const [_currency, getAmount] = useCurrency();

  const {
    createInterestPending,
    opportunity,
    pending: loading,
    submitInterestPending,
    investNowPending,
  } = useSelector((state) => state.opportunities);

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const balance = _currency === "$" ? dollarBalance : walletBalance;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxWidth: "90%",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Avatar src="/Assets/userIcon.png" alt="User" />

          {/* <h1 className="font-[600] text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">  <p className="text-sm lg:text-base font-[400] text-[#475467}"></p> */}

          <Typography
            variant="h6"
            style={{
              flex: 1,
              fontWeight: "600",
              color: "#101828",
              fontSize: "18px",
              // fontFamily: "TTI",
              fontFamily: "Inter",
              textAlign: "center",
            }}
          >
            Submit your interest in {opportunity?.name}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon
              style={{
                color: "#98A2B3",
                fontSize: "24px",
              }}
              fontSize="24px"
            />
          </IconButton>
        </Box>
        <hr />

        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          <p className="text-base lg:text-2xl font-500] text-[#475467}">
            Amount
          </p>

          <div className="w-full mb-4  ">
            <CustomInput
              fullWidth
              noBorder
              bg={true}
              height={40}
              autoFocus={true}
              placeholder={`${_currency}0.00`}
              customTextStyle={{}}
              textInputCustomStyles={{
                textAlign: "center",
                fontSize: "44px",
                color: "101828",
              }}
              variant={"standard"}
              selfValue={amount}
              prefix={_currency}
              customType="amount"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
        </Box>

        <div className="flex items-center ">
          <CustomButton
            variant="outlined"
            // customColor="#fff"
            customColor="#fff"
            borderColor="#159AA8"
            color="#159AA8"
            children={
              <h3 className="text-[10px] sm:text-xs  text-[#159AA8] font-[500] ">
                Submit and Invest Later
              </h3>
            }
            width="50%"
            height="40px"
            padding="10px"
            margin="0px 0px 15px 0px"
            // color="#000"
            // disabled={!amount || amount > Number(opportunity?.leftover_amount)}
            loading={submitInterestPending}
            disabled={
              !amount || loading || submitInterestPending || investNowPending
            }
            onClick={() => {
              if (Number(amount) > Number(opportunity?.leftover_amount)) {
                // dispatch(showErrorModal("The amount submitted is more than the maximum investment. "));
                dispatch(
                  showErrorModal(
                    "The amount submitted is more than the units left. "
                  )
                );
              } else if (Number(amount) < Number(opportunity?.minimum_amount)) {
                dispatch(
                  showErrorModal(
                    "The amount submitted is less than the minimum investment. "
                  )
                );
              } else {
                mixpanel.track(`Submit and Invest Later-Clicked`, {
                  user: user,
                });
                submitInterest(amount);
              }
            }}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children={
              <h3 className="text-[10px] sm:text-xs  text-[#fff] font-[500] ">
                Submit and Invest Now
              </h3>
            }
            loading={investNowPending}
            disabled={
              !amount || loading || submitInterestPending || investNowPending
            }
            width="50%"
            height="40px"
            padding="10px"
            margin="0px 0px 15px 15px"
            color="#fff"
            onClick={() => {
              if (Number(amount) > Number(opportunity?.leftover_amount)) {
                // dispatch(showErrorModal("The amount submitted is more than the maximum investment. "));
                dispatch(
                  showErrorModal(
                    "The amount submitted is more than the units left."
                  )
                );
              } else if (Number(amount) < Number(opportunity?.minimum_amount)) {
                dispatch(
                  showErrorModal(
                    "The amount submitted is less than the minimum investment. "
                  )
                );
              } else {
                mixpanel.track(`Submit and Invest Now-Clicked`, {
                  user: user,
                });

                openInvestmentModal(amount);
              }
            }}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default InterestModal;
