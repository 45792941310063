import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONTACT } from "../../../config/api";
import mixpanel from "mixpanel-browser";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const support = createAsyncThunk(
  "support/post",
  async (payload, { dispatch }) => {
    try {
      await Client({
        method: "POST",
        path: API_CONTACT,
        data: payload.data,
      });

      dispatch(
        showSuccessModal(
          "Message sent successfully We will get back to you as soon as possible"
        )
      );
      mixpanel.track("Success", {
        type: "Support",
        subtype: "Support",
      });
      payload.cb();
    } catch (error) {
      payload.failed();
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Support",
        subtype: "Support",
        message: msg,
      });
      dispatch(showErrorModal(msg));
      payload.setSubmitting(false);
    }
  }
);
