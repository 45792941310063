import React, { useMemo } from "react";
import CustomAccordion from "../accordion/CustomAccordion";
import { useState } from "react";
import CustomButton from "../buttons/Button";
import InvestModal from "../modal/InvestModal";
import { useDispatch, useSelector } from "react-redux";
import { showErrorModal } from "../../store/reducers/messageSlice";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import InterestModal from "../modal/InterestModal";
import { submitInterest } from "../../store/actions/investment/submitInterest";
import { investNow } from "../../store/actions/investment/investNow";
import mixpanel from "mixpanel-browser";

function Summary({ scrollToTerms, opportunity }) {
  const { interestDetails } = useSelector((state) => state.opportunities);
  const user = useSelector((state) => state.onboarding?.user);

  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [isAgreed, setIsAgreed] = useState(
    false ||
      interestDetails?.investment_product_id == opportunity?.id ||
      opportunity?.interested?.find((i) => i?.user_id == user?.id)
  );
  const handleAgreementChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  const accordionData = useMemo(
    () => [
      {
        title: "Campaign Description",
        content: opportunity?.description,
      },
    ],
    [opportunity]
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const closeInvestModal = () => {
    mixpanel.track(`Investment-Modal-Closed`, {
      user: user,
    });
    setIsInvestModalOpen(false);
  };

  const handleSubmitInterest = (amount, id) => {
    dispatch(
      submitInterest({
        data: { amount: Number(amount), investment_product_id: id },
        cb: () => {
          mixpanel.track("Submitted-Interest", {
            opportunity: opportunity?.name,
          });
          setIsInterestModalOpen(false);
          setShowModal(true);
        },
        failed: () => {},
      })
    );
  };
  const openInvestmentModal = (amount, id) => {
    dispatch(
      investNow({
        data: { amount: Number(amount), investment_product_id: id },
        cb: () => {
          setIsInterestModalOpen(false);
          setIsInvestModalOpen(true);
        },
        failed: () => {},
      })
    );
  };

  const handleInvestClick = () => {
    if (!isAgreed) {
      dispatch(showErrorModal("Please accept the terms of the investment"));
      scrollToTerms();
    } else {
      mixpanel.track(`Interest-Button-Clicked`, {
        user: user,
      });
      setIsInterestModalOpen(true);
    }
  };
  const handleInvestNowClick = () => {
    if (!isAgreed) {
      dispatch(showErrorModal("Please accept the terms of the investment"));
      scrollToTerms();
    } else {
      mixpanel.track(`Investment-Button-Clicked`, {
        user: user,
      });

      setIsInvestModalOpen(true);
    }
  };

  return (
    <div>
      {accordionData.map((data, index) => (
        <CustomAccordion
          key={index}
          title={data.title}
          content={data.content}
          image={data.image}
          opportunity={opportunity}
          investmentCategory={opportunity?.investment_category?.name}
        />
      ))}
      <div className="mt-5 mb-3 " id="terms">
        <label style={{ display: "flex", alignItems: "center" }}>
          <input
            id="checkbox"
            type="checkbox"
            checked={isAgreed}
            onChange={handleAgreementChange}
            style={{ width: "24px", height: "24px", marginRight: "10px" }}
          />
          <span style={{ margin: "10px 10px" }}>
            I have read the memo and I agree to the{" "}
            <a
              rel="noreferrer"
              href={
                "https://halvestco.com/Legal/INVESTMENT%20PLATFORM%20AGREEMENT.docx.pdf"
              }
              // download={`HALVEST INVESTMENT PLATFORM AGREEMENT`}
              target="_blank"
              // onClick={() => setShowTermsModal(true)}
              style={{ color: "#159AA8", cursor: "pointer" }}
            >
              Terms & Conditions
            </a>
          </span>
        </label>
      </div>

      <div className="flex flex-col lg:flex-row gap-4 justify-between w-full  lg:justify-end ">
        <div className=" w-full lg:w-[280px]">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children={
              interestDetails?.investment_product_id == opportunity?.id ||
              opportunity?.interested?.find((i) => i?.user_id == user?.id)
                ? "Invest Now"
                : "Invest"
            }
            width="100%"
            height="40px"
            padding="10px"
            color="#fff"
            onClick={
              interestDetails?.investment_product_id == opportunity?.id ||
              opportunity?.interested?.find((i) => i?.user_id == user?.id)
                ? handleInvestNowClick
                : handleInvestClick
            }
          />
        </div>
      </div>
      <InterestModal
        open={isInterestModalOpen}
        onClose={() => {
          mixpanel.track(`Interest-Modal-Closed`, {
            user: user,
          });
          setIsInterestModalOpen(false);
        }}
        isAgreed={isAgreed}
        setIsAgreed={setIsAgreed}
        submitInterest={(amount) =>
          handleSubmitInterest(amount, opportunity?.id)
        }
        openInvestmentModal={(amount) =>
          openInvestmentModal(amount, opportunity?.id)
        }
      />
      <InvestModal open={isInvestModalOpen} onClose={closeInvestModal} />
      <ResponseMessageModal
        open={showTermsModal}
        onClose={handleCloseTermsModal}
        handleContinue={() => {
          handleCloseTermsModal();
        }}
        subTextType={"justify"}
        type={3}
        width={600}
        btnTitle={"Ok"}
        mainText={"Terms & Conditions"}
        subText={
          "To enhance the security and efficiency of your investment transactions, we have partnered with a regulated bank in Nigeria to offer virtual accounts for funding investments and receiving payments on our portal. To align with banking regulations in Nigeria, we kindly request that you provide your Bank Verification Number (BVN) to complete your onboarding process. Rest assured, your information is handled with utmost confidentiality in line with our data privacy policy."
        }
      />
      <ResponseMessageModal
        type={1}
        open={showModal}
        onClose={handleCloseModal}
        handleContinue={handleCloseModal}
        btnTitle={"Ok"}
        mainText={"Thank you for submiting your interest."}
        subText={
          "Kindly ensure that you complete investment before the funding goal is met or before the campaign due date."
        }
      />
    </div>
  );
}

export default Summary;
