import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { height } from "@mui/system";

const HalDatePicker = ({
  label,
  rightImage,
  form,
  id,
  disabled,
  width,
  setActiveInput,
  height,
}) => {
  const handleDateChange = (date) => {
    form?.setFieldValue(id || "startedDate", date);
    setActiveInput && setActiveInput("others");
  };

  const CustomDatePicker = ({
    setOpen,
    label,
    id,
    value,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  }) => {
    const processCalenderDate = () => {
      const _fullDate = new Date(value) ? new Date(value) : new Date();
      const _date = _fullDate.getDate();
      const _month = _fullDate.getMonth() + 1;
      const _year = _fullDate.getFullYear();

      return `${_date < 10 ? "0" : ""}${_date}/${
        _month < 10 ? "0" : ""
      }${_month}/${_year}`;
    };
    return (
      <>
        <div
          id={id}
          disabled={disabled}
          ref={ref}
          aria-label={ariaLabel}
          onClick={() => setOpen((prev) => !prev)}
          style={{
            width: width || "131px",
            height: height || "40px",
          }}
          className="border border-[#D0D5DD] rounded-[8px] flex items-center justify-between py-[10px] px-[14px] "
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              //   width: "10%",
            }}
          >
            {rightImage && (
              <img
                src="/Assets/Calendar.png"
                alt="calender"
                className="w-4  h-4 object-contain"
              />
            )}
          </div>
          <div>
            <div className=" w-full h-full ">
              <h4 className="text-sm font-[500] text-[#101828]" htmlFor="date">
                {processCalenderDate()}
              </h4>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Inner = (props) => {
    const [open, setOpen] = React.useState(false);
    return (
      <div>
        <DatePicker
          slotProps={{ field: { setOpen } }}
          {...props}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          className="h-[158px] bg-[red]"
          sx={{ border: "none" }}
          slots={{
            field: CustomDatePicker,
          }}
        />
        {form?.errors[id] && (
          <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
            {form?.errors[id]}
          </div>
        )}
      </div>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Inner
        label={label}
        value={form?.values[id]}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
};

export default HalDatePicker;
