import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/actions/onboarding/resetPassword";
import {
  pinSchema,
  resetPasswordSchema,
  setPasswordSchema,
  setPinSchema,
} from "../../helper/validationSchemas";
import { useFormik } from "formik";
import { resetTransactionPin } from "../../store/actions/onboarding/resetTransactionPin";
import ConfirmModal from "../modal/ConfirmModal";
import { getSecurityPasswordOTP } from "../../store/actions/onboarding/getSecurityPasswordOTP";
import { getUpdateSecurityPINOTP } from "../../store/actions/onboarding/getUpdateSecurityPINOTP";
import { getCreateSecurityPINOTP } from "../../store/actions/onboarding/getCreateSecurityPINOTP";
import ForgotPinModal from "../modal/ForgotPinModal";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

function Security() {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  // const [passwordLoading, setPasswordLoading] = useState(false);
  const [updatingWhich, setUpdatingWhich] = useState(null);

  const navigate = useNavigate();

  const {
    user,
    getingOTP,
    resetPasswordPending: loading,
    pinLoading,
  } = useSelector((state) => state.onboarding);

  const form = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      new_confirm_password: "",
    },
    validationSchema:
      user && user?.hasPassword ? resetPasswordSchema : setPasswordSchema,
  });

  const handleSubmit = ({ type }) => {
    const data = { email: user?.email };

    if (type === "pin") {
      dispatch(
        getUpdateSecurityPINOTP({
          data,
          cb: () => {
            setUpdatingWhich(type);
            setIsConfirmModalOpen(true);
          },
        })
      );
    } else if (type === "new-pin") {
      dispatch(
        getCreateSecurityPINOTP({
          data,
          cb: () => {
            setUpdatingWhich(type);
            setIsConfirmModalOpen(true);
          },
        })
      );
    } else {
      dispatch(
        getSecurityPasswordOTP({
          data,
          cb: () => {
            setUpdatingWhich(type);
            setIsConfirmModalOpen(true);
          },
        })
      );
    }
  };

  const PinForm = useFormik({
    initialValues: {
      current_pin: "",
      new_pin: "",
      confirm_pin: "",
    },
    validationSchema: user?.hasSetPin ? pinSchema : setPinSchema,
    onSubmit: user?.hasSetPin
      ? () => handleSubmit({ type: "pin" })
      : () => handleSubmit({ type: "new-pin" }),
  });

  const closeConfirmModal = () => {
    if (updatingWhich === "pin") {
      PinForm?.setSubmitting(false);
      PinForm.resetForm();
    } else if (updatingWhich === "password") {
      form.resetForm();
    }
    setIsConfirmModalOpen(false);
  };

  const closeCreatePinModal = () => {
    setIsPinModalOpen(false);
  };

  const handleConfirm = useCallback(
    (otp) => {
      if (updatingWhich === "pin") {
        const { current_pin, new_pin } = PinForm?.values;
        const payload = {
          current_pin,
          transaction_pin: new_pin,
          otp: otp,
        };
        dispatch(
          resetTransactionPin({
            payload,
            cb: () => {
              // PinForm?.setSubmitting(false);
              PinForm.resetForm();
            },
            resetForm: () => PinForm.resetForm(),
            // setSubmitting: () => PinForm?.setSubmitting(false),
          })
        );
      } else if (updatingWhich === "password") {
        const payload = {
          ...form.values,
          otp: otp,
        };
        dispatch(resetPassword({ data: payload }));
        form.resetForm();
      } else if (updatingWhich === "new-pin") {
        const { new_pin } = PinForm?.values;

        const payload = {
          transaction_pin: new_pin,
          otp: otp,
        };
        dispatch(
          resetTransactionPin({
            payload,
            cb: () => {
              PinForm.resetForm();
            },

            resetForm: () => PinForm.resetForm(),
          })
        );
      }
    },
    [PinForm, form, dispatch, updatingWhich]
  );

  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Security", {
        user: user,
      });
    }
  }, [user]);

  return (
    <div>
      <div>
        <div className="flex justify-between mb-5">
          <div>
            <h1 className="font-[600]  text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">
              Security
            </h1>
            <p className="text-sm lg:text-base font-[400] text-[#475467}"></p>
          </div>
        </div>

        <div className="py-7 lg:py-12 border-y border-y-border-color-1 ">
          <h1 className="font-[600] text-[18px] mb-1 text-[#101828] ">
            Password
          </h1>
          <p className="text-sm font-[400] text-[#475467]">
            Please enter your current password to change your password.
          </p>
        </div>

        {user && user?.hasPassword ? (
          <div className="flex justify-between sm:pr-4  flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
            <label className="text-sm font-[600] text-[#344054]">
              Current Password
            </label>
            <div className="w-full lg:w-[400px]">
              <CustomInput
                width="100%"
                height="8px"
                placeholder={"********"}
                borderColor="#D0D5DD"
                pt="8px"
                id={"current_password"}
                form={form}
                type="password"
                mt="10px"
              />
              <p className="text-xs text-end mt-1 ">
                Forgot Password?{" "}
                <span
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="text-[#159AA8] cursor-pointer"
                >
                  Reset here
                </span>{" "}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="flex justify-between sm:pr-4 flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
          <label className="text-sm font-[600] text-[#344054]">
            New password
          </label>
          <div className="w-full lg:w-[400px]">
            <CustomInput
              width="100%"
              height="8px"
              placeholder={"********"}
              borderColor="#D0D5DD"
              form={form}
              id={"new_password"}
              type="password"
              pt="8px"
              mt="10px"
            />
          </div>
        </div>
        <div className="flex justify-between sm:pr-4 flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
          <label className="text-sm font-[600] text-[#344054]">
            Confirm new password
          </label>
          <div className="w-full lg:w-[400px]">
            <CustomInput
              width="100%"
              height="8px"
              placeholder={"********"}
              borderColor="#D0D5DD"
              type="password"
              form={form}
              id={"new_confirm_password"}
              pt="8px"
              mt="10px"
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 sm:pr-4 mt-5 mb-10 lg:flex-row justify-end">
          <CustomButton
            variant="outlined"
            customColor="#fff"
            children="Cancel"
            minWidth="100px"
            height="45px"
            padding="10px"
            color="#000"
            //  onClick={onClose}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children={
              user && user?.hasPassword ? "Update Password" : "Set Password"
            }
            minWidth="200px"
            height="45px"
            padding="10px"
            color="#fff"
            loading={(form.isValid && form?.dirty && getingOTP) || loading}
            disabled={
              !form.dirty ||
              !form.isValid ||
              (form.isValid && getingOTP) ||
              loading
            }
            onClick={() => handleSubmit({ type: "password" })}
          />
        </div>
      </div>
      <div>
        <div className="mb-4">
          <h1 className="font-[600] text-[18px] mb-1 text-[#101828] ">
            Transaction PIN
          </h1>
          <p className="text-sm font-[400] text-[#475467]">
            Please enter your current PIN to change your PIN.
          </p>
        </div>

        {user?.hasSetPin ? (
          <div className="flex justify-between sm:pr-4 flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
            <label className="text-sm font-[600] text-[#344054]">
              Current Transaction PIN
            </label>
            <div className="w-full  lg:w-[400px]">
              <CustomInput
                width="100%"
                height="8px"
                secured
                placeholder={"****"}
                borderColor="#D0D5DD"
                pt="8px"
                mt="10px"
                form={PinForm}
                id={"current_pin"}
                type="number"
              />
              <p className="text-xs text-end mt-1 ">
                Forgot Pin?{" "}
                <span
                  onClick={() => {
                    setIsPinModalOpen(true);
                  }}
                  className="text-[#159AA8] cursor-pointer"
                >
                  Reset here
                </span>{" "}
              </p>
            </div>
          </div>
        ) : null}
        <div className="flex justify-between sm:pr-4 flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
          <label className="text-sm font-[600] text-[#344054]">
            Enter new Transaction PIN
          </label>
          <div className="w-full lg:w-[400px]">
            <CustomInput
              width="100%"
              height="8px"
              placeholder={"****"}
              borderColor="#D0D5DD"
              pt="8px"
              mt="10px"
              secured
              form={PinForm}
              id={"new_pin"}
              type="number"
            />
            {/* <p className="text-xs mt-[1px] ml-[3px]">Enter a 4-digit pin</p> */}
          </div>
        </div>
        <div className="flex justify-between sm:pr-4 flex-col lg:flex-row py-5 border-b border-b-border-color-1 ">
          <label className="text-sm font-[600] text-[#344054]">
            Confirm Transaction PIN
          </label>
          <div className="w-full lg:w-[400px]">
            <CustomInput
              width="100%"
              height="8px"
              secured
              placeholder={"****"}
              form={PinForm}
              id={"confirm_pin"}
              type="number"
              borderColor="#D0D5DD"
              pt="8px"
              mt="10px"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 sm:pr-4 mt-5 mb-10 lg:flex-row  justify-end">
        <CustomButton
          variant="outlined"
          customColor="#fff"
          children="Cancel"
          minWidth="100px"
          // className={"w-full lg:w-[100px]"}
          height="45px"
          padding="10px"
          color="#000"
          //  onClick={onClose}
        />
        <CustomButton
          variant="contained"
          customColor="#159AA8"
          minWidth="200px"
          children={user?.hasSetPin ? "Update Pin" : "Create Pin"}
          height="45px"
          padding="10px"
          color="#fff"
          // loading={PinForm?.isSubmitting}
          loading={
            (PinForm.isValid && PinForm?.dirty && getingOTP) || pinLoading
          }
          disabled={
            !PinForm?.dirty ||
            !PinForm?.isValid ||
            (form.isValid && getingOTP) ||
            pinLoading
          }
          onClick={PinForm.handleSubmit}
        />
      </div>
      <ForgotPinModal open={isPinModalOpen} onClose={closeCreatePinModal} />
      <ConfirmModal
        title={""}
        subTitle={"Please enter the OTP sent to your email"}
        open={isConfirmModalOpen}
        onConfirm={handleConfirm}
        onClose={closeConfirmModal}
      />
    </div>
  );
}

export default Security;
