import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_PIN } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import {
  closeModal,
  showErrorModal,
  showSuccessModal,
} from "../../reducers/messageSlice";
import { fetchUserProfile } from "../profile/getProfile";
import mixpanel from "mixpanel-browser";

export const resetTransactionPin = createAsyncThunk(
  "user/resetTransactionPin",
  async ({ payload, cb, setSubmitting, resetForm }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_PIN,
        data: payload,
      });
      dispatch(fetchUserProfile());

      cb();

      dispatch(
        showSuccessModal(
          payload?.current_pin
            ? "Transaction Pin Updated Successfully"
            : "Transaction Pin Created Successfully"
        )
      );
      setTimeout(() => {
        dispatch(closeModal());
      }, 3000);
      mixpanel.track("Success", {
        type: "Onboarding",
        subtype: "update-PIN",
      });
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      // setSubmitting(false);
      resetForm();
      dispatch(showErrorModal(msg || "Transaction Pin failed"));
      mixpanel.track("Error", {
        type: "Onboarding",
        subtype: "update-PIN",
        message: msg,
      });

      setTimeout(() => {
        dispatch(closeModal());
      }, 3000);
      throw error;
    }
  }
);
