import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import NotificationAccordion from "../../components/accordion/NotificationAccordion";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifications } from "../../store/actions/notification/getAllNotifications";
import { IoArrowForward } from "react-icons/io5";
import { timeformatter } from "../../config/dateformatter";
import { clearAllNotifications } from "../../store/actions/notification/clearAllNotifications";
import NoData from "../../components/cards/NoData";
import { clearReadNotifications } from "../../store/reducers/notificationsSlice";
import { readNotification } from "../../store/actions/notification/readNotification";
import CustomButton from "../../components/buttons/Button";
import { readNotifications } from "../../store/actions/notification/readNotifications";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { deleteNotifications } from "../../store/actions/notification/deleteNotifications";
import { Client } from "../../client";
import { toast } from "react-toastify";

function Notification() {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Unread");
  const [fetchedDataUnRead, setFetchedDataUnRead] = useState(null);
  const [fetchedDataAll, setFetchedDataAll] = useState(null);
  const [loadingRead, setLoadingRead] = useState(false);
  const [loadingAllRead, setLoadingAllRead] = useState(false);
  const [selected, setSelected] = useState([]);

  const { allNotifications, unreadNotifications, pending } = useSelector(
    (state) => state?.notifications
  );

  const allNotificationsPageInfo = useMemo(
    () => allNotifications,
    [allNotifications]
  );
  const unReadNotificationsPageInfo = useMemo(
    () => unreadNotifications,
    [unreadNotifications]
  );
  // const unreadNotifications = useSelector((state) => state?.unreadNotifications);

  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);

  const handleClearNotification = () => {
    dispatch(clearAllNotifications());
  };

  const read = (notification) => {
    setLoadingRead(notification.id);

    dispatch(
      readNotification({
        notificationId: notification.id,
        cb: () => {
          // dispatch(getAllNotifications());
          setLoadingRead(false);
        },
      })
    );
  };

  const handleRenderNotification = useCallback(
    (n, ind) => {
      return (
        <div key={ind} className="w-full flex-1 relative gap-5 ">
          <div className="border-b border-b-[#DADADA]  pb-3 pt-4 flex gap-2">
            <div className="sm:w-[85%]   justify-between flex gap-3 sm:gap-5 items-center ">
              <div
                onClick={() => {
                  setSelected((prev) => {
                    if (
                      prev.find((s) => s === n?.id) ||
                      prev.find((s) => s === n?.id) === 0
                    ) {
                      return prev?.filter((s) => s !== n?.id);
                    } else {
                      return [...prev, n?.id];
                    }
                  });
                }}
                className={`flex justify-center cursor-pointer items-center `}
              >
                {selected.find((s) => s === n?.id) ||
                selected.find((s) => s === n?.id) === 0 ? (
                  <MdCheckBox size={20} color="#159AA8" />
                ) : (
                  <MdCheckBoxOutlineBlank size={20} color="#101828" />
                )}
              </div>
              <div className="w-[75%] ">
                {n?.created_at ? (
                  <h3
                    className={`${
                      n?.is_read === "0" ? "text-[#159AA8]" : "text-[#475467]"
                    }    text-sm font-[500]`}
                  >
                    {timeformatter(n?.created_at, "formal", true, true)}
                  </h3>
                ) : (
                  <></>
                )}
                <h4
                  className={`${
                    n?.is_read === "0" ? "text-[#159AA8]" : "text-[#475467]"
                  } font-[600] text-base`}
                >
                  {n?.title ? `${n?.title} : ` : ""}
                  <span
                    className={`${
                      n?.is_read === "0" ? "text-[#159AA8]" : "text-[#475467]"
                    } text-[15px]`}
                  >
                    {n?.message}
                  </span>
                </h4>
              </div>
              {n?.is_read === "0" ? (
                <div
                  // key={ind}
                  onClick={() => read(n)}
                  className="w-full flex-1 relative gap-5 cursor-pointer"
                >
                  <h4 className={`text-sm ${"text-[#101828]"}`}>Clear</h4>
                </div>
              ) : (
                <div className="w-full flex-1"></div>
              )}
            </div>
          </div>
          {loadingRead === n?.id ? (
            <div className="absolute top-0 w-full h-full bg-[rgba(246,248,250,0.2)] flex justify-center items-center">
              <img
                width="24px"
                height="24px"
                alt="Button Icon"
                src={"/Assets/loading2.gif"}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
    [loadingRead, selected]
  );

  const handleDeleteSelected = useCallback(() => {
    setLoadingAllRead(true);
    dispatch(
      deleteNotifications({
        selected: selected,
        cb: () => {
          setLoadingAllRead(false);
        },
      })
    );
  }, [selected]);
  const handleClearSelected = useCallback(() => {
    setLoadingAllRead(true);
    dispatch(
      readNotifications({
        selected: selected,
        cb: () => {
          setLoadingAllRead(false);
        },
      })
    );
  }, [selected]);

  const loadNewData = async ({ url, type }) => {
    try {
      setLoadingAllRead(true);

      const { data } = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });
      if (type === "all") {
        setFetchedDataAll((prev) => [...prev, ...data?.data]);
      } else {
        setFetchedDataUnRead((prev) => [...prev, ...data?.data]);
      }

      setLoadingAllRead(false);
    } catch (error) {
      setLoadingAllRead(false);
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (allNotificationsPageInfo?.total) {
      setFetchedDataAll(allNotificationsPageInfo?.data);
    }
  }, [allNotificationsPageInfo]);

  useEffect(() => {
    if (unReadNotificationsPageInfo?.total) {
      setFetchedDataUnRead(unReadNotificationsPageInfo?.data);
    }
  }, [unReadNotificationsPageInfo]);

  return (
    <DashboardLayout>
      <div className="px-6 lg:px-8  h-full w-full pt-4 lg:pr-14 pb-10">
        <div className="flex   justify-between items-center">
          <h1 className="mt-2 mb-3  font-[600] text-[30px]">Notifications</h1>
        </div>
        {loadingAllRead || pending ? (
          <div className=" h-[500px] w-full  flex justify-center items-center">
            <img
              width="48px"
              height="48px"
              alt="Button Icon"
              src={"/Assets/loading2.gif"}
            />
          </div>
        ) : (
          <>
            <div className="flex w-full justify-between items-center gap-y-3 flex-wrap mt-3 mb-3 ">
              <div className="flex w-full flex-wrap gap-x-1 lg:gap-x-0 gap-y-2">
                <CustomButton
                  variant="outlined"
                  customColor={active === "Unread" ? "#159AA8" : "#fff"}
                  children="New Notifications"
                  width="250px"
                  height="40px"
                  padding="10px"
                  className={
                    "!border  lg:border-none lg:!rounded-tr-none lg:!rounded-br-none !rounded-lg"
                  }
                  borderColor={"#D0D5DD"}
                  onClick={() => setActive("Unread")}
                  color={active === "Unread" ? "#fff" : "#000"}
                />

                <CustomButton
                  variant="outlined"
                  customColor={active === "Read" ? "#159AA8" : "#fff"}
                  children="All Notifications"
                  width="250px"
                  height="40px"
                  padding="10px"
                  custumStyle={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
                  }
                  onClick={() => setActive("Read")}
                  color={active === "Read" ? "#fff" : "#000"}
                  borderColor={"#D0D5DD"}
                />
              </div>
            </div>

            {active === "Read" ? (
              fetchedDataAll?.length > 0 ? (
                <div>
                  <div className="w-full flex-1 relative gap-5 ">
                    <div className="border-b border-b-[#DADADA]  pb-3 pt-4 flex gap-2">
                      <div className="sm:w-[85%] flex gap-3 sm:gap-5 items-center ">
                        <div
                          onClick={() => {
                            // setSelectAll((prev) => !prev);
                            if (
                              fetchedDataAll.every(
                                (i, ind) =>
                                  selected.find((s) => s === i.id) ||
                                  selected.find((s) => s === i.id) === 0
                              )
                            ) {
                              setSelected([]);
                            } else {
                              let all = [];
                              fetchedDataAll?.forEach((i, ind) =>
                                all.push(i.id)
                              );
                              setSelected(all);
                            }
                          }}
                          className={`flex justify-center cursor-pointer items-center `}
                        >
                          {fetchedDataAll.every((i, ind) =>
                            selected.find((s) => s === i.id)
                          ) ? (
                            <MdCheckBox
                              className="cursor-pointer"
                              size={20}
                              color="#159AA8"
                            />
                          ) : (
                            <MdCheckBoxOutlineBlank
                              className="cursor-pointer"
                              size={20}
                              color="#475467"
                            />
                          )}
                        </div>
                        <div className=" ">
                          <RiDeleteBin6Line
                            onClick={
                              selected.length > 0 ? handleDeleteSelected : null
                            }
                            className={`${
                              selected.length > 0
                                ? "cursor-pointer"
                                : "opacity-75 cursor-not-allowed"
                            } `}
                            size={22}
                            // color={"#475467"}
                            color={"#101828"}
                          />
                          {/* <h4
                            onClick={
                              selected.length > 0 ? handleClearSelected : null
                            }
                            className={`${
                              selected.length > 0
                                ? "cursor-pointer"
                                : "opacity-75 cursor-not-allowed"
                            } text-sm  ${"text-[#101828]"}`}
                          >
                            Clear Selected
                          </h4> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {fetchedDataAll?.map(handleRenderNotification)}
                  {fetchedDataAll?.length < allNotificationsPageInfo?.total ? (
                    <div className="flex justify-end">
                      <CustomButton
                        variant="contained"
                        customColor="#159AA8"
                        children="Load more"
                        width="200px"
                        height="40px"
                        padding="10px"
                        margin="15px 0"
                        color="#fff"
                        onClick={() => {
                          loadNewData({
                            url: allNotificationsPageInfo?.links[
                              Math.floor(fetchedDataAll?.length / 10 + 1)
                            ]?.url,
                            type: "all",
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <NoData
                  noImage={true}
                  title={`No Notifications`}
                  subtitle="Your notifications will be displayed here"
                  image="notransaction.png"
                />
              )
            ) : fetchedDataUnRead?.length > 0 ? (
              <div>
                <div className="w-full flex-1 relative gap-5 ">
                  <div className="border-b border-b-[#DADADA]  pb-3 pt-4 flex gap-2">
                    <div className="sm:w-[85%] flex gap-3 sm:gap-5 items-center ">
                      <div
                        onClick={() => {
                          // setSelectAll((prev) => !prev);
                          if (
                            fetchedDataUnRead.every(
                              (i, ind) =>
                                selected.find((s) => s === i.id) ||
                                selected.find((s) => s === i.id) === 0
                            )
                          ) {
                            setSelected([]);
                          } else {
                            let all = [];
                            fetchedDataUnRead?.forEach((i, ind) =>
                              all.push(i.id)
                            );
                            setSelected(all);
                          }
                        }}
                        className={`flex justify-center cursor-pointer items-center `}
                      >
                        {fetchedDataUnRead.every((i, ind) =>
                          selected.find((s) => s === i.id)
                        ) ? (
                          <MdCheckBox
                            className="cursor-pointer"
                            size={20}
                            color="#159AA8"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            className="cursor-pointer"
                            size={20}
                            color="#101828"
                          />
                        )}
                      </div>
                      <div className=" ">
                        <h4
                          onClick={
                            selected.length > 0 ? handleClearSelected : null
                          }
                          className={`${
                            selected.length > 0
                              ? "cursor-pointer"
                              : "opacity-75 cursor-not-allowed"
                          } text-sm  ${"text-[#101828]"}`}
                        >
                          Clear Selected
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                {fetchedDataUnRead?.map(handleRenderNotification)}

                {fetchedDataUnRead?.length <
                unReadNotificationsPageInfo?.total ? (
                  <div className="flex justify-end">
                    <CustomButton
                      variant="contained"
                      customColor="#159AA8"
                      children="Load more"
                      width="200px"
                      height="40px"
                      padding="10px"
                      margin="15px 0"
                      color="#fff"
                      onClick={() => {
                        loadNewData({
                          url: unReadNotificationsPageInfo?.links[
                            Math.floor(fetchedDataUnRead?.length / 10 + 1)
                          ]?.url,
                        });
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <NoData
                noImage={true}
                title={`No New Notifications`}
                subtitle="Your new notifications will be displayed here"
                image="notransaction.png"
              />
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default Notification;
