import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import { API_GET_PAYOUTS } from "../../../config/api";

export const getPayouts = createAsyncThunk(
  "payouts/get",
  async (currency, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_PAYOUTS}${currency}`,
      });

      return data;
    } catch (error) {
      //   toast.error(error?.response?.data?.message || "An error has occurred");
    }
  }
);
