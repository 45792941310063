import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Auth from "../../components/containers/Auth";
import CustomButton from "../../components/buttons/Button";
import { forgotPassword } from "../../store/actions/onboarding/forgotPassword";
import { logout } from "../../store/actions/user/logout";

const ForgotPasswordSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const email = params.get("email");

  const { pending: loading, user } = useSelector((state) => state.onboarding);

  const handleResend = (email) => {
    dispatch(forgotPassword({ data: { email } }));
  };

  const handleContinue = () => {
    if (user?.email) {
      dispatch(logout());
    }
    navigate("/");
  };
  return (
    <Auth>
      <div className="py-8 px-8 w-full h-screen flex items-center justify-center my-[-20]">
        <div>
          <div className="max-w-[400px] h-[498px]  my-62  flex flex-col ">
            <h2 className="  font-bold text-4xl ml-0 sm:mb-2  tracking-[1%]">
              Check your inbox
            </h2>
            <span className=" my-2 ">
              We’ve sent an email to{" "}
              <span className=" text-[#128591] font-[500] ">{email}</span> with
              password reset instructions. Please check your spam if you do not
              see it after a few minutes
            </span>

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              loading={loading}
              onClick={handleContinue}
              width="100%"
              height="48px"
              padding="10px"
              margin="15px 0px"
              color="#fff"
            >
              Continue
            </CustomButton>
            <div className="mt-10 text-center">
              <span>
                Didn't receive the email?{" "}
                <a
                  onClick={() => handleResend(email)}
                  className="text-[#128591] font-[500]"
                  href="#"
                >
                  Click to resend
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center m-4">
        <span>&copy; Halvest2024</span>
        <span className="flex items-center">
          <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
          support@halvestco.com
        </span>
      </div>
    </Auth>
  );
};

export default ForgotPasswordSuccess;
