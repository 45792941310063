import React from "react";

function MoreIcon() {
  return (
    <div>
      <img
        src={"/Assets/more.png"}
        alt="Learn Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default MoreIcon;
