import React, { useEffect } from "react";
import { timeformatter } from "../../config/dateformatter";
import { formatAmount } from "../../config/utils";

import DataGrid from "../dataGrid";
import useCurrency from "../../hooks/useCurrency";
import { statuses } from "../../config/data";

export default function PaymentTable({ paymentSchedules, amount }) {
  const [_currency] = useCurrency();

  const transactions = paymentSchedules;

  const transactionColumns = () => {
    return [
      {
        field: "transactionID",
        headerClassName: "super-app-theme--header",
        headerName: "ID",
        width: 160,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {params.row?.id}
            </h3>
          );
        },
      },
      {
        field: "repayment_type",
        headerClassName: "super-app-theme--header",
        headerName: "Repayment Type",
        width: 160,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {params.row?.repayment_type}
            </h3>
          );
        },
      },
      {
        field: "amount",
        headerClassName: "super-app-theme--header",
        headerName: "Amount",
        width: 160,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {`${_currency}${" "}${formatAmount(params.row?.monthly_payout)}`}
            </h3>
          );
        },
      },

      {
        field: "date",
        headerClassName: "super-app-theme--header",
        headerName: "Payout Date",
        width: 210,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {timeformatter(params.row?.payment_date, "formal", false, true)}
            </h3>
          );
        },
      },
      {
        field: "status",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        width: 220,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <div
              className=" h-6 px-[10px] min-w-[80px] flex justify-center items-center rounded-full"
              style={{
                backgroundColor: statuses[params.row.status]?.backgroundColor,
              }}
            >
              <h3
                style={{
                  color: statuses[params.row.status]?.textColor,
                }}
                className="text-sm capitalize font-[500] leading-5"
              >
                {params.row.status}
              </h3>
            </div>
          );
        },
      },
    ];
  };

  return (
    <div className="w-full border flex flex-col relative border-[#EAECF0] rounded-xl  h-[400px] sm:h-[540px] ">
      <div className="w-full pl-6 pt-4 pb-3  ">
        <h3 className="text[18px]  text-[#101828] font-[600] leading-7">
          Payment Schedule
        </h3>
      </div>
      <div className="flex-1 overflow-auto">
        {transactions && (
          <DataGrid
            columns={transactionColumns()}
            data={transactions}
            loading={false}
          />
        )}
      </div>
    </div>
  );
}
