import { BsDash, BsQuestionCircle } from "react-icons/bs";
import {
  getPackages,
  subscribe,
} from "../../store/actions/subscription/membership";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { showErrorModal } from "../../store/reducers/messageSlice";
import { IoIosArrowBack } from "react-icons/io";
import { checkPaymentStatus } from "../../store/actions/subscription/checkPaymentStatus";
import { getStartedFreePlan } from "../../store/actions/subscription/getStartedFreePlan";
import { Step, StepLabel, Stepper } from "@mui/material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import FundModal from "../modal/FundModal";
import PaymentModal from "../modal/PaymentModal";
import CustomButton from "../buttons/Button";
import SubscribeModal from "../modal/SubscribeModal";
import PaymentMethodModal from "../modal/PaymentMethodModal";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import mixpanel from "mixpanel-browser";

function Membership() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const accountInfo = {
    accountName: "SHIC global resources",
    accountNumber: "0123414614",
    bankName: "Wema Bank",
  };

  const freedata = [
    {
      title: "2% of invested capital",
      hasDetails: true,
    },
    { title: "Access to most investments" },
    { title: "Discounted Fee" },
    { title: "$500 Per annum " },
  ];
  const memberData = [
    { title: "0% - 1%" },
    { title: "First access to  all investments " },
    { title: "Free " },
    { title: "Free " },
  ];

  const titles = [
    { title: "Admin fee" },
    { title: "Investment access" },
    { title: "Halvest events " },
    { title: "Financial coaching session" },
  ];

  const steps = [
    {
      id: 1,
      label: "Personal Details",
      link: "/onboarding",
    },
    {
      id: 2,
      label: "Investor Profile",
      link: "/onboarding/investor-profile",
    },

    {
      id: 3,
      label: "Membership Plan",
      link: "/onboarding/membership",
    },
  ];
  const activeStep = 2;
  const pathname = "onboarding/membership";

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [currencyModalOpen, setCurrencyModalOpen] = useState(false);
  const [activePlan, setActivePlan] = useState(1);
  const [showFreeMembershipModal, setShowFreeMemberhipModal] = useState(false);
  const [showPaidMembershipModal, setShowPaidMemberhipModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const { pending, FreePending } = useSelector((state) => state?.subscribe);

  const user = useSelector((state) => state.onboarding?.user);
  const packages = useSelector((state) => state?.subscribe?.packages);
  // const payURL = useSelector(
  //   (state) => state?.subscribe?.paymentURL?.data?.authorization_url
  // );
  const paymentReference = useSelector(
    (state) => state?.subscribe?.paymentURL?.data?.reference
  );
  const memberPackage = packages?.find((plan) => plan.id === 1);

  const handleGetStarted = useCallback(() => {
    // if (activePlan === 2) {
    // setIsModalOpen(true);
    dispatch(
      subscribe({
        data: { id: memberPackage?.id },
        cb: (res) => {
          const _paymentURL = res?.data?.authorization_url;
          if (_paymentURL) {
            setIframeSrc(_paymentURL);
            setIsModalOpen(true);
            setInterval(() => {
              dispatch(
                checkPaymentStatus({
                  data: { reference: paymentReference },
                  cb: () => {
                    setIsModalOpen(false);
                    setShowPaidMemberhipModal(true);
                  },
                })
              );
            }, 10000);
          }
        },
      })
    );
    // }
  }, [memberPackage, user]);

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };
  const handleCloseFreeMembershipModal = () => {
    setShowFreeMemberhipModal(false);
  };
  const handleClosePaidMembershipModal = () => {
    setShowPaidMemberhipModal(false);
  };

  const handleClosModal = () => {
    setShowModal(false);
  };

  const handleFreePlan = () => {
    if (
      user?.selectedPlan?.toLowerCase() === "free" &&
      user?.free_approved_plan !== "approved"
    ) {
      dispatch(showErrorModal("Your membership is under review"));
      return;
    } else if (
      user?.selectedPlan?.toLowerCase() === "free" &&
      user?.free_approved_plan === "approved"
    ) {
      dispatch(showErrorModal("You are already on this Plan"));
      return;
    } else if (user?.selectedPlan?.toLowerCase() === "platinum") {
      dispatch(showErrorModal("You are on Platinum Plan"));
      return;
    }
    mixpanel.track("Free-Membership-Clicked", {
      user: user,
    });
    dispatch(
      getStartedFreePlan({
        cb: () => {
          if (activePlan === 1) {
            setShowFreeMemberhipModal(true);
          }
        },
      })
    );
  };

  const handleCloseCurrencyModal = (result) => {
    setCurrencyModalOpen(false);
    if (result === "naira") {
      setPaymentMethodModalOpen(true);
    } else if (result === "usd") {
    }
  };
  const handleClosePaymentMethodModal = (result) => {
    setPaymentMethodModalOpen(false);
    if (result === "paystack") {
      handleGetStarted(true);
    } else if (result === "transfer") {
      setPaymentModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderItems = (item, index, type) => {
    return (
      <div
        key={index}
        className={`flex px-3 lg:px-4  ${
          type === "titles" ? "justify-start" : "justify-center"
        } items-center h-[64px] w-full ${
          index % 2 ? "bg-transparent" : "bg-[#F9FAFB]"
        } ${item?.title ? "" : "justify-center"} `}
      >
        <div className="flex gap-1 items-center ">
          <h5
            className={`text-sm  ${
              type === "titles" ? "text-start" : "text-center"
            }  font-[700] leading-[20px]  text-[#475467]`}
          >
            {item?.title ? (
              item?.title
            ) : item?.checked ? (
              <IoCheckmarkCircleOutline color="#079455" size={24} />
            ) : (
              <BsDash color="#98A2B3" size={20} />
            )}
          </h5>
          {item?.hasDetails && (
            <BsQuestionCircle
              onClick={() => setShowModal(true)}
              className="cursor-pointer "
              size={"14px"}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getPackages());
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    mixpanel.track("New-User-Viewed-Onboarding-Stage-3", {
      user: user,
    });

    return () => {
      mixpanel.track("New-User-Left-Onboarding-Stage-3", {
        user: user,
      });
    };
  }, []);

  return (
    <div className="my-[30px] pb-5 mx-auto  lg:my-[67px] lg:mx-[54px] w-[90%]   ">
      <div className="flex pb-8 lg:pb-0 lg:hidden flex-col w-full lg:w-[600px]">
        <Stepper orientation="horizontal" activeStep={activeStep}>
          {steps.map(({ title, id, icon, link }, index) => (
            <Step
              onClick={() => {
                if (index <= activeStep) {
                  navigate(link);
                }
              }}
              key={id}
            >
              <StepLabel>
                <div
                  className={`onboarding-sidebar__item  ${
                    pathname === link && "onboarding-sidebar__active"
                  }`}
                >
                  <div>{icon}</div>
                  <span className="list-text text-[#000]">{title}</span>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="mt-2 w-full lg:mt-8 mb-7">
        <div
          onClick={() => navigate("/")}
          className="flex w-min mb-4 items-center cursor-pointer  hover:opacity-70 "
        >
          <IoIosArrowBack size={24} />
          <h3 className="text-base font-[500] text-text-color-1 ">Back</h3>
        </div>
        <section className="w-full mx-auto  py-5 flex  flex-col lg:flex-row gap-[64px] bg-[#fff] ">
          <div div className="w-[90%] ">
            <h1 className=" text-3xl lg:text-4xl   font-[600]  flex-col leading-[44px]  text-[#101828]">
              Membership Plans
            </h1>

            {/* <h5 className="  text-base lg:text-[18px]  lg:text-start font-[400] leading-[30px]  text-[#475467]">
              Powerful, self-serve product and growth analytics to help you
              convert, engage, and retain more users.
            </h5> */}
          </div>
        </section>
        <div className="flex justify-end items-end  lg:hidden mb-4 flex-wrap gap-y-2">
          <CustomButton
            variant="outlined"
            customColor={activePlan === 1 ? "#159AA8" : "#fff"}
            children="Basic Membership"
            width="165px"
            height="40px"
            padding="10px"
            custumStyle={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRightWidth: "0px",
            }}
            borderColor={"#D0D5DD"}
            onClick={() => setActivePlan(1)}
            color={activePlan === 1 ? "#fff" : "#000"}
          />

          <CustomButton
            variant="outlined"
            customColor={activePlan === 2 ? "#159AA8" : "#fff"}
            children="Premium Membership"
            width="175px"
            height="40px"
            padding="10px"
            custumStyle={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={() => setActivePlan(2)}
            color={activePlan === 2 ? "#fff" : "#000"}
            borderColor={"#D0D5DD"}
          />
        </div>
        <section className="w-full  mx-auto overflow-auto   flex  flex-col lg:flex-row gap-[64px] bg-[#fff] ">
          <div className=" flex w-full lg:flex-1 items-end overflow-auto  ">
            <div
              className={`py-6 border-transparent border-[2px] border-b-transparent flex-1 flex justify-end flex-col`}
            >
              <div className="my-4 w-full">
                {/* <div className="flex  items-center h-[64px] w-full">
                  <h5 className="  text-sm px-3 lg:px-4  font-[700] leading-[20px]  text-[#128591]">
                    Reporting and analyticss
                  </h5>
                </div> */}
                {/* <div className="flex px-3 lg:px-4 items-center h-[64px] w-full bg-[#F9FAFB]">
                  <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                    Analytics
                  </h5>
                </div> */}
                {titles.map((items, index) =>
                  renderItems(items, index, "titles")
                )}
                <div className="mx-6">
                  <div className=" opacity-0 rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8">
                    Get Started
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                setActivePlan(1);
              }}
              // className={` lg:block  py-6 lg:w-[405px] hover:border-[#FEDF89]  border-[2px] ${
              //   activePlan === 1
              //     ? "border-[#FEDF89] block"
              //     : "hidden border-transparent"
              // } rounded-2xl transition transform duration-300 ease-in-out `}
              className={` lg:block ${
                activePlan === 1 ? " block" : "hidden lg:block"
              } py-6 lg:w-[405px] border-transparent hover:border-[#FEDF89]  border-[2px]  rounded-2xl transition transform duration-300 ease-in-out `}
            >
              <div className="px-6 flex-col flex justify-center items-center">
                <img
                  src={require("../../Assets/Images/Featured icon (4).png")}
                  alt=""
                  className="w-12 h-12"
                />
                <div className="flex mb-3 mt-5 border border-[#FEDF89] bg-[#FFFAEB] rounded-[8px] justify-center items-center w-[165px] sm:w-[175px] h-9">
                  <h5 className="  text-[14px] sm:text-base   font-[500] leading-[28px]  text-[#B54708]">
                    Basic Membership
                  </h5>
                </div>

                <div className="flex gap-1 items-center">
                  <h3 className=" text-[16px] md:text-[18px] lg:text-3xl lg:min-w-[230px] text-center   my-2 font-[700] lg:leading-[50px]  text-[#101828]">
                    Free
                  </h3>
                </div>
                <h5 className="  text-xs md:text-sm  lg:text-base mt-4 text-center w-[60%]   font-[400] lg:leading-[24px]  text-[#475467]">
                  Start investing today with our Free plan
                </h5>

                {/* <button
                  onClick={handleFreePlan}
                  className="rounded-[8px] mb-5 text-sm w-full h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                >
                  {FreePending ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    "Get Started"
                  )}
                </button> */}
              </div>
              <div className="my-4 w-full">
                {/* <div className="flex mt-10 justify-center items-center h-[64px] w-full bg-[#F9FAFB]">
                  <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                    Advanced
                  </h5>
                </div> */}
                {freedata.map(renderItems)}
                <div className="mx-6">
                  <button
                    onClick={handleFreePlan}
                    className=" rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                  >
                    {FreePending ? (
                      <img
                        width="24px"
                        height="24px"
                        alt="Button Icon"
                        src={"/Assets/loading.gif"}
                      />
                    ) : (
                      "Get Started"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setActivePlan(2);
              }}
              // className={`py-6  lg:block lg:w-[405px] hover:border-[#159AA8]  border-[2px] ${
              //   activePlan === 2
              //     ? "border-[#159AA8] block"
              //     : "hidden border-transparent"
              // } rounded-2xl transition transform duration-300 ease-in-out `}
              className={`py-6 ${
                activePlan === 2 ? "block" : "hidden lg:block"
              } lg:block lg:w-[405px] border-transparent hover:border-[#159AA8]  border-[2px] rounded-2xl transition transform duration-300 ease-in-out `}
            >
              <div className="px-6 flex-col flex justify-center items-center">
                <img
                  src={require("../../Assets/Images/Featured icon (5).png")}
                  alt=""
                  className="w-12 h-12"
                />
                <div className="flex bg-[E4F9FC] mb-3 mt-5 border border-[#97E9F2] bg-[#E4F9FC] rounded-[8px] justify-center items-center  w-[165px] sm:w-[185px] h-9">
                  <h5 className="    text-[14px] sm:text-base   font-[500] leading-[28px]  text-[#128591]">
                    Premium Membership
                  </h5>
                </div>
                <div className="flex gap-1 flex-col md:flex-row w-full justify-center items-center">
                  <h3 className="text-[16px] md:text-[18px] lg:text-3xl  lg:leading-[50px]    my-2 font-[700]   text-[#101828]">
                    $300 / ₦250K
                  </h3>
                  <h5 className=" text-xs md:text-[13px]  text-center   font-[700] leading-[24px]  text-[#475467]">
                    per annum
                  </h5>
                </div>

                <h5 className="text-xs mg:text-sm  lg:text-base mt-4 text-center w-[80%]   font-[400] lg:leading-[24px]  text-[#475467]">
                  Enjoy more benefits with our Membership plan
                </h5>

                {/* <button
                  onClick={pending ? null : handleGetStarted}
                  className="rounded-[8px] mb-5 text-sm w-full h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                >
                  {pending ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    "Get Started"
                  )}
                </button> */}
              </div>
              <div className="my-4 w-full">
                {/* <div className="flex mt-10 justify-center items-center h-[64px] w-full bg-[#F9FAFB]">
                  <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                    Advanced
                  </h5>
                </div> */}
                {memberData.map(renderItems)}
                <div className="mx-6">
                  <button
                    onClick={
                      pending
                        ? null
                        : () => {
                            if (
                              user?.selectedPlan?.toLowerCase() === "platinum"
                            ) {
                              dispatch(
                                showErrorModal("You are already on this Plan")
                              );
                            } else {
                              mixpanel.track("Premium-Membership-Clicked", {
                                user: user,
                              });
                              setCurrencyModalOpen(true);
                            }
                          }
                    }
                    className="  rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                  >
                    {pending ? (
                      <img
                        width="24px"
                        height="24px"
                        alt="Button Icon"
                        src={"/Assets/loading.gif"}
                      />
                    ) : (
                      "Get Started"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscribeModal
          open={isModalOpen}
          onClose={handleCloseModal}
          iframeSrc={iframeSrc}
        />

        <ResponseMessageModal
          onClose={handleCloseFreeMembershipModal}
          open={showFreeMembershipModal}
          type={0}
          mainText={"Your membership is under review"}
          subText={
            "Thank you for your interest in joining Halvest. Your application is under review and we typically respond within 48 hours."
          }
          handleContinue={() => navigate("/", { selectedPlan: "NonMembers" })}
        />
        <ResponseMessageModal
          onClose={handleClosePaidMembershipModal}
          open={showPaidMembershipModal}
          type={1}
          mainText={"Your Payment for membership is successful "}
          subText={"Thank you for your interest in joining Halvest."}
          handleContinue={() => navigate("/")}
        />
        <FundModal
          subTitle={"Select the desired currency you'd like to pay in."}
          open={currencyModalOpen}
          onClose={handleCloseCurrencyModal}
        />
        <PaymentModal
          noSubtitle
          accountInfo={accountInfo}
          open={isPaymentModalOpen}
          onClose={handleClosePaymentModal}
        />
        <PaymentMethodModal
          open={paymentMethodModalOpen}
          onClose={handleClosePaymentMethodModal}
        />
        <ResponseMessageModal
          open={showModal}
          onClose={handleClosModal}
          handleContinue={handleClosModal}
          type={0}
          btnTitle={"Ok"}
          mainText={""}
          subText={
            "2% of invested capital will be paid upfront for each investment  a FREE member invests in"
          }
        />
      </div>
    </div>
  );
}

export default Membership;
