import React from "react";
import OpportunityCard from "../cards/OpportunityCard";
import { useNavigate } from "react-router-dom";
import Loader from "../sharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../buttons/Button";
import { showErrorModal } from "../../store/reducers/messageSlice";
import useCurrency from "../../hooks/useCurrency";

function Opportunities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_currency] = useCurrency();
  const opportunitiesData = useSelector(
    (state) => state?.opportunities?.opportunities
  );
  const loading = useSelector((state) => state?.opportunities?.pending);

  return (
    <div className="min-h-[250px]">
      <div className="flex justify-between  mt-10">
        <h1 className="font-[600] text-[18px] text-[#101828]">
          New Opportunities
        </h1>
        <h4
          onClick={() => navigate("/opportunities")}
          className="font-[600] cursor-pointer text-[14px] text-[#475467]"
        >
          See all
        </h4>
      </div>
      <div>
        <hr className="mt-5 mb-5" />
      </div>
      {opportunitiesData.length > 0 ? (
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-6">
          {opportunitiesData?.slice(0, 3).map((opportunity) => (
            <div key={opportunity.id}>
              <OpportunityCard
                hanldeClick={
                  opportunity?.investment_status == 0
                    ? () => {
                        dispatch(
                          showErrorModal(`Sorry, this investment has closed.`)
                        );
                      }
                    : null
                }
                opportunity={opportunity}
              />
            </div>
          ))}
        </div>
      ) : loading ? (
        <div className="w-full justify-center items-center flex min-h-[300px]">
          <Loader />
        </div>
      ) : (
        <div className="flex items-center flex-col  max-w-[352px] mx-auto p-6 font-semibold">
          <img src="/Assets/noopportunity.png" alt="" className="w-24 h-24" />
          <h1 className="mb-1 mt-2 text-base font-[600] text-text-color-1">
            {`No ${_currency === "$" ? "USD" : "Naira"} Opportunities Yet`}
          </h1>
          <h4 className="font-[400] text-sm text-center text-text-color-4">
            We are working on bringing new opportunities your way. Please check
            back soon.
          </h4>
          <CustomButton
            onClick={() => navigate("/opportunities")}
            variant="contained"
            customColor="#159AA8"
            children="Explore Opportunities"
            width="199px"
            height="44px"
            margin="24px"
            padding="10px"
          />
        </div>
      )}
    </div>
  );
}

export default Opportunities;
