import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

const OnboardingSidebar = ({ handleCloseSideBar }) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const steps = [
    {
      id: 1,
      title: "Personal Details",
      link: "/onboarding",
    },
    {
      id: 2,
      title: "Investor Profile",
      link: "/onboarding/investor-profile",
    },

    {
      id: 3,
      title: "Membership Plan",
      link: "/onboarding/membership",
    },
  ];

  const profile = useSelector((state) => state.onboarding?.profile);

  const activeStep = steps.findIndex((step) => pathname === step.link);

  return (
    <div className="main-sidebar onboarding-sidebar z-10 relative h-full w-full">
      <div>
        <div
          className=" lg:justify-center justify-between ml-8  mt-8 mb-10"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/Assets/logo.png"
            alt="logo"
            width="133px"
            height="37px"
            className="max-w-max"
          />
          <AiOutlineClose
            onClick={handleCloseSideBar}
            className="cursor-pointer text-[#159AA8] lg:hidden"
            size={26}
          />
        </div>
        <div className="flex h-full pb-8 lg:pb-0 flex-col w-full ml-10">
          <Stepper
            orientation="vertical"
            // activeStep={user.hasCompletedProfile ? 1 : activeStep}
            activeStep={activeStep}
          >
            {steps.map(({ title, id, icon, link }, index) => (
              <Step
                completed={
                  (index === 0 && profile?.id_number) ||
                  index < activeStep ||
                  (index === 1 && profile?.investorProfile?.risk_tolerance)
                }
                onClick={() => {
                  if (
                    index <= activeStep ||
                    (index === 1 && profile?.id_number) ||
                    (index === 2 && profile?.investorProfile?.risk_tolerance)
                  ) {
                    navigate(link);
                  }
                }}
                key={id}
              >
                <StepLabel key={id}>
                  <div
                    style={{
                      cursor:
                        index <= activeStep ||
                        (index === 1 && profile?.id_number) ||
                        (index === 2 &&
                          profile?.investorProfile?.risk_tolerance)
                          ? "pointer"
                          : "not-allowed",
                    }}
                    className={`onboarding-sidebar__item cursor-pointer  ${
                      pathname === link && " onboarding-sidebar__active"
                    }`}
                  >
                    <div>{icon}</div>
                    <span className="list-text text-[#000]">{title}</span>
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSidebar;
