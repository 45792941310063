import React from "react";

function TotalIcon() {
  return (
    <div>
      <img
        src={"/Assets/total.png"}
        alt="Total Icon"
        className={`w-8 h-8
         }`}
      />
    </div>
  );
}

export default TotalIcon;
