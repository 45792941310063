import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_LOGIN } from "../../../config/api";
import { toast } from "react-toastify";
import { resendOTP } from "./resendotp";
// import { processErrorMessage } from "../../../Helper/processErrorMessage";
import mixpanel from "mixpanel-browser";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const login = createAsyncThunk(
  "users/login",
  async ({ data: payload, navigate, cb }, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await Client({
        method: "POST",
        path: API_LOGIN,
        data: payload,
      });

      const user = data?.user;
      if (data && !user?.email_verified) {
        const data = { email: user?.email };
        await dispatch(resendOTP({ data }));
        navigate("/verification", {
          state: data,
        });
        return;
      } else if (data && data?.access_token) {
        const { access_token } = data;
        localStorage.setItem("accessToken", access_token);

        mixpanel.identify(user?.email);
        mixpanel.register({ email: user?.email });
        mixpanel.people.set({
          $name: user?.name,
          $email: user?.email,
          // $created: new Date(),
        });
        mixpanel.track("Portal-Login", {});

        cb();
        return data;
      } else {
        toast.error("Login failed: Access token not found in the response");
        mixpanel.track("Error", {
          type: "onboarding",
          subtype: "login",
          message: "Access token not found in the response",
        });
        return rejectWithValue("Access token not found in the response");
      }
    } catch (error) {
      toast.error("Login failed: Invalid email or password");
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "login",
        message: msg,
      });
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);
