import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_COUNTRIES } from "../../../config/api";

export const getCountries = createAsyncThunk("countries/get", async () => {
  try {
    const {
      data: { data },
    } = await Client({
      method: "GET",
      path: API_COUNTRIES,
    });

    return data;
  } catch (error) {
    throw error;
  }
});
