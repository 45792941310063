import React, { useMemo } from "react";
import { useState } from "react";
import OpportunityCard from "../cards/OpportunityCard";
import CustomPagination from "../pagination/CustomPagination";
import CustomButton from "../buttons/Button";
import Loader from "../sharedLayouts/Loader";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunities } from "../../store/actions/opportunities/getopportunities";
import NoData from "../cards/NoData";
import { showErrorModal } from "../../store/reducers/messageSlice";
import useCurrency from "../../hooks/useCurrency";
import { Client } from "../../client";
import { updateOpportunities } from "../../store/reducers/opportunitiesSlice";
import { toast } from "react-toastify";

// export const categoryOptions = [
//   ...[
//     ...new Set(
//       opportunitiesData.map((opportunity) => opportunity.header.headerName)
//     ),
//   ].map((category) => ({
//     value: category,
//     label: category,
//   })),
// ];

function AllOpportunities() {
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();
  const { opportunities: opportunitiesData, opportunitiesListDetails } =
    useSelector((state) => state.opportunities);
  const [loadingPage, setLoadingPage] = useState(false);

  const loadNewData = async ({ url }) => {
    try {
      setLoadingPage(true);
      const { data } = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      dispatch(updateOpportunities(data?.data));

      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      toast.error("Error Fetching Data");
    }
  };

  const currency = useSelector((state) => state.dashboard.currency) || "NGR";

  const { pending: loading } = useSelector((state) => state.opportunities);

  useEffect(() => {
    dispatch(getOpportunities(currency));
    // const clean = setInterval(() => {
    //   dispatch(getOpportunities(currency));
    // }, 60000);
    // return () => {
    //   clearInterval(clean);
    // };
  }, [currency]);

  // const [filterCriteria, setFilterCriteria] = useState({
  //   searchText: "",
  //   isOpen: "all",
  //   riskProfile: "all",
  // });

  const [currentPage, setCurrentPage] = useState(1);

  // const [selectedCategory, setSelectedCategory] = useState("all");

  const handlePageChange = (page) => {
    if (
      page * 6 > opportunitiesData?.length &&
      opportunitiesData.length < opportunitiesListDetails?.total &&
      opportunitiesListDetails?.links[
        Math.floor(
          opportunitiesData.length / opportunitiesListDetails?.per_page
        ) + 2
      ]?.url
    ) {
      loadNewData({
        url: opportunitiesListDetails?.links[
          Math.floor(
            opportunitiesData.length / opportunitiesListDetails?.per_page
          ) + 1
        ]?.url,
      });
    }

    setCurrentPage(page);
  };

  // const handleCategoryChange = (event) => {
  //   setSelectedCategory(event.target.value);
  // };
  // const categoryOptions = [
  //   ...[
  //     ...new Set(opportunitiesData.map((opportunity) => opportunity.name)),
  //   ].map((category) => ({
  //     value: category,
  //     label: category,
  //   })),
  // ];

  // const filterOpportunities = () => {
  //   return opportunitiesData.filter((opportunity) => {
  //     const isOpenMatch =
  //       filterCriteria.isOpen === "all" ||
  //       opportunity.header.isOpen === filterCriteria.isOpen;
  //     const riskProfileMatch =
  //       filterCriteria.riskProfile === "all" ||
  //       opportunity.riskProfile === filterCriteria.riskProfile;
  //     const searchTextMatch =
  //       opportunity.title &&
  //       opportunity.title
  //         .toLowerCase()
  //         .includes(filterCriteria.searchText.toLowerCase());

  //     let categoryMatch = true;
  //     if (selectedCategory !== "all") {
  //       categoryMatch =
  //         opportunity.header.headerName === selectedCategory?.value;
  //     }
  //     return (
  //       isOpenMatch && riskProfileMatch && searchTextMatch && categoryMatch
  //     );
  //   });
  // };

  const pageCount = useMemo(
    () =>
      Math.ceil(
        (opportunitiesListDetails?.total || opportunitiesData?.length) / 6
      ),
    [opportunitiesListDetails]
  );

  // const handleFilter = () => {
  //   setFilterCriteria({
  //     searchText: "",
  //     isOpen: "all",
  //     riskProfile: "all",
  //   });
  //   setSelectedCategory("all");
  // };

  const [filterState, setFilterState] = useState("All");

  const filteredOpportunities = useMemo(() => {
    return opportunitiesData
      ?.filter(
        (item) =>
          filterState === "All" ||
          item?.investment_category?.name
            ?.replaceAll(" ", "")
            ?.toLowerCase() === filterState?.replaceAll(" ", "")?.toLowerCase()
      )
      ?.slice((currentPage - 1) * 6, currentPage * 6);
  }, [opportunitiesData, filterState, currentPage]);

  return (
    <div>
      <h1 className="mt-5 mb-5 font-[600] text-[30px]">Opportunities</h1>
      {(loading && opportunitiesData?.length < 1) || loadingPage ? (
        <div className="w-full justify-center items-center flex min-h-[300px]">
          <Loader />
        </div>
      ) : opportunitiesData?.length <= 0 ? (
        <NoData
          py={"80px"}
          title={`No ${_currency === "$" ? "USD" : "Naira"} Opportunities Yet`}
          subtitle="We are working on bringing new opportunities your way. Please check back soon."
          image="noopportunity.png"
        />
      ) : (
        <>
          <div className="flex flex-wrap gap-3 items-center py-5 w-full   mb-5 ">
            <div className="flex   flex-wrap gap-x-2 lg:gap-x-0 gap-y-2">
              <CustomButton
                variant="outlined"
                customColor={filterState === "All" ? "#159AA8" : "#fff"}
                borderColor={"#D0D5DD"}
                children={"All"}
                height="40px"
                padding="10px"
                custumStyle={
                  {
                    // borderRightWidth: "0px",
                  }
                }
                className={
                  "!border !w-full md:!w-[180px] lg:border-none lg:!rounded-tr-none lg:!rounded-br-none !rounded-lg"
                }
                onClick={() => setFilterState("All")}
                color={filterState === "All" ? "#fff" : "#000"}
              />
              <CustomButton
                variant="outlined"
                customColor={
                  filterState === "SME Financing" ? "#159AA8" : "#fff"
                }
                children={"SME Financing"}
                height="40px"
                padding="10px"
                onClick={() => setFilterState("SME Financing")}
                custumStyle={
                  {
                    // borderLeftWidth: "0px",
                    // borderRightWidth: "0px",
                  }
                }
                className={
                  "!border !w-full md:!w-[180px] lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-tr-none lg:!rounded-bl-none lg:!rounded-br-none !rounded-lg"
                }
                color={filterState === "SME Financing" ? "#fff" : "#000"}
                borderColor={"#D0D5DD"}
              />
              <CustomButton
                variant="outlined"
                customColor={filterState === "Real Estate" ? "#159AA8" : "#fff"}
                children={"Real Estate"}
                onClick={() => setFilterState("Real Estate")}
                height="40px"
                padding="10px"
                custumStyle={{}}
                className={
                  "!border !w-full md:!w-[180px] lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-tr-none lg:!rounded-bl-none lg:!rounded-br-none !rounded-lg"
                }
                color={filterState === "Real Estate" ? "#fff" : "#000"}
                borderColor={"#D0D5DD"}
              />

              <CustomButton
                variant="outlined"
                customColor={
                  filterState === "Startup Investing" ? "#159AA8" : "#fff"
                }
                children={"Startup Investing"}
                onClick={() => setFilterState("Startup Investing")}
                height="40px"
                padding="10px"
                custumStyle={
                  {
                    // borderTopLeftRadius: 0,
                    // borderBottomLeftRadius: 0,
                    // borderLeftWidth: 0,
                  }
                }
                className={
                  // "!border !w-full md:!w-[180px] lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
                  "!border !w-full md:!w-[180px] lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-tr-none lg:!rounded-bl-none lg:!rounded-br-none !rounded-lg"
                }
                color={filterState === "Startup Investing" ? "#fff" : "#000"}
                borderColor={"#D0D5DD"}
              />
              <CustomButton
                variant="outlined"
                customColor={
                  filterState === "Exchange Traded Commodities"
                    ? "#159AA8"
                    : "#fff"
                }
                children={"Exchange Traded Commodities"}
                onClick={() => setFilterState("Exchange Traded Commodities")}
                height="40px"
                padding="10px"
                custumStyle={
                  {
                    // borderTopLeftRadius: 0,
                    // borderBottomLeftRadius: 0,
                    // borderLeftWidth: 0,
                  }
                }
                className={
                  "!border !w-full md:!w-[240px] lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
                }
                color={
                  filterState === "Exchange Traded Commodities"
                    ? "#fff"
                    : "#000"
                }
                borderColor={"#D0D5DD"}
              />
            </div>
          </div>

          <>
            <div className="grid min-h-[80vh] grid-cols-1 md:grid-cols-3 gap-6">
              {filteredOpportunities.map((opportunity) => (
                <div key={opportunity.id} style={{ textDecoration: "none" }}>
                  <OpportunityCard
                    hanldeClick={
                      opportunity?.investment_status == 0
                        ? () => {
                            dispatch(
                              showErrorModal(
                                `Sorry, this investment has closed.`
                              )
                            );
                          }
                        : null
                    }
                    opportunity={opportunity}
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-between mt-10">
              <div className="mb-10 flex justify-center items-center w-full">
                <CustomPagination
                  pageCount={pageCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
}

export default AllOpportunities;
