import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MembershipTable from "../table/MembershipTable";
import { MdOutlineArrowOutward } from "react-icons/md";
import { getSubscriptionHistory } from "../../store/actions/subscription/getSubscriptionHistory";
import { BsQuestionCircle } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import mixpanel from "mixpanel-browser";
function Membership() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const { subscriptionHistory } = useSelector((state) => state.subscribe);
  const user = useSelector((state) => state.onboarding?.user);

  const handleClosModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(getSubscriptionHistory());
  }, []);

  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Membership", {
        user: user,
      });
    }
  }, [user]);

  return (
    <div className="w-full px-2">
      <div className="flex justify-between pb-5 mb-8 border-b border-b-[#EAECF0]">
        <div>
          <h1 className="font-[600] text-2xl lg:text-[30px]  leading-[38px] mb-1 text-[#101828]">
            Membership
          </h1>
        </div>
      </div>

      <div className="pb-2 mb-4  border-b border-b-[#EAECF0] ">
        <h1 className=" text-[18px] leading-[28px] font-[600] mb-1">
          Your Plan
        </h1>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 lg:items-center">
        {user?.selectedPlan?.toLowerCase() !== "platinum" && (
          <div
            style={{
              border:
                user?.selectedPlan?.toLowerCase() === "free" &&
                user?.free_approved_plan === "approved"
                  ? "2px solid #159AA8"
                  : "1px solid #D0D5DD",

              borderRadius: "10px",

              flex: 1,
            }}
            className="w-full  md:max-w-[48%] h-[465px] flex-col flex justify-between "
          >
            <div className="w-full  ">
              <div
                style={{
                  padding: "30px 20px 16px",
                  borderBottom:
                    user?.selectedPlan?.toLowerCase() === "free" &&
                    user?.free_approved_plan === "approved"
                      ? "2px solid #159AA8"
                      : "1px solid #D0D5DD",
                }}
                className="flex justify-between w-full p-4 "
              >
                <h6 className="font-[500] text-[18px] text-[#344054]">
                  Basic Membership
                </h6>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    border:
                      user?.selectedPlan?.toLowerCase() === "free" &&
                      user?.free_approved_plan === "approved"
                        ? "6px solid #159AA8"
                        : "1px solid #D0D5DD",
                  }}
                ></div>
              </div>

              <div
                style={{
                  padding: "16px",
                }}
              >
                <h1 className="font-[700] text-[#101828] mb-8 text-sm">FREE</h1>

                <h1 className="font-[700] text-[#101828] mb-6 text-sm">
                  FEATURES
                </h1>
                <div className="gap-3 flex flex-col">
                  <div className="flex gap-2 items-center">
                    <img
                      src="/Assets/check.png"
                      alt="check_icon"
                      className="w-6 h-6 "
                    />
                    <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                      Admin fee :
                    </h4>
                    <div className="flex gap-1 items-center ">
                      <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                        2% of invested capital
                      </h4>

                      <BsQuestionCircle
                        onClick={() => setShowModal(true)}
                        className="cursor-pointer "
                        size={"14px"}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img
                      src="/Assets/check.png"
                      alt="check_icon"
                      className="w-6 h-6 "
                    />
                    <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                      Investment access :
                    </h4>
                    <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                      Access to most investments
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img
                      src="/Assets/check.png"
                      alt="check_icon"
                      className="w-6 h-6 "
                    />
                    <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                      Halvest events :
                    </h4>
                    <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                      Discounted Fee
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img
                      src="/Assets/check.png"
                      alt="check_icon"
                      className="w-6 h-6 "
                    />
                    <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                      Financial coaching session :
                    </h4>
                    <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                      $500 Per annum
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderTop:
                  user?.selectedPlan?.toLowerCase() === "free" &&
                  user?.free_approved_plan === "approved"
                    ? "1px solid #159AA8"
                    : "1px solid #D0D5DD",
              }}
              className="flex justify-end  mt-2 py-4 px-6 gap-[6px] cursor-pointer items-center"
              onClick={() => {
                navigate("/plan/upgrade");
              }}
            >
              <h4
                style={{
                  color:
                    user?.selectedPlan?.toLowerCase() === "free" &&
                    user?.free_approved_plan === "approved"
                      ? "#159AA8"
                      : "#159AA8",
                }}
                className="text-[#159AA8] text-base font-[600] "
              >
                Upgrade Plan
              </h4>
              <MdOutlineArrowOutward
                size={"24px"}
                style={{
                  color:
                    user?.selectedPlan?.toLowerCase() === "free" &&
                    user?.free_approved_plan === "approved"
                      ? "#159AA8"
                      : "#159AA8",
                }}
              />
            </div>
          </div>
        )}
        <div
          style={{
            border:
              user?.selectedPlan?.toLowerCase() === "platinum" &&
              subscriptionHistory &&
              new Date(
                subscriptionHistory[
                  subscriptionHistory?.length - 1
                ]?.nextDateRenew
              ) > new Date()
                ? "2px solid #159AA8"
                : "1px solid #D0D5DD",
            borderRadius: "10px",

            flex: 1,
          }}
          className="w-full md:max-w-[48%] h-[465px] flex-col flex justify-between"
        >
          <div className="w-full  ">
            <div
              style={{
                padding: "30px 20px 16px",
                borderBottom:
                  user?.selectedPlan?.toLowerCase() === "platinum" &&
                  subscriptionHistory &&
                  new Date(
                    subscriptionHistory[
                      subscriptionHistory?.length - 1
                    ]?.nextDateRenew
                  ) > new Date()
                    ? "2px solid #159AA8"
                    : "1px solid #D0D5DD",
              }}
              className="flex justify-between w-full p-4"
            >
              <h6>Premium Membership</h6>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border:
                    user?.selectedPlan?.toLowerCase() === "platinum" &&
                    subscriptionHistory &&
                    new Date(
                      subscriptionHistory[
                        subscriptionHistory?.length - 1
                      ]?.nextDateRenew
                    ) > new Date()
                      ? "6px solid #159AA8"
                      : "1px solid #D0D5DD",
                }}
              ></div>
            </div>

            <div style={{ padding: "16px" }}>
              <h1 className="mb-8">
                <span className="font-bold text-md"> $300(₦250K)</span> per
                annum
              </h1>
              <h1 className="font-[700] text-[#101828] mb-6 text-sm">
                FEATURES
              </h1>

              <div className="gap-3 flex flex-col">
                <div className="flex gap-2 items-center">
                  <img
                    src="/Assets/check.png"
                    alt="check_icon"
                    className="w-6 h-6 "
                  />
                  <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                    Admin fee :
                  </h4>
                  <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                    0% - 1%
                  </h4>
                </div>
                <div className="flex gap-2 items-center">
                  <img
                    src="/Assets/check.png"
                    alt="check_icon"
                    className="w-6 h-6 "
                  />
                  <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                    Investment access :
                  </h4>
                  <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                    First access to all investments
                  </h4>
                </div>
                <div className="flex gap-2 items-center">
                  <img
                    src="/Assets/check.png"
                    alt="check_icon"
                    className="w-6 h-6 "
                  />
                  <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                    Halvest events :
                  </h4>
                  <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                    Free
                  </h4>
                </div>
                <div className="flex gap-2 items-center">
                  <img
                    src="/Assets/check.png"
                    alt="check_icon"
                    className="w-6 h-6 "
                  />
                  <h4 className="text-sm font-[500] leading-[20px]  text-[#475467]">
                    Financial coaching session :
                  </h4>
                  <h4 className="text-sm font-[700] leading-[20px]  text-[#475467]">
                    Free
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop:
                user?.selectedPlan?.toLowerCase() === "platinum" &&
                subscriptionHistory &&
                new Date(
                  subscriptionHistory[
                    subscriptionHistory?.length - 1
                  ]?.nextDateRenew
                ) > new Date()
                  ? "1px solid #159AA8"
                  : "1px solid #D0D5DD",
            }}
            className="flex justify-end mt-2 py-4 px-6 gap-[6px]  items-center"
          >
            <h4 className="opacity-0 text-[#98A2B3] text-sm font-[600] cursor-pointer">
              Upgrade Plan
            </h4>
            <img
              src="/Assets/arrow.png "
              className="opacity-0 w-5 h-5"
              alt=""
            />
          </div>
        </div>
      </div>

      {user?.selectedPlan?.toLowerCase() === "platinum" ? (
        <div className="w-full my-3">
          <MembershipTable />
        </div>
      ) : (
        <></>
      )}

      <ResponseMessageModal
        open={showModal}
        onClose={handleClosModal}
        handleContinue={handleClosModal}
        type={0}
        btnTitle={"Ok"}
        mainText={""}
        subText={
          "2% of invested capital will be paid upfront for each investment  a FREE member invests in"
        }
      />
    </div>
  );
}

export default Membership;
