const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const groupbydate = ({ data, dateKey }) => {
  return Object.values(
    data.reduce((r, item) => {
      const [year, month, day] = item[dateKey].substr(0, 10).split("-");
      const key = `${year}_${month}`;
      r[key] = r[key] || { month: months[Number(month) - 1], year, groups: [] };
      r[key].groups.push(item);
      return r;
    }, {})
  );
};
