import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../store/actions/notification/notification";

function NotificationAccordion({ title, content, image, id }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const dispatch = useDispatch();
  const handleAccordionToggle = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isAccordionOpen) dispatch(markNotificationAsRead(id));
  }, [isAccordionOpen]);

  return (
    <Accordion expanded={isAccordionOpen} onChange={handleAccordionToggle}>
      <AccordionSummary
        className="font-[600] text-[#101828] text-[18px] lg:text-[20px] mt-10 p-5"
        style={{ backgroundColor: "#fff", marginBottom: "20px" }}
        expandIcon={<ExpandMoreIcon />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex  w-full flex-wrap gap-2 justify-between">
          <div className="flex flex-1 min-w-[200px] items-center ">
            <img src={image} alt="Image" className="w-5 h-5 mr-4" />

            <p className="flex-1 text-[#475467] text-sm  sm:text-base font-[400]">
              {content}
            </p>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default NotificationAccordion;
