import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../buttons/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function WelcomeModal({ open, onClose }) {
  const navigate = useNavigate();

  const startJourney = () => {
    navigate("/onboarding");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: 480,
            height: 580,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            lineHeight: 1.9,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div>
            <img src="/Assets/video.png" alt="" />
            <h6 className="font-semibold mt-4">
              Carefully selected investments for ethical investors who deserves
              the best.
            </h6>

            <p>
              As an FCA authorised firm, we need to grab a few details from you
              and make sure you're a good fit for our investment products. This
              should take only 5 minutes. <br /> It will unlock all the deal
              information & and enable you to easily invest.
            </p>

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Start your journey"
              width="100%"
              height="40px"
              padding="10px"
              margin="15px 0px"
              color="#fff"
              onClick={startJourney}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default WelcomeModal;
