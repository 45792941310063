import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const InvestmentCard = ({
  icon,
  title,
  investment,
  iconColor,
  width,
  height,
}) => {
  const theme = useTheme();
  const lassThan900px = useMediaQuery(theme.breakpoints.down("md"));
  const iconStyle = {
    width: "56px",
    height: "56px",
    backgroundColor: iconColor,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px",
  };

  return (
    <Card
      sx={{
        width: width,
        height: height,
        boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        borderWidth: "1px",

        padding: "8px",
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={lassThan900px ? "center" : "flex-start"}
            >
              <div style={iconStyle}>{icon}</div>
              <Typography
                variant="h5"
                component="div"
                fontSize={14}
                fontWeight={500}
                color={"#475467"}
              >
                {title}
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Typography
                className="mt-1"
                color="text.secondary"
                align={lassThan900px ? "center" : "left"}
                fontWeight={600}
                fontSize={24}
              >
                {investment}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestmentCard;
