import React from "react";
import { useSelector } from "react-redux";
import DataGrid from "../dataGrid";
import useCurrency from "../../hooks/useCurrency";
import { formatAmount } from "../../config/utils";
import { timeformatter } from "../../config/dateformatter";
import Loader from "../sharedLayouts/Loader";

function Transaction() {
  const transactions =
    useSelector((state) => state.transactions.transactions) || [];
  const { pending } = useSelector((state) => state.transactions);

  const [_currency] = useCurrency();

  const statuses = {
    paid: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
    pending: {
      textColor: "#B42318",
      borderColor: "#FECDCA",
      backgroundColor: "#FEF3F2",
    },
    success: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
  };

  const columns = () => {
    return [
      {
        field: "transaction_type",
        headerClassName: "super-app-theme--header",

        headerName: "Transaction Type",
        width: 155,
        sortable: false,
        renderHeader: ({ colDef, field }) => {
          return (
            <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
              {colDef.headerName}
            </h3>
          );
        },
        renderCell: (params) => (
          <div className="flex items-center">
            <h3 className="text-sm  text-[#101828] font-[400] leading-5">
              {params.row?.transaction_type}
            </h3>
          </div>
        ),
      },

      {
        field: "amount",
        headerClassName: "super-app-theme--header",
        headerName: "Amount",
        width: 200,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {`${_currency}${" "}${formatAmount(params.row?.amount)}`}
            </h3>
          );
        },
      },
      {
        field: "transaction_id",
        headerClassName: "super-app-theme--header",
        headerName: "Transaction ID",
        width: 260,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {params.row?.transaction_id}
            </h3>
          );
        },
      },

      {
        field: "transactionDate",
        headerClassName: "super-app-theme--header",
        headerName: "Transaction Date",
        width: 300,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {timeformatter(
                params.row?.transaction_date,
                "formal",
                false,
                true
              )}
            </h3>
          );
        },
      },
      {
        field: "status",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        width: 180,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <div
              className=" h-6 px-[10px] flex justify-center items-center rounded-full"
              style={{
                backgroundColor:
                  statuses[params.row.status?.toLowerCase()]?.backgroundColor,
              }}
            >
              <h3
                style={{
                  color: statuses[params.row.status?.toLowerCase()]?.textColor,
                }}
                className="text-sm font-[500] leading-5"
              >
                {params.row.status}
              </h3>
            </div>
          );
        },
      },
    ];
  };

  return (
    <div className=" border border-border-color-1 min-h-[250px] mb-6 rounded-xl mt-10">
      <div className="flex justify-between mb-4">
        <h1 className="font-[600] mt-5 ml-5 text-[18px] text-[#101828]">
          Recent Transactions
        </h1>
      </div>
      {pending ? (
        <div className="w-full justify-center items-center flex min-h-[300px]">
          <Loader />
        </div>
      ) : transactions.length > 0 ? (
        <div className="flex-1  w-full overflow-auto">
          {transactions && (
            <DataGrid columns={columns()} data={transactions} loading={false} />
          )}
        </div>
      ) : (
        <div className="flex items-center min-h-[300px] flex-col max-w-[352px] p-6 mx-auto font-semibold">
          <img src="/Assets/notransaction.png" alt="" className="w-24 h-24" />
          <h1 className="mb-1 mt-2 text-base font-[600] text-text-color-1">
            {`No Upcoming ${_currency === "$" ? "USD" : "Naira"} Payments`}
          </h1>
          <h4 className="font-[400] text-sm text-center text-text-color-4">
            You do not have recent transaction. Please check back later.
          </h4>
          {/* <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Explore Opportunities"
            width="199px"
            height="44px"
            margin="24px"
            padding="10px"
          /> */}
        </div>
      )}
    </div>
  );
}

export default Transaction;
