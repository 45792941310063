import { createSlice } from "@reduxjs/toolkit";
import { addBankDetails } from "../actions/bank/bankUserActions";
import { getBankList } from "../actions/bank/getBankList";
import { getUserBanks } from "../actions/bank/getUserBanks";
import { deleteUserBank } from "../actions/bank/deleteUserBank";
import { bankUSDUserActions } from "../actions/bank/bankUSDUserActions";
export const bankSlice = createSlice({
  name: "bank",
  initialState: {
    error: null,
    pending: false,
    isAddBank: false,
    success: false,
    banks: [],
    userBanks: [],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(bankUSDUserActions.pending, (state, action) => {
      state.isAddBank = true;
      state.success = false;
    });
    builder.addCase(bankUSDUserActions.fulfilled, (state, action) => {
      state.isAddBank = false;
      state.success = true;
    });
    builder.addCase(bankUSDUserActions.rejected, (state, action) => {
      state.isAddBank = false;
      state.success = false;
    });
    builder.addCase(addBankDetails.pending, (state, action) => {
      state.isAddBank = true;
      state.success = false;
    });
    builder.addCase(addBankDetails.fulfilled, (state, action) => {
      state.isAddBank = false;
      state.success = true;
    });
    builder.addCase(addBankDetails.rejected, (state, action) => {
      state.isAddBank = false;
      state.success = false;
    });
    builder.addCase(getBankList.pending, (state, action) => {
      state.pending = true;
      state.banks = [];
    });
    builder.addCase(getBankList.fulfilled, (state, action) => {
      state.pending = false;
      state.banks = action.payload;
    });
    builder.addCase(getBankList.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getUserBanks.pending, (state, action) => {
      state.pending = true;
      state.userBanks = [];
    });
    builder.addCase(getUserBanks.fulfilled, (state, action) => {
      state.pending = false;
      state.userBanks = [...action.payload.NGR, ...action.payload.USD];
    });
    builder.addCase(getUserBanks.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(deleteUserBank.pending, (state, action) => {
      // state.pending = true;
    });
    builder.addCase(deleteUserBank.fulfilled, (state, action) => {
      // state.pending = false;
    });
    builder.addCase(deleteUserBank.rejected, (state, action) => {
      // state.pending = false;
    });
  },
});

export default bankSlice.reducer;
