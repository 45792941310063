export const API_REGISTER = "auth/register";
export const API_REGISTER_TEAL = "subscribe/create";
export const API_LOGIN = "auth/login";
export const API_REFRESH_TOKEN = "auth/refresh";
export const API_FORGOT_PASSWORD = "auth/password/email";
export const API_RESET_PASSWORD = "auth/password/reset";
export const API_VERIFY_OTP = "auth/otp_verify";
export const API_RESEND_OTP = "auth/resend_otp";
export const API_SECURITY_PASSWORD_OTP = "auth/password/otp";
export const API_SECURITY_PIN_OTP = "wallet/otp";
export const API_LOGOUT = "auth/logout";

export const API_GET_USER_INVESTMENT = "profile/userInvestment";
export const API_GET_INVESTMENT_PORTFOLIO =
  "investment/userInvestmentByCurrency?currency=";
export const API_GET_WITHDRAWALS = "investment/userWithdrawalRequests";
export const API_GET_paymentSchedule =
  "investment/user-paymentSchedule-payout?currency=";

export const API_WITHDRAWAL_REQUEST = "profile/withdrawalRequest";
export const API_CANCEL_WITHDRAWAL_REQUEST =
  "investment/cancelWithdrawalRequest?withdrawal_request_id=";

export const API_GET_OPPORTUNITIES = "investment/currency/";
export const API_GET_OPPORTUNITY_DETAILS = "investment/_product/{id}";
export const API_CREATE_INTEREST = "product/_interest";
export const API_GET_INTEREST = "investment/_product";

export const API_UPDATE_PASSWORD = "update-password";
export const API_VERIFY = "auth/otp_verify";
export const API_GOOGLE_LOGIN = "auth/google?accessToken=";
export const API_GOOGLE_REGISTER = "auth/register/google";
export const API_RESEND_VERIFICATION = "auth/resend-token";
export const API_GET_USERS = "user";
export const API_LOGIN_PIN = "login-pin";
export const API_RESET_PIN = "customer/reset-pin";

export const API_CHANGE_PASSWORD = "auth/change-password";

export const API_CREATE_PROFILE = "user/profile";
export const API_PIN = "wallet/transactionPin";
export const API_RESET_BANK_PIN = "wallet/resetPin";
export const API_INVESTMENT_TRANSACTIONS =
  "wallet/investment?investment_product_id=";

export const API_NOTIFICATION = "notification";
export const API_COUNTRIES = "countries";
export const API_MARK_NOTIFICATION_AS_READ = "notifications/mark_read";
export const API_ALL_NOTIFICATIONS = "notifications";
export const API_GET_UNREAD_NOTIFICATION = "notifications/unread";
export const API_GET_READ_NOTIFICATIONS = "notification/read";
export const API_READ_NOTIFICATIONS = "notifications/multiple_read";
export const API_DELETE_NOTIFICATIONS = "notifications/delete";

export const API_FUND_WALLET = "wallet/fundWallet";
export const GET_WALLET_BALANCE = "wallet/myWallet";
// export const API_USER_BANK_DETAILS = "profile/bank";
export const API_USER_BANK_DETAILS = "investment/bank";
export const API_USER_USD_BANK_DETAILS = "investment/dollar_acct";
export const API_GET_BANK_NAMES = "all-banks";
export const API_GET_ACCOUNT_NAME = "verify-bank-account";
export const API_VERIFY_ACCOUNT = "verify-bank-account";
export const API_REMOVE_BANK = "profile/bank";

export const API_CONTACT = "contact";

export const API_UPDATE_USER_PROFILE = "profile/profile";
export const API_UPDATE_INVESTOR_PROFILE = "profile/investor-profile";
// export const API_GET_USER_PROFILE = "profile/profile";

export const API_UPDATE_BVN = "profile/update-bvn";

export const API_GET_ALL_PACKAGES = "Management/subscription/packages";
export const API_SUBSCRIBE_PACKAGE = "payment/start?subscriptionPackageId=";
export const API_GET_PAYMENT_DETAILS = "payment/get";
export const API_SUBMIT_INVESTMENT = "investment/investment";
export const API_GET_TRANSACTIONS = "wallet/transactionCurrency?currency=";
export const API_SUBSCRIPTION_HISTORY = "wallet/subscription";
export const API_FREE_PLAN = "Management/subscription/choose-free";

export const API_GET_SUBSCRIPTION = "wallet/transaction";
export const API_GET_PAYOUTS =
  "investment/upcoming-investment-payout?currency=";
export const API_GET_TOTAL_PAYOUTS = "investment/userTotalPayout?currency=";
export const API_GET_TOTAL_PAYOUTS_DETAILS =
  "investment/myTotalPayout?investment_product_id=";

export const API_INVESTMENT_STATEMENT =
  "investment/getUserTotalInvestmentMetrics?currency=";

export const API_ACCOUNT_STATEMENT = "wallet/account-statement";
export const API_WITHDRAW = "investment/withdrawalRequest?currency=";
