import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";
import { Drawer, List, Menu, Popover } from "@mui/material";
import SearchIcon from "../Icons/SearchIcon";
import CurrencySwitch from "../buttons/CurrencySwitch";
import MenuIcon from "../Icons/Menu";
import NotificationIcon from "../Icons/NotificationIcon";
import MenubarIcon from "../Icons/MenubarIcon";
import { fetchUnreadNotifications } from "../../store/actions/notification/notification";
import Fade from "@mui/material/Fade";
import { switchCurrency } from "../../store/actions/dashboard/switchCurrency";
import { getAllNotifications } from "../../store/actions/notification/getAllNotifications";

const NotificationComponent = () => {
  return <div className="w-[800px] rounded-xl  h-[500px] "></div>;
};

const Header = ({ title, hideCurrencyToggle, handleOpenSideBar }) => {
  const [usdNgnChecked, setUsdNgnChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const unreadNotifications = useSelector(
    (state) => state?.notifications?.unreadNotifications
  );
  const handleUsdNgnToggle = () => {
    dispatch(switchCurrency(currency === "USD" ? "NGR" : "USD"));
  };

  const handleNotification = () => {
    navigate("/notifications");
  };

  useEffect(() => {
    dispatch(fetchUnreadNotifications());
  }, []);

  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const openNotification = Boolean(anchorElNotification);

  const handleNotificationClick = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorElNotification(null);
  };

  return (
    <header
      style={{ zIndex: 1 }}
      className="pt-6 pr-10 pl-[30px] border-[#eeeff7] sticky top-0 right-0 bg-white border-[1px] pb-2 lg:pb-[23px] w-full overflow-hidden"
    >
      <div className="header__content flex items-center justify-between w-full lg:justify-between">
        <div className="lg:hidden flex items-center justify-between">
          <img
            src="/Assets/logo.png"
            alt="logo"
            width="100px"
            height="37px"
            className="max-w-max"
          />
        </div>

        <div className="flex items-center justify-between w-full gap-5 lg:justify-between">
          <div className="search-wrapper hidden  items-center">
            <SearchIcon />
            <input placeholder="Search" />

            <img src="/Assets/helpIcon.png" alt="" style={{ width: "15px" }} />
            <img
              src="/Assets/shortcut.png"
              alt=""
              style={{ width: "25px", marginLeft: "5px" }}
            />
          </div>

          <div className="flex items-center gap-1 justify-end w-full ">
            {hideCurrencyToggle ? (
              <></>
            ) : (
              <div className="border border-gray-300 w-[150px] lg:w-[150px] lg:h-[35px] px-4 py-1  rounded-lg lg:bg-[#F9FAFB] flex items-center lg:mr-6 lg:justify-end">
                <CurrencySwitch
                  checked={currency === "NGR" ? true : false}
                  onChange={handleUsdNgnToggle}
                />
              </div>
            )}
            <React.Fragment key={"right"}>
              <Tooltip title="Click here to see your notifications">
                <div onClick={handleNotification} style={{ cursor: "pointer" }}>
                  <Badge
                    badgeContent={unreadNotifications?.length}
                    color="error"
                  >
                    <NotificationIcon />
                  </Badge>
                </div>
              </Tooltip>

              <Popover
                id="fade-menu"
                anchorEl={anchorElNotification}
                open={openNotification}
                onClose={handleNotificationClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <NotificationComponent />
              </Popover>
            </React.Fragment>
          </div>

          <div onClick={handleOpenSideBar} className="lg:hidden">
            <MenubarIcon />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
