import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Reminder from "../modal/Reminder";
import { useCookies } from "react-cookie";

const DashboardLayout = ({
  title,
  hideNotification,
  children,
  hideCurrencyToggle,
  noMarginTop,
}) => {
  const [show, setShow] = useState(false);
  const handleCloseSideBar = () => {
    setShow(false);
  };
  const handleOpenSideBar = () => {
    setShow(true);
  };
  const user = useSelector((state) => state.onboarding?.user);
  const { pending } = useSelector((state) => state.onboarding);

  const [cookies, setCookie, removeCookie] = useCookies(["reminder"]);

  const reminder = cookies[`${user?.email}`];

  const [showReminder, setShowReminder] = useState(true);

  return (
    <>
      <div className="flex h-screen  w-screen  relative bg-[#fff]">
        <div
          className={`h-screen sm:w-7/12 md:w-4/12 lg:w-[19.444%]  bg-[#FFFFFF]  z-40 fixed left-0 top-0 ${
            show ? "block" : "hidden"
          } lg:block `}
        >
          <div
            onClick={handleCloseSideBar}
            className="bg-[#ffffff] block lg:hidden z-2 absolute top-0 left-0 w-screen h-screen"
          ></div>
          <Sidebar handleCloseSideBar={handleCloseSideBar} />
        </div>

        <main className="flex-1 min-h-screen overflow-y-auto overflow-x-hidden  lg:ml-[19.444%] ">
          <Box>
            <Collapse>
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    // onClick={() => {
                    //   setOpen(false);
                    // }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              ></Alert>
            </Collapse>
          </Box>

          <Header
            hideCurrencyToggle={hideCurrencyToggle}
            title={title}
            handleOpenSideBar={handleOpenSideBar}
          />
          {!hideNotification &&
            user?.selectedPlan?.toLowerCase() === "free" &&
            user?.free_approved_plan !== "approved" && (
              <div className="w-full flex justify-end relative   ">
                <div className="flex gap-4 items-center fixed m-3 bg-white w-[300px] sm:w-[400px] border-[D0D5DD] border p-2 lg:p-4 rounded-xl ">
                  <img
                    src="/Assets/alert-icon.png"
                    alt=""
                    className="w-[35px] h-[35px]"
                  />
                  <h4 className="font-[600] text-[14px] leading-5 text-[#101828]">
                    Your membership is under review
                  </h4>
                </div>
              </div>
            )}
          <div
            style={{ marginTop: noMarginTop ? 0 : "10px" }}
            className="w-full lg:ml-[16px]"
          >
            {children}
          </div>
        </main>
      </div>

      {user?.email && !user?.selectedPlan && !reminder?.reminded && !pending ? (
        <Reminder
          showReminder={showReminder}
          setShowReminder={setShowReminder}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardLayout;
