import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_SUBSCRIPTION_HISTORY } from "../../../config/api";

export const getSubscriptionHistory = createAsyncThunk(
  "subscriptionHistory/get",
  async () => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_SUBSCRIPTION_HISTORY}`,
      });

      return data?.data;
    } catch (error) {}
  }
);
