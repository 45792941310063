import React, { useEffect } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import AllOpportunities from "../../components/opportunities/AllOpportunities";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";

function Opportunity() {
  const user = useSelector((state) => state.onboarding?.user);
  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Opportunities", {
        user: user,
      });
    }
  }, [user]);
  return (
    <DashboardLayout>
      <div className="px-5  sm:pl-8 sm:pr-12  py-4">
        <div className="">
          <AllOpportunities />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Opportunity;
