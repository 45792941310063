import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_UPDATE_USER_PROFILE } from "../../../config/api";
import { logout } from "../user/logout";

export const fetchUserProfile = createAsyncThunk(
  "userProfile/get",
  async (payload, { dispatch }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_UPDATE_USER_PROFILE,
      });

      return response?.data?.data;
    } catch (error) {
      if (
        error?.response?.data?.message === "Authentication required." ||
        error?.response?.data?.message === "Token is Invalid"
      ) {
        dispatch(logout());
      }
      // toast.error(
      //   error?.response?.data?.errors?.BVN || "An error has occurred"
      // );
      payload.setSubmitting(false);
    }
  }
);
