import { createSlice } from "@reduxjs/toolkit";
import { updateUserProfile } from "../actions/profile/updateProfile";
import { updateUserBvn } from "../actions/profile/updateBVN";
import { getCountries } from "../actions/user/getCountries";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    error: null,
    profileDetails: {},
    countries: [],
    pending: false,
    success: false,
    userProfileData: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(updateUserProfile.pending, (state, action) => {
      state.pending = true;
      state.userProfileData = null;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.pending = false;
      state.userProfileData = action.payload;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(updateUserBvn.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(updateUserBvn.fulfilled, (state, action) => {
      state.pending = false;
      state.success = true;
    });
    builder.addCase(updateUserBvn.rejected, (state, action) => {
      state.pending = false;
      state.rejected = false;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

export default profileSlice.reducer;
