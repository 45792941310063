import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CREATE_INTEREST } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const submitInterest = createAsyncThunk(
  "submitInterest/Post",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_INTEREST,
        data,
      });
      cb();
      mixpanel.track("Submitted-Interest-Successfully", {});

      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      mixpanel.track("Error", {
        type: "Investment",
        subtype: "submit-interest",
        message: msg,
      });

      failed();
    }
  }
);
