import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./App.scss";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store, { persist } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { CookiesProvider } from "react-cookie";

// import "./Assets/fonts/ttinterfaces/TTInterfaces-Light.ttf";
// import "./Assets/fonts/ttinterfaces/TTInterfaces-Bold.ttf";
// import "./Assets/fonts/ttinterfaces/TTInterfaces-Regular.ttf";
// import "./Assets/fonts/ttinterfaces/TTInterfaces-Medium.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <CookiesProvider>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persist}>
            <App />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </CookiesProvider>
  </GoogleOAuthProvider>
);

reportWebVitals();
