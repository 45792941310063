import React, { useState } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import CustomInput from "../../components/inputs/CustomInput";
import WalletTable from "../../components/table/WalletTable";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles

function WalletTransactions() {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [transactionFilter, setTransactionFilter] = useState("all"); // Default to "all" transactions

  // Function to handle date filter changes
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  // Function to handle transaction filter
  const handleTransactionFilter = (filter) => {
    setTransactionFilter(filter);
  };

  return (
    <DashboardLayout>
      <div style={{ width: "1200px", margin: "0px auto" }}>
        <div className="flex">
          <CustomInput
            width="280px"
            icon={<SearchIcon />}
            iconPosition="left"
            placeholder="Search Transactions"
          />
          <div className="flex">
            <DatePicker
              selected={selectedStartDate}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              dateFormat="MM/dd/yyyy"
            />
            <DatePicker
              selected={selectedEndDate}
              onChange={handleEndDateChange}
              placeholderText="End Date"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="flex">
            <button
              onClick={() => handleTransactionFilter("all")}
              className={`${
                transactionFilter === "all" ? "bg-blue-500" : "bg-gray-300"
              } hover:bg-blue-600 px-4 py-2 rounded-md`}
            >
              All Transactions
            </button>
            <button
              onClick={() => handleTransactionFilter("deposit")}
              className={`${
                transactionFilter === "deposit" ? "bg-green-500" : "bg-gray-300"
              } hover:bg-green-600 px-4 py-2 rounded-md ml-2`}
            >
              Deposit
            </button>
            <button
              onClick={() => handleTransactionFilter("withdraw")}
              className={`${
                transactionFilter === "withdraw" ? "bg-red-500" : "bg-gray-300"
              } hover:bg-red-600 px-4 py-2 rounded-md ml-2`}
            >
              Withdraw
            </button>
          </div>
        </div>

        <div style={{ marginTop: "20px" }} className="bg-white p-4 rounded-md">
          <div className="flex justify-between items-center font-bold">
            <h1 className="font-bold text-l mt-5 mb-5">Wallet Transactions</h1>
          </div>

          <WalletTable
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            transactionFilter={transactionFilter}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default WalletTransactions;
