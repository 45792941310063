import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        width="44px"
        height="44px"
        alt="Button Icon"
        src={"/Assets/loading2.gif"}
      />
    </div>
  );
};

export default Loader;
