import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { RxArrowTopRight } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { getAllWithdrawals } from "../../store/actions/wallet/getAllWithdrawals";
import { timeformatter } from "../../config/dateformatter";
import NoData from "../cards/NoData";
import CustomButton from "../buttons/Button";
import { cancelWithdrawal } from "../../store/actions/wallet/cancelWithdrawal";
import Loader from "../sharedLayouts/Loader";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import { Client } from "../../client/index";

const MyWithdraws = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [loadingCancelWithdrawal, setLoadingCancelWithdrawal] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.onboarding?.user);
  const { myWithdrawals, pendingAllWithdrawals } = useSelector(
    (state) => state.dashboard
  );

  const pageInfo = useMemo(() => myWithdrawals, [myWithdrawals]);

  const handleCancelWithdrawal = (id) => {
    setLoadingCancelWithdrawal(true);
    dispatch(
      cancelWithdrawal({
        id,
        cb: () => {
          setShowConfirmation(false);
          setLoadingCancelWithdrawal(false);
        },
        failed: () => {
          setLoadingCancelWithdrawal(false);
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Withdrawal-Requests", {
        user: user,
      });
    }
  }, [user]);

  const loadNewData = async ({ url }) => {
    try {
      setLoading(true);
      const { data } = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });
      setFetchedData((prev) => [...prev, ...data?.data?.data]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (pageInfo?.total) {
      setFetchedData(pageInfo?.data);
    }
  }, [pageInfo]);

  useEffect(() => {
    dispatch(
      getAllWithdrawals(() => {
        setLoading(false);
      })
    );
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
        sx={{
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            py: 2,
            px: 1,
          }}
          className="w-[90%] lg:w-[550px] bg-black"
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full mt-7   ">
            {showConfirmation ? (
              <div className="w-full pl-4 pr-2 h-[150px] flex pt-5 pb-3  justify-between flex-col bg-white">
                <h3 className="text-xs sm:text-sm font-[500] text-[#344054]">
                  Are you sure you want to cancel this request?
                </h3>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="border-t border-t-[#EAECF0]"
                >
                  <CustomButton
                    variant="outlined"
                    customColor="#fff"
                    children="No"
                    width="130px"
                    height="36px"
                    borderColor="#D0D5DD"
                    color="#000"
                    iconWidth="22px"
                    margin="10px 5px 10px 0px"
                    onClick={() => {
                      setShowConfirmation(false);
                    }}
                  />

                  <CustomButton
                    variant="contained"
                    customColor="#159AA8"
                    children={"Yes"}
                    width={"130px"}
                    height="36px"
                    loading={loadingCancelWithdrawal}
                    padding="10px"
                    onClick={() => {
                      handleCancelWithdrawal(showConfirmation);
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="w-full  pl-4 pr-6  ">
                  <h4 className=" text-lg mb-2 font-[500]">
                    Withdrawal Requests
                  </h4>
                  <div className="border-b w-full mb-3"></div>
                </div>
                <div className="w-full min-h-[450px] pl-4 pr-6 max-h-[650px]  overflow-y-auto mb-4 ">
                  <div className="w-full ">
                    {loading || pendingAllWithdrawals ? (
                      <div className="w-full min-h-[330px] justify-center items-center flex">
                        <Loader />
                      </div>
                    ) : fetchedData?.length > 0 ? (
                      <>
                        {[...fetchedData]?.map((i, index) => (
                          <div
                            key={index}
                            onClick={() => {}}
                            className={`flex w-full shadow hover:bg-[#daf1f3] transform transition duration-300 ease-in-out gap-x-4 p-1 mb-2 rounded-[8px] border-0 border-[#EAECF0] justify-between py-4`}
                          >
                            <div className="flex justify-between  w-full pr-5 gap-x-3">
                              <div className="flex fles-1  gap-x-3">
                                <div className="h-full w-8 justify-center items-center flex">
                                  <RxArrowTopRight color="159AA8" />
                                </div>
                                <div className="">
                                  <div className="flex flex-row gap-[4px] items-center">
                                    <h3 className="text-xs sm:text-sm font-[600] text-[#344054]">
                                      {i?.currency}
                                    </h3>

                                    <h3 className="text-xs sm:text-sm font-[600] text-[#344054]">
                                      {i?.amount}
                                    </h3>
                                  </div>
                                  <h4 className="text-xs sm:text-sm font-400] text-[#475467]">
                                    {timeformatter(
                                      i?.withdrawal_date,
                                      "formal"
                                      // "withInThreeDays"
                                    )}
                                  </h4>
                                </div>
                              </div>
                              <div className="flex flex-col items-end">
                                <h3
                                  className={`text-xs sm:text-sm capitalize font-[600] ${
                                    i?.status === "approved"
                                      ? "text-[#159AA8]"
                                      : "text-[#667085]"
                                  }`}
                                >
                                  {i?.status}
                                </h3>
                                {i?.status !== "approved" &&
                                i?.status !== "cancelled" ? (
                                  <h3
                                    onClick={() => {
                                      setShowConfirmation(i?.id);
                                    }}
                                    className={
                                      "text-xs sm:text-sm capitalize cursor-pointer font-[600] mt-2 text-[#D92D20]"
                                    }
                                  >
                                    Cancel Request
                                  </h3>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                        {fetchedData?.length < pageInfo?.total ? (
                          <div className="flex justify-end">
                            <CustomButton
                              variant="contained"
                              customColor="#159AA8"
                              children="Load more"
                              width="200px"
                              height="40px"
                              padding="10px"
                              margin="15px 0"
                              color="#fff"
                              onClick={() => {
                                loadNewData({
                                  url: pageInfo?.links[
                                    Math.floor(fetchedData?.length / 10 + 1)
                                  ]?.url,
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <NoData
                          // noImage={true}
                          title={`No Withdrawals`}
                          subtitle="Your withdrawals will be displayed here"
                          image="notransaction.png"
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MyWithdraws;
