import React, { useState } from "react";
import useCurrency from "../../hooks/useCurrency";
import { formatAmount } from "../../config/utils";
import DataGrid from "../dataGrid";
import { timeformatter } from "../../config/dateformatter";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { cancelWithdrawal } from "../../store/actions/wallet/cancelWithdrawal";
import Loader2 from "../sharedLayouts/Loader2";
import Confirmation from "../modal/Confirmation";
import CustomTooltip from "../containers/ToolTip";
import MyWithdraws from "../modal/MyWithdraws";

function WalletTable({ data }) {
  const [_currency, getAmount] = useCurrency();
  const [openWithdrawals, setOpenWithdrawals] = useState(false);

  const [loadingCancelRequest, setLoadingCancelRequest] = useState(false);

  const dispatch = useDispatch();

  const handleCancel = (id) => {
    setLoadingCancelRequest(true);

    dispatch(
      cancelWithdrawal({
        id,
        cb: () => {
          setLoadingCancelRequest(false);
        },
        failed: () => {
          setLoadingCancelRequest(false);
        },
      })
    );
  };

  const statuses = {
    paid: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
    pending: {
      textColor: "#B42318",
      borderColor: "#FECDCA",
      backgroundColor: "#FEF3F2",
    },
    processing: {
      textColor: "#B42318",
      borderColor: "#FECDCA",
      backgroundColor: "#FEF3F2",
    },
    success: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
    approved: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
  };

  const columns = () => {
    return [
      {
        field: "transaction_type",
        headerClassName: "super-app-theme--header",

        headerName: "Transaction Type",
        width: 300,
        sortable: false,
        renderHeader: ({ colDef, field }) => {
          return (
            <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
              {colDef.headerName}
            </h3>
          );
        },
        renderCell: (params) => (
          <div className="flex items-center">
            <h3 className="text-sm  text-[#101828] font-[400] leading-5">
              {params.row?.narration || params.row?.transaction_type}
            </h3>
          </div>
        ),
      },

      {
        field: "amount",
        headerClassName: "super-app-theme--header",
        headerName: "Amount",
        width: 130,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {`${_currency}${" "}${formatAmount(params.row?.amount)}`}
            </h3>
          );
        },
      },
      // {
      //   field: "adminFee",
      //   headerClassName: "super-app-theme--header",
      //   headerName: "Admin Fee",
      //   width: 150,
      //   renderHeader: ({ colDef, field }) => (
      //     <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
      //       {colDef.headerName}
      //     </h3>
      //   ),
      //   renderCell: (params) => {
      //     return (
      //       <h3 className="text-sm text-[#475467] font-[400] leading-5">
      //         {/* {`${_currency}${" "}${formatAmount(params.row?.amount)}`} */}
      //       </h3>
      //     );
      //   },
      // },
      {
        field: "transaction_id",
        headerClassName: "super-app-theme--header",
        headerName: "Transaction ID",
        width: 180,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {params.row?.transaction_id}
            </h3>
          );
        },
      },
      {
        field: "transactionDate",
        headerClassName: "super-app-theme--header",
        headerName: "Transaction Date",
        width: 240,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {timeformatter(
                params.row?.transaction_date,
                "formal",
                false,
                true
              )}
            </h3>
          );
        },
      },

      {
        field: "status",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        width: 220,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <div
              className=" h-6 px-[10px] min-w-[120px] border flex justify-center items-center rounded-full"
              style={{
                borderColor:
                  statuses[params.row.status?.toLowerCase()]?.borderColor,
                backgroundColor:
                  statuses[params.row.status?.toLowerCase()]?.backgroundColor,
              }}
            >
              <h3
                style={{
                  color: statuses[params.row.status?.toLowerCase()]?.textColor,
                }}
                className="text-sm font-[500] capitalize leading-5"
              >
                {params.row.status}
              </h3>
            </div>
          );
        },
      },
      // {
      //   field: "",
      //   headerClassName: "super-app-theme--header",
      //   headerName: "",
      //   width: 70,
      //   renderHeader: ({ colDef, field }) => (
      //     <h3 className="text-xs text-[#475467] font-[500] leading-[18px]"></h3>
      //   ),
      //   renderCell: (params) => {
      //     return params.row.transaction_type === "Withdrawal Request" &&
      //       params.row.status === "processing" ? (
      //       <div className="  w-full px-[10px]  flex justify-center items-center ">
      //         {openWithdrawals === params.row.id ? (
      //           <Loader2 />
      //         ) : (
      //           <CustomTooltip
      //             placement={"left"}
      //             message="Cancel withdrawal request."
      //           >
      //             <RiDeleteBin6Line
      //               onClick={() => {
      //                 setOpenWithdrawals(params.row.id);
      //               }}
      //               className={"cursor-pointer"}
      //               size={22}
      //               color={"#101828"}
      //             />
      //           </CustomTooltip>
      //         )}
      //       </div>
      //     ) : (
      //       <></>
      //     );
      //   },
      // },
    ];
  };

  return (
    <div style={{}}>
      {openWithdrawals ? (
        <Confirmation
          open={openWithdrawals}
          loading={loadingCancelRequest}
          onClose={() => {
            setOpenWithdrawals(false);
          }}
          handleButtonClicked={() => handleCancel(openWithdrawals)}
        />
      ) : (
        <></>
      )}
      <div className="flex-1 overflow-auto">
        {data && <DataGrid columns={columns()} data={data} loading={false} />}
      </div>
    </div>
  );
}

export default WalletTable;
