import React, { useEffect } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import TextArea from "../../components/inputs/TextArea";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { supprtSchema } from "../../helper/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { support } from "../../store/actions/support/support";
import mixpanel from "mixpanel-browser";

function Support() {
  const dispatch = useDispatch();
  const supportData = [];

  const form = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        support({
          data: values,
          cb: () => {
            setSubmitting(false);
            form.resetForm();
          },
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
    validationSchema: supprtSchema,
  });

  const user = useSelector((state) => state.onboarding?.user);
  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Support", {
        user: user,
      });
    }
  }, [user]);

  return (
    <DashboardLayout>
      <div className="px-6 lg:px-8 py-4">
        <h1 className="my-5 font-[600] text-[30px]">Support</h1>

        <div className="flex flex-col lg:flex-row gap-[66px] justify-between">
          <div className="flex w-full lg:w-[419px] flex-col">
            <div
              className=" p-8"
              style={{
                backgroundColor: "#159AA8",
                borderRadius: "16px",
                width: "100%",
              }}
            >
              <div>
                <h1 className="font-[600] text-2xl text-[#fff]">
                  Hi, How can we help you?
                </h1>
                <p className="text-sm font-[400] text-[#D0D5DD] mt-5 mb-1">
                  Type your question or message below
                </p>
                <div>
                  <TextArea
                    form={form}
                    id={"message"}
                    placeholder="Enter message here"
                  />
                  {form?.isSubmitting ? (
                    <img
                      width="25px"
                      height="25px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    <Button
                      onClick={form.handleSubmit}
                      disabled={
                        form?.isSubmitting || !form?.dirty || !form?.isValid
                      }
                      variant="outline"
                      children="Send message"
                    />
                  )}
                </div>
              </div>

              <div
                className="mt-16"
                style={{
                  height: "170px",
                  backgroundColor: "#FAFCFF",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                }}
              >
                <p className="mb-2 font-semibold text-lg">Contact Us:</p>
                <p className="flex items-center mb-4">
                  {" "}
                  <img
                    src="/Assets/mailfill.png"
                    alt=""
                    className="w-4 h-4 mr-2"
                  />{" "}
                  support@halvestco.com
                </p>
                <p className="flex items-center mb-4">
                  {" "}
                  <img
                    src="/Assets/call.png"
                    alt=""
                    className="w-4 h-4 mr-2"
                  />{" "}
                  +234 704 291 5411
                </p>
              </div>
            </div>

            <div className="w-full ">
              {supportData.map((support) => {
                return (
                  <div
                    key={support?.id}
                    style={{
                      width: "100%",
                      height: "74px",
                      border: "1px solid #E4E7EC",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                      marginTop: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <h1 className="font-[500] text-sm"> {support?.title}</h1>
                      <p className="flex font-[400] text-xs justify-between">
                        {support?.content}
                      </p>
                    </div>
                    <img
                      src="/Assets/chev-right.png"
                      className="w-4 h-4"
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Support;
