import { IconButton, Step, StepLabel, Stepper } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCountries } from "../../store/actions/user/getCountries";
import { IoIosArrowBack } from "react-icons/io";
import { showErrorModal } from "../../store/reducers/messageSlice";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import { convertBirthDate } from "../../config/utils";
import { updateUserProfile } from "../../store/actions/profile/updateProfile";
import { userDetailsSchema } from "../../helper/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import {
  genderOptions,
  idTypesOptions,
  referrals,
  nationalities,
  countries,
  nigerianStates,
  nigerian,
} from "../../config/data";

import "react-phone-number-input/style.css";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import CustomInput from "../inputs/CustomInput";
import SelectField from "../inputs/SelectField";
import CustomButton from "../buttons/Button";
import HalDatePicker from "../DatePicker";
import PhoneInputField from "../inputs/PhoneInputField";
import { BsQuestionCircle } from "react-icons/bs";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import { useCookies } from "react-cookie";
import mixpanel from "mixpanel-browser";

const steps = [
  { label: "Personal Details" },
  { label: "Investor Profile" },
  { label: "Membership Plan" },
];

function UserDetails() {
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const now = new Date();
  const next30Days = new Date(now.setDate(now.getDate() + 30));

  const userProfileData = {};

  const birthDateFrmBackend = useSelector(
    (state) => state?.userProfile?.profileDetails?.data?.birth_date
  );

  const { profile, user } = useSelector((state) => state.onboarding);
  const loading = useSelector((state) => state?.userProfile?.pending);

  const [cookies, setCookie, removeCookie] = useCookies(["onboarding"]);

  const preProfile = cookies[`preProfile-${user?.email}`];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [myImage, setMyImage] = useState(null);
  const [myImagePreview, setMyImagePreview] = useState(null);

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    form.setFieldValue("my-id-file-name", convertedFiles.name);

    if (!/(gif|jpe?g|png|jpg|svg|jpeg|pdf)$/i.test(convertedFiles.name)) {
      dispatch(
        showErrorModal(
          "Invalid file type. Only PNG, jpg, GIF, JPEG, SVG, and PDF files are accepted."
        )
      );
      return;
    }
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 1.5) {
      dispatch(showErrorModal("File size is too big (max. 1.5MB)"));
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setMyImagePreview(result.target.result);
    });

    setMyImage(convertedFiles);
  };

  const handleRemoveImage = () => {
    setMyImagePreview(null);
    form.setFieldValue("id_Photo_URL", null);
    setMyImage(null);
  };

  const form = useFormik({
    initialValues: {
      ...preProfile,
      first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
      last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
      birth_date: dayjs(preProfile?.birth_date),
      phone: userProfileData?.phone || user?.phone,
    } || {
      first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
      last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
      address: profile?.address || "",
      country: profile?.country
        ? countries?.find((country) => country?.value === profile?.country)
        : "",
      country_of_residence: profile?.country_of_residence
        ? countries?.find(
            (country) => country?.value === profile?.country_of_residence
          )
        : {
            value: "Nigeria",
            label: "Nigeria",
          },
      state: nigerianStates?.find(
        (item) => item?.value?.toLowerCase() === profile?.state?.toLowerCase()
      ),
      city: nigerian
        .find(
          (item) => item.state?.toLowerCase() === profile?.state?.toLowerCase()
        )
        ?.lgas?.map((item) => {
          return { value: item, label: item };
        })
        ?.find((item) => {
          return item.value?.toLowerCase() === profile?.city?.toLowerCase();
        }),
      postal_code: profile?.postal_code || "",
      gender: profile?.gender
        ? {
            value: profile?.gender,
            label: profile?.gender,
          }
        : null,
      nationality: userProfileData?.nationality || {
        CountryCode: "NG",
        Nationality: "Nigerian",
        NationalityID: 120,
        label: "Nigerian",
        value: "Nigerian",
      },
      postal_code: userProfileData?.postal_code || "",
      gender: userProfileData?.gender || "",
      phoneCode: "+234",
      phone: userProfileData?.phone || user?.phone,

      birth_date: userProfileData?.birth_date || null,
      profession: userProfileData?.profession || "",
      linked_in_profile: userProfileData?.linked_in_profile || "",
      referral: userProfileData?.referral || "",
      id_number: userProfileData?.id_number || "",
      id_type: userProfileData?.id_type || {
        value: "national_identity_card",
        label: "National Identity Card",
      },
      id_Photo: "",
      learn: "",
    },
    validationSchema: userDetailsSchema,
  });

  useEffect(() => {
    if (profile?.first_name) {
      form.setValues({
        first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
        last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
        address: profile?.address || "",
        country: profile?.country
          ? countries?.find((country) => country?.value === profile?.country)
          : null,
        country_of_residence: profile?.country_of_residence
          ? countries?.find(
              (country) => country?.value === profile?.country_of_residence
            )
          : null,
        state: nigerianStates?.find(
          (item) => item?.value?.toLowerCase() === profile?.state?.toLowerCase()
        ),
        city: nigerian
          .find(
            (item) =>
              item.state?.toLowerCase() === profile?.state?.toLowerCase()
          )
          ?.lgas?.map((item) => {
            return { value: item, label: item };
          })
          ?.find((item) => {
            return item.value?.toLowerCase() === profile?.city?.toLowerCase();
          }),
        postal_code: profile?.postal_code || "",
        gender: profile?.gender
          ? genderOptions?.find(
              (i) => i.value?.toLowerCase() === profile?.gender?.toLowerCase()
            )
          : null,
        phoneCode: "+234",
        phone: profile?.phone,
        phone: profile?.phone || user?.phone,
        birth_date: profile?.birth_date ? dayjs(profile?.birth_date) : null,
        profession: profile?.profession || "",
        linked_in_profile: profile?.linked_in_profile || "",

        referee: profile?.referee || "",
        learn: profile?.referral
          ? {
              value: profile?.referral,
              label: profile?.referral,
            }
          : null,
        id_type: profile?.id_type
          ? idTypesOptions?.find((item) => item?.value === profile?.id_type)
          : null,
        nationality:
          nationalities.find(
            (item) =>
              item?.label?.toLowerCase() === profile?.nationality?.toLowerCase()
          ) || null,
        id_number: profile?.id_number || "",

        id_Photo: "",
        id_Photo_URL: profile?.id_photo ? profile?.id_photo : null,
      });
    }
  }, [profile, user]);

  const handleSubmitUserDetails = () => {
    const formData = new FormData();
    const birthDate = new Date(form.values.dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    const nationality = form.values?.nationality?.Nationality;

    if (nationality?.toLowerCase() !== "nigerian") {
      dispatch(showErrorModal("Sorry! For now, we only allow Nigerians."));
      return;
    }

    if (age < 18) {
      dispatch(showErrorModal("You must be 18 years or older to proceed."));
      return;
    }

    if (myImage) {
      formData.append("id_photo", myImage);
    }

    const formattedBirthDate = convertBirthDate(
      new Date(form.values.birth_date)
    );

    formData.append("first_name", form.values?.first_name);
    formData.append("last_name", form.values?.last_name);
    formData.append("address", form.values?.address);
    formData.append("country", form.values.country.value);
    formData.append(
      "country_of_residence",
      form.values.country_of_residence.value
    );
    // formData.append("state", form.values.state.label);
    formData.append("state", form.values.state?.value);
    formData.append("city", form.values.city?.value);
    formData.append("postal_code", form.values.postal_code);
    formData.append("gender", form.values?.gender?.value?.toLowerCase());
    formData.append("phone", form.values.phone);
    formData.append("birth_date", formattedBirthDate);
    formData.append("profession", form.values.profession);
    formData.append("linked_in_profile", form.values.linked_in_profile);
    formData.append("referral", form.values.learn?.label);
    formData.append("referee", form.values.referee);
    formData.append("id_type", form.values?.id_type?.value);
    formData.append("nationality", nationality?.toLowerCase());
    formData.append("id_number", form.values.id_number);

    mixpanel.track("New-User-Submitted-Onboarding-Stage-1", {
      user: user,
    });

    dispatch(
      updateUserProfile({ data: formData, navigate, formValues: form.values })
    );
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const clearIconStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const activeStep = 0;
  const pathname = "onboarding";

  useEffect(() => {
    dispatch(getCountries());
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    return () => {
      if (form.values?.first_name && user?.email) {
        setCookie(`preProfile-${user?.email}`, form.values, {
          expires: next30Days,
          secure: true,
        });
      }
    };
  }, [form.values]);

  useEffect(() => {
    mixpanel.track("New-User-Viewed-Onboarding-Stage-1", {
      user: user,
    });
    return () => {
      mixpanel.track("New-User-Left-Onboarding-Stage-1", {
        user: user,
      });
    };
  }, []);

  return (
    <div className="my-[30px] mx-auto  lg:my-[67px] lg:mx-[54px] w-[90%]  lg:w-[800px] ">
      <div className="flex pb-8 lg:pb-0 lg:hidden flex-col w-full">
        <Stepper
          orientation="horizontal"
          // activeStep={user.hasCompletedProfile ? 1 : activeStep}
          activeStep={activeStep}
        >
          {steps.map(({ title, id, icon, link }, index) => (
            <Step
              completed={index === 0 && profile?.id_number}
              onClick={() => {
                if (index === 1 && profile?.id_number) {
                  navigate("/onboarding/investor-profile");
                }
              }}
              key={id}
            >
              <StepLabel>
                <div
                  className={`onboarding-sidebar__item  ${
                    pathname === link && "onboarding-sidebar__active"
                  }`}
                >
                  <div>{icon}</div>
                  <span className="list-text text-[#000]">{title}</span>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="mt-0 md:mt-8 mb-8">
        <div
          onClick={() => navigate("/")}
          className="flex w-min mb-4 items-center cursor-pointer  hover:opacity-70 "
        >
          <IoIosArrowBack size={24} />
          <h3 className="text-base font-[500] text-text-color-1 ">Back</h3>
        </div>
        <h1 className="font-[600] text-[30px] leading-[38px]  text-[#101828]">
          Personal Details
        </h1>
        <p className="text-[16px] font-[400] mt-2 text-[#475467}">
          Tell us a bit about yourself
        </p>
      </div>
      <div className="w-full ">
        <div className="w-full py-5 mt-3 lg:gap-10 flex flex-col md:flex-row justify-between border-b border-b-[#EAECF0]">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
              Name
            </h4>
          </div>

          <div className="flex w-full md:w-[605px] gap-2 md:gap-5  flex-col md:flex-row">
            <div className="flex-1">
              <CustomInput
                fullWidth
                height="10px"
                form={form}
                id="first_name"
                placeholder={"First Name"}
                borderColor="#D0D5DD"
                pt="10px"
                mt="10px"
                mr="20px"
              />
            </div>
            <div className="flex-1 ">
              <CustomInput
                fullWidth
                height="10px"
                form={form}
                id="last_name"
                placeholder={"Last Name"}
                borderColor="#D0D5DD"
                pt="10px"
                mt="10px"
              />
            </div>
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between  ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
              Nationality
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <SelectField
              label="Nationality"
              id="nationality"
              form={form}
              options={nationalities}
              sx={{ flex: 1, height: "52px" }}
            />
            {/* <AutoComplete
              label="Nationality"
              id="nationality"
              form={form}
              options={nationalities}
              sx={{ flex: 1, height: "52px" }}
            /> */}
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
              Country of Residence
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <SelectField
              label="Choose country"
              id="country_of_residence"
              form={form}
              options={countries}
              sx={{ flex: 1, height: "52px" }}
            />
          </div>
        </div>

        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className="flex md:w-[280px]">
            <h4 className=" text-[14px] leading-[20px] font-[600]">
              Residential Address (Nigerian)
            </h4>
            <BsQuestionCircle
              onClick={() => setShowModal(true)}
              className="cursor-pointer mx-2 my-1 "
              size={"14px"}
            />
          </div>
          <div className="w-full flex-1 md:flex-auto md:w-[605px]  ">
            <div className="flex flex-col  gap-x-5 gap-y-2 md:gap-y-0 items-center  md:flex-row justify-between mb-4">
              <div className=" w-full flex-1">
                <SelectField
                  label="Choose country"
                  id="country"
                  form={form}
                  options={countries}
                  clearFields={["state", "city", "address", "postal_code"]}
                  sx={{ flex: 1, height: "52px", marginBottom: "3px" }}
                />
                {/* <AutoComplete
                  label="Choose country"
                  id="country"
                  form={form}
                  options={countries}
                  clearFields={["state", "city", "address", "postal_code"]}
                  sx={{ flex: 1, height: "52px", marginBottom: "3px" }}
                /> */}
              </div>

              <div className=" w-full flex-1">
                <SelectField
                  label="Choose state"
                  id="state"
                  form={form}
                  clearFields={["city", "address", "postal_code"]}
                  options={
                    form?.values?.country?.value?.toLowerCase() === "nigeria"
                      ? nigerianStates
                      : []
                  }
                  sx={{ flex: 1, height: "52px", marginBottom: "3px" }}
                />
                {/* <CustomInput
                  fullWidth
                  id="state"
                  height={"100%"}
                  form={form}
                  placeholder="Choose state"
                  borderColor="#D0D5DD"
                /> */}
              </div>
            </div>
            <div className="flex flex-col md:flex-row  gap-y-2 md:gap-y-0 gap-x-5  justify-between my-4 ">
              <div className="  flex-1">
                <SelectField
                  clearFields={["address", "postal_code"]}
                  label="Choose city"
                  id="city"
                  form={form}
                  defaultValue={form?.values?.city}
                  options={
                    form?.values?.state?.value
                      ? nigerian
                          .find(
                            (item) =>
                              item.state?.toLowerCase() ===
                              form?.values?.state?.value?.toLowerCase()
                          )
                          ?.lgas?.map((item) => {
                            return { value: item, label: item };
                          })
                      : []
                  }
                  sx={{ flex: 1, height: "52px", marginBottom: "3px" }}
                />
                {/* <CustomInput
                  fullWidth
                  id="city"
                  form={form}
                  placeholder="Enter City"
                  borderColor="#D0D5DD"
                /> */}
              </div>
              <div className="flex-1">
                <CustomInput
                  fullWidth
                  type="number"
                  width="100%"
                  form={form}
                  id="postal_code"
                  placeholder="Postal code"
                  borderColor="#D0D5DD"
                />
              </div>
            </div>
            <CustomInput
              fullWidth
              height="10px"
              form={form}
              placeholder={"Street Address"}
              id="address"
              borderColor="#D0D5DD"
            />
          </div>
        </div>

        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
              Gender
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <SelectField
              label="Gender"
              id="gender"
              form={form}
              options={genderOptions || []}
              sx={{ flex: 1, height: "52px" }}
            />
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
              Phone Number
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <PhoneInputField
              fullWidth
              name={"phone"}
              form={form}
              id={"phone"}
            />
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
              Date of Birth
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <HalDatePicker
              label="Date of Birth"
              id={"birth_date"}
              form={form}
              selectedDate={birthDateFrmBackend}
              rightImage
            />
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0]">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
              Profession
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <div className="flex-1 ">
              <CustomInput
                fullWidth={true}
                height="10px"
                id="profession"
                placeholder={"Enter profession"}
                form={form}
                borderColor="#D0D5DD"
              />
            </div>
          </div>
        </div>
        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
              Linkedln Profile
            </h4>
          </div>
          <div className="flex flex-1 md:flex-auto md:w-[605px]">
            <div className="flex-1 ">
              <CustomInput
                fullWidth={true}
                height="10px"
                id="linked_in_profile"
                placeholder={"https://"}
                form={form}
                borderColor="#D0D5DD"
              />
            </div>
          </div>
        </div>
        <div className="w-full border-b border-b-[#EAECF0]">
          <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col lg:flex-row  justify-between ">
            <div className="md:w-[280px]">
              <h4 className=" text-[14px]  flex-1 leading-[20px] font-[600]">
                How did you learn about us
              </h4>
            </div>
            <div className="flex flex-col md:flex-auto  gap-y-2  md:w-[605px]">
              <div className="w-full   ">
                <SelectField
                  label="How did you learn about us"
                  id="learn"
                  form={form}
                  options={referrals}
                  sx={{ width: "100%", height: "52px" }}
                />
              </div>
            </div>
          </div>
          {form.values?.learn?.value === "Referred by a member" ? (
            <div className="w-full pb-5  lg:gap-10  flex flex-col lg:flex-row   justify-between  ">
              <div className="md:w-[280px]">
                <h4 className=" text-[14px] mt-1 flex-1 leading-[20px] font-[600]">
                  Name of the person who referred you
                </h4>
              </div>
              <div className="flex flex-col md:flex-auto  gap-y-2  md:w-[605px]">
                <div className="w-full   ">
                  <CustomInput
                    fullWidth
                    height="10px"
                    form={form}
                    id="referee"
                    placeholder={"Name of the person who referred you"}
                    borderColor="#D0D5DD"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full  lg:gap-10 py-5 mt-3 flex flex-col md:flex-row  justify-between border-b border-b-[#EAECF0] ">
          <div className=" md:w-[280px]">
            <h4 className=" text-[14px] flex-1 leading-[20px] font-[600]">
              Upload ID
            </h4>
          </div>

          <div className="flex flex-col md:flex-auto  gap-y-2  md:w-[605px]">
            <div className="w-full   ">
              <SelectField
                label="Choose ID type"
                id="id_type"
                form={form}
                options={idTypesOptions}
                sx={{ width: "100%", height: "52px" }}
              />
            </div>
            <div className="w-full   ">
              <CustomInput
                fullWidth
                // type="number"
                height="10px"
                form={form}
                id="id_number"
                placeholder={"Enter your uploaded Id number"}
                borderColor="#D0D5DD"
              />
            </div>
            <>
              <div
                style={{
                  width: "100%",
                  height: "250px",
                  border: "1px solid #EAECF0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  textAlign: "center",
                  lineHeight: 1.8,
                  padding: "10px",
                  marginTop: "10px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <input
                  type="file"
                  accept=".png, .jpg, .gif, .jpeg, .svg, .pdf"
                  onChange={handleImageChange}
                  hidden
                  ref={fileInputRef}
                />
                {myImagePreview || form.values?.id_Photo_URL ? (
                  <>
                    <IconButton
                      onClick={handleRemoveImage}
                      style={clearIconStyle}
                    >
                      <ClearIcon />
                    </IconButton>
                    <img
                      src={myImagePreview || form.values?.id_Photo_URL}
                      alt="Preview"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        height: "100%",
                      }}
                    />
                  </>
                ) : (
                  <div
                    onClick={handleDivClick}
                    className="w-full flex-col h-full flex justify-center items-center"
                  >
                    <p className="text-sm mt-4">
                      <div className="flex items-center justify-center">
                        <img
                          src="/Assets/upload.png"
                          alt="Personal icon"
                          className="w-12 h-12 mr-4 "
                        />
                      </div>
                      <span className="text-[#159AA8] mt-2">
                        Click to upload{" "}
                      </span>
                      {/* or drag and drop */}
                    </p>
                    <p className="text-sm">
                      SVG, PNG, JPG, GIF or PDF (max. 1.5MB)
                    </p>
                  </div>
                )}
              </div>
              {!myImagePreview && !form.values?.id_Photo_URL && (
                <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
                  File upload is required
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      <div className="my-5 flex justify-end items-center gap-3">
        <CustomButton
          variant="outlined"
          customColor="#fff"
          children="Cancel"
          width="77px"
          height="40px"
          padding="10px"
          color="#000"
          onClick={() => navigate(-1)}
        />

        <CustomButton
          width="225px"
          height="40px"
          variant="contained"
          customColor="#159AA8"
          children="Proceed to Investor Profile"
          loading={loading}
          disabled={
            (!myImagePreview && !form.values?.id_Photo_URL) ||
            loading ||
            !form.isValid
            // ||
            // !form.dirty
          }
          padding="10px"
          margin="15px 0"
          color="#fff"
          onClick={handleSubmitUserDetails}
        />
      </div>

      <ResponseMessageModal
        open={showModal}
        onClose={() => setShowModal(false)}
        handleContinue={() => setShowModal(false)}
        type={0}
        btnTitle={"Ok"}
        mainText={""}
        subText={"Please provide a Nigerian address to verify your account."}
      />
    </div>
  );
}

export default UserDetails;
