import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { Parser } from "html-to-react";
import { validateYouTubeUrl } from "../../helper/validateYoutubeLink";
import mixpanel from "mixpanel-browser";

function CustomAccordion({
  title,
  content,
  image,
  opportunity,
  investmentCategory,
}) {
  const htmlParser = new Parser();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { pending: loading } = useSelector((state) => state.opportunities);

  const user = useSelector((state) => state.onboarding?.user);
  const handleAccordionToggle = () => {
    if (!isAccordionOpen) {
      mixpanel.track(`Viewed-${title}`, {
        user: user,
      });
    }
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <Accordion expanded={isAccordionOpen} onChange={handleAccordionToggle}>
      <AccordionSummary
        className="font-[700] text-[#101828] text-base mt-10 p-5"
        style={{ backgroundColor: "#F9FAFB", marginBottom: "20px" }}
        expandIcon={<ExpandMoreIcon />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {image ? (
          <img src={image} alt="Image" style={{ width: "100%" }} />
        ) : (
          <div className="in-app-html">
            {htmlParser.parse(content)}
            <div className="mt-10 sm:mr-[24px] mb-6 ">
              {investmentCategory === "Startup Investing" &&
              opportunity?.video_link &&
              validateYouTubeUrl(opportunity?.video_link) ? (
                <>
                  <h1 className="font-[600] capitalize  mb-1 text-base">
                    Title:{" "}
                    <span className="font-[400] capitalize text-base">
                      {opportunity?.video_title}
                    </span>
                  </h1>

                  <iframe
                    className="w-full md:w-1/2"
                    width="50%"
                    height="315"
                    style={{
                      borderRadius: "8px",
                    }}
                    src={
                      opportunity?.video_link.includes("https://youtu.be")
                        ? opportunity?.video_link.replace(
                            "https://youtu.be",
                            "https://www.youtube.com/embed"
                          )
                        : opportunity?.video_link
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
