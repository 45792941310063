import React from "react";
import CustomButton from "../buttons/Button";
import { useState } from "react";
import AddBankModal from "../modal/AddBankModal";
import { removeUserBank } from "../../store/actions/bank/bankUserActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBankList } from "../../store/actions/bank/getBankList";
import { deleteUserBank } from "../../store/actions/bank/deleteUserBank";
import { getUserBanks } from "../../store/actions/bank/getUserBanks";
import { showErrorModal } from "../../store/reducers/messageSlice";
import useCurrency from "../../hooks/useCurrency";
import mixpanel from "mixpanel-browser";

function BankAccounts() {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [_currency, getAmount] = useCurrency();
  const { pending } = useSelector((state) => state?.bank);

  const allBanksList = useSelector((state) => state?.bank?.banks) || [];
  const { userBanks, userUSDBanks } = useSelector((state) => state?.bank) || [];

  const [isAddBankModalOpen, setIsAddBankModalOpen] = useState(false);

  const closeAddBankModal = () => {
    setIsAddBankModalOpen(false);
  };

  const openAddBankModal = () => {
    setIsAddBankModalOpen(true);
  };

  const handleRemoveBank = (bankId, currency) => {
    setDeleteLoading(bankId);
    dispatch(
      deleteUserBank({
        bankId,
        setDeleteLoading,
        currency,
        cb: () => {
          dispatch(getUserBanks());
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getUserBanks());
  }, []);

  const user = useSelector((state) => state.onboarding?.user);
  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Bank-Accounts", {
        user: user,
      });
    }
  }, [user]);

  return (
    <div className="h-full">
      <div className="flex flex-col lg:flex-row gap-y-4 justify-between sm:pr-4 mb-4">
        <div>
          <h1 className="font-[600] text-2xl lg:text-3xl mb-2">
            Bank Accounts
          </h1>
          <p className="text-sm  lg:text-base ">
            Bank accounts you can withdraw to
          </p>
        </div>
        <div className="mb-4">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Add new bank"
            // disabled={userBanks?.length > 0 || pending}
            disabled={pending}
            width="150px"
            height="45px"
            padding="10px"
            margin="5px 0"
            color="#fff"
            onClick={openAddBankModal}
          />
        </div>
      </div>
      <div>
        <hr />
      </div>

      {pending ? (
        <div className="w-full h-full flex justify-center items-center">
          <img
            width="100px"
            height="100px"
            alt="Button Icon"
            src={"/Assets/loading2.gif"}
          />
        </div>
      ) : userBanks?.length === 0 ? (
        <div className="flex items-center flex-col relative mt-10">
          <img src="/Assets/circle.png" className="w-32 h-32 " alt="" />
          <img
            src="/Assets/cards.png"
            className="w-24 h-24 absolute bottom-20"
            alt=""
          />
          <p className="my-1">You haven't added any bank accounts</p>
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Add a new bank"
            width="150px"
            height="45px"
            padding="10px"
            margin="5px 0"
            color="#fff"
            onClick={openAddBankModal}
          />
        </div>
      ) : (
        userBanks?.length > 0 &&
        userBanks?.map((bank, index) => (
          <div className="flex flex-col lg:flex-row  mt-10" key={index}>
            <div className="flex flex-col sm:flex-row w-full gap-x-4 lg:w-[60%]  justify-between border-b border-b-[#EAECF0] py-4">
              <div className="flex fles-1  gap-x-3">
                <img src="/Assets/bankIcon.png" alt="" className="w-10 h-10" />
                <div className="">
                  <div className="flex gap-[4px] items-center">
                    <h3 className="text-sm font-[600] text-[#344054]">
                      {bank?.account_name}
                    </h3>
                    <div className="w-2 h-2 rounded-full bg-[#667085] "></div>
                    <h3 className="text-sm font-[600] text-[#344054]">
                      {bank?.account_number}
                    </h3>
                  </div>
                  <h4 className="text-sm font-400] text-[#475467]">
                    {bank?.bank_name || bank?.bank_type}
                  </h4>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="flex justify-between items-center ">
                  {deleteLoading === bank?.id ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading2.gif"}
                    />
                  ) : (
                    <h3
                      className="text-[#D92D20] cursor-pointer font-[600] text-sm"
                      onClick={() => handleRemoveBank(bank.id, bank?.currency)}
                    >
                      Remove
                    </h3>
                  )}
                </div>
                {/* <div className="flex justify-between items-center ">
                  {deleteLoading ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading2.gif"}
                    />
                  ) : (
                    <h3
                      className={`text-[#159AA8] ${
                        false ? "" : "cursor-pointer"
                      } font-[600] text-sm`}
                      // onClick={false ? null : () => handleRemoveBank(bank.id)}
                    >
                      {false ? "Default" : "Set as default"} {bank?.currency}{" "}
                      bank
                    </h3>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        ))
      )}
      {isAddBankModalOpen ? (
        <AddBankModal
          // _currency={_currency}
          // disableCurrency={userBanks?.length > 0}
          open={isAddBankModalOpen}
          cb={() => {}}
          onClose={closeAddBankModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default BankAccounts;
