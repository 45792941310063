import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_FREE_PLAN } from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";
export const getStartedFreePlan = createAsyncThunk(
  "getStatedFree/post",
  async (payload, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_FREE_PLAN,
      });

      payload.cb();
      mixpanel.track("Success", {
        type: "Subscription",
        subtype: "get-started-with-free-plan",
      });
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Subscription",
        subtype: "get-started-with-free-plan",
        message: msg,
      });
      dispatch(showErrorModal(msg));
    }
  }
);
