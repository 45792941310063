import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_NOTIFICATION } from "../../../config/api";
import {
  API_MARK_NOTIFICATION_AS_READ,
  API_GET_UNREAD_NOTIFICATION,
} from "../../../config/api";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";

export const fetchNotifications = createAsyncThunk(
  "notification/get",
  async (payload) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_NOTIFICATION,
      });
      return response.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notificationId) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_MARK_NOTIFICATION_AS_READ}/${notificationId}`,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUnreadNotifications = createAsyncThunk(
  "notifications/fetchUnreadNotifications",
  async () => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_UNREAD_NOTIFICATION,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
