import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ALL_NOTIFICATIONS } from "../../../config/api";
import { getAllNotifications } from "./getAllNotifications";
import mixpanel from "mixpanel-browser";

export const readNotification = createAsyncThunk(
  "notifications/readNotification",
  async ({ notificationId, cb }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `notifications/mark_read/${notificationId}`,
      });
      dispatch(getAllNotifications());
      cb();
      mixpanel.track("Success", {
        type: "Notfication",
        subtype: "Read-Notification",
      });
      return notificationId;
    } catch (error) {
      cb();
      mixpanel.track("Error", {
        type: "Notfication",
        subtype: "Read-Notification",
      });
      throw error;
    }
  }
);
