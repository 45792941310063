import React, { useEffect, useRef } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { protectedRoutes, publicRoutes } from "./config/routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./muiTheme";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./components/modal/MessageModal";
import MessageModal from "./components/modal/MessageModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { logout } from "./store/actions/user/logout";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { saveChathandle } from "./store/reducers/dashboardSlice";
import SeasionTimeOut from "./components/modal/SeasionTimeOut";
import mixpanel from "mixpanel-browser";
import Whatsapp from "./components/modal/Whatsapp";
import { HelmetProvider } from "react-helmet-async";

const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";
mixpanel.init(PROJECT_TOKEN, {
  debug: true,
  ignore_dnt: true,
  track_pageview: "full-url",
});

function App() {
  const tawkMessengerRef = useRef();
  const dispatch = useDispatch();
  const { user, pendingToken } = useSelector((state) => state.onboarding);
  const isAccessTokenAvailable = localStorage.getItem("accessToken");
  const { isModalOpened, modalText } = useSelector((state) => state?.message);

  useEffect(() => {
    if (
      modalText === "Token is Invalid" ||
      modalText === "Authentication required."
    ) {
      dispatch(logout());
    }
  }, [modalText]);

  useEffect(() => {
    mixpanel.track("Portal-URL-Launched");
  }, []);

  useEffect(() => {
    if (tawkMessengerRef?.current) {
      dispatch(saveChathandle(tawkMessengerRef?.current));
    }
  }, [tawkMessengerRef]);

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <RouterProvider
          // router={isAccessTokenAvailable ? protectedRoutes : publicRoutes}
          router={
            isAccessTokenAvailable && user?.id ? protectedRoutes : publicRoutes
          }
        />
        {/* <TawkMessengerReact
        propertyId="657c2c6f70c9f2407f801b30"
        widgetId="1hhmgr99c"
        ref={tawkMessengerRef}
        customStyle={{}}
      /> */}
        {isModalOpened && <MessageModal />}
        {/* {isAccessTokenAvailable && user?.id ? (
        <>
          <SeasionTimeOut />

          {pendingToken ? (
            <div className="w-full absolute top-0 left-0 z-50 bg-[rgba(0,0,0,0.6)] h-full justify-center items-center flex">
              <img
                width="44px"
                height="44px"
                alt="loading"
                src={"/Assets/loading.gif"}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )} */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          ltr={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          hideProgressBar={true}
        />
        <Whatsapp />
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
