import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { API_UPDATE_USER_PROFILE } from "../../../config/api";
import { closeModal, showErrorModal } from "../../reducers/messageSlice";
import { fetchUserProfile } from "./getProfile";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const updateUserProfile = createAsyncThunk(
  "userProfile/update",
  async (payload, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_UPDATE_USER_PROFILE,
        data: payload.data,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });

      dispatch(fetchUserProfile());
      if (payload?.from === "settings") {
        payload?.cb();
      } else {
        payload.navigate("/onboarding/investor-profile");

        setTimeout(() => {
          dispatch(closeModal());
        }, 3000);
      }
      mixpanel.track("Success", {
        type: "Profile",
        subtype: "update-profile",
      });

      return { ...payload.formValues };
    } catch (error) {
      const msg = proccessErrorMessage(error);

      mixpanel.track("Error", {
        type: "Profile",
        subtype: "update-profile",
        message: msg,
      });

      dispatch(showErrorModal(msg || "An error has occurred"));
      // toast.error(error?.response?.data?.errors?.BVN);
      payload.setSubmitting(false);
    }
  }
);
