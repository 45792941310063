import React, { useEffect, useMemo } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import ActiveIcon from "../../components/Icons/ActiveIcon";
import TotalIcon from "../../components/Icons/TotalIcon";
import ReturnIcon from "../../components/Icons/ReturnIcon";
import InvestmentCard from "../../components/cards/InvestmentCard";
import PortfolioDetails from "../../components/portfolio/PortfolioDetails";
import InvestmentOpportunities from "../../components/portfolio/InvestmentOpportunities";
import Loader from "../../components/sharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolio } from "../../store/actions/portfolio/getportfolio";
import useCurrency from "../../hooks/useCurrency";
import mixpanel from "mixpanel-browser";

function Portfolio() {
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();

  const { portfolio, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const localCurrency = currency === "NGR" ? "Naira" : currency;

  useEffect(() => {
    dispatch(getPortfolio(currency));
  }, [currency]);

  const expectedReturns = portfolio?.reduce((sum, item) => {
    return (
      sum +
      (item?.investment_category?.name !== "Startup Investing" ||
      item?.investment_category?.name !== "Exchange Traded Commodities"
        ? (Number(item?.amount) * Number(item?.investment_product?.roi)) / 100
        : 0)
    );
  }, 0);

  const investmentDetails = useMemo(
    () => [
      // {
      //   id: 1,
      //   icon: <ActiveIcon />,
      //   iconColor: "#e8ecf5",
      //   title: "Active Investments",
      //   investment: portfolioPending
      //     ? "--"
      //     : portfolio?.filter((item) => {
      //         return !item?.paymentSchedules?.expected_date?.every(
      //           (paymentSchedule) => paymentSchedule?.status === "Paid"
      //         );
      //       })?.length,
      // },
      // {
      //   id: 2,
      //   icon: <TotalIcon />,
      //   iconColor: "#f7f3fb",
      //   title: `Total ${localCurrency} Investments`,
      //   investment: `${_currency}${
      //     portfolioPending
      //       ? "--"
      //       : portfolio && portfolio?.length < 1
      //       ? 0
      //       : getAmount(
      //           portfolio?.reduce((sum, item) => sum + +item?.amount, 0),
      //           2
      //         )
      //   }`,
      // },
      // {
      //   id: 3,
      //   icon: <TotalIcon />,
      //   iconColor: "#f7f3fb",
      //   title: `Active ${localCurrency} Investments`,
      //   investment: `${_currency}${
      //     portfolioPending
      //       ? "--"
      //       : portfolio && portfolio?.length < 1
      //       ? 0
      //       : getAmount(
      //           portfolio?.reduce((sum, item) => {
      //             return !item?.paymentSchedules?.expected_date?.every(
      //               (paymentSchedule) => paymentSchedule?.status === "Paid"
      //             )
      //               ? sum + +item?.amount
      //               : sum;
      //           }, 0),
      //           2
      //         )
      //   }`,
      // },
      // {
      //   id: 4,
      //   icon: <ReturnIcon />,
      //   iconColor: "#daf1f3",
      //   title: `Expected ${localCurrency} Returns`,
      //   investment: `${_currency}${
      //     expectedReturns
      //       ? getAmount(expectedReturns)
      //       : portfolioPending
      //       ? "--"
      //       : 0
      //   }`,
      // },
    ],
    [
      portfolioPending,
      localCurrency,
      expectedReturns,
      getAmount,
      _currency,
      portfolio,
    ]
  );

  const user = useSelector((state) => state.onboarding?.user);
  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Portfolio", {
        user: user,
      });
    }
  }, [user]);

  return (
    <DashboardLayout>
      <div className="px-5  sm:pl-8 sm:pr-12  py-4">
        <h1 className="my-5 font-[600] text-[30px]">My Portfolio</h1>

        <div className="lg:grid md:grid-cols-3 flex flex-col gap-5">
          {investmentDetails.map((detail) => (
            <div key={detail.id}>
              <InvestmentCard
                currency="NG"
                currencyCode="NGN"
                width={"100%"}
                height={180}
                icon={detail.icon}
                title={detail.title}
                iconColor={detail.iconColor}
                investment={detail.investment}
              />
            </div>
          ))}
        </div>
        <div className="my-3  p-4 ">
          <PortfolioDetails />
        </div>
        <div className=" pt-4">
          <InvestmentOpportunities />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Portfolio;
