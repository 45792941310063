import React, { useEffect } from "react";
import OnboardingLayout from "../../components/sharedLayouts/OnboardingLayout";
import UserDetails from "../../components/onboarding/UserDetails";
import { useNavigate, useParams } from "react-router-dom";
import InvestorProfile from "../../components/onboarding/InvestorProfile";
import Membership from "../../components/onboarding/Membership";
import { useSelector } from "react-redux";

function Onboarding() {
  const navigate = useNavigate();
  const { type } = useParams();

  const user = useSelector((state) => state.onboarding?.user);
  const profile = useSelector((state) => state.onboarding.profile);

  useEffect(() => {
    if (
      user?.hasCompletedProfile &&
      user?.selectedPlan &&
      profile?.investorProfile?.risk_tolerance
    ) {
      navigate(-1);
    }
  }, [user, profile]);

  return (
    <OnboardingLayout>
      <div className="w-full">
        {type === undefined && <UserDetails />}
        {type === undefined ||
          (type === "investor-profile" && <InvestorProfile />)}
        {type === undefined || (type === "membership" && <Membership />)}
      </div>
    </OnboardingLayout>
  );
}

export default Onboarding;
