import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../client";
import { API_ACCOUNT_STATEMENT } from "../../../config/api";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const generateAccountStatement = createAsyncThunk(
  "generateAccountStatement/post",
  async ({ data: payload, cb, setSubmitting }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_ACCOUNT_STATEMENT,
        data: payload,
      });

      cb();
      dispatch(showSuccessModal(data.message));
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "statement",
        subtype: "generate-account-statement",
        message: msg,
      });

      setSubmitting(false);
      dispatch(showErrorModal(msg));
    }
  }
);
