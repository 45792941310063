import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import { useParams, useNavigate } from "react-router-dom";
import Summary from "../../components/opportunities/Summary";
import BackArrow from "../../components/BackArrow";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityDetails } from "../../store/actions/opportunities/getopportunitydetails";
import Loader from "../../components/sharedLayouts/Loader";
import useCurrency from "../../hooks/useCurrency";
import ResponseMessageModal from "../../components/modal/ResponseMessageModal";
import { getBalance } from "../../store/actions/dashboard/manageFunds";
import { HiOutlineDownload } from "react-icons/hi";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { MdOutlineLink } from "react-icons/md";
import mixpanel from "mixpanel-browser";

function OpportunityDetail() {
  const [_currency, getAmount] = useCurrency();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, profile } = useSelector((state) => state.onboarding);

  const { opportunity, pending: loading } = useSelector(
    (state) => state.opportunities
  );

  const [openDocList, setOpenDocList] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const scrollToTerms = () => {
    scroller.scrollTo("terms", {
      duration: 800,
      smooth: "easeInOutQuart",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(getOpportunityDetails(id));
    dispatch(getBalance());
  }, [dispatch, id]);

  useEffect(() => {
    if (
      (user?.selectedPlan?.toLowerCase() === "free" &&
        user?.free_approved_plan !== "approved") ||
      (user && !user?.selectedPlan) ||
      (profile && !profile?.BVN) ||
      (user && user?.document_verified === false) ||
      !profile
    ) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (
      opportunity &&
      ((_currency === "₦" && opportunity?.currency?.toLowerCase() !== "ngr") ||
        (_currency === "$" && opportunity?.currency?.toLowerCase() !== "usd"))
    ) {
      navigate(-1);
    }
  }, [_currency, navigate, opportunity]);

  const [showDocError, SetShowDocError] = useState(false);
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: "390",
    width: "100%",

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (opportunity?.name && user) {
      mixpanel.track("Viewed-Opportunity-Detail", {
        opportunity: opportunity?.name,
        user: user,
      });
    }
  }, [opportunity?.name, user]);

  return (
    <>
      <DashboardLayout hideCurrencyToggle={true}>
        <div className="m-5 " style={{ overflowX: "hidden" }}>
          <div className="">
            <BackArrow marginLeft={"0px"} />
          </div>
          {loading ? (
            <div className="w-full justify-center items-center flex min-h-[300px]">
              <Loader />
            </div>
          ) : (
            <div className="lg:pr-2">
              <h1 className="font-[600] capitalize  mt-10 text-3xl">
                {opportunity?.name}
              </h1>
              <h6 className="font-[700] text-[#101828] capitalize text-base lg:text-xl mt-10 py-5 ">{`About ${opportunity?.name}`}</h6>
              <div className="mt-5 flex flex-col md:flex-row justify-between">
                <div style={{ marginRight: "10px" }}>
                  <img
                    src={opportunity?.banner_link}
                    alt=""
                    className="rounded-lg object-cover"
                    style={{ height: "500px", width: "1000px" }}
                  />
                </div>

                <div className="w-full sm:w-[350px] sm:ml-[10px] sm:mr-[24px] my-[10px]">
                  <h3 className="font-[600] text-[#101828] text-[18px] pb-10">
                    Investment Detail
                  </h3>
                  {opportunity?.investment_category?.name === "Real Estate" ? (
                    <div className="flex justify-between pb-7">
                      <h5 className="text-[#475467] font-[500] text-sm">
                        Expected Annual Rental Return
                      </h5>
                      <h4 className="font-[700] text-[#101828] text-sm">
                        {Number(opportunity?.annual_rental_return)}%
                      </h4>
                    </div>
                  ) : opportunity?.investment_category?.name ===
                      "Startup Investing" ||
                    opportunity?.investment_category?.name ===
                      "Exchange Traded Commodities" ? (
                    <div className="flex justify-between pb-7">
                      <h5 className="text-[#475467] font-[500] text-sm">
                        {opportunity?.investment_category?.name ===
                        "Exchange Traded Commodities"
                          ? "Minimum Expected Return:"
                          : "Annualized Target Return:"}
                      </h5>
                      <h4 className="font-[700] text-[#101828] text-sm">
                        {Number(opportunity?.annualized_target_return)}%
                      </h4>
                    </div>
                  ) : (
                    <div className="flex justify-between pb-7">
                      <h5 className="text-[#475467] font-[500] text-sm">
                        Net Return:
                      </h5>
                      <h4 className="font-[700] text-[#101828] text-sm">
                        {Number(opportunity?.roi)}%
                      </h4>
                    </div>
                  )}
                  {opportunity?.investment_category?.name === "Real Estate" ||
                  opportunity?.investment_category?.name ===
                    "Startup Investing" ||
                  opportunity?.investment_category?.name ===
                    "Exchange Traded Commodities" ? (
                    <></>
                  ) : (
                    <>
                      <div className="flex justify-between pb-7">
                        <h5 className="text-[#475467] font-[500] text-sm">
                          Tenure:
                        </h5>
                        <h4 className="font-[700] text-[#101828] text-sm">
                          {`${opportunity?.duration} ${
                            Number(opportunity?.duration) < 2
                              ? opportunity?.duration_type?.slice(
                                  0,
                                  opportunity?.duration_type?.length - 1
                                )
                              : opportunity?.duration_type
                          }`}
                        </h4>
                      </div>
                      <div className="flex justify-between pb-7">
                        <h5 className="text-[#475467] font-[500] text-sm">
                          Payment Frequency:
                        </h5>
                        <h4 className="font-[700] capitalize text-[#101828] text-sm">
                          {opportunity?.payment_frequency}
                        </h4>
                      </div>
                    </>
                  )}

                  <div className="flex justify-between pb-7">
                    <h5 className="text-[#475467] font-[500] text-sm">
                      Risk Rating:
                    </h5>
                    <h4 className="font-[700] text-[#101828] text-sm">
                      {opportunity?.risk_factor}
                    </h4>
                  </div>

                  <div className="flex justify-between pb-7">
                    <h5 className="text-[#475467] font-[500] text-sm">
                      Minimum Investment:
                    </h5>
                    <span className="font-[700] text-[#101828] text-sm">
                      {`${_currency}${getAmount(
                        opportunity?.minimum_amount,
                        2
                      )}`}
                    </span>
                  </div>
                  <div className="flex justify-between pb-7">
                    <h5 className="text-[#475467] font-[500] text-sm">
                      Funding Goal:
                    </h5>
                    <span className="font-[700] text-[#101828] text-sm">
                      {`${_currency}${getAmount(
                        opportunity?.maximum_amount,
                        2
                      )}`}
                    </span>
                  </div>
                  {opportunity?.investment_category?.name === "Real Estate" ||
                  opportunity?.investment_category?.name ===
                    "Startup Investing" ||
                  opportunity?.investment_category?.name ===
                    "Exchange Traded Commodities" ? (
                    <>
                      <div className="flex justify-between pb-7">
                        <h5 className="text-[#475467] font-[500] text-sm">
                          {opportunity?.investment_category?.name ===
                          "Real Estate"
                            ? "Property Type"
                            : "Liquidity:"}
                        </h5>
                        <h4 className="font-[700] text-[#101828] text-sm">
                          {opportunity?.investment_category?.name ===
                          "Real Estate"
                            ? opportunity?.property_type
                            : opportunity?.liquidity}
                        </h4>
                      </div>
                      <div className="flex justify-between pb-7">
                        <h5 className="text-[#475467] font-[500] text-sm">
                          Projected Timeline:
                        </h5>
                        <h4 className="font-[700] text-[#101828] text-sm">
                          {opportunity.projected_timeline}
                        </h4>
                      </div>
                      <div className="flex justify-between pb-7">
                        <h5 className="text-[#475467] font-[500] text-sm">
                          {opportunity?.investment_category?.name ===
                          "Exchange Traded Commodities"
                            ? "Exit Windows:"
                            : "Annual Dividend:"}
                        </h5>
                        <h4 className="font-[700] text-[#101828] text-sm">
                          {opportunity?.annual_dividend}
                        </h4>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {opportunity?.investment_category?.name === "Real Estate" ||
                  opportunity?.investment_category?.name ===
                    "Startup Investing" ||
                  opportunity?.investment_category?.name ===
                    "Exchange Traded Commodities" ? (
                    <>
                      <div
                        onClick={() => setOpenDocList((prev) => !prev)}
                        className="flex w-[100%] sm:w-full flex-col mt-10"
                      >
                        <div className="cursor-pointer h-10 w-full gap-2 flex bg-[#fff] border-[#159AA8] border-2 rounded-lg justify-center items-center">
                          <h3 className="font-[600] text-sm leading-[20px] text-[#159AA8] ">
                            Campaign Documents
                          </h3>
                          {openDocList ? (
                            <HiOutlineChevronUp color="#159AA8" size={"20px"} />
                          ) : (
                            <HiOutlineChevronDown
                              color="#159AA8"
                              size={"20px"}
                            />
                          )}
                        </div>
                      </div>
                      {openDocList ? (
                        <div className="mt-3 ml-1">
                          {opportunity && opportunity?.documents?.length ? (
                            opportunity?.documents?.map((i, index) => {
                              return (
                                <a
                                  className=" h-6 w-full mb-1 flex gap-2 items-center"
                                  rel="noreferrer"
                                  href={i}
                                  download={`${
                                    opportunity?.name + (index + 1)
                                  } Documents`}
                                  target="_blank"
                                >
                                  <MdOutlineLink
                                    color="#159AA8"
                                    size={"26px"}
                                  />
                                  <h4 className="text-[#159AA8] font-Source-Sans-Pro font-[600] text-[18px]">
                                    Campaign Document {index + 1}
                                  </h4>
                                  {/* <GrFormNextLink color="#159AA8" size={"20px"} /> */}
                                </a>
                              );
                            })
                          ) : (
                            <h4 className="text-text-color-6 -mt-1 font-Source-Sans-Pro font-[400] text-[16px]">
                              There are no documents uploaded yet.
                            </h4>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <div className="flex w-[100%] sm:w-full flex-col mt-10">
                      <a
                        onClick={() => {
                          if (!opportunity?.investment_memo) {
                            SetShowDocError(true);
                          }
                        }}
                        rel="noreferrer"
                        href={
                          opportunity?.id === 69
                            ? "/Doc/Anfani-Transport.pdf"
                            : opportunity?.investment_memo
                        }
                        download={`${opportunity?.name} Documents`}
                        target="_blank"
                      >
                        <div className=" h-10 w-full gap-2 flex cursor-pointer bg-[#FFF] border-[#159AA8] border-2 rounded-lg justify-center items-center">
                          <HiOutlineDownload color="#159AA8" size={"20px"} />
                          <h3 className="font-[600] text-sm leading-[20px] text-[#159AA8] ">
                            {opportunity?.investment_category?.name ===
                            "SME Financing"
                              ? "Investment Memo"
                              : "Download Documents"}
                          </h3>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-10 sm:mr-[24px] mb-6 flex gap-8 flex-wrap">
                <div className="flex-1">
                  <Summary
                    opportunity={opportunity}
                    scrollToTerms={scrollToTerms}
                  />
                </div>
              </div>

              <ResponseMessageModal
                type={0}
                open={showDocError}
                onClose={() => {
                  SetShowDocError(false);
                }}
                handleContinue={() => {
                  SetShowDocError(false);
                }}
                btnTitle={"Ok"}
                subText={"Sorry! The document has not been uploaded."}
              />
              <ResponseMessageModal
                type={1}
                open={showModal}
                onClose={handleCloseModal}
                handleContinue={handleCloseModal}
                btnTitle={"Ok"}
                mainText={"Thank you for submiting your interest."}
                subText={
                  "Kindly ensure that you complete investment before the funding goal is met or before the campaign due date."
                }
              />
            </div>
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

export default OpportunityDetail;
