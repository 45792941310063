import { toast } from "react-toastify";

export function handleAxiosErrorException(error) {
  let message = error?.response?.data?.message;

  // if (typeof error?.data?.errors !== "undefined") {
  //   let firstErrorKey = Object.keys(error?.data.errors)[0];
  //   message = error.data.errors[firstErrorKey][0];
  // }

  return toast.error(message);

  // if (error.status === 401) {
  //   return toast.error("Session expired. Please login again");

  //   // TODO: Push to login screen;
  // }

  // if (error.status >= 500) {
  //   return toast.error(`Oops! something isn't right. please try again later`);
  // }
}

export const formatAmount = (amount) => {
  return `${new Intl.NumberFormat("en-US").format(amount)}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const convertPrice = (digit, toKobo = false) => {
  const unit = 100;
  return (toKobo ? Number(digit) * unit : Number(digit) / unit) ?? 0;
};

export const formatAsNaira = (value) => {
  if (typeof value !== "number") {
    value = parseFloat(value);
  }

  if (!isNaN(value)) {
    return `₦${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  }

  return "₦0.00";
};

export function convertBirthDate(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}/${month}/${day} `;
}
