import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../client";
import { API_WITHDRAW } from "../../../config/api";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { getBalance } from "./manageFunds";
import mixpanel from "mixpanel-browser";

export const withdraw = createAsyncThunk(
  "withdraw/post",
  async ({ data: payload, cb, setSubmitting }, { dispatch, getState }) => {
    const currency = getState().dashboard.currency;
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_WITHDRAW}${currency}`,
        data: payload,
      });
      cb();
      dispatch(showSuccessModal(data.message));
      dispatch(getBalance());
      mixpanel.track("Success", {
        type: "Withdrawal",
        subtype: "Create-Withdrawal-Request",
      });
      return data;
    } catch (error) {
      setSubmitting(false);
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Withdrawal",
        subtype: "Create-Withdrawal-Request",
        message: msg,
      });
      dispatch(showErrorModal(msg));
    }
  }
);
