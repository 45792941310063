import React from "react";

function WalletIcon() {
  return (
    <div>
      <img
        src={"/Assets/wallet.png"}
        alt="Wallet Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default WalletIcon;
