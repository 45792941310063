import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import OnboardingSidebar from "./OnboardingSidebar";

const OnboardingLayout = ({ title, children }) => {
  const [show, setShow] = useState(false);
  const handleCloseSideBar = () => {
    setShow(false);
  };

  return (
    <div className="flex h-screen  relative bg-[#fff]">
      <div
        className={`h-screen sm:w-7/12 md:w-4/12 lg:w-1/6  bg-[#FFFFFF]  z-40 fixed left-0 top-0 ${
          show ? "block" : "hidden"
        } lg:block `}
      >
        <div
          onClick={handleCloseSideBar}
          className="bg-[#ffffff] block lg:hidden z-2 absolute top-0 left-0 w-screen h-screen"
        ></div>
        <OnboardingSidebar handleCloseSideBar={handleCloseSideBar} />
      </div>

      <main className="flex-1 min-h-screen  lg:ml-[16.666667%] ">
        <Box>
          <Collapse>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => {
                  //   setOpen(false);
                  // }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            ></Alert>
          </Collapse>
        </Box>

        <div className="mt-[10px] w-full  lg:ml-[16px]">{children}</div>
      </main>
    </div>
  );
};

export default OnboardingLayout;
