import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_GET_BANK_NAMES } from "../../../config/api";

export const getBankList = createAsyncThunk(
  "users/getBankList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_BANK_NAMES,
      });

      return response?.data?.data || response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
