import { createSlice } from "@reduxjs/toolkit";
import { addFunds, getBalance } from "../actions/dashboard/manageFunds";
import { switchCurrency } from "../actions/dashboard/switchCurrency";
import { getAllWithdrawals } from "../actions/wallet/getAllWithdrawals";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: null,
    pending: false,
    success: false,
    halvestBalance: null,
    savedCreditCards: [],
    myWithdrawals: [],
    transactions: [],
    currency: "USD",
    pendingAllWithdrawals: false,
    tawkMessengerRef: null,
  },

  reducers: {
    addCreditCards: (state, action) => {
      state.savedCreditCards = [...state.savedCreditCards, action.payload];
    },
    updateCreditCards: (state, action) => {
      state.savedCreditCards = action.payload;
    },

    saveChathandle: (state, action) => {
      state.tawkMessengerRef = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(switchCurrency.fulfilled, (state, action) => {
      state.currency = action.payload;
    });
    builder.addCase(addFunds.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addFunds.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addFunds.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getBalance.pending, (state, action) => {
      state.pending = true;
      state.halvestBalance = null;
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.pending = false;
      state.halvestBalance = action.payload;
    });
    builder.addCase(getBalance.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getAllWithdrawals.pending, (state, action) => {
      state.pendingAllWithdrawals = true;

      state.myWithdrawals = [];
    });
    builder.addCase(getAllWithdrawals.fulfilled, (state, action) => {
      state.pendingAllWithdrawals = false;
      state.myWithdrawals = action.payload;
    });
    builder.addCase(getAllWithdrawals.rejected, (state, action) => {
      state.pendingAllWithdrawals = false;
    });
  },
});

export const { updateCreditCards, saveChathandle, addCreditCards } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
