import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomButton from "../buttons/Button";

const PaymentMethodModal = ({ open, subTitle, onClose }) => {
  const [paymentOption, setPaymentOption] = useState("naira");

  const handleChange = (event) => {
    setPaymentOption(event.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: 540,
            maxWidth: "90%",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 15,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
          <div className="mb-5">
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "5px" }}
            >
              Select Payment Method
            </Typography>
            {/* <Typography>
              {subTitle || "Select the desired currency you'd like to fund."}
            </Typography> */}
          </div>
          <hr
            style={{
              backgroundColor: "#EAECF0",
            }}
          />
          <RadioGroup
            aria-label="currency"
            name="currency"
            sx={{ marginBottom: 2 }}
            value={paymentOption}
            onChange={handleChange}
          >
            <FormControlLabel
              value="paystack"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Paystack
                </div>
              }
            />
            <FormControlLabel
              value="transfer"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Transfer
                </div>
              }
            />
          </RadioGroup>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="130px"
              height="32px"
              borderColor="#D0D5DD"
              color="#000"
              iconWidth="22px"
              onClick={onClose}
            />

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Continue"
              width="230px"
              height="32px"
              margin="10px"
              padding="10px"
              onClick={() => onClose(paymentOption)}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentMethodModal;
