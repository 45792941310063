import React, { useEffect, useMemo } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import CustomButton from "../../components/buttons/Button";
import { useState } from "react";
import PaymentTable from "../../components/table/PaymentTable";
import InvestmentTransactionsTable from "../../components/table/InvestmentTransactionsTable";
import { useLocation, useNavigate } from "react-router-dom";
import { timeformatter } from "../../config/dateformatter";
import useCurrency from "../../hooks/useCurrency";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentSchedule } from "../../store/actions/portfolio/getPaymentSchedule";
import { getInvestmentTransactions } from "../../store/actions/portfolio/getInvestmentTransactions";
import CustomAccordion from "../../components/accordion/CustomAccordion";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { MdOutlineLink } from "react-icons/md";
import mixpanel from "mixpanel-browser";
import { getPayoutDetails } from "../../store/actions/payouts/getPayoutDetails";

function PortfolioDetails() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, getAmount] = useCurrency();

  const isStartUp =
    state?.opportunity?.investment_category?.name === "Real Estate" ||
    state?.opportunity?.investment_category?.name === "Startup Investing" ||
    state?.opportunity?.investment_category?.name ===
      "Exchange Traded Commodities";
  const { investment_product, amount, paymentSchedules } = state?.opportunity;
  const _currency = investment_product?.currency === "NGR" ? "₦" : "$";

  const _roi = investment_product?.roi;
  const accordionData = useMemo(
    () => [
      {
        title: "Campaign Description",
        content: state?.opportunity?.investment_product?.description,
      },
    ],
    [state?.opportunity]
  );

  const ExpectedRio = useMemo(() => {
    const ROI = Number(_roi) / 100;
    const ROIAmount = ROI * amount;

    return amount && _roi ? ROIAmount : null;
  }, [amount, _currency, _roi]);

  const [openDocList, setOpenDocList] = useState(false);
  const [openCDocList, setOpenCDocList] = useState(false);
  const [activeTable, setActiveTable] = useState(1);
  const user = useSelector((state) => state.onboarding?.user);
  const {
    paymentSchedule,
    investmentMaturityDate,
    myInvestmentTotalPayout,
    transactions,
    pendingTotalPayout,
  } = useSelector((state) => state.portfolio);

  const startupDocuments = [
    // {
    //   title: "Term Sheet",
    //   ref: investment_product?.investment_memo,
    //   name: `${investment_product?.name} Documents`,
    // },
    // {
    //   title: "Participation Contract",
    //   ref: investment_product?.investment_memo,
    //   name: `${investment_product?.name} Documents`,
    // },
  ];

  useEffect(() => {
    if (!isStartUp) {
      dispatch(
        getPaymentSchedule({
          currency: investment_product?.currency,
          product_id: investment_product?.id,
        })
      );
    }
  }, [isStartUp]);

  useEffect(() => {
    dispatch(
      getInvestmentTransactions({
        product_id: investment_product?.id,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getPayoutDetails({ investmentID: investment_product?.id }));
  }, []);

  useEffect(() => {
    if (!state?.opportunity) {
      navigate(-1);
    }
  }, [state?.opportunity]);

  useEffect(() => {
    if (investment_product && user) {
      mixpanel.track("Viewed-Portfolio-Detail", {
        investment: investment_product?.name,
        user: user,
      });
    }
  }, [investment_product, user]);

  return (
    <DashboardLayout hideCurrencyToggle={true}>
      <div className="w-full h-full p-5">
        <div
          onClick={() => navigate(-1)}
          className="flex w-min mb-4 items-center cursor-pointer  hover:opacity-70 "
        >
          <IoIosArrowBack size={24} />
          <h3 className="text-base font-[500] text-text-color-1 ">Back</h3>
        </div>
        <h1 className="my-5 font-[600] text-[30px] text-[#101828]">
          {investment_product?.name}
        </h1>
        <div className="w-full pb-12  h-full">
          {isStartUp ? (
            <div className="flex flex-col w-full md:flex-row flex-wrap  gap-6">
              <div className="w-full flex flex-col items-center md:items-start md:w-[300px] p-5  border  border-[#D0D5DD]  rounded-xl">
                <img
                  src={require("../../Assets/Icons/Icon-2.png")}
                  className="w-[56px] h-[56px] mb-6"
                />
                <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                  Amount Invested
                </h4>
                <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                  {`${_currency}${getAmount(amount)}`}
                </h6>
              </div>
              <div className="w-full flex flex-col items-center md:items-start md:w-[300px] p-5  border  border-[#D0D5DD]  rounded-xl">
                <img
                  src={require("../../Assets/Icons/Icon-3.png")}
                  className="w-[56px] h-[56px] mb-6"
                />
                <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                  Projected Timeline
                </h4>
                <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                  {investment_product?.projected_timeline}
                </h6>
              </div>

              <div className="w-full flex flex-col items-center md:items-start md:w-[300px]  border  p-5  border-[#D0D5DD]  rounded-xl">
                <img
                  src={require("../../Assets/Icons/Icon-3.png")}
                  className="w-[56px] h-[56px] mb-6"
                />
                <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                  Total Payout
                </h4>
                <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                  {myInvestmentTotalPayout?.total_payout
                    ? `${_currency}${getAmount(
                        myInvestmentTotalPayout?.total_payout
                      )}`
                    : pendingTotalPayout
                    ? `${_currency}--`
                    : `${_currency}0`}
                </h6>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full md:flex-row flex-wrap  gap-6">
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px] p-5 border  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-2.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Payout Start Date
                  </h4>
                  <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                    {!paymentSchedules?.payout_date
                      ? "--"
                      : timeformatter(
                          new Date(paymentSchedules?.payout_date),
                          "formal",
                          false
                        )}
                  </h6>
                </div>
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px]  border  p-5  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-1.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Investment Maturity Date
                  </h4>
                  <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                    {!investmentMaturityDate
                      ? // ? "Campaign Ongoing"
                        "--"
                      : timeformatter(
                          new Date(investmentMaturityDate),
                          "formal",
                          false
                        )}
                  </h6>
                </div>
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px] p-5  border  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-3.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Tenure:
                  </h4>
                  <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                    {investment_product?.duration +
                      " " +
                      investment_product?.duration_type}
                  </h6>
                </div>
              </div>
              <div className="flex flex-col w-full mt-6 md:flex-row flex-wrap  gap-6">
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px] p-5  border  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-2.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Amount Invested
                  </h4>
                  <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                    {`${_currency}${getAmount(amount)}`}
                  </h6>
                </div>
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px]  border  p-5  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-3.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Total Expected Payout
                  </h4>
                  <h6 className="font-[600] text-2xl mb-1 leading-8  text-[#101828]">
                    {ExpectedRio
                      ? `${_currency}${getAmount(ExpectedRio + +amount)}`
                      : `${_currency}`}
                  </h6>
                </div>
                <div className="w-full flex flex-col items-center md:items-start md:w-[300px]  border  p-5  border-[#D0D5DD]  rounded-xl">
                  <img
                    src={require("../../Assets/Icons/Icon-1.png")}
                    className="w-[56px] h-[56px] mb-6"
                  />
                  <h4 className="font-[500] text-sm mb-1 leading-5 text-[#475467]">
                    Net ROI
                  </h4>
                  <h6 className="font-[600] flex gap-1 items-end text-2xl mb-1 leading-8  text-[#101828]">
                    {ExpectedRio
                      ? `${_currency}${getAmount(ExpectedRio)}`
                      : `${_currency}`}
                    {_roi ? (
                      <h6 className="font-[600]  text-base mb-1   text-[#475467]">
                        ({Number(_roi)}%)
                      </h6>
                    ) : (
                      <></>
                    )}
                  </h6>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col lg:flex-row gap-2 my-10 min-h-[72px] py-4  pr-6 rounded-lg justify-between lg:items-center w-full">
            <h3 className="font-[700] text-xl capitalize leading-[30px] text-[#101828] ">
              About {investment_product?.name}
            </h3>
            {isStartUp ? (
              <></>
            ) : (
              <a
                href={investment_product?.investment_memo}
                download={`${investment_product?.name} Documents`}
                target="_blank"
                rel="noreferrer"
              >
                <div className=" h-10 w-[199px] gap-2 flex cursor-pointer bg-[#159AA8] rounded-lg justify-center items-center">
                  <img
                    src={require("../../Assets/Icons/download.png")}
                    alt=""
                    className="w-5 h-5"
                  />
                  <h3 className="font-[600] text-sm leading-[20px] text-[#fff] ">
                    {isStartUp ? "Campaign Documents" : "Download Documents"}
                  </h3>
                </div>
              </a>
            )}
          </div>
          <section className="flex flex-wrap mb-6 justify-between">
            <img
              src={investment_product?.banner_link}
              // className="h-[240px] w-[349px] rounded-[16x] overflow-hidden"
              className="rounded-lg object-cover w-full sm:w-1/2 mb-4 "
              style={{ height: "280px" }}
            />
            {isStartUp ? (
              <>
                <div className="flex flex-col gap-2  min-h-[72px]  pr-6 rounded-lg  ">
                  <div
                    onClick={() => setOpenCDocList((prev) => !prev)}
                    className="flex w-[100%] sm:w-full flex-col "
                  >
                    <div className="cursor-pointer h-11 w-[280px] gap-4 flex bg-[#fff] border-[#159AA8] border-2 rounded-lg justify-center items-center">
                      <h3 className="font-[600] text-sm leading-[20px] text-[#159AA8] ">
                        Campaign Documents
                      </h3>
                      {openCDocList ? (
                        <HiOutlineChevronUp color="#159AA8" size={"20px"} />
                      ) : (
                        <HiOutlineChevronDown color="#159AA8" size={"20px"} />
                      )}
                    </div>
                  </div>
                  {openCDocList ? (
                    <div className="mt-1 ml-1">
                      {investment_product &&
                      investment_product?.documents?.length ? (
                        investment_product?.documents?.map((i, index) => {
                          return (
                            <a
                              className=" h-6 w-full mb-1 flex gap-2 items-center"
                              rel="noreferrer"
                              href={i}
                              download={`${
                                investment_product?.name + (index + 1)
                              } Documents`}
                              target="_blank"
                            >
                              <MdOutlineLink color="#159AA8" size={"28px"} />
                              <h4 className="text-[#159AA8] font-Source-Sans-Pro font-[600] text-[20px]">
                                Campaign Document {index + 1}
                              </h4>
                            </a>
                          );
                        })
                      ) : (
                        <h4 className="text-text-color-6 -mt-1 font-Source-Sans-Pro font-[400] text-[16px]">
                          There are no documents uploaded yet.
                        </h4>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex flex-col w-full gap-2 my-4 min-h-[72px] py-4  pr-6 rounded-lg justify-between  ">
                  <div
                    onClick={() => setOpenDocList((prev) => !prev)}
                    className="flex w-[100%] sm:w-full flex-col "
                  >
                    <div className="cursor-pointer h-11 w-[280px] gap-4 flex border-[#FFF] bg-[#159AA8] border rounded-lg justify-center items-center">
                      <h3 className="font-[600] text-sm leading-[20px] text-[#fff] ">
                        Investor Documents
                      </h3>
                      {openDocList ? (
                        <HiOutlineChevronUp color="#fff" size={"20px"} />
                      ) : (
                        <HiOutlineChevronDown color="#fff" size={"20px"} />
                      )}
                    </div>
                  </div>
                  {openDocList ? (
                    <div className="w-[300px] ml-1">
                      {startupDocuments.length ? (
                        startupDocuments?.map((i, index) => {
                          return (
                            // <div className="border-b w-[260px] border-b-[#159AA8] pt-[8px] mt-1 pb-1">
                            <div className=" pt-[6px] mt-[2px] ">
                              <a
                                className=" h-6   mb-1  flex gap-2 items-center"
                                rel="noreferrer"
                                href={i.ref}
                                download={i.name}
                                target="_blank"
                              >
                                <MdOutlineLink color="#159AA8" size={"24px"} />
                                <h4 className="text-[#159AA8] font-Source-Sans-Pro font-[600] text-[18px]">
                                  {i.title}
                                </h4>
                              </a>
                            </div>
                          );
                        })
                      ) : (
                        <div className=" pt-[6px]  ">
                          <h4 className="text-text-color-6 font-[400]  font-Source-Sans-Pro  text-[16px]">
                            There are no documents uploaded yet.
                          </h4>
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </section>
          {/* <section>
            <img
              src={investment_product?.banner_link}
              // className="h-[240px] w-[349px] rounded-[16x] overflow-hidden"
              className="rounded-lg object-cover"
              style={{ height: "280px", width: "50%" }}
            />
          </section> */}
          {/* {isStartUp ? (
            <div className="flex">
              <div className="flex flex-col gap-2 my-4 min-h-[72px] py-4  pr-6 rounded-lg justify-between  w-full">
                <div
                  onClick={() => setOpenDocList((prev) => !prev)}
                  className="flex w-[100%] sm:w-full flex-col "
                >
                  <div className="cursor-pointer h-10 w-[260px] gap-4 flex bg-[#FFF] border-[#159AA8] border rounded-lg justify-center items-center">
                    <h3 className="font-[600] text-sm leading-[20px] text-[#159AA8] ">
                      Investor Documents
                    </h3>
                    {openDocList ? (
                      <HiOutlineChevronUp color="#159AA8" size={"20px"} />
                    ) : (
                      <HiOutlineChevronDown color="#159AA8" size={"20px"} />
                    )}
                  </div>
                </div>
                {openDocList ? (
                  <div className="mt-1 ml-1">
                    {startupDocuments?.map((i, index) => {
                      return (
                        <a
                          className=" h-6 w-full mb-1 flex gap-2 items-center"
                          rel="noreferrer"
                          href={i.ref}
                          download={i.name}
                          target="_blank"
                        >
                          <MdOutlineLink color="#159AA8" size={"28px"} />
                          <h4 className="text-[#159AA8] font-Source-Sans-Pro font-[600] text-[20px]">
                            {i.title}
                          </h4>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col gap-2 my-4 min-h-[72px] py-4  pr-6 rounded-lg justify-between  w-full">
                <div
                  onClick={() => setOpenCDocList((prev) => !prev)}
                  className="flex w-[100%] sm:w-full flex-col "
                >
                  <div className="cursor-pointer h-10 w-[260px] gap-4 flex bg-[#FFF] border-[#159AA8] border rounded-lg justify-center items-center">
                    <h3 className="font-[600] text-sm leading-[20px] text-[#159AA8] ">
                      Campaign Documents
                    </h3>
                    {openCDocList ? (
                      <HiOutlineChevronUp color="#159AA8" size={"20px"} />
                    ) : (
                      <HiOutlineChevronDown color="#159AA8" size={"20px"} />
                    )}
                  </div>
                </div>
                {openCDocList ? (
                  <div className="mt-1 ml-1">
                    {startupDocuments?.map((i, index) => {
                      return (
                        <a
                          className=" h-6 w-full mb-1 flex gap-2 items-center"
                          rel="noreferrer"
                          href={i.ref}
                          download={i.name}
                          target="_blank"
                        >
                          <MdOutlineLink color="#159AA8" size={"28px"} />
                          <h4 className="text-[#159AA8] font-Source-Sans-Pro font-[600] text-[20px]">
                            {i.title}
                          </h4>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )} */}
          <section className="mt-5  pr-6">
            {accordionData.map((data, index) => (
              <CustomAccordion
                key={index}
                title={data.title}
                content={data.content}
                image={data.image}
                opportunity={investment_product}
                investmentCategory={
                  state?.opportunity?.investment_category?.name
                }
              />
            ))}
          </section>
          <div className="mt-10 mb-4">
            {isStartUp ? (
              <></>
            ) : (
              <div className="flex flex-wrap gap-y-2">
                <CustomButton
                  variant="outlined"
                  customColor={activeTable === 1 ? "#159AA8" : "#fff"}
                  children="Investment Transactions"
                  width="250px"
                  height="40px"
                  padding="10px"
                  className={`!border  lg:border-none ${
                    isStartUp ? "" : "lg:!rounded-tr-none lg:!rounded-br-none"
                  } !rounded-lg`}
                  borderColor={"#D0D5DD"}
                  onClick={() => setActiveTable(1)}
                  color={activeTable === 1 ? "#fff" : "#000"}
                />

                <CustomButton
                  variant="outlined"
                  customColor={activeTable === 2 ? "#159AA8" : "#fff"}
                  children="Payment Schedule"
                  width="250px"
                  height="40px"
                  padding="10px"
                  className={
                    "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
                  }
                  onClick={() => setActiveTable(2)}
                  color={activeTable === 2 ? "#fff" : "#000"}
                  borderColor={"#D0D5DD"}
                />
              </div>
            )}
          </div>
          <div className="w-full">
            {activeTable === 1 ? (
              <InvestmentTransactionsTable data={transactions} />
            ) : activeTable === 2 ? (
              isStartUp ? (
                <></>
              ) : (
                <PaymentTable
                  amount={amount}
                  paymentSchedules={paymentSchedule}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PortfolioDetails;
