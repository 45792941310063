import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import useCurrency from "../../hooks/useCurrency";
import { timeformatter } from "../../config/dateformatter";
import { IoMdInformationCircleOutline } from "react-icons/io";
import CustomTooltip from "../containers/ToolTip";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ResponseMessageModal from "../modal/ResponseMessageModal";

const OpportunityCard = ({ hanldeClick, opportunity, portfolio }) => {
  const navigate = useNavigate();

  const [_currency, getAmount] = useCurrency();

  const [warningModal, setShowWarningModal] = useState(false);

  const { user, profile } = useSelector((state) => state.onboarding);

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const handleOpenModal = () => {
    if (portfolio) {
      hanldeClick();
    } else if (!user?.hasCompletedProfile) {
      setShowWarningModal({
        modalText: "Sorry! Complete your profile to proceed",
        callToAction: {
          type: "navigate",
          path: "/onboarding",
          action: () => {
            navigate("/onboarding");
          },
        },
      });

      return;
    } else if (profile && !profile?.investorProfile?.risk_tolerance) {
      setShowWarningModal({
        modalText:
          "Sorry! Complete your Investor Profile and choose a Plan to proceed",
        callToAction: {
          type: "navigate",
          path: "/onboarding/investor-profile",
          action: () => {
            navigate("/onboarding/investor-profile");
          },
        },
      });

      return;
    } else if (
      user?.selectedPlan?.toLowerCase() === "free" &&
      user?.free_approved_plan !== "approved"
    ) {
      setShowWarningModal({
        modalText: "Sorry! Your membership is under review",
        callToAction: {
          type: "close",
          title: "Ok",
          action: () => {
            handleCloseWarningModal();
          },
        },
      });

      return;
    } else if (user && !user?.selectedPlan) {
      setShowWarningModal({
        modalText: "Sorry! Choose a Plan to proceed",
        callToAction: {
          type: "navigate",
          path: "/onboarding/membership",
          action: () => {
            navigate("/onboarding/membership");
          },
        },
      });

      return;
      // } else if (profile && !profile?.BVN && !user?.internal_user) {
    } else if (profile && !profile?.BVN) {
      setShowWarningModal({
        modalText: "Please uplaod your BVN to proceed",
        callToAction: {
          title: "Ok",
          type: "close",
          action: () => {
            navigate("/wallet?next=bvn");
          },
        },
      });
    } else if (user && user?.document_verified === false) {
      setShowWarningModal({
        modalText: "Sorry! Your identification is pending verification",
        callToAction: {
          title: "Ok",
          type: "close",
          action: () => {
            handleCloseWarningModal();
          },
        },
      });
      return;
    } else if (!profile) {
      setShowWarningModal({
        modalText: "Sorry! You do not have a profile",
        callToAction: {
          title: "Ok",
          type: "close",
          action: () => {
            handleCloseWarningModal();
          },
        },
      });
      return;
    } else {
      navigate(`/opportunities/${opportunity.id}`);
    }
  };

  const cardMediaStyle = {
    width: "100%", // Set the width of the image to 100% of its container
    height: "260px", // Set the height of the image
    objectFit: "cover", // Ensure the image covers the entire container
  };

  const colors = {
    default: {
      textColor: "#C11574",
      backgroundColor: "#FDF2FA",
      borderColor: "#FCCEEE",
    },
    agriculture: {
      textColor: "#C11574",
      backgroundColor: "#FDF2FA",
      borderColor: "#FCCEEE",
    },
    "real estate": {
      textColor: "#175CD3",
      backgroundColor: "#EFF8FF",
      borderColor: "#B2DDFF",
    },
    transportation: {
      textColor: "#B54708",
      backgroundColor: "#FFFAEB",
      borderColor: "#FEDF89",
    },
    open: {
      textColor: "#067647",
      backgroundColor: "#ECFDF3",
      borderColor: "#ABEFC6",
    },
    paidOut: {
      textColor: "#B54708",
      backgroundColor: "#FFFAEB",
      borderColor: "#FEDF89",
    },
  };
  const show = false;

  return (
    <>
      <Card
        onClick={handleOpenModal}
        style={{
          borderRadius: "10px",
          cursor: "pointer",
          position: "relative",
          boxShadow: "none",
          border: "1px solid #EAECF0",
          minHeight: portfolio ? null : "700px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardMedia
          component="img"
          height={100}
          width={10}
          //   maxHeight="140px"
          src={encodeURI(opportunity?.banner_link)}
          style={cardMediaStyle}
          alt="Opportunity"
        />
        <div className="w-full flex flex-1 flex-col overflow-hidden p-3  lg:px-5 lg:pt-4 lg:pb-3">
          {(opportunity?.investment_category?.name ||
            opportunity?.category?.name) && (
            <div className="px-[10px] bg-[#FFFFFF] absolute right-4 top-4 py-[2px] flex justify-center items-center rounded-full">
              <h4 className="font-[500] text-[#344054] text-[12px]">
                {opportunity?.investment_category?.name ||
                  opportunity?.category?.name}
              </h4>
            </div>
          )}
          <div className="flex gap-2  items-center ">
            {opportunity?.investment_status == 0 ? (
              <div
                style={{
                  backgroundColor: colors["paidOut"].backgroundColor,
                  borderColor: colors["paidOut"].borderColor,
                }}
                className="px-[10px] py-[2px] border flex justify-center items-center rounded-full"
              >
                <h4
                  style={{ color: colors["paidOut"].textColor }}
                  className="font-[500] leading-5 text-[14px]"
                >
                  Closed
                </h4>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: colors["open"].backgroundColor,
                  borderColor: colors["open"].borderColor,
                }}
                className="px-[10px] py-[2px] border flex justify-center items-center rounded-full"
              >
                <h4
                  style={{ color: colors["open"].textColor }}
                  className="font-[500] leading-5 text-[14px]"
                >
                  Open
                </h4>
              </div>
            )}
            {opportunity?.investment_category?.name === "Real Estate" ||
            opportunity?.category?.name === "Real Estate" ? (
              <></>
            ) : (
              opportunity?.sector && (
                <div
                  style={{
                    backgroundColor:
                      colors[opportunity?.sector?.toLocaleLowerCase()]
                        ?.backgroundColor || "#FDF2FA",
                    borderColor:
                      colors[opportunity?.sector?.toLocaleLowerCase()]
                        ?.borderColor || "#FCCEEE",
                  }}
                  className="px-[10px] py-[2px] border flex justify-center items-center rounded-full"
                >
                  <h4
                    style={{
                      color:
                        colors[opportunity?.sector?.toLocaleLowerCase()]
                          ?.textColor || "#C11574",
                    }}
                    className="font-[500] leading-5 text-[14px]"
                  >
                    {opportunity?.sector}
                  </h4>
                </div>
              )
            )}
          </div>
          <div className="w-5 h-6 "></div>

          <CardContent
            sx={{
              padding: "0",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h5"
                component="div"
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginBottom: "8px",
                  color: "#101828",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                paddingBottom={1}
                className="capitalize"
              >
                {opportunity?.name}
              </Typography>

              <Box
                variant="body2"
                display="flex"
                gap={"8px"}
                alignItems="center"
                paddingBottom={1}
              >
                <div className="flex  items-center">
                  <div className="flex font-[500] text-sm text-[#475467] items-center">
                    {opportunity?.investment_category?.name === "Real Estate" ||
                    opportunity?.category?.name === "Real Estate"
                      ? "Expected Annual Rental Return"
                      : opportunity?.investment_category?.name ===
                          "Exchange Traded Commodities" ||
                        opportunity?.category?.name ===
                          "Exchange Traded Commodities"
                      ? "Minimum Expected Return"
                      : opportunity?.investment_category?.name ===
                          "Startup Investing" ||
                        opportunity?.category?.name === "Startup Investing"
                      ? "Annualized Target Return"
                      : "Net Return"}
                    {show ? (
                      <div className="hiddden md:block ">
                        <CustomTooltip message={"info"} placement="right">
                          <IoMdInformationCircleOutline
                            size={14}
                            color="rgba(29,53,81,1)"
                          />
                        </CustomTooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    :
                  </div>
                </div>
                <Typography
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#101828",
                  }}
                >
                  {opportunity?.investment_category?.name === "Real Estate" ||
                  opportunity?.category?.name === "Real Estate"
                    ? `${Number(opportunity?.annual_rental_return)}%`
                    : opportunity?.investment_category?.name ===
                        "Exchange Traded Commodities" ||
                      opportunity?.category?.name ===
                        "Exchange Traded Commodities" ||
                      opportunity?.investment_category?.name ===
                        "Startup Investing" ||
                      opportunity?.category?.name === "Startup Investing"
                    ? `${Number(opportunity?.annualized_target_return)}%`
                    : `${Number(opportunity?.roi)}%`}
                </Typography>
              </Box>

              {opportunity?.investment_category?.name === "Real Estate" ||
              opportunity?.category?.name === "Real Estate" ||
              opportunity?.investment_category?.name ===
                "Exchange Traded Commodities" ||
              opportunity?.category?.name === "Exchange Traded Commodities" ||
              opportunity?.investment_category?.name === "Startup Investing" ||
              opportunity?.category?.name === "Startup Investing" ? (
                <></>
              ) : (
                <>
                  <Box
                    display="flex"
                    gap={"8px"}
                    alignItems="center"
                    paddingBottom={1}
                  >
                    <div className="flex items-center">
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#475467",
                        }}
                      >
                        Tenure:
                      </Typography>
                      {show ? (
                        <div className="hiddden md:block ">
                          <CustomTooltip message={"info"} placement="right">
                            <IoMdInformationCircleOutline
                              size={14}
                              color="rgba(29,53,81,1)"
                            />
                          </CustomTooltip>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#101828",
                      }}
                    >
                      {`${opportunity?.duration} ${
                        Number(opportunity?.duration) < 2
                          ? opportunity?.duration_type?.slice(
                              0,
                              opportunity?.duration_type?.length - 1
                            )
                          : opportunity?.duration_type
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={"8px"}
                    alignItems="center"
                    paddingBottom={1}
                  >
                    <div className="flex items-center">
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#475467",
                        }}
                      >
                        Payment Frequency:
                      </Typography>{" "}
                      {show ? (
                        <div className="hiddden md:block ">
                          <CustomTooltip message={"info"} placement="right">
                            <IoMdInformationCircleOutline
                              size={14}
                              color="rgba(29,53,81,1)"
                            />
                          </CustomTooltip>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#101828",
                      }}
                      className="capitalize"
                    >
                      {opportunity?.payment_frequency}
                    </Typography>
                  </Box>
                </>
              )}

              <Box
                display="flex"
                gap={"8px"}
                alignItems="center"
                paddingBottom={1}
              >
                <div className="flex items-center">
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#475467",
                    }}
                  >
                    Risk Rating:
                  </Typography>{" "}
                  {show ? (
                    <div className="hiddden md:block ">
                      <CustomTooltip message={"info"} placement="right">
                        <IoMdInformationCircleOutline
                          size={14}
                          color="rgba(29,53,81,1)"
                        />
                      </CustomTooltip>
                    </div>
                  ) : (
                    <></>
                  )}{" "}
                </div>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#101828",
                  }}
                >
                  {opportunity?.risk_factor}
                </Typography>
              </Box>

              <Box
                display="flex"
                gap={"8px"}
                alignItems="center"
                paddingBottom={1}
              >
                {" "}
                <div className="flex items-center">
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#475467",
                    }}
                  >
                    Minimum Investment:
                  </Typography>{" "}
                  {show ? (
                    <div className="hiddden md:block ">
                      <CustomTooltip message={"info"} placement="right">
                        <IoMdInformationCircleOutline
                          size={14}
                          color="rgba(29,53,81,1)"
                        />
                      </CustomTooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#101828",
                  }}
                >
                  {`${_currency} ${getAmount(opportunity?.minimum_amount, 2)}`}
                </Typography>
              </Box>
              {opportunity?.investment_category?.name === "SME Financing" ||
              opportunity?.category?.name === "SME Financing" ? (
                <Box
                  display="flex"
                  gap={"8px"}
                  alignItems="center"
                  paddingBottom={1}
                >
                  <div className="flex items-center">
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#475467",
                        minWidth: "84px",
                      }}
                    >
                      Payout type:
                    </Typography>{" "}
                    {show ? (
                      <div className="hiddden md:block ">
                        <CustomTooltip message={"info"} placement="right">
                          <IoMdInformationCircleOutline
                            size={14}
                            color="rgba(29,53,81,1)"
                          />
                        </CustomTooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#101828",
                    }}
                    className="capitalize"
                  >
                    {opportunity?.payout_type === "PMCE"
                      ? "Profit Monthly, Capital at the end"
                      : opportunity?.payout_type === "PACM"
                      ? "Profit and Capital Monthly"
                      : opportunity?.payout_type === "PACE"
                      ? "Profit and Capital at the end"
                      : ""}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              {/* <Box
              display="flex"
              gap={"8px"}
              alignItems="center"
              paddingBottom={1}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#475467",
                }}
              >
                Funding Goal:
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#101828",
                }}
              >
                {`${_currency} ${getAmount(opportunity?.maximum_amount, 2)}`}
              </Typography>
            </Box> */}
              {opportunity?.investment_category?.name === "Real Estate" ||
              opportunity?.category?.name === "Real Estate" ||
              opportunity?.investment_category?.name ===
                "Exchange Traded Commodities" ||
              opportunity?.category?.name === "Exchange Traded Commodities" ||
              opportunity?.investment_category?.name === "Startup Investing" ||
              opportunity?.category?.name === "Startup Investing" ? (
                <>
                  <Box
                    display="flex"
                    gap={"8px"}
                    alignItems="center"
                    paddingBottom={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#475467",
                      }}
                    >
                      {opportunity?.investment_category?.name ===
                        "Real Estate" ||
                      opportunity?.category?.name === "Real Estate"
                        ? "Property Type:"
                        : "Liquidity:"}
                    </Typography>{" "}
                    {show ? (
                      <div className="hiddden md:block">
                        <CustomTooltip message={"info"} placement="right">
                          <IoMdInformationCircleOutline
                            size={14}
                            color="rgba(29,53,81,1)"
                          />
                        </CustomTooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#101828",
                      }}
                    >
                      {opportunity?.investment_category?.name ===
                        "Real Estate" ||
                      opportunity?.category?.name === "Real Estate"
                        ? opportunity?.property_type
                        : opportunity?.liquidity}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={"8px"}
                    alignItems="center"
                    paddingBottom={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#475467",
                      }}
                    >
                      Projected Timeline:
                    </Typography>{" "}
                    {show ? (
                      <div className="hiddden md:block ">
                        <CustomTooltip message={"info"} placement="right">
                          <IoMdInformationCircleOutline
                            size={14}
                            color="rgba(29,53,81,1)"
                          />
                        </CustomTooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#101828",
                      }}
                    >
                      {opportunity.projected_timeline}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={"8px"}
                    alignItems="center"
                    paddingBottom={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#475467",
                      }}
                    >
                      {opportunity?.investment_category?.name ===
                        "Real Estate" ||
                      opportunity?.category?.name === "Real Estate" ||
                      opportunity?.investment_category?.name ===
                        "Exchange Traded Commodities" ||
                      opportunity?.category?.name ===
                        "Exchange Traded Commodities"
                        ? "Exit Windows:"
                        : "Annual Dividend:"}
                    </Typography>{" "}
                    {show ? (
                      <div className="hiddden md:block">
                        <CustomTooltip message={"info"} placement="right">
                          <IoMdInformationCircleOutline
                            size={14}
                            color="rgba(29,53,81,1)"
                          />
                        </CustomTooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#101828",
                      }}
                    >
                      {opportunity?.annual_dividend}
                    </Typography>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              {opportunity?.leftover_amount && opportunity?.maximum_amount ? (
                <>
                  <div className="mt-0 mb-[20px]">
                    <hr />
                  </div>
                  <div>
                    <LinearProgress
                      variant="determinate"
                      value={
                        Number(
                          (opportunity?.maximum_amount -
                            opportunity?.leftover_amount) /
                            Number(opportunity?.maximum_amount)
                        ) * 100
                      }
                      className=" rounded-full"
                      sx={{
                        height: "8px",

                        backgroundColor: "#EAECF0",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#17B26A",
                          borderRadius: 10,
                        },
                      }}
                    />
                    <div className="flex gap-1 mt-3 w-full">
                      <h4 className="text-[#101828] font-[600] text-sm">
                        {_currency}
                        {getAmount(
                          Number(
                            opportunity?.maximum_amount -
                              opportunity?.leftover_amount
                          ),
                          2
                        )}{" "}
                        Raised of{" "}
                        <h4 className="text-[#667085] inline font-[500] text-sm">
                          {_currency}
                          {getAmount(opportunity?.maximum_amount, 2)}
                        </h4>
                      </h4>
                    </div>

                    <div className="w-full mt-3 h-[30px] rounded-[10px] bg-[#FFFFFF] p-1 pr-[10px] border border-[#D0D5DD]  flex justify-between items-center">
                      <div className="flex justify-center items-center h-[22px] w-[120px] rounded-md border border-[#D0D5DD] ">
                        <h4 className="text-xs text-[#344054] font-[500] leading-[18px] ">
                          Campaign Due Date
                        </h4>
                      </div>
                      <h3 className="font-[600] text-sm text-[#101828] ">
                        {timeformatter(
                          // opportunity?.end_date,
                          opportunity?.campaign_end_date,
                          "formal",
                          "withInThreeDays"
                        )}
                      </h3>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </CardContent>
        </div>
      </Card>
      <ResponseMessageModal
        onClose={handleCloseWarningModal}
        open={warningModal}
        closeIcon={true}
        type={0}
        btnTitle={warningModal?.callToAction?.title}
        subText={warningModal?.modalText}
        handleContinue={() => {
          const { type, path, params, action } = warningModal?.callToAction;
          action();
        }}
      />
    </>
  );
};

export default OpportunityCard;
