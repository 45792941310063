export const proccessErrorMessage = (error) => {
  const responseData = error?.response?.data;
  const responseDataError = responseData?.error;
  const responseDataErrors = responseData?.errors;
  const responseDataMessage = responseData?.message;

  let userErrorMessage;

  const innerErrors =
    (responseDataMessage === "The given data was invalid." ||
      responseDataMessage === "error") &&
    responseDataErrors &&
    typeof responseDataErrors === "object"
      ? responseDataErrors
      : typeof responseDataMessage === "object"
      ? responseDataMessage
      : responseDataErrors;

  const proccessedInnerErrors = [];
  if (innerErrors) {
    for (let x in innerErrors) {
      proccessedInnerErrors.push(innerErrors[x]);
    }
  }

  const actuallError =
    (responseDataMessage === "The given data was invalid." ||
      responseDataMessage === "error") &&
    responseDataErrors &&
    typeof responseDataErrors === "object" &&
    proccessedInnerErrors.length > 0
      ? proccessedInnerErrors?.join(" ")
      : responseDataMessage
      ? responseDataMessage
      : responseDataError
      ? responseDataError
      : responseDataErrors
      ? responseDataErrors
      : typeof responseDataMessage === "object"
      ? proccessedInnerErrors?.join(" ")
      : "";

  switch (actuallError) {
    case "timeout of 20000ms exceeded":
      userErrorMessage = "Request Timed Out";
      break;
    default:
      userErrorMessage =
        typeof actuallError === "string"
          ? actuallError.length > 0
            ? actuallError
            : "Please check your network connection."
          : "An error has occurred, please try again";
      break;
  }
  return userErrorMessage === "error"
    ? "An unknown error occurred, please try again"
    : userErrorMessage;
};
