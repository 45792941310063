import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import { updateInvestorProfile } from "../../store/actions/profile/updateInvestorProfile";
import { investorProfileSchema } from "../../helper/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import {
  Stepper,
  Step,
  StepLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useCookies } from "react-cookie";
import "react-phone-number-input/style.css";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../buttons/Button";
import {
  categories,
  goals,
  hasPastInvestments,
  incomeRanges,
  incomeSources,
  ristTolerance,
  steps,
} from "../../config/data";
import mixpanel from "mixpanel-browser";

const ControlForm = ({ id, multiple, options, form, label }) => {
  return (
    <FormControl component="fieldset">
      <h4 className="font-[600] mb-2 text-[#101828] text-base">{label}</h4>
      <RadioGroup
        aria-label={`private-fund-${id}`}
        name={`private-fund-${id}`}
        id={id}
        value={form.values[id]}
        style={{ fontWeight: 500, fontSize: 14 }}
        onChange={(e) => {
          form.setFieldValue(id, e.target.value);
        }}
      >
        {options?.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item}
            control={
              <Radio
                sx={{
                  color: "#159AA8",
                  "&.Mui-checked": {
                    color: "#159AA8",
                  },
                }}
              />
            }
            label={item}
          />
        ))}
      </RadioGroup>
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
          {form?.errors[id]}
        </div>
      )}
    </FormControl>
  );
};
const ControlCheckForm = ({ id, multiple, options, form, label }) => {
  return (
    <FormControl component="fieldset">
      <h4 className="font-[600] mb-2 text-[#101828] text-base">{label}</h4>
      <FormGroup
        aria-label={`private-fund-${id}`}
        name={`private-fund-${id}`}
        id={id}
        style={{ fontWeight: 500, fontSize: 14 }}
      >
        {options?.map((item, index) => {
          const itemName = item?.replaceAll(" ", "");
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{
                    color: "#1C1C1C",
                    "&.Mui-checked": {
                      color: "#1C1C1C",
                    },
                  }}
                  checked={
                    typeof form.values[id] === "array"
                      ? form.values[id]?.includes(item)
                      : form.values[itemName]
                  }
                  // checked={true}
                  onChange={(e) => {
                    form.setFieldValue(itemName, !form.values[itemName]);
                  }}
                  name={itemName}
                />
              }
              label={item}
            />
          );
        })}
      </FormGroup>
      {!form?.values?.SMEFinancing &&
        !form?.values?.RealEstateInvesting &&
        !form?.values?.StartupInvesting && (
          <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
            Required
          </div>
        )}
    </FormControl>
  );
};

const InvestorProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.onboarding?.user);

  const now = new Date();
  const next30Days = new Date(now.setDate(now.getDate() + 30));

  const [cookies, setCookie, removeCookie] = useCookies(["onboarding"]);

  const investorPreProfile = cookies[`investorPreProfile-${user?.email}`];

  const profile = useSelector((state) => state.onboarding?.profile);

  const activeStep = 1;
  const pathname = "onboarding/investor-profile";

  const proceedToMembership = (values, { setSubmitting }) => {
    const {
      incomeRange,
      sourceOfIncome,
      investmentGoals,
      pastInvestment,
      riskTolerance,
      yearly_invest,
      other_goals,
    } = values;
    const payload = {
      annual_income_range:
        incomeRanges.findIndex((item) => item === incomeRange) + 1,
      source_of_income: sourceOfIncome,
      capital_to_invest: Number(yearly_invest),
      investment_categories: categories?.reduce((acc, curr) => {
        const items = [...acc];
        if (values[curr?.replaceAll(" ", "")]) {
          items.push(curr);
        }
        return items;
      }, []),

      investment_goals: investmentGoals,

      previously_invested_ethical: pastInvestment === "Yes",
      risk_tolerance:
        ristTolerance.findIndex((item) => item === riskTolerance) + 1,
    };

    if (investmentGoals === "Others (please specify)") {
      payload.other_investment_goals = other_goals;
    }
    mixpanel.track("New-User-Submitted-Onboarding-Stage-2", {
      user: user,
    });

    dispatch(
      updateInvestorProfile({
        payload,
        setSubmitting,
        cb: () => {
          setSubmitting(false);
          navigate("/onboarding/membership");
        },
      })
    );
  };

  const form = useFormik({
    initialValues: investorPreProfile || {
      sourceOfIncome: "",
      incomeRange: "",
      yearly_invest: "",
      category_invest: "",
      investmentGoals: "",
      other_goals: "",
      pastInvestment: "",
      riskTolerance: "",
    },
    validationSchema: investorProfileSchema,
    onSubmit: proceedToMembership,
  });

  useEffect(() => {
    if (profile?.investorProfile) {
      const { investorProfile } = profile;

      form.setValues({
        sourceOfIncome: investorProfile.source_of_income,
        incomeRange: incomeRanges.find(
          (item, ind) => ind + 1 == investorProfile?.annual_income_range
        ),
        yearly_invest: Number(investorProfile?.capital_to_invest),
        category_invest: investorProfile?.investment_categories,
        SMEFinancing:
          investorProfile?.investment_categories?.includes("SME Financing"),
        RealEstateInvesting: investorProfile?.investment_categories?.includes(
          "Real Estate Investing"
        ),
        StartupInvesting:
          investorProfile?.investment_categories?.includes("Startup Investing"),
        investmentGoals: investorProfile?.investment_goals || "",
        other_goals: investorProfile?.other_investment_goals || "",
        pastInvestment: hasPastInvestments?.find(
          (item, ind) => ind == investorProfile?.previously_invested_ethical
        ),
        riskTolerance: ristTolerance.find(
          (item, ind) => ind + 1 == investorProfile?.risk_tolerance
        ),
      });
    }
  }, [profile?.investorProfile]);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    return () => {
      if (form.values?.sourceOfIncome && user?.email) {
        setCookie(`investorPreProfile-${user?.email}`, form.values, {
          expires: next30Days,
          secure: true,
        });
      }
    };
  }, [form.values]);

  useEffect(() => {
    mixpanel.track("New-User-Viewed-Onboarding-Stage-2", {
      user: user,
    });
    return () => {
      mixpanel.track("New-User-Left-Onboarding-Stage-2", {
        user: user,
      });
    };
  }, []);

  return (
    <div className="my-[30px] mx-auto  lg:my-[67px] lg:mx-[54px] w-[90%]  lg:w-[800px] ">
      <div className="flex pb-8 lg:pb-0 lg:hidden flex-col w-full">
        <Stepper orientation="horizontal" activeStep={activeStep}>
          {steps.map(({ title, id, icon, link }, index) => (
            <Step
              onClick={() => {
                if (
                  index <= activeStep ||
                  (index == 2 && profile?.investorProfile?.risk_tolerance)
                ) {
                  navigate(link);
                }
              }}
              key={id}
            >
              <StepLabel>
                <div
                  className={`onboarding-sidebar__item  ${
                    pathname === link && "onboarding-sidebar__active"
                  }`}
                >
                  <div>{icon}</div>
                  <span className="list-text text-[#000]">{title}</span>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="mt-2  lg:mt-8 mb-7">
        <div
          onClick={() => navigate("/")}
          className="flex w-min mb-4 items-center cursor-pointer  hover:opacity-70 "
        >
          <IoIosArrowBack size={24} />
          <h3 className="text-base font-[500] text-text-color-1 ">Back</h3>
        </div>

        <h1 className="font-[600] text-[30px] leading-[38px]  text-[#101828]">
          Investor Profile
        </h1>
        <p className="text-[14px] font-[400] mt-2 text-[#475467}">
          We need to ask you a few investment questions. We do this to make sure
          you know what you are investing in.
        </p>
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          options={incomeSources}
          id={"sourceOfIncome"}
          form={form}
          label={" What is your main source of income?"}
        />
      </div>
      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"What is your annual income range (equivalent of naira value)"}
          form={form}
          id={"incomeRange"}
          options={incomeRanges}
        />
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <h4 className="font-[600] mb-2 text-[#101828] text-base">
          How much capital can you comfortably invest in a year? (equivalent of
          naira value)
        </h4>
        <div className="w-full   ">
          <CustomInput
            fullWidth
            height="12px"
            form={form}
            // icon="₦"
            prefix="$"
            customType="amount"
            // iconPosition={"left"}
            id="yearly_invest"
            placeholder={"Enter amount"}
            borderColor="#D0D5DD"
          />
        </div>
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlCheckForm
          label={
            "Select the category of investment (s) that you are mostly interested in?"
          }
          multiple
          form={form}
          id={"category_invest"}
          options={categories}
        />
      </div>
      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"What are your primary investment goals?"}
          form={form}
          id={"investmentGoals"}
          options={goals}
        />

        {form.values?.investmentGoals === "Others (please specify)" ? (
          <div className="w-full   ">
            <CustomInput
              fullWidth
              height="10px"
              form={form}
              id="other_goals"
              placeholder={"Other goals"}
              borderColor="#D0D5DD"
            />
          </div>
        ) : null}
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"Have you previously invested in ethical investments?"}
          form={form}
          id={"pastInvestment"}
          options={hasPastInvestments}
        />
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"How would you describe your risk tolerance?"}
          form={form}
          id={"riskTolerance"}
          options={ristTolerance}
        />
      </div>

      <div className="py-5 flex justify-end items-center gap-3">
        <CustomButton
          variant="outlined"
          customColor="#fff"
          children="Back"
          width="65px"
          height="40px"
          padding="10px"
          color="#000"
          onClick={() => navigate(-1)}
        />
        <CustomButton
          variant="contained"
          customColor="#159AA8"
          children="Proceed to Membership"
          width="224px"
          height="40px"
          padding="10px"
          color="#fff"
          loading={form.isSubmitting}
          disabled={!form.isValid || (!form.dirty && !investorPreProfile)}
          onClick={form.handleSubmit}
        />
      </div>
    </div>
  );
};

export default InvestorProfile;
