import React from "react";

function WithdrawIcon() {
  return (
    <div>
      <img src={"/Assets/withdraw.png"} alt="WIthdraw Icon" />
    </div>
  );
}

export default WithdrawIcon;
