import React, { useState } from "react";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  TablePagination,
  // ThemeProvider,
} from "@mui/material";
// import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
// import { theme } from "../../config/MUITheme";
// import {HiOutlineChevronLeft,} from "react-icons/hi";
// import Menu from "../submenus/Menu";
// import BackDrop from "../BackDrop";

const CustomFooterStatusComponent = ({ rowsPerPage, setRowsPerPage }) => {
  const TablePag = (handleToggle, rowsPerPage) => {
    return (
      <div
        onClick={(e) => {
          handleToggle(e);
        }}
        className="flex items-center gap-5"
      >
        {/* <h4 className="font-Inter font-medium text-sm leading-5 text-black">
          Rows per page{" "}
        </h4>
        <div className="flex items-center justify-center gap-2 h-[36px] w-[61px] border border-[#d0d5dd] rounded-lg ">
          <h4 className="text-[#344054] font-Inter font-semibold leading-5 text-sm">
            {rowsPerPage}
          </h4>
          <img alt={"updown"} src={require("../../assets/updown.png")} />
        </div> */}
      </div>
    );
  };
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  // const handleClickedOptions = (type) => {
  //   setRowsPerPage(type);
  //   handleToggle();
  // };

  const [showModal, setShowModal] = useState(false);
  const handleToggle = (event) => {
    setShowModal((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="p-3 sm:p-8"
    >
      <Box className="flex gap-5 items-center">
        <TablePagination
          onPageChange={() => {}}
          component={() => TablePag(handleToggle, rowsPerPage)}
          count={100}
          page={page}
          rowsPerPage={20}
        />
      </Box>
      {/* <BackDrop handleClicked={handleToggle} open={showModal}>
        <Menu type="pagination" onClickOptions={handleClickedOptions} />
      </BackDrop> */}
      <Box className="flex  gap-5 items-center">
        <Pagination
          shape="rounded"
          // sx={(theme) => ({ padding: theme.spacing(1.5, 0), color: "red" })}
          color="primary"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          renderItem={(item) => {
            return (
              <PaginationItem
                components={{
                  next: (props) => <HiOutlineChevronRight />,

                  previous: (props) => <HiOutlineChevronLeft />,
                }}
                {...item}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomFooterStatusComponent;
