import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import onboarding from "./reducers/onboardingSlice";
import transactions from "./reducers/transactionsSlice";
import message from "./reducers/messageSlice";
import dashboard from "./reducers/dashboardSlice";
import portfolio from "./reducers/portfolioSlice";
import opportunities from "./reducers/opportunitiesSlice";
import bank from "./reducers/bankSlice";
import userProfile from "./reducers/profileSlice";
import subscribe from "./reducers/subscribeSlice";
import notifications from "./reducers/notificationsSlice";

const reducers = combineReducers({
  bank,
  onboarding,
  dashboard,
  portfolio,
  message,
  opportunities,
  userProfile,
  subscribe,
  transactions,
  notifications
});

const rootReducer = (state, action) => {
  if (action.type === "users/logout/fulfilled") {
    // This will reset the state back to its initial state on logout
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["onboarding", "profile", "ui", "dashboard"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const persist = persistStore(store);
