import React from "react";
import Grid from "@mui/material/Grid";

const AuthVerify = ({ children, type }) => {
  return (
    <Grid
      // columnSpacing={4}
      rowSpacing={2}
      container
      style={{ width: "100%", height: "100%" }}
    >
      <Grid item xs={12} lg={6} style={{ width: "50%", height: "100%" }}>
        {children}
      </Grid>
      <Grid
        className="hidden laptop:block fixed h-screen right-0 bottom-4"
        item
        xs={0}
        lg={6}
        style={{ width: "50%" }}
      >
        <div className="w-full h-screen relative">
          <img
            src="/Assets/auth-verify.jpeg"
            className="object-cover object-left-bottom w-full h-full"
          />
          <div className="absolute top-0 left-0  w-full  h-full bg-[rgba(0,0,0,0.3)]"></div>
          <div className="absolute top-0 left-0  w-full  h-full flex flex-col justify-end items-center">
            <div className="w-full  h-[72%] flex flex-col justify-center  items-center">
              <h2 className="color-[#FFF] mb-5 font-[600] max-w-[592px] text-[60px] text-white leading-[72px]">
                Start your journey to building generational wealth
              </h2>
              <h4 className="color-[#FFF] font-[500] max-w-[592px] text-[18px] text-white leading-[28px]">
                Welcome back to the community of smart Investors who are growing
                their wealth the ethical way.
              </h4>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AuthVerify;
