import React from "react";

function PortfolioIcon() {
  return (
    <div>
      <img
        src={"/Assets/portfolio.png"}
        alt="Portfolio Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default PortfolioIcon;
