import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../buttons/Button";
import { closeModal } from "../../store/reducers/messageSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MessageModal = ({
  _isModalOpened,
  _modalText,
  _success,
  _error,
  _buttonClicked,
  _handleClose,
  btnTitle,
}) => {
  const theme = useTheme();
  const lassThan900px = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { isModalOpened, modalText, success, error } = useSelector(
    (state) => state.message
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={isModalOpened || _isModalOpened}
      onClose={_modalText ? _handleClose : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={lassThan900px}
      maxWidth={lassThan900px ? "400px" : null}
    >
      <DialogContent className="sm:min-w-[500px] rounded-[10px] bg-white sm:max-w-[600px]  ">
        <DialogContentText id="alert-dialog-description">
          <div className="flex items-center w-full flex-col">
            <img
              width="100px"
              height="100px"
              src={`/Assets/${success || _success ? "success" : "error"}.png`}
              alt={success || _success ? "Success" : "Error"}
            />
            <h1 className="mt-5 text-2xl text-[#101828] text-center font-bold">
              {success || _success ? "Success" : "Error"}
            </h1>
            <p className="text-center text-sm font-[400]  w-[85%] text-[#475467] mt-3">
              {modalText || _modalText}
            </p>
          </div>
        </DialogContentText>
        <div className="flex justify-center mt-5">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children={btnTitle || "OK"}
            width="100%"
            height="50px"
            margin="10px"
            padding="10px"
            onClick={_buttonClicked || handleClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageModal;
