import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Auth from "../../components/containers/Auth";
import CustomButton from "../../components/buttons/Button";
import CustomInput from "../../components/inputs/CustomInput";
import { useFormik } from "formik";
import { forgotValidationSchema } from "../../helper/validationSchemas";
import { forgotPassword } from "../../store/actions/onboarding/forgotPassword";
import { IoArrowBackSharp } from "react-icons/io5";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { pending: loading, user } = useSelector((state) => state.onboarding);

  const handlSubmit = () => {
    dispatch(forgotPassword({ data: form.values, navigate }));
  };

  const form = useFormik({
    initialValues: {
      email: user?.email || "",
    },
    onSubmit: handlSubmit,
    validationSchema: forgotValidationSchema,
  });

  return (
    <Auth>
      <div className="py-8 px-8  w-full h-screen flex items-center justify-center my-[-20]">
        <div>
          <div className="max-w-[400px] h-[498px]  my-62  flex flex-col ">
            <div>
              <div
                // onClick={() => navigate("/")}
                onClick={() => navigate(-1)}
                // className="flex cursor-pointer justify-between items-center rounded-[8px] my-6 border  border-[#D0D5DD] px-4 py-[10px] h-11 w-[170px]"
                className="flex cursor-pointer justify-between gap-3 items-center rounded-[8px] my-6 border  border-[#D0D5DD] px-4 py-[10px] h-11 w-min"
              >
                <IoArrowBackSharp size={20} color={"#344054"} />

                <h4 className="text-[18px] font-[500] leading-6">
                  {/* back to Sign In */}
                  Back
                </h4>
              </div>
            </div>
            <h2 className="  font-[600] leading-[44px] mb-2 text-4xl text-[#101828]   ml-0 sm:mb-2  tracking-[1%]">
              Reset Password
            </h2>
            <h4 className="font-[400] text-base leading-6 text-[#475467] ">
              Enter your email address and We'll send you instructions to reset
              your password.
            </h4>
            <div className="w-full mt-8">
              <label>Email*</label>
              <CustomInput
                width="100%"
                height="18px"
                id="email"
                form={form}
                placeholder={"Enter your email"}
                borderColor="#D0D5DD"
              />
            </div>

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              loading={loading}
              width="100%"
              height="48px"
              padding="10px"
              margin="24px 0px"
              color="#fff"
              onClick={form.handleSubmit}
            >
              Reset Password
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center m-4">
        <span>&copy; Halvest2024</span>
        <span className="flex items-center">
          <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
          support@halvestco.com
        </span>
      </div>
    </Auth>
  );
};

export default ForgotPassword;
