import React, { useCallback, useEffect } from "react";
import CustomButton from "../buttons/Button";
import { useState } from "react";
import WelcomeModal from "../modal/WelcomeModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

const CompleteProfile = ({
  title,
  info,
  type,
  btnTitle,
  handleButtonClick,
  buttonEnable,
  buttonLoading,
  longText,
  applicationStatus,
}) => {
  const user = useSelector((state) => state.onboarding?.user);

  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const navigate = useNavigate();
  const { profile, pending } = useSelector((state) => state.onboarding);
  const handleCompleteProfileClick = useCallback(() => {
    if (profile?.id_number && !profile?.investorProfile?.risk_tolerance) {
      navigate("/onboarding/investor-profile");
    } else if (profile?.investorProfile?.risk_tolerance) {
      navigate("/onboarding/membership");
    } else {
      navigate("/onboarding");
    }
    setWelcomeModalOpen(true);
  }, [profile]);

  const handleWelcomeModalClose = () => {
    // Close the WelcomeModal
    setWelcomeModalOpen(false);
  };

  useEffect(() => {
    mixpanel.track("New-User-Viewed-Dashboard", {
      user: user,
    });
  }, []);

  return (
    <div
      className="complete-profile !overflow-hidden relative"
      style={{
        backgroundImage: 'url("/Assets/banner.png")',
        backgroundPosition: "center",
        backgroundColor: "rgba(0,0,0,1)",
        minHeight: "220px",
        width: "100%",
      }}
    >
      <div className="complete-profile__left w-full pb-7 ">
        <h1 className="  font-medium text-[14px] sm:text-[24px] leading-[24px] sm:leading-[36px] tracking-[1%]">
          {title}
        </h1>

        <p
          style={{
            color: "#fff",
            marginTop: "15px",
            lineHeight: 2.0,

            fontSize: "14px",
            marginBottom: "10px",
          }}
          className=" font-medium text-[10px] pr-6 lg:pr-0  sm:text-xs leading-[16px] sm:leading-[18px] text-[#fff]"
        >
          {info}
        </p>

        <CustomButton
          variant="contained"
          customColor="#159AA8"
          children="Complete Profile"
          width="150px"
          height="32px"
          padding="10px"
          marginTop="15px"
          color="#fff"
          disabled={pending}
          onClick={handleCompleteProfileClick}
        />
        <div className="w-3/4 hidden md:block">
          <img
            className="absolute  right-0 top-10 "
            height={"260px"}
            src="/Assets/bannerLogo.png"
            alt="cute-user"
            style={{
              width: "310px",
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
      </div>
      <WelcomeModal open={welcomeModalOpen} onClose={handleWelcomeModalClose} />
    </div>
  );
};

export default CompleteProfile;
