import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomInput from "../inputs/CustomInput";
import { useFormik } from "formik";
import CustomButton from "../buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { addFunds } from "../../store/actions/dashboard/manageFunds";
import { useDispatch, useSelector } from "react-redux";

function AmountModal({ open, onClose }) {
  const loading = useSelector((state) => state?.dashboard?.pending);
  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      amount: "",
    },
  });

  const handleWallet = () => {
    const amount = form.values.amount;
    dispatch(addFunds({ data: { amount } }));

    setTimeout(() => {
      onClose();
    }, 3000);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5">
          <h1 className="font-semibold text-2xl">Enter amount</h1>
          <p className="text-sm mb-4">
            How much do you want to fund? You will be charged 1.4% of the amount
            (up to a maximum amount of N2,000) for adding money with a card
          </p>

          <div className="w-full mb-4 ">
            <CustomInput
              id="amount"
              width="450px"
              form={form}
              label={"Amount (₦)"}
              height="25px"
              mt="10"
              mr="10px"
            />
          </div>
          <div style={{ width: "420px" }}>
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Fund"
              loading={loading}
              width="100%"
              height="50px"
              padding="10px"
              onClick={handleWallet}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default AmountModal;
