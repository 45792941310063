import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addBankDetails } from "../../store/actions/bank/bankUserActions";
import { useFormik } from "formik";
import { countries, currencyOptions, formatArray } from "../../config/data";
import { Autocomplete, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { bankSchema } from "../../helper/validationSchemas";
import SelectField from "../inputs/SelectField";
import { getBankList } from "../../store/actions/bank/getBankList";
import { getAccountName } from "../../store/actions/bank/getAccountName";
import useCurrency from "../../hooks/useCurrency";
import { bankUSDUserActions } from "../../store/actions/bank/bankUSDUserActions";

const AddBankModal = ({ open, onClose, disableCurrency, _currency, cb }) => {
  const dispatch = useDispatch();
  // const [_currency, getAmount] = useCurrency();

  const [selectedBank, setSelectedBank] = useState("");
  const [a, seta] = useState();

  const { banks } = useSelector((state) => state?.bank);
  const { userBanks } = useSelector((state) => state?.bank);
  const loading = useSelector((state) => state?.bank?.isAddBank);

  const form = useFormik({
    initialValues: {
      account_name: "",
      account_number: "",
      bank_type: {
        value: "International Bank Account",
        label: "International Bank Account",
      },
      beneficiary_country: {
        value: "United States",
        label: "United States",
      },
    },
    validationSchema: bankSchema.pick(["account_name", "account_number"]),
  });

  const handleBankDetails = () => {
    const payload = {
      data:
        form?.values?.currency?.value === "USD"
          ? {
              bank_type: form.values.bank_type?.value,

              beneficiary_bank_address: form.values.beneficiary_bank_address,
              beneficiary_address: form.values.beneficiary_address,
              beneficiary_country: form.values.beneficiary_country?.value,
              account_number: form.values.account_numberUSD,

              account_name: form.values.beneficiary_bank_name,
              currency: form.values.currency?.value,
              beneficiary_name: form.values.beneficiary_name,
            }
          : {
              account_name: form.values.account_name,
              account_number: form.values.account_number,
              bank_name: selectedBank?.label,
              currency: form.values.currency?.value,
            },
      cb: () => {
        form.resetForm();
        onClose();
        cb();
      },
    };

    if (form.values?.bank_type?.value === "Nigerian Bank Account") {
      payload.data.swift_code = form.values.swift_code || "N/A";
      payload.data.sort_code = form.values.sort_code || "N/A";
    } else if (form.values?.bank_type?.value === "International Bank Account") {
      payload.data.routing_number = form.values.routing_number || "N/A";
      payload.data.SWIFT_BIC = form.values.SWIFT_BIC || "N/A";
      payload.data.IBAN = form.values.IBAN || "N/A";
    }
    if (form?.values?.currency?.value === "USD") {
      dispatch(bankUSDUserActions(payload));
    } else {
      dispatch(addBankDetails(payload));
    }
  };

  useEffect(() => {
    dispatch(getBankList());
  }, []);

  const [gettingAccountName, setgettingAccountName] = useState(false);

  useEffect(() => {
    const _account_number = form?.values?.account_number;
    if (_account_number?.length === 10) {
      setgettingAccountName(true);
      dispatch(
        getAccountName({
          payload: {
            bank_code: selectedBank?.code,
            account_number: _account_number,
          },
          cb: (accountName) => {
            setgettingAccountName(false);
            form.setFieldValue("account_name", accountName);
          },
          deleteLastDegit: () => {
            form.setFieldValue("account_number", _account_number?.slice(0, 9));
          },
          setgettingAccountName,
        })
      );
    } else form.setFieldValue("account_name", "");
  }, [form?.values?.account_number, selectedBank]);

  useEffect(() => {
    // if (userBanks?.length < 1) {
    form.setFieldValue(
      "currency",
      _currency === "$"
        ? { value: "USD", label: "USD ($)" }
        : { value: "NGR", label: "Naira (₦)" }
    );
    // } else if (
    //   userBanks?.length > 0 &&
    //   userBanks?.some((bank) => bank?.currency?.toLowerCase() === "dollar")
    // ) {
    //   form.setFieldValue("currency", { value: "NGR", label: "Naira (₦)" });
    // } else if (
    //   userBanks?.length > 0 &&
    //   userBanks?.some((bank) => bank?.currency == "NGR")
    // ) {
    //   form.setFieldValue("currency", { value: "USD", label: "USD ($)" });
    // }
  }, [userBanks, _currency]);

  useEffect(() => {
    seta(form.values.currency);
  }, [form]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: 500,
            maxWidth: "90%",
            // minHeight: 700,
            maxHeight: "90%",
            overflowY: "auto",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 25,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full  my-2">
            <h1 className="text-2xl font-semibold mb-2">Add a bank account</h1>
            <p className="text-sm">
              Please, only add a bank account that you own. Transactions to
              accounts that don’t belong to you will be flagged
            </p>

            <div className="w-full m-2 mt-5"></div>

            <div className="w-full  my-2">
              <label className="text-sm font-[600] text-[#344054]">
                Select Currency
              </label>
              <div className="w-full mb-4">
                <SelectField
                  label="Choose Currency"
                  id="currency"
                  form={form}
                  disabled={disableCurrency}
                  options={currencyOptions}
                  sx={{ width: "100%", height: "50px" }}
                />
              </div>

              {form.values?.currency?.value === "USD" || _currency === "$" ? (
                <>
                  <label className="text-sm font-[600] text-[#344054]">
                    Bank Type
                  </label>
                  <div className="w-full relative mb-4">
                    <SelectField
                      label="Choose Bank Type"
                      id="bank_type"
                      clearFields={[
                        // "account_name",
                        "account_numberUSD",
                        "sort_code",
                        "swift_code",
                        "routing_number",
                        "IBAN",
                        "beneficiary_bank_name",
                        "beneficiary_name",
                        "SWIFT_BIC",
                        "beneficiary_bank_address",
                        "beneficiary_address",
                        "beneficiary_country",
                      ]}
                      form={form}
                      options={[
                        {
                          value: "Nigerian Bank Account",
                          label: "Nigerian Bank Account",
                        },
                        {
                          value: "International Bank Account",
                          label: "International Bank Account",
                        },
                      ]}
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Beneficiary Bank Name
                  </label>
                  <div className="w-full relative mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="beneficiary_bank_name"
                      form={form}
                      placeholder={"Enter Beneficiary Bank Name "}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Beneficiary Bank Address
                  </label>
                  <div className="w-full relative mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="beneficiary_bank_address"
                      form={form}
                      placeholder={"Enter Beneficiary Bank Address "}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Beneficiary Address
                  </label>
                  <div className="w-full relative mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="beneficiary_address"
                      form={form}
                      placeholder={"Enter Beneficiary Address "}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>

                  {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                    <></>
                  ) : (
                    <>
                      <label className="text-sm font-[600] text-[#344054]">
                        Beneficiary Country
                      </label>
                      <div className="w-full mb-4">
                        <SelectField
                          label="Choose Beneficiary Country"
                          id="beneficiary_country"
                          form={form}
                          options={countries}
                          sx={{ width: "100%", height: "50px" }}
                        />
                      </div>
                    </>
                  )}

                  <label className="text-sm font-[600] text-[#344054]">
                    Account Number
                  </label>
                  <div className="w-full mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="account_numberUSD"
                      type={"number"}
                      form={form}
                      placeholder={"Enter your Account Number"}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Beneficiary Name
                  </label>
                  <div className="w-full relative mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="beneficiary_name"
                      form={form}
                      placeholder={"Enter Beneficiary Name "}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>
                  {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                    <></>
                  ) : (
                    <>
                      <label className="text-sm font-[600] text-[#344054]">
                        Routing Number
                      </label>
                      <div className="w-full mb-4">
                        <CustomInput
                          // width="400px"
                          fullWidth
                          height="18px"
                          id="routing_number"
                          // type={"number"}
                          form={form}
                          placeholder={"Enter your Routing Number"}
                          borderColor="#D0D5DD"
                          mt="10px"
                          pt="28px"
                        />
                      </div>
                    </>
                  )}
                  {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                    <>
                      {" "}
                      <label className="text-sm font-[600] text-[#344054]">
                        Sort Code
                      </label>
                      <div className="w-full mb-4">
                        <CustomInput
                          // width="400px"
                          fullWidth
                          height="18px"
                          id="sort_code"
                          // type={"number"}
                          form={form}
                          placeholder={"Enter your  Sort Code "}
                          borderColor="#D0D5DD"
                          mt="10px"
                          pt="28px"
                        />
                      </div>
                      <label className="text-sm font-[600] text-[#344054]">
                        Swift Code
                      </label>
                      <div className="w-full mb-4">
                        <CustomInput
                          // width="400px"
                          fullWidth
                          height="18px"
                          id="swift_code"
                          // type={"number"}
                          form={form}
                          placeholder={"Enter your Swift Code"}
                          borderColor="#D0D5DD"
                          mt="10px"
                          pt="28px"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="text-sm font-[600] text-[#344054]">
                        SWIFT / BIC
                      </label>
                      <div className="w-full mb-4">
                        <CustomInput
                          // width="400px"
                          fullWidth
                          height="18px"
                          id="SWIFT_BIC"
                          // type={"number"}
                          form={form}
                          placeholder={"Enter your SWIFT / BIC"}
                          borderColor="#D0D5DD"
                          mt="10px"
                          pt="28px"
                        />
                      </div>
                      <label className="text-sm font-[600] text-[#344054]">
                        IBAN
                      </label>
                      <div className="w-full mb-4">
                        <CustomInput
                          // width="400px"
                          fullWidth
                          height="18px"
                          id="IBAN"
                          // type={"number"}
                          form={form}
                          placeholder={"Enter your IBAN"}
                          borderColor="#D0D5DD"
                          mt="10px"
                          pt="28px"
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <label className="text-sm font-[600] text-[#344054]">
                    Select Bank
                  </label>
                  <div className="w-full mb-4">
                    <Stack spacing={2} sx={{ width: "100%" }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={
                          form.values?.currency?.value === "USD"
                            ? []
                            : formatArray(banks, "name")
                        }
                        value={selectedBank}
                        onChange={(_, newValue) => setSelectedBank(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            id="bank_name"
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "20px",
                                padding: "15px",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Account Number
                  </label>
                  <div className="w-full mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="account_number"
                      type={"number"}
                      form={form}
                      disabled={!selectedBank}
                      placeholder={"Enter your Account Number"}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />
                  </div>
                  <label className="text-sm font-[600] text-[#344054]">
                    Account Name
                  </label>
                  <div className="w-full relative mb-4">
                    <CustomInput
                      // width="400px"
                      fullWidth
                      height="18px"
                      id="account_name"
                      disabled={true}
                      form={form}
                      placeholder={"Account Name"}
                      borderColor="#D0D5DD"
                      mt="10px"
                      pt="28px"
                    />

                    {gettingAccountName ? (
                      <div className="w-full top-0 h-full absolute flex justify-center items-center ">
                        <img
                          width="30px"
                          height="30px"
                          alt="Button Icon"
                          src={"/Assets/loading2.gif"}
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              )}

              <div className="flex w-full  items-center">
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Continue"
                  width="100%"
                  height="45px"
                  disabled={
                    form?.values?.currency?.value === "USD"
                      ? false
                      : !form?.dirty || !selectedBank || !form?.isValid
                  }
                  loading={loading}
                  padding="10px"
                  margin="15px 0"
                  color="#fff"
                  onClick={handleBankDetails}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddBankModal;
