import React from "react";
import Grid from "@mui/material/Grid";

const TealAuth = ({ children, type }) => {
  return (
    <Grid
      // columnSpacing={4}
      rowSpacing={2}
      container
      style={{ width: "100%", height: "100%" }}
    >
      <Grid item xs={12} lg={6} style={{ width: "50%", height: "100%" }}>
        {children}
      </Grid>
      <Grid
        className="hidden laptop:block fixed h-screen right-0 bottom-4"
        item
        xs={0}
        lg={6}
        style={{ width: "50%" }}
      >
        <div className="w-full h-screen relative">
          <img
            src="/Assets/dashboard.jpeg"
            className="object-cover object-left-bottom w-full h-full"
          />
          <div className="absolute top-0 left-0  w-full  h-full bg-[rgba(0,0,0,0.3)]"></div>
          <div className="absolute top-0 left-0  w-full  h-full flex flex-col justify-end items-center">
            <div className="w-full  h-[72%] flex flex-col justify-center  items-center">
              <h2 className="color-[#FFF] mb-5 font-[600] max-w-[592px] text-[60px] text-white leading-[72px]">
                Halvest Simplifies Wealth Building
              </h2>
              <ul className="list-disc list-inside w-full max-w-[592px] ">
                <li
                  className="text-[rgba(253,254,255,0.93)] mb-2 text-[18px] font-[500] cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                  // className={`main-sidebar__item mb-2 ${
                  //   pathname === link && "main-sidebar__active"
                  // }  hover:bg-#E4F9FC`}
                >
                  Invest in Bits
                </li>
                <li
                  className="text-[rgba(253,254,255,0.93)] font-[500] mb-2 text-[18px] cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                  // className={`main-sidebar__item mb-2 ${
                  //   pathname === link && "main-sidebar__active"
                  // }  hover:bg-#E4F9FC`}
                >
                  Customizable savings
                </li>
                <li
                  className="text-[rgba(253,254,255,0.93)] mb-2 font-[500] text-[18px] cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                  // className={`main-sidebar__item mb-2 ${
                  //   pathname === link && "main-sidebar__active"
                  // }  hover:bg-#E4F9FC`}
                >
                  Beginner-friendly investment options
                </li>
                <li
                  className="text-[rgba(253,254,255,0.93)] font-[500] mb-2 text-[18px] cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                  // className={`main-sidebar__item mb-2 ${
                  //   pathname === link && "main-sidebar__active"
                  // }  hover:bg-#E4F9FC`}
                >
                  Track and monitor your savings and investments in one place
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default TealAuth;
