import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_FORGOT_PASSWORD } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async ({ data, navigate }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_FORGOT_PASSWORD,
        data,
      });

      mixpanel.track("Success", {
        type: "onboarding",
        subtype: "forgot-password",
      });
      if (navigate) navigate(`/forgot-password-success?email=${data.email}`);
      return response;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "forgot-password",
        message: msg,
      });
      toast.error(msg);
      throw error;
    }
  }
);
