import { createSlice } from "@reduxjs/toolkit";
import { getPackages, subscribe } from "../actions/subscription/membership";
import { getStartedFreePlan } from "../actions/subscription/getStartedFreePlan";
import { getSubscriptionHistory } from "../actions/subscription/getSubscriptionHistory";

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState: {
    error: null,
    packages: [],
    paymentURL: {},
    subscriptionHistory: [],

    pending: false,
    success: false,
    FreePending: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getPackages.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.pending = false;
      state.packages = action.payload?.data;
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(subscribe.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(subscribe.fulfilled, (state, action) => {
      state.pending = false;
      state.paymentURL = action.payload;
    });
    builder.addCase(subscribe.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getStartedFreePlan.pending, (state, action) => {
      state.FreePending = true;
    });
    builder.addCase(getStartedFreePlan.fulfilled, (state, action) => {
      state.FreePending = false;
    });
    builder.addCase(getStartedFreePlan.rejected, (state, action) => {
      state.FreePending = false;
    });
    builder.addCase(getSubscriptionHistory.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getSubscriptionHistory.fulfilled, (state, action) => {
      state.pending = false;
      state.subscriptionHistory = action?.payload || [];
    });
    builder.addCase(getSubscriptionHistory.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export default subscribeSlice.reducer;
