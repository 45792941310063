import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import Loader from "../sharedLayouts/Loader";

const SubscribeModal = ({ open, onClose, iframeSrc }) => {
  const pending = useSelector((state) => state?.subscribe?.pending);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: "600px",
          maxWidth: "90vw",
          height: "900px",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          margin: "10px",
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        <div>
          {pending && (
            <div className="w-full justify-center items-center flex min-h-[300px]">
              <Loader />
            </div>
          )}
          {iframeSrc && !pending && (
            <iframe
              title="Payment"
              src={iframeSrc}
              width="100%"
              height="750px"
              frameBorder="0"
              allowFullScreen
            />
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default SubscribeModal;
