import { createSlice } from "@reduxjs/toolkit";
import { getPortfolio } from "../actions/portfolio/getportfolio";
import { login } from "../actions/onboarding/login";
import { switchCurrency } from "../actions/dashboard/switchCurrency";
import { getPaymentSchedule } from "../actions/portfolio/getPaymentSchedule";
import { getInvestmentTransactions } from "../actions/portfolio/getInvestmentTransactions";
import { getPayoutDetails } from "../actions/payouts/getPayoutDetails";

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: {
    error: null,
    pending: false,
    pendingTotalPayout: false,
    success: false,
    myInvestmentTotalPayout: null,
    portfolio: [],
    investmentMaturityDate: null,
    paymentSchedule: [],
    transactions: [],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(switchCurrency.fulfilled, (state, action) => {
        state.portfolio = [];
      })
      .addCase(getPortfolio.pending, (state, action) => {
        state.pending = true;
        state.portfolio = [];
      })
      .addCase(getPortfolio.fulfilled, (state, action) => {
        state.pending = false;
        state.portfolio = action.payload?.reverse();
      })
      .addCase(getPortfolio.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(getPaymentSchedule.pending, (state, action) => {
        state.pending = true;
        state.investmentMaturityDate = null;
        state.paymentSchedule = [];
      })
      .addCase(getPaymentSchedule.fulfilled, (state, action) => {
        state.investmentMaturityDate = action.payload?.reduce(
          (cur, acc) => {
            return acc?.payment_date < cur?.payment_date ? cur : acc;
          },
          { payment_date: new Date() }
        )?.payment_date;
        state.pending = false;
        state.paymentSchedule = action.payload;
      })
      .addCase(getPaymentSchedule.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(getInvestmentTransactions.pending, (state, action) => {
        state.pending = true;
        state.transactions = [];
      })
      .addCase(getInvestmentTransactions.fulfilled, (state, action) => {
        state.pending = false;
        state.transactions = action.payload;
      })
      .addCase(getInvestmentTransactions.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(getPayoutDetails.rejected, (state, action) => {
        state.pendingTotalPayout = false;
      })
      .addCase(getPayoutDetails.pending, (state, action) => {
        state.pendingTotalPayout = true;
        state.myInvestmentTotalPayout = null;
      })
      .addCase(getPayoutDetails.fulfilled, (state, action) => {
        state.pendingTotalPayout = false;
        state.myInvestmentTotalPayout = action.payload;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.pending = false;
      });
  },
});

export default portfolioSlice.reducer;
