import React from "react";

function NotificationIcon() {
  return (
    <div>
      <img
        src={"/Assets/notification.png"}
        alt="Notification Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default NotificationIcon;
