import React from "react";

function OpportunitiesIcon() {
  return (
    <div>
      <img
        src={"/Assets/opportunities.png"}
        alt="Opportunities Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default OpportunitiesIcon;
