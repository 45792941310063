import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Client } from "../../../client";
import { API_GOOGLE_LOGIN } from "../../../config/api";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const loginWithGoogle = createAsyncThunk(
  "users/googleLogin",
  async (token, { dispatch, rejectWithValue }) => {
    try {
      mixpanel.track("Portal-Login-Google-Init", {});
      const response = await Client({
        method: "POST",
        path: API_GOOGLE_LOGIN + token,
      });

      if (!response?.data?.user?.email_verified) {
        toast.error("Please verify your email");
        return rejectWithValue("Please verify your email");
      }
      if (response?.data && response?.data?.access_token) {
        const { access_token } = response?.data;
        localStorage.setItem("accessToken", access_token);
        const user = response?.data?.user;

        mixpanel.identify(user?.email);
        mixpanel.register({ email: user?.email });
        mixpanel.people.set({
          $name: user?.name,
          $email: user?.email,
          // $created: new Date(),
        });
        mixpanel.track("Portal-Login-Google", {});

        return response?.data;
      } else {
        toast.error("Login failed: Access token not found in the response");
        return rejectWithValue("Access token not found in the response");
      }
    } catch (error) {
      mixpanel.track("Portal-Login-Google-Failed", {});
      const message = proccessErrorMessage(error);
      toast.error(message || "Login failed: Invalid email or password");
      mixpanel.track("Error", {
        type: "onboarding",
        subtype: "login-google",
        message: message,
      });
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);
