import React from "react";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PhoneInputField = ({
  form,
  name, // The 'name' prop is used to manage value in Formik
  setActiveInput,
  label,
  fullWidth,
  height,
  disabled,
  width,
}) => {
  const onFocus = () => {
    setActiveInput && setActiveInput(name);
  };

  return (
    <>
      <div
        className="phone-input-container1 w-full border border-[#D0D5DD] rounded-lg  lg:w-full"
        style={{ width: fullWidth ? "100%" : width, height: height || "44px" }}
      >
        <PhoneInput
          className="w-full flex justify-end phone-input-container1 "
          style={{
            width: "100%",
            height: "100%",
            fontWeight: "500",
            color: disabled ? "#999" : "#101828",
            // fontFamily: "TTI",
            fontFamily: "Inter",
          }}
          defaultCountry="NG"
          value={form.values[name]} // Use 'name' to retrieve value from Formik state
          onChange={(phoneNumber) => {
            form.setFieldValue(name, phoneNumber);
          }}
          onFocus={onFocus}
          focusInputOnCountrySelection={false}
          onCountryChange={(code) => {
            if (code) {
              const countryCode = "+" + getCountryCallingCode(code);
              form.setFieldValue("phoneCode", countryCode);
            }
          }}
          disabled={disabled}
          countrySelectProps={{
            arrowComponent: () => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginLeft: 10, marginRight: 15 }}>
                  {form.values.phoneCode}
                </span>
                <ExpandMoreIcon />
              </div>
            ),
          }}
        />
        {form?.errors[name] && (
          <div className="text-red-500 text-xs mt-1 ml-1">
            {form.errors[name]}
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneInputField;
