import React from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomButton from "../../components/buttons/Button";
import AuthVerify from "../../components/containers/AuthVerify";
import { useDispatch, useSelector } from "react-redux";
import { verifyAccount } from "../../store/actions/onboarding/verifyAccount";
import { resendOTP } from "../../store/actions/onboarding/resendotp";

function Verification() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state.onboarding.pending);
  const email = location?.state?.email;

  const handleVerify = () => {
    const data = { email, otp };
    dispatch(verifyAccount({ data, navigate }));
  };

  const handleResendOTP = () => {
    const data = { email };
    dispatch(resendOTP({ data }));
  };
  return (
    <AuthVerify>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div className=" w-[100%] max-w-[360px]   m-auto laptop:w-[65%] justify-center items-center flex flex-col">
          <div className="flex items-center flex-col ">
            <img
              src="/Assets/borderMail.png"
              className="w-[56px] h-[56px]"
              alt=""
            />
            <h1 className="mt-6 mb-3 text-center font-[600] text-[30px] leading-[38px] ">
              Check your email
            </h1>
            <h6 className="w-[100%] text-center text-[rgba(99,99, 99,1)]   text-base leading-[24px]">
              We sent a 4 digit OTP to
              <h6 className="inline"> {email}</h6>
            </h6>
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputType="password"
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            placeholder="----"
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              marginTop: "34px",
              marginBottom: "18px",
            }}
            inputStyle={{
              width: "50px",
              height: "50px",
              border: "1.5px solid #159AA8",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "#159AA8",
              fontSize: "20px",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Verify Email"
            width="100%"
            height="48px"
            padding="10px"
            loading={loading}
            margin="35px 0px"
            color="#fff"
            onClick={handleVerify}
            disabled={otp.length < 4}
          />
          <h6 className="w-[100%] text-[rgba(51,51,51,1)] font-[400] text-sm leading-[20px] text-center">
            Didn’t receive the code?{" "}
            <h6
              onClick={handleResendOTP}
              className="font-bold inline text-[#159AA8] cursor-pointer hover:opacity-75"
            >
              Click to Resend
            </h6>
          </h6>

          <div
            onClick={() => navigate(-1)}
            className=" flex group items-center justify-center  mt-[28px] gap-1 cursor-pointer"
          >
            <img src="/Assets/arrow-left.png" alt="" className="w-5 h-5 " />
            <h4 className="group-hover:text-text-color-1 text-[#475467] font-[600] leading-5">
              Go Back
            </h4>
          </div>
        </div>
      </div>
    </AuthVerify>
  );
}

export default Verification;
