import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onGoogleAuthSuccess } from "../../helper/oauth";
import { useGoogleLogin } from "@react-oauth/google";
import Auth from "../../components/containers/Auth";
import CustomButton from "../../components/buttons/Button";
import GoogleIcon from "../../components/Icons/GoogleIcon";
import { useFormik } from "formik";
import { signUpValidationSchema } from "../../helper/validationSchemas";
import { registerUsers } from "../../store/actions/onboarding/registerUsers";
import { CustomInputWithLabel } from "../../components/inputs/CustomInput";
import SEO from "../../config/Seo";
import PhoneInputField from "../../components/inputs/PhoneInputField";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.onboarding.pending);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgreementChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const dividerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  };

  const horizontalLineStyle = {
    height: "2px",
    width: "50%",
    backgroundColor: "#EAECF0",
  };
  const orTextStyle = {
    fontSize: "16px",
    color: "#000",
    margin: "0 10px",
  };

  const handleRegister = (option) => {
    const { phone_number, phoneCode, confirmPassword, ...rest } = form.values;
    dispatch(registerUsers({ data: rest, navigate }));
  };

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phoneCode: "+234",
      phone_number: "",
      confirmPassword: "",
    },
    onSubmit: handleRegister,
    validationSchema: signUpValidationSchema,
  });

  const googleSignUp = useGoogleLogin({
    scope: "email profile",
    onSuccess: (token) => onGoogleAuthSuccess(token, "signup", dispatch),
  });
  return (
    <>
      <SEO
        title={"Signup | Register with Halvest!"}
        description={
          "Register with Halvest and unlock access to ethical finance and Islamic banking solutions. Sign up for Sharia-compliant investments, halal finance, and sustainable investing options."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Auth>
        <div className="h-screen w-[100%]  flex flex-col">
          <div className="py-8  w-[100%]   m-auto laptop:w-[65%] px-8   flex-1 flex items-center justify-center ">
            <div className="w-full  min-h-[498px] flex flex-col items-center ">
              <h2 className="  font-[600] w-full text-4xl text-text-color-1  leading-[44px] my-8   tracking-[1%]">
                Sign Up
              </h2>

              <CustomInputWithLabel
                label={"Name"}
                Required
                width="360px"
                height="18px"
                placeholder={"Firstname Surname"}
                borderColor="#D0D5DD"
                form={form}
                id="name"
              />

              <CustomInputWithLabel
                label={"Email"}
                Required
                width="360px"
                height="18px"
                placeholder={"Enter your email"}
                borderColor="#D0D5DD"
                form={form}
                id="email"
              />

              <div className="w-full">
                <h4 className="font-[500] text-sm leading-5">Phone Number*</h4>
                <div className="w-full mb-5 flex flex-col md:flex-row mt-1  justify-between ">
                  <div className="flex flex-1 md:flex-auto md:w-[605px]">
                    <PhoneInputField
                      fullWidth
                      name={"phone"}
                      form={form}
                      id={"phone_number"}
                    />
                  </div>
                </div>
              </div>
              <CustomInputWithLabel
                label={"Password"}
                Required
                width="360px"
                height="18px"
                type="password"
                placeholder={"Enter your password"}
                borderColor="#D0D5DD"
                id="password"
                form={form}
              />

              <CustomInputWithLabel
                label={"Confirm Password"}
                Required
                width="360px"
                type="password"
                height="18px"
                placeholder={"Enter your password"}
                id="confirmPassword"
                form={form}
                borderColor="#D0D5DD"
              />
              <div className="mt-2 mb-3 w-full ">
                <div className=" w-[95%] " id="terms">
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="checkbox"
                      type="checkbox"
                      checked={isAgreed}
                      onChange={handleAgreementChange}
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "16px",
                        marginRight: "8px",
                      }}
                    />
                    <h4 className="font-[500] text-sm text-[#344054] cursor-pointer ">
                      I have read and I agree to the{" "}
                      <a
                        rel="noreferrer"
                        href={"https://halvestco.com/Legal/Terms.docx.pdf"}
                        // download={`HALVEST TERMS`}
                        target="_blank"
                        className="text-[#159AA8]  text-sm "
                      >
                        Terms
                      </a>{" "}
                      &{" "}
                      <a
                        rel="noreferrer"
                        href={
                          "https://halvestco.com/Legal/Privacy%20Policy.docx.pdf"
                        }
                        // download={`HALVEST PRIVACY POLICY`}
                        target="_blank"
                        className="text-[#159AA8]  text-sm "
                      >
                        Privacy Policy
                      </a>
                    </h4>
                  </label>
                </div>
              </div>
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                loading={loading}
                width="100%"
                height="48px"
                padding="10px"
                margin="15px 0px"
                color="#fff"
                disabled={!isAgreed}
                onClick={form.handleSubmit}
              >
                Sign Up
              </CustomButton>

              <div style={dividerStyle}>
                <div style={horizontalLineStyle}></div>
                <div style={orTextStyle}>OR</div>
                <div style={horizontalLineStyle}></div>
              </div>

              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Continue with Google"
                width="100%"
                height="48px"
                onClick={googleSignUp}
                padding="10px"
                margin="15px 0px"
                color="#000"
                disabled={!isAgreed}
                borderColor="#D0D5DD"
                icon={<GoogleIcon />}
              />

              <span className="mt-5 text-l" style={{ fontSize: "16px" }}>
                Already have an account?{" "}
                <span
                  className="text-[#128591] font-semibold text-l"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </span>
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center mx-4 py-7 ">
            <span>&copy; Halvest2024</span>
            <span className="flex items-center">
              <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
              support@halvestco.com
            </span>
          </div>
        </div>
      </Auth>
    </>
  );
};

export default Register;
