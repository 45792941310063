import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_UPDATE_BVN } from "../../../config/api";

import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { fetchUserProfile } from "./getProfile";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";

export const updateUserBvn = createAsyncThunk(
  "userBvn/update",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_UPDATE_BVN,
        data,
      });
      toast.success("BVN updated successfully");
      dispatch(fetchUserProfile());
      cb();
      mixpanel.track("Success", {
        type: "Profile",
        subtype: "update-BVN",
      });
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "Profile",
        subtype: "update-BVN",
        message: msg,
        error: error?.response?.data,
      });

      failed(msg);
    }
  }
);
