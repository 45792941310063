import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../inputs/CustomInput";
import { useFormik } from "formik";
import CustomButton from "../buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { updateUserBvn } from "../../store/actions/profile/updateBVN";
import { BVNSchema } from "../../helper/validationSchemas";
import MessageModal from "./MessageModal";
import { actions } from "../../config/errorTypes";
import { useNavigate } from "react-router-dom";

function AddBvnModal({ open, onClose, next }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalIsOpen] = useState(false);

  const pending = useSelector((state) => state?.userProfile?.pending);
  const bvnStatus = useSelector((state) => state?.userProfile?.success);

  const _handleClose = useCallback(() => {
    const route = actions(dispatch).find(
      (action) => action.msg === isModalOpen?.msg
    )?.navigateTo;
    setModalIsOpen(false);

    if (route) {
      navigate(route);
    }
  }, [isModalOpen]);

  const form = useFormik({
    initialValues: {
      BVN: "",
    },
    validationSchema: BVNSchema,
  });

  const handleUpdateBVN = () => {
    const BVN = form.values.BVN;
    const data = { BVN };
    dispatch(
      updateUserBvn({
        data,
        cb: () => {
          form.resetForm();
          next();
        },
        failed: (msg) => {
          form.resetForm();
          setModalIsOpen({
            error: true,
            success: false,
            msg,
          });
        },
      })
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
          className="w-[90%] lg:w-[500px] bg-black"
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full  p-5">
            <h1 className="font-semibold text-2xl mb-4">Enter BVN</h1>
            <p className="text-sm mb-4">
              Please update your BVN to fund your wallet.
            </p>

            <div className="w-full  mb-4 ">
              <h4 className=" text-sm leading-[20px] font-[500]">BVN</h4>
              <CustomInput
                id="BVN"
                width="100%"
                type={"number"}
                form={form}
                height="25px"
                mt="10"
                mr="10px"
              />
            </div>
            <div className="w-full">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Enter BVN"
                disabled={!form?.dirty || !form?.isValid}
                loading={pending}
                width="100%"
                height="50px"
                padding="10px"
                onClick={handleUpdateBVN}
              />
            </div>
          </div>
        </Box>
      </Modal>
      {isModalOpen ? (
        <MessageModal
          _error={isModalOpen?.error}
          _success={isModalOpen?.success}
          _buttonClicked={_handleClose}
          _handleClose={() => setModalIsOpen(false)}
          _isModalOpened={isModalOpen}
          _modalText={isModalOpen?.msg}
          btnTitle={
            actions(dispatch, _handleClose).find(
              (action) => action.msg === isModalOpen?.msg
            )?.call
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default AddBvnModal;
