import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../client";
import { API_INVESTMENT_STATEMENT } from "../../../config/api";
import { showErrorModal, showSuccessModal } from "../../reducers/messageSlice";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const generateInvestmentStatement = createAsyncThunk(
  "generateInvestmentStatement/post",
  async ({ data: payload, cb, setSubmitting }, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_INVESTMENT_STATEMENT}${payload?.currency}`,
        // data: payload,
      });

      cb();
      dispatch(showSuccessModal(data.message));
      return data;
    } catch (error) {
      setSubmitting(false);
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "statement",
        subtype: "generate-investment-statement",
        message: msg,
      });

      dispatch(showErrorModal(msg));
    }
  }
);
