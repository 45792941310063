import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_GET_TRANSACTIONS } from "../../../config/api";
import mixpanel from "mixpanel-browser";

export const getTransactions = createAsyncThunk(
  "wallet/transactions",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_TRANSACTIONS + currency,
      });
      return response?.data?.data ? response?.data?.data?.reverse() : [];
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "wallet",
        subtype: "get-transactions",
        message: msg,
      });
      toast.error(msg);
      throw error;
    }
  }
);
