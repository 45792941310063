import React, { useEffect, useMemo } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import WalletCard from "../../components/cards/WalletCard";
import ActiveIcon from "../../components/Icons/ActiveIcon";
import TotalIcon from "../../components/Icons/TotalIcon";
import ReturnIcon from "../../components/Icons/ReturnIcon";
import PayoutIcon from "../../components/Icons/PayoutIcon";
import InvestmentCard from "../../components/cards/InvestmentCard";
import Opportunities from "../../components/dashboard/Opportunities";
import Transaction from "../../components/dashboard/Transaction";
import CompleteProfile from "../../components/dashboard/CompleteProfile";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { getPortfolio } from "../../store/actions/portfolio/getportfolio";
import { getOpportunities } from "../../store/actions/opportunities/getopportunities";
import { useLocation } from "react-router-dom";
import { getTransactions } from "../../store/actions/transactions/gettransactions";
import { getPayout } from "../../store/actions/payouts/getPayout";
import { autoFetchPayout } from "../../store/actions/payouts/autoFetchPayout";
import { autoGetProfile } from "../../store/actions/profile/autoGetProfile ";

const Dashboard = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";

  const { portfolio: myInvestments, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );

  const localCurrency = currency === "NGR" ? "Naira" : currency;

  const { user, profile, pending } = useSelector((state) => state.onboarding);

  const [_currency, getAmount] = useCurrency();
  const location = useLocation();
  const selectedPlan = location.state?.selectedPlan || "DefaultPlan";
  const {
    transactions,
    pending: transactionsPending,
    totalpayOut,
    payOutsPending,
  } = useSelector((state) => state.transactions);
  const profileDetails = useSelector(
    (state) => state?.userProfile?.profileDetails?.data
  );

  useEffect(() => {
    dispatch(getPortfolio(currency));
    dispatch(getOpportunities(currency));
    dispatch(getPayout(currency));

    const clean = setInterval(() => {
      dispatch(autoFetchPayout(currency));
      dispatch(autoGetProfile());
      // dispatch(getPortfolio(currency));
      // dispatch(getOpportunities(currency));
    }, 60000);
    return () => {
      clearInterval(clean);
    };
  }, [currency]);

  let investmentDetails = useMemo(() => {
    return [
      {
        id: 1,
        icon: <ReturnIcon />,
        iconColor: "#e8ecf5",
        title: "SME Financing",
        investment: portfolioPending
          ? "-"
          : myInvestments?.filter((item) => {
              console.log(item, "item");
              return (
                item?.investment_category?.name === "SME Financing" &&
                !item?.paymentSchedules?.expected_date?.every(
                  (paymentSchedule) => paymentSchedule?.status === "Paid"
                )
              );
            })?.length,
      },
      {
        id: 1,
        icon: <TotalIcon />,
        iconColor: "#e8ecf5",
        title: "Real Estate",
        investment: portfolioPending
          ? "-"
          : myInvestments?.filter((item) => {
              console.log(item, "item");
              return (
                item?.investment_category?.name === "Real Estate" &&
                !item?.paymentSchedules?.expected_date?.every(
                  (paymentSchedule) => paymentSchedule?.status === "Paid"
                )
              );
            })?.length,
      },
      // {
      //   id: 1,
      //   icon: <TotalIcon />,
      //   iconColor: "#e8ecf5",
      //   title: "Startup Investing",
      //   investment: portfolioPending
      //     ? "-"
      //     : myInvestments?.filter((item) => {
      //         console.log(item, "item");
      //         return (
      //           item?.investment_category?.name === "Real Estate" &&
      //           !item?.paymentSchedules?.expected_date?.every(
      //             (paymentSchedule) => paymentSchedule?.status === "Paid"
      //           )
      //         );
      //       })?.length,
      // },

      // {
      //   id: 2,
      //   icon: <TotalIcon />,
      //   iconColor: "#f7f3fb",
      //   title: `Active ${localCurrency} Investments`,
      //   investment: `${_currency}${
      //     portfolioPending
      //       ? "--"
      //       : myInvestments && myInvestments?.length < 1
      //       ? 0
      //       : getAmount(
      //           myInvestments?.reduce((sum, item) => {
      //             return !item?.paymentSchedules?.expected_date?.every(
      //               (paymentSchedule) => paymentSchedule?.status === "Paid"
      //             )
      //               ? sum + +item?.amount
      //               : sum;
      //           }, 0),
      //           2
      //         )
      //   }`,
      // },

      // {
      //   id: 3,
      //   icon: <PayoutIcon />,
      //   iconColor: "#f9e3be",
      //   title: `Total ${localCurrency} Payouts`,
      //   investment:
      //     _currency +
      //     (transactionsPending
      //       ? "--"
      //       : totalpayOut?.total_payout
      //       ? getAmount(totalpayOut.total_payout)
      //       : 0),
      //   // (transactionsPending
      //   //   ? " --"
      //   //   : transactions && transactions?.length > 0
      //   //   ? getAmount(
      //   //       transactions
      //   //         .filter(
      //   //           (item) =>
      //   //             item?.transaction_type?.toLowerCase() ===
      //   //             "campaign payout"
      //   //         )
      //   //         ?.reduce((sum, item) => sum + +item?.amount, 0),
      //   //       2
      //   //     )
      //   //   : 0),
      // },

      // {
      //   id: 4,
      //   icon: <ReturnIcon />,
      //   iconColor: "#daf1f3",
      //   title: `Expected ${localCurrency} Returns`,
      //   investment: `${_currency}${
      //     portfolioPending
      //       ? "--"
      //       : myInvestments && myInvestments?.length > 0
      //       ? getAmount(
      //           myInvestments?.reduce((sum, item) => {
      //             return (
      //               sum +
      //               (item?.investment_category?.name !== "Startup Investing"
      //                 ? (Number(item?.amount) *
      //                     Number(item?.investment_product?.roi)) /
      //                   100
      //                 : 0)
      //             );
      //           }, 0)
      //         )
      //       : 0
      //   }`,
      // },
    ];
  }, [
    myInvestments,
    _currency,
    getAmount,
    totalpayOut,
    localCurrency,
    portfolioPending,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DashboardLayout>
      <div className="px-5  sm:pl-8 sm:pr-12  py-4">
        <div className="mb-10">
          <h1 className="my-5 font-[600] text-[30px]">Dashboard</h1>
          <h1 className="text-2xl capitalize font-bold mb-2">
            Hey {user?.name?.split(" ")[0]}
          </h1>
          <span>Welcome back!</span>
        </div>

        {(user?.hasCompletedProfile === false ||
          !user?.selectedPlan ||
          profile?.investorProfile === null) &&
          !pending && (
            <div>
              <CompleteProfile
                title="Complete Your Profile"
                info="You need to complete your investor profile to be able to invest or view deals."
              />
            </div>
          )}
        {user?.hasCompletedProfile ? (
          <div>
            {selectedPlan === "NonMembers" && (
              <div>
                <h1 className="font-semibold">
                  Hi, {profileDetails?.user?.name}
                </h1>
                <p className="text-lg font-bold mt-4 text-red-500 ">
                  we are currently reviewing your profile. We will get in touch
                  with you.
                </p>
              </div>
            )}
          </div>
        ) : null}

        <div className="mt-20">
          {selectedPlan !== "NonMembers" && (
            <div className="grid gap-8 grid-cols-12">
              <div className="col-span-12 md:col-span-6 ">
                <WalletCard />
              </div>

              <div className="col-span-12 md:col-span-6">
                <div className="grid gap-6 sm:grid-cols-1 ">
                  {investmentDetails.map((detail) => (
                    <div key={detail.id} className="col-span-1">
                      <InvestmentCard
                        width={"100%"}
                        height={180}
                        icon={detail?.icon}
                        title={detail?.title}
                        iconColor={detail?.iconColor}
                        investment={detail?.investment}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-2">
          <Opportunities />
        </div>

        <div className="mb-3 mt-5 ">
          <Transaction />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
