import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginWithGoogle } from "../../store/actions/onboarding/loginWithGoogle";

const GoogleAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      dispatch(loginWithGoogle({ code }))
        .unwrap()
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          console.error("Error exchanging Google code for token:", error);
        });
    } else {
      navigate("/login");
    }
  }, [searchParams, dispatch, navigate]);
  return <div>Loading...</div>;
};

export default GoogleAuthCallback;
