import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CustomButton from "../buttons/Button";
import ConfirmModal from "./ConfirmModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { submitInvestment } from "../../store/actions/investment/submitinvestment";
import { useParams } from "react-router";
import CustomInput from "../inputs/CustomInput";
import { showErrorModal } from "../../store/reducers/messageSlice";
import CreatePinModal from "./CreatePinModal";
import MessageModal from "./MessageModal";
import { actions } from "../../config/errorTypes";

import { useNavigate } from "react-router-dom";

function InvestModal({ open, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [expectedReturn, setExpectedReturn] = useState(0);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [_currency, getAmount] = useCurrency();

  const _handleClose = useCallback(() => {
    const route = actions(dispatch).find(
      (action) => action.msg === isModalOpen?.msg
    )?.navigateTo;

    setModalIsOpen(false);

    if (route) {
      navigate(route);
    }
  }, [isModalOpen]);

  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const closeCreatePinModal = () => {
    setIsPinModalOpen(false);
  };

  const loading =
    useSelector((state) => state.opportunities?.investPending) || false;
  const user = useSelector((state) => state.onboarding?.user);
  const { interestDetails, opportunity } = useSelector(
    (state) => state.opportunities
  );
  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const balance = _currency === "$" ? dollarBalance : walletBalance;

  const adminFee = useMemo(() => {
    return (amount * +opportunity?.admin_fee) / 100;
    // if (user?.selectedPlan?.toLowerCase() === "free") {
    //   return amount * 0.02;
    // } else {
    //   return amount * 0;
    // }
  }, [opportunity, amount]);

  const calculatedAmount = useMemo(() => {
    return adminFee + +amount;
  }, [amount, adminFee]);

  const openConfirmModal = (amount) => {
    if (amount > Number(opportunity?.leftover_amount)) {
      return dispatch(
        showErrorModal(
          "Sorry! The amount you wish to invest exceeds the units left."
        )
      );
    }
    if (
      !walletBalance ||
      !dollarBalance ||
      calculatedAmount > Number(balance)
    ) {
      return setModalIsOpen({
        error: true,
        success: false,
        msg: "Insufficient balance, click here to fund your wallet.",
      });
    }

    if (!user?.hasSetPin) {
      setIsPinModalOpen(true);
    } else if (calculatedAmount < opportunity?.minimum_amount) {
      dispatch(
        showErrorModal(
          `The minimum investment of (${_currency}${getAmount(
            opportunity?.minimum_amount,
            2
          )}) is not met by the amount of (${_currency}${getAmount(
            calculatedAmount,
            2
          )}) that you wish to invest.`
        )
      );
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    if (amount > -1) {
      let total = (opportunity?.roi * amount) / 100;
      setExpectedReturn(total);
    }
  }, [amount, opportunity?.roi]);

  const handleConfirm = useCallback(
    (pin) => {
      const total = adminFee + +amount;
      const data = {
        data: {
          amount: Number(amount),
          admin_charges: Number(adminFee),
          debit_amount: Number(total),
          investment_product_id: Number(id),
          payment_type: "transfer",
          transaction_pin: pin,
        },
        name: opportunity?.name,
      };

      dispatch(submitInvestment(data));
    },
    [adminFee, amount, id, dispatch]
  );

  useEffect(() => {
    if (interestDetails?.amount) {
      setAmount(interestDetails?.amount);
    }
  }, [interestDetails?.amount]);

  // useEffect(() => {
  //   if (!walletBalance || !dollarBalance) {
  // dispatch(
  //   showErrorModal(`Error fetching your wallet balances. Please try again.`)
  // );
  //   }
  // }, [walletBalance, dollarBalance]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            maxWidth: "90%",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Box
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Avatar src="/Assets/userIcon.png" alt="User" />

            {/* <h1 className="font-[600] text-2xl lg:text-[30px] leading-[38px] mb-1 text-[#101828]">  <p className="text-sm lg:text-base font-[400] text-[#475467}"></p> */}

            <Typography
              variant="h6"
              style={{
                flex: 1,
                fontWeight: "600",
                color: "#101828",
                fontSize: "18px",
                // fontFamily: "TTI",
                fontFamily: "Inter",
                textAlign: "center",
              }}
            >
              Invest in {opportunity?.name}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: "#98A2B3",
                  fontSize: "24px",
                }}
                fontSize="24px"
              />
            </IconButton>
          </Box>
          <hr />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <p className="text-base lg:text-2xl font-[500] text-[#475467}">
              Amount to Invest
            </p>

            <div className="w-full mb-4  ">
              <CustomInput
                fullWidth
                noBorder
                bg={true}
                height={40}
                autoFocus={true}
                placeholder={`${_currency}0.00`}
                customTextStyle={{}}
                textInputCustomStyles={{
                  textAlign: "center",
                  fontSize: "44px",
                  color: "101828",
                }}
                variant={"standard"}
                selfValue={amount}
                prefix={_currency}
                customType="amount"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>

            <div className="  bg-[#FFFFFF] px-3 mr-5 flex justify-between gap-[9px] items-center">
              <div
                className={`border justify-center pb-[2px] flex items-center gap-1   ${
                  !walletBalance ||
                  !dollarBalance ||
                  calculatedAmount > Number(balance)
                    ? "bg-[#FDA29B] border-[#D92D20]"
                    : "border-[#D0D5DD]"
                } px-[7px] rounded-lg`}
              >
                <div
                  className={`flex items-center  justify-center rounded-full w-2 h-2  ${
                    !walletBalance ||
                    !dollarBalance ||
                    calculatedAmount > Number(balance)
                      ? "bg-[#FDA29B]"
                      : "bg-[#DCFAE6]"
                  }`}
                >
                  <div
                    className={`flex items-center justify-center rounded-full w-1  ${
                      !walletBalance ||
                      !dollarBalance ||
                      calculatedAmount > Number(balance)
                        ? "bg-[#D92D20]"
                        : "bg-[#17B26A]"
                    } h-1`}
                  ></div>
                </div>

                <h4
                  className={`text-xs font-[500] leading-[20px]  ${
                    !walletBalance ||
                    !dollarBalance ||
                    calculatedAmount > Number(balance)
                      ? "text-[#D92D20]"
                      : "text-[#344054]"
                  }`}
                >
                  Available Balance
                </h4>
              </div>

              <h4
                className={`text-xs font-[500]  ${
                  !walletBalance ||
                  !dollarBalance ||
                  calculatedAmount > Number(balance)
                    ? "text-[#D92D20]"
                    : "text-[#344054}"
                }`}
              >
                {_currency}
                {_currency === "$"
                  ? dollarBalance
                    ? getAmount(dollarBalance, 2)
                    : " --"
                  : walletBalance
                  ? getAmount(walletBalance, 2)
                  : " --"}{" "}
              </h4>
            </div>
          </Box>
          <div className="mb-5">
            <hr />
          </div>
          <div>
            <div className="flex justify-between pb-7">
              <h4 className="font-[600] text-base text-[#101828]">
                {`Admin Fee (${
                  opportunity?.admin_fee ? opportunity?.admin_fee : "--"
                }%)`}
                {/* {user?.selectedPlan?.toLowerCase() === "free"
                ? "Admin Fee (2%)"
                : "Admin Fee (0%)"} */}
              </h4>
              <h5 className="font-[400] text-base text-[#475467]">
                {_currency}
                {adminFee ? getAmount(adminFee) : adminFee}
              </h5>
            </div>

            {opportunity?.investment_category?.name ===
              "Exchange Traded Commodities" && false ? (
              <></>
            ) : (
              <div className="flex justify-between pb-7">
                <h4 className="font-[600] text-base text-[#101828]">ROI</h4>
                <h5 className="font-[400] text-base text-[#475467]">
                  {opportunity?.roi}%
                </h5>
              </div>
            )}
            {/* <div className="flex justify-between pb-7">
            <h4 className="font-[600] text-base text-[#101828]">Unit Left</h4>
            <h5 className="font-[400] text-base text-[#475467]">
              {_currency}
              {getAmount(opportunity?.leftover_amount, 2)}
            </h5>
          </div> */}

            <div className="flex justify-between pb-7">
              <h4 className="font-[600] text-base text-[#101828]">
                Expected Return
              </h4>
              <h5 className="font-[400] text-base text-[#475467]">
                {_currency}

                {/* {user?.selectedPlan?.toLowerCase() === "free"
                ? getAmount(expectedReturn * 0.995, 2)
                : getAmount(expectedReturn, 2)} */}
                {getAmount(expectedReturn, 2)}
              </h5>
            </div>
            <hr />
            <div className="flex pt-6 justify-between pb-9">
              <h4 className="font-[600] text-[18px] sm:text-[24px] text-[#101828]">
                Total Amount
              </h4>
              <h5 className="font-[700] text-[18px] sm:text-[24px] text-[#475467]">
                {_currency}
                {getAmount(calculatedAmount, 2)}
              </h5>
            </div>
          </div>
          <div className="flex items-center ">
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="150px"
              height="40px"
              padding="10px"
              margin="0px 0px 15px 0px"
              color="#000"
              onClick={onClose}
            />
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Continue"
              loading={loading}
              disabled={!amount}
              width="100%"
              height="40px"
              padding="10px"
              margin="0px 0px 15px 15px"
              color="#fff"
              onClick={
                !amount
                  ? null
                  : () => openConfirmModal(amount, calculatedAmount)
              }
            />
          </div>
          <CreatePinModal open={isPinModalOpen} onClose={closeCreatePinModal} />
          <ConfirmModal
            open={isConfirmModalOpen}
            onConfirm={handleConfirm}
            onClose={closeConfirmModal}
          />
        </Box>
      </Modal>
      {isModalOpen ? (
        <MessageModal
          _error={isModalOpen.error}
          _success={isModalOpen.success}
          _handleClose={() => setModalIsOpen(false)}
          _buttonClicked={_handleClose}
          _isModalOpened={isModalOpen}
          _modalText={isModalOpen.msg}
          btnTitle={
            actions(dispatch, _handleClose).find(
              (action) => action.msg === isModalOpen?.msg
            )?.call
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default InvestModal;
