import React from "react";

import { IconButton, Tooltip, TooltipProps } from "@mui/material";
import CustomizedTooltip from "./CustomizedTooltip";

const CustomTooltip = ({ children, placement, message, w, h, fontSize }) => {
  return (
    <CustomizedTooltip placement={placement} title={message} arrow>
      <IconButton
        color="primary"
        sx={{
          fontSize: fontSize || 8,
          // width: w || 20,
          // height: h || 20,
          // color: disabled ? "#555" : "black",
        }}
      >
        {children}
      </IconButton>
    </CustomizedTooltip>
  );
};

export default CustomTooltip;
