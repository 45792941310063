import React from "react";

function GoogleIcon() {
  return (
    <div>
      <img
        src={"/Assets/google.png"}
        alt="GoogleIcon"
        className={`w-6 h-6
         }`}
      />
    </div>
  );
}

export default GoogleIcon;
