import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import WalletTable from "../../components/table/WalletTable";
import UpcomingPayments from "../../components/wallet/UpcomingPayments";
import NoData from "../../components/cards/NoData";
import WalletCard from "../../components/cards/WalletCard";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../store/actions/transactions/gettransactions";
import { useEffect, useState } from "react";
import useCurrency from "../../hooks/useCurrency";
import Loader from "../../components/sharedLayouts/Loader";
import { autoFetchTransactions } from "../../store/actions/transactions/autoFetchTransactions";
import CustomButton from "../../components/buttons/Button";
import MyWithdraws from "../../components/modal/MyWithdraws";
import mixpanel from "mixpanel-browser";

function Wallet() {
  const [openWithdrawals, setOpenWithdrawals] = useState(false);
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const dispatch = useDispatch();
  const [_currency] = useCurrency();
  const { pending, transactions: walletTransactions } = useSelector(
    (state) => state.transactions
  );

  useEffect(() => {
    dispatch(getTransactions(currency));
    const clean = setInterval(() => {
      dispatch(autoFetchTransactions(currency));
    }, 60000);
    return () => {
      clearInterval(clean);
    };
  }, [currency, dispatch]);

  const user = useSelector((state) => state.onboarding?.user);
  useEffect(() => {
    if (user) {
      mixpanel.track("Viewed-Wallet", {
        user: user,
      });
    }
  }, [user]);

  return (
    <DashboardLayout>
      <div className="px-5 w-full sm:pl-6 sm:pr-10 mxl:pl-8 mxl:pr-12  py-4">
        <h1 className="my-5 w-full font-[600] text-[30px]">Wallets</h1>
        <div className=" flex w-full gap-x-5 mxl:gap-x-6  gap-y-6 flex-col xl:flex-row ">
          <div className="w-full 2xl:flex-1 mxl:w-[60%]">
            <div className="w-full xl:w-[650px] 2xl:w-full">
              <WalletCard />
            </div>
            <div className="mt-6 w-full pb-5 xl:w-[650px] 2xl:w-full overflow-x-scroll border border-border-color-1 rounded-xl  ">
              <div className="flex justify-between pr-3 2xl:w-full items-center font-bold">
                <h1 className="font-[600]  ml-5 text-[18px] text-[#101828]  ">
                  Wallet Transactions
                </h1>

                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Withdrawal Requests"
                  width="200px"
                  height="40px"
                  padding="10px"
                  margin="15px 0"
                  color="#fff"
                  // loading={loading}

                  onClick={() => {
                    setOpenWithdrawals(true);
                  }}
                />
              </div>
              {pending ? (
                <div className="w-full justify-center items-center flex min-h-[300px]">
                  <Loader />
                </div>
              ) : walletTransactions?.length <= 0 ? (
                <NoData
                  title={`No ${
                    _currency === "$" ? "USD" : "Naira"
                  } Wallet Transactions`}
                  subtitle="There is currently no wallet transaction, please check back later"
                  image="notransaction.png"
                />
              ) : (
                <WalletTable data={walletTransactions} />
              )}
            </div>
          </div>
          <div className="bg-white  w-full 2xl:w-auto rounded-md">
            <UpcomingPayments />
          </div>
        </div>
      </div>
      {openWithdrawals ? (
        <MyWithdraws
          open={openWithdrawals}
          onClose={() => {
            setOpenWithdrawals(false);
          }}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
}

export default Wallet;
