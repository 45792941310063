import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomInput from "../inputs/CustomInput";
import { useFormik } from "formik";
import CustomButton from "../buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { WitdrawalSchema } from "../../helper/validationSchemas";
import useCurrency from "../../hooks/useCurrency";
import CreatePinModal from "./CreatePinModal";
import ConfirmModal from "./ConfirmModal";
import { withdraw } from "../../store/actions/dashboard/withdraw";
import MessageModal from "./MessageModal";
import { actions } from "../../config/errorTypes";
import { useNavigate } from "react-router-dom";
import ResponseMessageModal from "./ResponseMessageModal";

const WithdrawalModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();
  const navigate = useNavigate();
  const user = useSelector((state) => state.onboarding?.user);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [warningModal, setShowWarningModal] = useState(false);
  const profile = useSelector((state) => state.onboarding?.profile);
  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const [submitting, setSubmitting] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const balancePending = useSelector((state) => state?.dashboard?.pending);
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const userBanks = useSelector((state) => state?.bank?.userBanks);
  const banksPending = useSelector((state) => state?.bank?.pending);
  const filteredUserBanks = useMemo(
    () =>
      userBanks?.filter((bank) =>
        _currency === "₦"
          ? bank.currency?.toLowerCase() === "ngr"
          : bank?.currency?.toLowerCase() === "usd" && bank?.bank_type
      ),
    [userBanks]
  );
  const balance = _currency === "$" ? dollarBalance : walletBalance;

  const closeCreatePinModal = () => {
    setIsPinModalOpen(false);
  };

  const onSubmit = useCallback(
    (values) => {
      if (!user?.hasCompletedProfile) {
        setShowWarningModal({
          modalText:
            "You must complete your profile before you can process withdrawal.",
          callToAction: {
            type: "navigate",
            path: "/onboarding",
            action: () => {
              navigate("/onboarding");
            },
          },
        });
      } else if (profile && !profile?.investorProfile?.risk_tolerance) {
        setShowWarningModal({
          modalText:
            "You must complete your Investor Profile and choose a Plan before you can process withdrawal.",
          callToAction: {
            type: "navigate",
            path: "/onboarding/investor-profile",
            action: () => {
              navigate("/onboarding/investor-profile");
            },
          },
        });
      } else if (
        user?.selectedPlan?.toLowerCase() === "free" &&
        user?.free_approved_plan !== "approved"
      ) {
        setShowWarningModal({
          modalText: "Your membership is under review.",
          callToAction: {
            type: "close",
            title: "Ok",
            action: () => {
              handleCloseWarningModal();
            },
          },
        });
      } else if (user && !user?.selectedPlan) {
        setShowWarningModal({
          modalText: "Please select a Plan before you can process withdrawal",
          callToAction: {
            type: "navigate",
            path: "/onboarding/membership",
            action: () => {
              navigate("/onboarding/membership");
            },
          },
        });
      } else if (user && user?.document_verified === false) {
        setShowWarningModal({
          modalText:
            "Sorry! You can not process withdrawal until your identification document has been verified.",
          callToAction: {
            title: "Ok",
            type: "close",
            action: () => {
              handleCloseWarningModal();
            },
          },
        });
      } else if (!Number(balance) && Number(values?.amount) > Number(balance)) {
        setModalIsOpen({
          error: true,
          success: false,
          msg: "Insufficient balance.",
        });
      } else if (!user?.hasSetPin) {
        setIsPinModalOpen(true);
      } else if (user?.hasSetPin) {
        setIsConfirmModalOpen(true);
      }
    },
    [user, balance]
  );

  const form = useFormik({
    initialValues: {
      amount: "",
      account_number: "",
    },
    validationSchema: WitdrawalSchema,
    onSubmit,
  });

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirm = useCallback(
    (pin) => {
      setSubmitting(true);
      const { amount, bank } = form.values;
      const data = {
        amount: Number(amount),
        bank_id: bank?.id,
        bank_name: bank?.bank_name || bank?.bank_type,
        bank_code: bank?.bank_code || bank?.swift_code || bank?.routing_number,
        bank_account: bank?.account_number,
        bank_holder: bank?.account_name,
        transaction_pin: pin,
      };

      dispatch(
        withdraw({
          data,
          setSubmitting,
          cb: () => {
            form.resetForm();
            setSubmitting(false);
            onClose();
          },
        })
      );
    },
    [form.values, dispatch]
  );

  const isWalletWithdrawable = useMemo(() => {
    return walletBalance && dollarBalance;
  }, [form, balance, dollarBalance, walletBalance]);

  useEffect(() => {
    form.setFieldValue("bank", filteredUserBanks[0]);
  }, [filteredUserBanks]);

  const _handleClose = useCallback(() => {
    const route = actions(dispatch).find(
      (action) => action.msg === isModalOpen?.msg
    )?.navigateTo;

    setModalIsOpen(false);

    if (route) {
      navigate(route);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
          className="w-[90%] lg:w-[500px] bg-black"
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full  py-5 px-4">
            <h1 className="font-semibold text-2xl mb-4">Enter Amount</h1>

            <div className="w-full  mb-4 ">
              <h4 className=" text-sm leading-[20px] font-[500]">Amount</h4>
              <CustomInput
                id="amount"
                width="100%"
                // type={"number"}
                // form={form}
                height="25px"
                mt="10"
                mr="10px"
                autoFocus={true}
                placeholder={`${_currency}0.00`}
                customTextStyle={{}}
                textInputCustomStyles={{
                  // textAlign: "center",
                  // fontSize: "44px",
                  color: "101828",
                }}
                // variant={"standard"}
                selfValue={form?.values?.amount}
                prefix={_currency}
                customType="amount"
                onChange={(e) => {
                  form.setFieldValue("amount", e.target.value);
                }}
              />
            </div>
            <div className="  bg-[#FFFFFF] mr-5 mb-7 flex  gap-[9px] items-center">
              <div
                className={`border justify-center pb-[2px] flex items-center gap-1   ${
                  isWalletWithdrawable
                    ? "border-[#D0D5DD]"
                    : "bg-[#FDA29B] border-[#D92D20]"
                } px-[7px] rounded-lg`}
              >
                <div
                  className={`flex items-center  justify-center rounded-full w-2 h-2  ${
                    isWalletWithdrawable ? "bg-[#DCFAE6]" : "bg-[#FDA29B]"
                  }`}
                >
                  <div
                    className={`flex items-center justify-center rounded-full w-1  ${
                      isWalletWithdrawable ? "bg-[#17B26A]" : "bg-[#D92D20]"
                    } h-1`}
                  ></div>
                </div>

                <h4
                  className={`text-xs font-[500] leading-[20px]  ${
                    isWalletWithdrawable ? "text-[#344054]" : "text-[#D92D20]"
                  }`}
                >
                  Available Balance
                </h4>
              </div>

              <h4
                className={`text-xs font-[500]  ${
                  isWalletWithdrawable ? "text-[#344054}" : "text-[#D92D20]"
                }`}
              >
                {_currency}
                {_currency === "$"
                  ? dollarBalance
                    ? getAmount(dollarBalance, 2)
                    : " --"
                  : walletBalance
                  ? getAmount(walletBalance, 2)
                  : " --"}{" "}
              </h4>
            </div>
            <div className="w-full max-h-[300px] overflow-y-auto pr-1 mb-4 ">
              <h4 className=" text-sm leading-[20px] font-[500]">To</h4>
              {filteredUserBanks?.map((bank, index) => (
                <div
                  key={index}
                  onClick={() => {
                    form.setFieldValue("bank", bank);
                  }}
                  className={`flex w-full cursor-pointer hover:bg-[#daf1f3] transform transition duration-300 ease-in-out gap-x-4 p-1 mb-1 rounded-[8px] ${
                    form.values.bank?.account_number === bank?.account_number
                      ? "bg-[#daf1f3]"
                      : ""
                  } justify-between border-b border-b-[#EAECF0] py-4`}
                >
                  <div className="flex fles-1  gap-x-3">
                    <img
                      src="/Assets/bankIcon.png"
                      alt=""
                      className="w-10 h-10"
                    />
                    <div className="">
                      <div className="flex flex-col sm:flex-row gap-[4px] sm:items-center">
                        <h3 className="text-xs sm:text-sm font-[600] text-[#344054]">
                          {bank?.account_name}
                        </h3>
                        <div className="w-2 h-2 rounded-full bg-[#667085] "></div>
                        <h3 className="text-xs sm:text-sm font-[600] text-[#344054]">
                          {bank?.account_number}
                        </h3>
                      </div>
                      <h4 className="text-xs sm:text-sm font-400] text-[#475467]">
                        {bank?.bank_name || bank?.bank_type}
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Withdraw"
                disabled={
                  !form?.dirty ||
                  !form?.isValid ||
                  // !isWalletWithdrawable ||
                  Number(form?.values?.amount) == 0 ||
                  Number(form?.values?.amount) < 0 ||
                  banksPending ||
                  balancePending ||
                  submitting
                }
                loading={banksPending || balancePending || submitting}
                width="100%"
                height="50px"
                padding="10px"
                onClick={form?.handleSubmit}
              />
            </div>
          </div>
          <CreatePinModal open={isPinModalOpen} onClose={closeCreatePinModal} />
          <ConfirmModal
            title={""}
            subTitle={"Please enter your 4 digit transaction pin"}
            open={isConfirmModalOpen}
            onConfirm={handleConfirm}
            onClose={closeConfirmModal}
          />
        </Box>
      </Modal>
      {isModalOpen ? (
        <MessageModal
          _error={isModalOpen.error}
          _success={isModalOpen.success}
          _handleClose={() => setModalIsOpen(false)}
          _buttonClicked={_handleClose}
          _isModalOpened={isModalOpen}
          _modalText={isModalOpen.msg}
          btnTitle={
            actions(dispatch, _handleClose).find(
              (action) => action.msg === isModalOpen?.msg
            )?.call
          }
        />
      ) : (
        <></>
      )}
      <ResponseMessageModal
        onClose={handleCloseWarningModal}
        open={warningModal}
        type={0}
        btnTitle={warningModal?.callToAction?.title}
        subText={warningModal?.modalText}
        handleContinue={() => {
          const { type, path, params, action } = warningModal?.callToAction;
          action();
        }}
      />
    </>
  );
};

export default WithdrawalModal;
