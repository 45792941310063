import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import Auth from "../../components/containers/Auth";
import CustomButton from "../../components/buttons/Button";
import GoogleIcon from "../../components/Icons/GoogleIcon";
import { login } from "../../store/actions/onboarding/login";
import { useFormik } from "formik";
import { loginValidationSchema } from "../../helper/validationSchemas";
import { onGoogleAuthSuccess } from "../../helper/oauth";
import { CustomInputWithLabel } from "../../components/inputs/CustomInput";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { clearLoading } from "../../store/reducers/onboardingSlice";
import SEO from "../../config/Seo";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const message = queryParams.get("message");

    if (message) {
      // toast.error(message);
    }
    return () => {
      if (message) {
        navigate("/");
      }
    };
  }, []);

  const [remember, setRemember] = useState(false);
  const loading = useSelector((state) => state.onboarding.pending);
  const [cookies, setCookie, removeCookie] = useCookies(["login"]);

  useEffect(() => {
    if (cookies.email) {
      setRemember(true);
    }
  }, [cookies.email]);

  const now = new Date();

  const next30Days = new Date(now.setDate(now.getDate() + 30));

  const dividerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "18px",
    width: "100%",
  };

  const horizontalLineStyle = {
    height: "2px",
    width: "50%",
    backgroundColor: "#EAECF0",
  };
  const orTextStyle = {
    fontSize: "16px",
    color: "#000",
    margin: "0 10px",
  };

  const handleLogin = (values) => {
    if (remember) {
      setCookie("email", values.email, {
        path: "http://localhost:3000/",
        expires: next30Days,
        secure: true,
      });
      setCookie("email", values.email, {
        path: "https://app.halvestco.com/",
        expires: next30Days,
        secure: true,
      });
      // setCookie("password", values.password, {
      //   path: "http://localhost:3000/",
      //   expires: next30Days,
      //   secure: true,
      // });
    } else {
      if (cookies.email)
        removeCookie("email", { path: "http://localhost:3000/" });
      if (cookies.email)
        removeCookie("email", { path: "https://app.halvestco.com/" });
      // if (cookies.password)
      //   removeCookie("password", { path: "http://localhost:3000/" });
    }

    dispatch(
      login({
        data: values,
        remember,
        navigate,
        cb: () => {
          navigate("/");
        },
      })
    );
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    onSuccess: (token, dem) => {
      onGoogleAuthSuccess(token, "login", dispatch);
    },
  });

  const form = useFormik({
    initialValues: {
      email: cookies?.email || "",
      password: cookies?.password || "",
    },
    onSubmit: handleLogin,
    validationSchema: loginValidationSchema,
  });

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    dispatch(clearLoading());
  }, []);

  return (
    <>
      <SEO
        title={"Login | Access Your Ethical Finance Investments | Halvest"}
        description={
          "Log in to your Halvest account and explore ethical finance and Islamic banking solutions."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Auth>
        <div className="h-screen w-[100%]  flex flex-col">
          <div className="py-8  w-[100%]  m-auto max-w-[700px] laptop:w-[65%] px-8 flex-1 flex items-center justify-center ">
            <div className="w-full  h-[498px] flex flex-col items-center ">
              <h2 className="  font-[600] w-full text-4xl text-text-color-1  leading-[44px] my-8   tracking-[1%]">
                Welcome Back
              </h2>

              <CustomInputWithLabel
                label={"Email"}
                Required
                width="100%"
                height="18px"
                id="email"
                form={form}
                placeholder={"Enter your email"}
                // borderColor="#D0D5DD"
              />

              <CustomInputWithLabel
                label={"Password"}
                Required
                width="100%"
                id="password"
                height="18px"
                type="password"
                form={form}
                placeholder={"Enter your password"}
                borderColor="#D0D5DD"
              />

              <div className="flex justify-between  w-full">
                <div className="flex gap-2 justify-center items-center">
                  <input
                    id="checkbox"
                    type="checkbox"
                    checked={remember}
                    onChange={(e) => {
                      setRemember(e.target.checked);
                    }}
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "16px",
                      color: "#D0D5DD",
                    }}
                  />
                  <label className="font-[500] text-sm leading-5">
                    Remember for 30 days
                  </label>
                </div>
                <div>
                  <a
                    onClick={handleForgotPassword}
                    href="#"
                    className="font-[600] text-sm leading-5 text-text-primary-color"
                  >
                    Forgot Password
                  </a>
                </div>
              </div>
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                loading={loading}
                width="100%"
                height="48px"
                padding="10px"
                margin="16px 0 0 0 "
                color="#fff"
                onClick={form.handleSubmit}
              >
                Sign In
              </CustomButton>

              <div style={dividerStyle}>
                <div style={horizontalLineStyle}></div>
                <div style={orTextStyle}>OR</div>
                <div style={horizontalLineStyle}></div>
              </div>

              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Continue with Google"
                width="100%"
                height="48px"
                onClick={googleLogin}
                padding="10px"
                margin="15px 0px"
                color="#000"
                borderColor="#D0D5DD"
                icon={<GoogleIcon />}
              />
              <span className="mt-4 text-sm font-[400]">
                Don't have an account?{" "}
                <span
                  className="text-[#128591] font-[600] text-sm"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => navigate("/register")}
                >
                  Become a member
                </span>
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center mx-4 py-7 ">
            <span>&copy; Halvest2024</span>
            <span className="flex items-center">
              <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
              support@halvestco.com
            </span>
          </div>
        </div>
      </Auth>
    </>
  );
};

export default Login;
