import React from "react";

function ReturnIcon() {
  return (
    <div>
      <img
        src={"/Assets/return.png"}
        alt="Return Icon"
        className={`w-8 h-8
         }`}
      />
    </div>
  );
}

export default ReturnIcon;
