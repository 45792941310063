import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_TOTAL_PAYOUTS_DETAILS } from "../../../config/api";

export const getPayoutDetails = createAsyncThunk(
  "getPayoutDetails/get",
  async ({ investmentID }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_TOTAL_PAYOUTS_DETAILS}${investmentID}`,
      });

      return data;
    } catch (error) {
      //   toast.error(error?.response?.data?.message || "An error has occurred");
    }
  }
);
