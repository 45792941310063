import { loginWithGoogle } from "../store/actions/onboarding/loginWithGoogle";

import { signupWithGoogle } from "../store/actions/onboarding/signupWithGoogle";

export const onGoogleAuthSuccess = async (tokenResponse, type, dispatch) => {
  const token = tokenResponse.access_token;

  switch (type) {
    case "login":
      return dispatch(loginWithGoogle(token));
    case "signup":
      return dispatch(signupWithGoogle(token));

    default:
      return;
  }
};
