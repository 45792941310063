import React, { useCallback, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addBankDetails } from "../../store/actions/bank/bankUserActions";
import { useFormik } from "formik";
import { currencyOptions } from "../../config/data";
import { Autocomplete, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { bankSchema, setPinSchema } from "../../helper/validationSchemas";
import SelectField from "../inputs/SelectField";
import { getBankList } from "../../store/actions/bank/getBankList";
import { getAccountName } from "../../store/actions/bank/getAccountName";
import ConfirmModal from "./ConfirmModal";
import { resetTransactionPin } from "../../store/actions/onboarding/resetTransactionPin";
import { getCreateSecurityPINOTP } from "../../store/actions/onboarding/getCreateSecurityPINOTP";
import { forgotPin } from "../../store/actions/onboarding/forgotPin";

const ForgotPinModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { user, getingOTP, pinLoading } = useSelector(
    (state) => state.onboarding
  );

  const handleSubmit = ({ type }) => {
    const data = { email: user?.email };

    dispatch(
      getCreateSecurityPINOTP({
        data,
        cb: () => {
          setIsConfirmModalOpen(true);
        },
      })
    );
  };

  const PinForm = useFormik({
    initialValues: {
      current_pin: "",
      new_pin: "",
      confirm_pin: "",
    },
    validationSchema: setPinSchema,
    onSubmit: handleSubmit,
  });

  const closeConfirmModal = () => {
    PinForm?.setSubmitting(false);
    PinForm.resetForm();

    setIsConfirmModalOpen(false);
  };

  const handleConfirm = useCallback(
    (otp) => {
      const { new_pin } = PinForm?.values;

      const payload = {
        transaction_pin: new_pin,
        otp: otp,
      };
      dispatch(
        forgotPin({
          payload,
          cb: () => {
            PinForm.resetForm();
            onClose();
          },

          resetForm: () => {
            PinForm.resetForm();
          },
        })
      );
    },
    [PinForm.values]
  );
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: 500,
            maxWidth: "90%",
            height: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 25,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full  my-2">
            <h1 className="text-2xl font-semibold mb-2">
              Reset Transaction PIN
            </h1>

            <div className="w-full m-2 mt-5"></div>

            <div className="w-full  my-2">
              <label className="text-sm font-[600] text-[#344054]">
                Enter new Transaction PIN
              </label>
              <div className="w-full mb-4">
                <CustomInput
                  fullWidth
                  height="18px"
                  placeholder={"****"}
                  borderColor="#D0D5DD"
                  mt="10px"
                  pt="28px"
                  secured
                  form={PinForm}
                  id={"new_pin"}
                  type="number"
                />
              </div>
              <label className="text-sm font-[600] text-[#344054]">
                Confirm Transaction PIN
              </label>
              <div className="w-full mb-4">
                <CustomInput
                  fullWidth
                  height="18px"
                  placeholder={"****"}
                  borderColor="#D0D5DD"
                  mt="10px"
                  pt="28px"
                  secured
                  form={PinForm}
                  id={"confirm_pin"}
                  type="number"
                />
              </div>

              <div className="flex w-full  items-center">
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Continue"
                  width="100%"
                  height="45px"
                  loading={
                    (PinForm.isValid && PinForm?.dirty && getingOTP) ||
                    pinLoading
                  }
                  disabled={!PinForm?.dirty || !PinForm?.isValid || pinLoading}
                  padding="10px"
                  margin="15px 0"
                  color="#fff"
                  onClick={PinForm.handleSubmit}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmModal
        title={""}
        subTitle={"Please enter the OTP sent to your email"}
        open={isConfirmModalOpen}
        onConfirm={handleConfirm}
        onClose={closeConfirmModal}
      />
    </div>
  );
};

export default ForgotPinModal;
