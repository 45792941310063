import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "../buttons/Button";

const Confirmation = ({ open, onClose, loading, handleButtonClicked }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
        sx={{
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            py: 2,
            px: 1,
          }}
          className="w-[90%] lg:w-[500px] bg-black"
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full mt-7 pl-4 pr-2  ">
            <div className="w-full h-[150px] flex pt-5 pb-3  justify-between flex-col bg-white">
              <h3 className="text-xs sm:text-sm font-[500] text-[#344054]">
                Are you sure you want to cancel this request?
              </h3>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="border-t border-t-[#EAECF0]"
              >
                <CustomButton
                  variant="outlined"
                  customColor="#fff"
                  children="No"
                  width="130px"
                  height="36px"
                  borderColor="#D0D5DD"
                  color="#000"
                  iconWidth="22px"
                  margin="10px 5px 10px 0px"
                  onClick={() => {
                    onClose(false);
                  }}
                />

                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children={"Yes"}
                  width={"130px"}
                  height="36px"
                  loading={loading}
                  padding="10px"
                  onClick={() => {
                    handleButtonClicked();
                  }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Confirmation;
