import React, { useEffect, useState } from "react";
import FundIcon from "../Icons/FundIcon";
import WithdrawIcon from "../Icons/WithdrawIcon";
import CustomButton from "../buttons/Button";
import FundModal from "../modal/FundModal";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../../store/actions/dashboard/manageFunds";
import AddBvnModal from "../modal/AddBvnModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PaymentModal from "../modal/PaymentModal";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import ResponseMessageModal from "../modal/ResponseMessageModal";
import { useNavigate } from "react-router-dom";
import WithdrawalModal from "../modal/WithdrawalModal";
import AddBankModal from "../modal/AddBankModal";
import { getUserBanks } from "../../store/actions/bank/getUserBanks";
import useCurrency from "../../hooks/useCurrency";
import { useLocation } from "react-router-dom";
import { switchCurrency } from "../../store/actions/dashboard/switchCurrency";
import { usdAccOptions } from "../../config/data";

const WalletCard = ({ variant }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: variant === "small" ? 1 : 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "30px",
    dotsClass: "balance-dots",
  };
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_currency, getAmount] = useCurrency();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usdAccountType, setUsdAccountType] = useState(null);
  const [isBVNModalOpen, setIsBVNModalOpen] = useState(false);
  const [isWithdralModalOpen, setIsWithdralModalOpen] = useState(false);
  const [currencyModalOpen, setCurrencyModalOpen] = useState(false);
  const [usdModalOpen, setusdModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isAddBankModalOpen, setIsAddBankModalOpen] = useState(false);
  const [warningModal, setShowWarningModal] = useState(false);

  const dollarAccountInfo = {
    accountName: "",
    accountNumber: "",
    bankName: "",
  };

  const _wallet = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );
  const dashboardPending = useSelector((state) => state?.dashboard?.pending);

  const { userBanks, pending: pendingBanks } = useSelector(
    (state) => state?.bank
  );
  const user = useSelector((state) => state.onboarding?.user);
  const pending = useSelector((state) => state.onboarding.pending);
  const profile = useSelector((state) => state.onboarding?.profile);

  const halvestWallet = { ..._wallet, accountName: user?.name };

  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);

  const handleClosModal = () => {
    setShowModal(false);
  };

  const closeAddBankModal = () => {
    setIsAddBankModalOpen(false);
  };

  const openAddBankModal = () => {
    setIsAddBankModalOpen(true);
  };

  const handleCloseBVNModal = () => {
    setIsBVNModalOpen(false);
  };
  const handleCloseWithdralModal = () => {
    setIsWithdralModalOpen(false);
  };

  const handleCloseCurrencyModal = (result, type) => {
    if (!type) return setCurrencyModalOpen(false);

    if (result === "usd") {
      dispatch(switchCurrency("USD"));
    } else if (result === "naira") {
      dispatch(switchCurrency("NGR"));
    }

    dispatch(getBalance());
    setCurrencyModalOpen(false);
    if (type === "fund") {
      if (result === "naira") {
        setIsModalOpen(true);
      } else if (result === "usd") {
        setusdModalOpen("fund");
      }
    } else if (type === "withdraw") {
      if (userBanks?.length === 0) {
        openAddBankModal();
      } else if (
        result === "naira" &&
        userBanks?.some((bank) => bank?.currency?.toLowerCase() == "ngr")
      ) {
        setIsWithdralModalOpen(true);
      } else if (
        result === "usd" &&
        userBanks?.some((bank) => bank?.currency?.toLowerCase() === "usd")
      ) {
        setIsWithdralModalOpen(true);
      } else {
        openAddBankModal();
      }
    }
  };
  const handleCloseUsdModal = (result, type) => {
    if (!type) return setusdModalOpen(false);

    setusdModalOpen(false);

    setIsModalOpen(true);
    setUsdAccountType(result);
  };

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (usdAccountType) {
      setUsdAccountType(null);
    }
  };
  const handleOpenModal = () => {
    // return setCurrencyModalOpen("fund");
    if (!user?.hasCompletedProfile) {
      setShowWarningModal({
        modalText:
          "You must complete your profile before you can fund your wallet.",
        callToAction: {
          type: "navigate",
          path: "/onboarding",
          action: () => {
            navigate("/onboarding");
          },
        },
      });

      return;
    } else if (profile && !profile?.investorProfile?.risk_tolerance) {
      setShowWarningModal({
        modalText:
          "You must complete your Investor Profile and choose a Plan before you can fund your wallet.",
        callToAction: {
          type: "navigate",
          path: "/onboarding/investor-profile",
          action: () => {
            navigate("/onboarding/investor-profile");
          },
        },
      });

      return;
    } else if (
      user?.selectedPlan?.toLowerCase() === "free" &&
      user?.free_approved_plan !== "approved"
    ) {
      setShowWarningModal({
        modalText: "Your membership is under review.",
        callToAction: {
          type: "close",
          title: "Ok",
          action: () => {
            handleCloseWarningModal();
          },
        },
      });

      return;
    } else if (user && !user?.selectedPlan) {
      setShowWarningModal({
        modalText: "You must choose a Plan before you can fund your wallet",
        callToAction: {
          type: "navigate",
          path: "/onboarding/membership",
          action: () => {
            navigate("/onboarding/membership");
          },
        },
      });

      return;
    } else if (profile && !profile?.BVN) {
      setShowModal(true);
    } else if (user && user?.document_verified === false) {
      setShowWarningModal({
        modalText:
          "Sorry! You can not fund your wallet until your identification document has been verified.",
        callToAction: {
          title: "Ok",
          type: "close",
          action: () => {
            handleCloseWarningModal();
          },
        },
      });
      return;
    } else if (profile) {
      setCurrencyModalOpen("fund");
    }
  };

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    dispatch(getUserBanks());
    dispatch(getBalance());
  }, [_currency]);

  useEffect(() => {
    if (queryParams.get("next") === "bvn") {
      handleOpenModal();
    }
  }, []);

  return (
    <div className="w-[100%] h-[390px]  border border-gray-300 rounded-[12px] py-6  pl-0 pr-0 bg-[#FCFCFD]">
      <div className="flex justify-between items-center px-6 mb-8">
        <h1 className=" font-[600]">Your Wallet</h1>
      </div>
      <div className=" max-w-[900px] ">
        <Slider {...settings}>
          <div className="w-[50%] max-w-[400px]  flex flex-row">
            <div className=" h-[160px] rounded-3xl   relative overflow-hidden">
              <div className="w-full h-[100%] rounded-t-3xl relative">
                <img src="/Assets/17.png" alt="" className="w-full h-full " />
                <div className="w-full  absolute bottom-3 left-4 mb-4">
                  <h1 className="text-white font-[400] text-[9px] sm:text-[10.4px] leading-[15.1px]">
                    NAIRA BALANCE
                  </h1>
                  <p className="text-white font-[600] mt- text-sm lg:text-[20.4px]  leading-[27px]">
                    {formattedWalletBalance}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-[50%] max-w-[400px] flex pl-2 flex-row">
            <div className="  h-[160px] rounded-3xl  relative overflow-hidden">
              <div className="w-full h-[100%] rounded-t-3xl relative">
                <img src="/Assets/23.png" alt="" className="w-full h-full " />
                <div className="w-[50%]  absolute bottom-3 left-4 mb-4">
                  <h1 className="text-white font-[400] text-[9px] sm:text-[10.4px] leading-[15.1px]">
                    USD BALANCE
                  </h1>
                  {
                    <p className="text-white font-[600] mt-[2px] text-sm md:text-[20.4px]  leading-[27px]">
                      {usdFormatter}
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <div className="flex mt-8 ml-8 w-full items-center  ">
        <CustomButton
          variant="contained"
          customColor="#159AA8"
          icon={<FundIcon />}
          disabled={pending}
          children="Fund Wallet"
          width="133px"
          height="40px"
          padding="10px"
          onClick={handleOpenModal}
        />
        <CustomButton
          variant="outlined"
          disabled={
            pending ||
            pendingBanks ||
            (user?.selectedPlan?.toLowerCase() === "free" &&
              user?.free_approved_plan !== "approved")
          }
          onClick={() => {
            setCurrencyModalOpen("withdraw");
          }}
          icon={<WithdrawIcon />}
          children="Withdraw"
          width="119px"
          height="41px"
          margin={12}
          customColor="#fff"
          borderColor="#159AA8"
          color="#159AA8"
          iconWidth="22px"
        />
      </div>

      <WithdrawalModal
        open={isWithdralModalOpen}
        onClose={handleCloseWithdralModal}
      />

      <AddBvnModal
        next={() => {
          handleCloseBVNModal();

          if (user && user?.document_verified === false) {
            setShowWarningModal({
              modalText:
                "Sorry! You can not fund your wallet until your identification document has been verified.",
              callToAction: {
                title: "Ok",
                type: "close",
                action: () => {
                  handleCloseWarningModal();
                },
              },
            });
          } else {
            setCurrencyModalOpen(true);
          }
        }}
        open={isBVNModalOpen}
        onClose={handleCloseBVNModal}
      />

      <PaymentModal
        handleGoBack={() => {
          setIsModalOpen(false);
          if (usdAccountType) {
            setUsdAccountType(null);
            setusdModalOpen("fund");
          } else {
            setCurrencyModalOpen("fund");
          }
        }}
        loading={dashboardPending}
        accountInfo={
          isModalOpen?.type === "USD" ? dollarAccountInfo : halvestWallet
        }
        isUSDModal={isModalOpen?.type === "USD" || usdAccountType}
        open={isModalOpen}
        genericAccountInfo={usdAccOptions.find(
          (acc) => acc.method === usdAccountType
        )}
        onClose={handleCloseModal}
      />
      <ResponseMessageModal
        onClose={handleCloseWarningModal}
        open={warningModal}
        closeIcon={true}
        type={0}
        btnTitle={warningModal?.callToAction?.title}
        subText={warningModal?.modalText}
        handleContinue={() => {
          const { type, path, params, action } = warningModal?.callToAction;
          action();
        }}
      />

      <FundModal
        open={currencyModalOpen}
        type={currencyModalOpen}
        onClose={handleCloseCurrencyModal}
      />
      <FundModal
        open={usdModalOpen}
        type={usdModalOpen}
        list={usdAccOptions.map((item) => item.method)}
        onClose={handleCloseUsdModal}
      />
      {isAddBankModalOpen ? (
        <AddBankModal
          _currency={_currency}
          open={isAddBankModalOpen}
          disableCurrency={true}
          cb={() => {
            setIsWithdralModalOpen(true);
          }}
          onClose={closeAddBankModal}
        />
      ) : (
        <></>
      )}
      <ResponseMessageModal
        open={showModal}
        onClose={handleClosModal}
        handleContinue={() => {
          setIsBVNModalOpen(true);

          handleClosModal();
        }}
        subTextType={"justify"}
        type={0}
        width={600}
        btnTitle={"Ok"}
        mainText={""}
        subText={
          "To enhance the security and efficiency of your investment transactions, we have partnered with a regulated bank in Nigeria to offer virtual accounts for funding investments and receiving payments on our portal. To align with banking regulations in Nigeria, we kindly request that you provide your Bank Verification Number (BVN) to complete your onboarding process. Rest assured, your information is handled with utmost confidentiality in line with our data privacy policy."
        }
      />
    </div>
  );
};

export default WalletCard;
