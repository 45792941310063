import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Auth from "../../components/containers/Auth";
import CustomButton from "../../components/buttons/Button";
import CustomInput, {
  CustomInputWithLabel,
} from "../../components/inputs/CustomInput";
import { useFormik } from "formik";
import { forgotPassword } from "../../store/actions/onboarding/forgotPassword";
import { resetPasswordValidationSchema } from "../../helper/validationSchemas";
import { changePassword } from "../../store/actions/onboarding/changePassword";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const handlSubmit = useCallback(
    (values, { setSubmitting }) => {
      dispatch(
        changePassword({
          data: { ...values, token },
          cb: () => {
            setSubmitting(false);
            navigate("/reset-password-success", { replace: true });
          },
          setSubmitting,
        })
      );
    },
    [token]
  );

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: handlSubmit,
    validationSchema: resetPasswordValidationSchema,
  });

  useEffect(() => {
    form.setFieldValue("email", searchParams?.get("email"));
  }, [searchParams]);

  useEffect(() => {
    localStorage.setItem("accessToken", token);
  }, [token]);

  return (
    <Auth>
      <div className="h-screen w-[100%]  flex flex-col">
        <div className="py-8  w-[100%]   m-auto laptop:w-[65%] px-8   flex-1 flex items-center justify-center ">
          <div className="w-full  min-h-[498px] flex flex-col items-center ">
            <h2 className="  font-[600] w-full text-4xl text-text-color-1  leading-[44px] my-8   tracking-[1%]">
              Change Password
            </h2>

            <CustomInputWithLabel
              label={"Email"}
              disabled={true}
              Required
              width="360px"
              height="18px"
              placeholder={"Enter your email"}
              borderColor="#D0D5DD"
              form={form}
              id="email"
            />

            <CustomInputWithLabel
              label={"New Password"}
              Required
              width="360px"
              height="18px"
              type="password"
              placeholder={"Enter your password"}
              borderColor="#D0D5DD"
              id="password"
              form={form}
            />

            <CustomInputWithLabel
              label={"Confirm Password"}
              Required
              width="360px"
              type="password"
              height="18px"
              placeholder={"Enter your password"}
              id="password_confirmation"
              form={form}
              borderColor="#D0D5DD"
            />

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              loading={form.isSubmitting}
              disabled={!form.dirty || !form.isValid || form.isSubmitting}
              width="100%"
              height="48px"
              padding="10px"
              margin="24px 0px"
              color="#fff"
              onClick={form.handleSubmit}
            >
              Change Password
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center m-4">
        <span>&copy; Halvest2024</span>
        <span className="flex items-center">
          <img src="/Assets/mail.png" className="w-4 h-4 mr-2" alt="" />
          support@halvestco.com
        </span>
      </div>
    </Auth>
  );
};

export default ResetPassword;
