import React from "react";
import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";

// padding: "10px",
// textAlign: "left",
// color: "#47CD89",
// margin: "0",
// borderBottom: "1px solid #ccc",

const Legend = ({ insightItems }) => {
  const [_currency, getAmount] = useCurrency();

  return (
    <div>
      <h2 className="text-[#128591] text-sm font-[600] pb-2 border-b-[#128591] border-b-2 inline  ">
        Legend
      </h2>
      {/* <hr style={hrStyle} /> */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Asset</th>
            <th style={tableHeaderStyle}>Allocation</th>
            <th style={tableHeaderStyle}>Value</th>
          </tr>
        </thead>
        <tbody>
          {insightItems.map((item, index) => (
            <tr key={index}>
              <td
                style={{
                  ...assetCellStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: item.color,
                    marginRight: "10px",
                  }}
                ></div>
                <h4>{item.name}</h4>
              </td>

              <td className="text-center">{`${Number(item?.value).toFixed(
                2
              )}%`}</td>
              <td style={valueCellStyle}>{`${_currency} ${getAmount(
                item?.amount,
                2
              )}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const tableHeaderStyle = {
  color: "white",
  padding: "10px",
  textAlign: "left",
};

const assetCellStyle = {
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
};

const valueCellStyle = {
  padding: "5px 2px",
};

export default Legend;
