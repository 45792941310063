import { Navigate, createBrowserRouter } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Wallet from "../pages/Wallet/Wallet";
import Login from "../pages/Onboarding/Login";
import Support from "../pages/Support/Support";
import Settings from "../pages/Settings/Settings";
import Register from "../pages/Onboarding/Register";
import Portfolio from "../pages/Portfolio/Portfolio";
import Onboarding from "../pages/Onboarding/Onboarding";
import SetPassword from "../pages/Onboarding/SetPassword";
import Opportunity from "../pages/Opportunity/Opportunity";
import Verification from "../pages/Onboarding/Verification";
import Notification from "../pages/Notiification/Notification";
import ForgotPassword from "../pages/Onboarding/ForgotPassword";
import PortfolioDetails from "../pages/Portfolio/PortfolioDetails";
import WalletTransactions from "../pages/Wallet/WalletTransactions";
import OpportunityDetail from "../pages/Opportunity/OpportunityDetail";
import GoogleAuthCallback from "../pages/Onboarding/GoogleAuthCallback";
import VerificationSuccess from "../pages/Onboarding/VerificationSuccess";
import UpgradeMembership from "../components/onboarding/UpgradeMembership";
import ForgotPasswordSuccess from "../pages/Onboarding/ForgotPasswordSuccess";
import GoogleRegisterAuthCallback from "../pages/Onboarding/GoogleRegisterCallback";
import RenewMembership from "../components/onboarding/RenewMembership";
import RessetPassword from "../pages/Onboarding/ResetPassword";
import ResetPasswordSuccess from "../pages/Onboarding/ResetPasswordSuccess";
import Terms from "../pages/Terms";
import RegisterTeal from "../pages/Onboarding/RegisterTeal";

const neutralRoutes = [
  // {
  //   path: "/terms",
  //   element: <Terms />,
  // },
  // {
  //   path: "/reset-password",
  //   element: <ResetPassword />,
  // },

  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/forgot-password-success",
    element: <ForgotPasswordSuccess />,
  },
];

export const publicRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/waitlist",
    element: <RegisterTeal />,
  },
  {
    path: "/verification",
    element: <Verification />,
  },

  {
    path: "/verification-success",
    element: <VerificationSuccess />,
  },

  {
    path: "/auth/google/callback",
    element: <GoogleAuthCallback />,
  },
  {
    path: "/auth/google/callback",
    element: <GoogleRegisterAuthCallback />,
  },

  {
    path: "/reset-password/:token",
    element: <RessetPassword />,
  },
  {
    path: "/reset-password-success",
    element: <ResetPasswordSuccess />,
  },

  // {
  //   path: "/set-password",
  //   element: <SetPassword />,
  // },

  // {
  //   path: "/verifiedsuccessfully",
  //   element: <VerifiedSuccessfully />,
  // },
  // {
  //   path: "/login/email",
  //   element: <LoginWithEmail />,
  // },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },

  // {
  //   path: "/register/email",
  //   element: <RegisterWithEmail />,
  // },
  // {
  //   path: "/forgot-password",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/verify-email",
  //   element: <VerifyEmail />,
  // },

  ...neutralRoutes,
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

export const protectedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/portfolio/details",
    element: <PortfolioDetails />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/opportunities",
    element: <Opportunity />,
  },
  {
    path: "/opportunities/:id",
    element: <OpportunityDetail />,
  },
  {
    path: "/wallet",
    element: <Wallet />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/plan/upgrade",
    element: <UpgradeMembership />,
  },
  {
    path: "/plan/renew",
    element: <RenewMembership />,
  },
  {
    path: "/onboarding/:type",
    element: <Onboarding />,
  },
  {
    path: "/wallet/transactions",
    element: <WalletTransactions />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/notifications",
    element: <Notification />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/settings/:type",
    element: <Settings />,
  },

  {
    path: "/register",
    element: <Navigate to={"/"} />,
  },

  // {
  //   path: "/verification",
  //   element: <Navigate to={"/"} />,
  // },

  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },

  ...neutralRoutes,

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
