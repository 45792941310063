import React from "react";
import { ThemeProvider, tableCellClasses } from "@mui/material";
import { DataGrid as DataGridMUI, gridClasses } from "@mui/x-data-grid";
import CustomFooterStatusComponent from "./Footer";
import { theme } from "../../config/MUITheme";

const DataGrid = ({ data, columns, onRowClick, loading, sx, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <DataGridMUI
        onRowClick={onRowClick}
        rows={[...data].sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )}
        columns={columns}
        loading={loading}
        getRowId={(row) => row?._id || row?.id}
        autoPageSize
        rowsPerPageOptions={[20, 30, 50, 100]}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
          "& .super-app-theme--header": {
            backgroundColor: "#F9FAFB",
            paddingLeft: "24px",
            height: "44px",
          },
          [`& .${gridClasses.row}`]: {
            paddingLeft: "16px",
            // backgroundColor: "#F9FAFB",
            "&:hover": {
              backgroundColor: "#F9FAFB",
            },
          },
          marginTop: "10px",
          minHeight: "500px",

          borderColor: "transparent",
          ...sx,
        }}
        header
        slots={{
          footer: CustomFooterStatusComponent,
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export default DataGrid;
