import React from "react";

import CustomButton from "../../components/buttons/Button";
import AuthVerify from "../../components/containers/AuthVerify";
import { useDispatch, useSelector } from "react-redux";
import { loginFromVerify } from "../../store/reducers/onboardingSlice";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function VerificationSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.onboarding.pending);
  const tempAuth = useSelector((state) => state.onboarding?.tempAuth);

  const handleVerify = () => {
    const { access_token } = tempAuth;
    localStorage.setItem("accessToken", access_token);
    dispatch(loginFromVerify(tempAuth));
  };

  return (
    <AuthVerify>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          widthL: "100%",
        }}
      >
        <div className="w-full max-w-[360px] px-6  m-auto laptop:w-[65%]">
          <div className="flex items-center flex-col ">
            <img
              width={56}
              height={56}
              src="/Assets/verify-success-icon.png"
              alt=""
            />
            <h1 className="mb-3 mt-5 font-[600] text-[#101828] text-[30px]">
              Email verified
            </h1>
            <h6 className="w-[90%] font-[400] text-[#475467]  text-base  leading-6 text-center">
              Your email has been successfully verified. Click below to log in.
            </h6>
          </div>
          <div className="text-center">
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Continue"
              width="100%"
              height="40px"
              padding="10px"
              loading={loading}
              margin="32px 0px"
              color="#fff"
              onClick={handleVerify}
            />
          </div>

          <div
            onClick={() => navigate("/")}
            className="flex cursor-pointer items-center gap-[6px] justify-center mt-5"
          >
            <IoArrowBackSharp color={"#475467"} size={"20px"} />
            <h4 className="font-[600] text-[14px] ">Back to Login</h4>
          </div>
        </div>
      </div>
    </AuthVerify>
  );
}

export default VerificationSuccess;
