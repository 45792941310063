import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import HomeIcon from "../Icons/HomeIcon";
import PortfolioIcon from "../Icons/PortfolioIcon";
import OpportunitiesIcon from "../Icons/OpportunitiesIcon";
import WalletIcon from "../Icons/WalletIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import SupportIcon from "../Icons/SupportIcon";
import MoreIcon from "../Icons/MoreIcon";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/user/logout";
import { getSubscriptionHistory } from "../../store/actions/subscription/getSubscriptionHistory";

const Sidebar = ({ handleCloseSideBar }) => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(true);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const user = useSelector((state) => state.onboarding?.user);
  const profile = useSelector((state) => state.onboarding?.profile);
  const { subscriptionHistory } = useSelector((state) => state.subscribe);
  const sidebarItem = [
    {
      id: 1,
      title: "Dashboard",
      link: "/",
      icon: <HomeIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    },
    {
      id: 2,
      title: "My Portfolio",
      link: "/portfolio",
      icon: <PortfolioIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    },
    {
      id: 3,
      title: "Opportunities",
      link: "/opportunities",
      icon: (
        <OpportunitiesIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />
      ),
    },
    {
      id: 4,
      title: "Wallet",
      link: "/wallet",
      icon: <WalletIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    },
    // {
    //   id: 4,
    //   title: "Learn",
    //   link: "/learn",
    //   icon: <LearnIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    // },
    {
      id: 5,
      title: "Notifications",
      link: "/notifications",
      icon: (
        <NotificationIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />
      ),
    },
    {
      id: 6,
      title: "Support",
      link: "/support",
      icon: <SupportIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    },
    {
      id: 6,
      title: "More",
      link: "/settings/profile",
      icon: <MoreIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
    },
  ];

  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    dispatch(getSubscriptionHistory());
  }, []);

  return (
    <div className="main-sidebar z-10 relative h-full overflow-y-auto overflow-x-hidden w-full">
      <div>
        <div
          className="flex lg:justify-start justify-between mx-4  mt-8 mb-10"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/Assets/logo.png"
            alt="logo"
            width="133px"
            height="37px"
            className="max-w-max"
          />

          <AiOutlineClose
            onClick={handleCloseSideBar}
            className="cursor-pointer text-[#159AA8] lg:hidden"
            size={26}
          />
        </div>
        <div className="flex h-full pb-8 lg:pb-0 flex-col w-full">
          <ul className="main-sidebar__nav flex-1 ">
            {sidebarItem.map(({ title, icon, link }, index) => (
              <NavLink key={index} to={link}>
                <li
                  className={`main-sidebar__item mb-2 ${
                    pathname === link && "main-sidebar__active"
                  }  hover:bg-#E4F9FC`}
                >
                  <div className="flex items-center">
                    <div>{icon}</div>
                    <span className="list-text ">{title}</span>
                  </div>
                </li>
              </NavLink>
            ))}
            <li
              onClick={handleLogout}
              className={`main-sidebar__item hover:!bg-[#FEF3F2]`}
            >
              <div className="flex items-center">
                <span className="list-text  !text-[#B42318]">Logout</span>
              </div>
            </li>
          </ul>
        </div>
        {showInfo && user?.selectedPlan?.toLowerCase() === "free" ? (
          <div className=" bg-gray-100 m-5 px-4 py-5 rounded-lg ">
            <div className="   items-center ">
              <div className="flex justify-between mb-[2px]">
                <h4 className="font-[600] text-sm leading-5 mb-1">
                  Upgrade Plan
                </h4>
                <img
                  onClick={() => {
                    setShowInfo(false);
                  }}
                  src="/Assets/close.png"
                  alt=""
                  className="w-4 cursor-pointer h-4"
                />
              </div>
              <p className="text-sm font-[400] leading-5">
                Enjoy full access to the ethical investment experience when you
                become a member
              </p>
              <div className="relative">
                <img src="/Assets/sideImage.png" alt="" className="mt-4 " />
                <img
                  src="/Assets/sideButton.png"
                  alt=""
                  className="absolute top-0 left-4 z-50 bottom-4 w-10 h-10"
                />
              </div>

              <div className="mt-4 flex justify-between flex-wrap items-center">
                <h4
                  onClick={() => {
                    setShowInfo(false);
                  }}
                  className="mr-2 text-[#475467] cursor-pointer text-sm font-[600] "
                >
                  Dismiss
                </h4>
                <h4
                  onClick={() => {
                    navigate("/plan/upgrade");
                  }}
                  className="text-[#128591] cursor-pointer text-sm font-[600]"
                >
                  Upgrade Now
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* {showInfo && subscriptionHistory && user?.subscription?.to ? (
          new Date(user?.subscription?.to) < new Date()
        ) : showInfo && */}
        {showInfo &&
        (new Date(
          subscriptionHistory[subscriptionHistory?.length - 1]?.nextDateRenew
        ) < new Date() ||
          (user?.subscription?.to &&
            new Date(user?.subscription?.to) < new Date())) ? (
          <div className=" bg-gray-100 m-5 px-4 py-5 rounded-lg ">
            <div className="   items-center ">
              <div className="flex justify-between mb-[2px]">
                <h4 className="font-[600] text-sm leading-5 mb-1">
                  Renew Your Membership
                </h4>
                <div></div>
              </div>
              <p className="text-sm font-[400] leading-5">
                Enjoy full access to the ethical investment experience
              </p>
              <div className="relative">
                <img src="/Assets/sideImage.png" alt="" className="mt-4 " />
                <img
                  src="/Assets/sideButton.png"
                  alt=""
                  className="absolute top-0 left-4 z-50 bottom-4 w-10 h-10"
                />
              </div>

              <div
                onClick={() => {
                  setShowInfo(false);
                }}
                className="mt-4 flex justify-between flex-wrap items-center"
              >
                <h4 className="mr-2 text-[#475467] cursor-pointer text-sm font-[600] ">
                  Dismiss
                </h4>
                <h4
                  onClick={() => {
                    navigate("/plan/renew");
                  }}
                  className="text-[#128591] cursor-pointer text-sm font-[600]"
                >
                  Renew Plan Now
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          onClick={() => {
            navigate("/settings/profile");
          }}
          className="flex border-t-2 items-center hover:cursor-pointer border-gray-200 pt-6  my-5 mx-4 justify-between relative  "
        >
          <div className="flex items-center w-[70%] gap-4 ">
            {profile?.profile_pic ? (
              <img
                src={profile?.profile_pic}
                alt="Preview"
                className="w-10 h-10 object-contain rounded-full"
              />
            ) : (
              <div className="w-10 h-10 bg-[#F2F4F7] rounded-full flex items-center justify-center text-#667085 ">
                <h4 className="text-base text-[#667085] font-[600] lead6">
                  {profile?.first_name
                    ? `${profile?.first_name?.slice(
                        0,
                        1
                      )}${profile?.last_name?.slice(0, 1)}`.toUpperCase()
                    : user?.email?.slice(0, 1)?.toUpperCase()}
                </h4>
              </div>
            )}

            <div className=" w-[70%] overflow-x-hidden">
              <h4 className="text-sm font-[600] w-full truncate leading-5">
                {user?.name}
              </h4>
              <h6 className="text-sm font-[400] w-full leading-5 truncate">
                {user?.email}
              </h6>
            </div>
          </div>
          <img src="/Assets/logout.png" alt="" className="w-6 h-6 " />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
