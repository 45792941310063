import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { API_GET_TOTAL_PAYOUTS } from "../../../config/api";

export const autoFetchPayout = createAsyncThunk(
  "payout/autoFetchPayout",
  async (currency, { dispatch }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_TOTAL_PAYOUTS}${currency}`,
      });

      return data;
    } catch (error) {
      //   toast.error(error?.response?.data?.message || "An error has occurred");
    }
  }
);
