import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  API_CANCEL_WITHDRAWAL_REQUEST,
  API_GET_WITHDRAWALS,
} from "../../../config/api";
import { Client } from "../../../client";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import mixpanel from "mixpanel-browser";

export const cancelWithdrawal = createAsyncThunk(
  "wallet/cancelWithdrawal",
  async ({ id, cb, failed }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_CANCEL_WITHDRAWAL_REQUEST}${id}`,
      });

      mixpanel.track("Success", {
        type: "Withdrawal",
        subtype: "Cancel-Withdrawal",
      });

      cb();
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      mixpanel.track("Error", {
        type: "Withdrawal",
        subtype: "Cancel-Withdrawal",
        message: msg,
      });
      failed();
      throw error;
    }
  }
);
