import React, { useState } from "react";
import DashboardLayout from "../../components/sharedLayouts/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import Profile from "../../components/settings/Profile";
import Security from "../../components/settings/Security";
import BankAccounts from "../../components/settings/BankAccounts";
import AccountStatement from "../../components/settings/AccountStatement";
import Membership from "../../components/settings/Membership";
import { RiMenuUnfoldLine } from "react-icons/ri";
import CustomButton from "../../components/buttons/Button";
import InvestmentStatement from "../../components/settings/InvestmentStatement";

function Settings() {
  const { type } = useParams();
  const profileLinks = [
    {
      id: 1,
      name: "Profile",
      link: "/profile",
    },
    {
      id: 2,
      name: "Security",
      link: "/security",
    },
    {
      id: 3,
      name: "Bank Accounts",
      link: "/bankaccounts",
    },
    {
      id: 4,
      name: "Investment Statement",
      link: "/accountstatement",
    },

    {
      id: 6,
      name: "Membership",
      link: "/membership",
    },
  ];

  const [showInnerSideBar, setShowInnerSideBar] = useState(false);

  return (
    <DashboardLayout hideNotification noMarginTop>
      <div className=" relative">
        <div
          onClick={() => {
            setShowInnerSideBar(false);
          }}
          className={` ${
            showInnerSideBar ? "" : "hidden"
          } fixed w-full h-full bg-[rgba(0,0,0,0.1)] lg:hidden`}
        ></div>
        {showInnerSideBar ? (
          <></>
        ) : (
          <div
            onClick={() => {
              setShowInnerSideBar(true);
            }}
            className="fixed p-4 z-20 w-full bg-white  lg:hidden cursor-pointer"
          >
            <div className="w-min  p-[10px] left-0  bg-[#159AA8]">
              <RiMenuUnfoldLine color={"#fff"} size={"28px"} />
            </div>
          </div>
        )}

        <div
          onClick={() => {
            setShowInnerSideBar(true);
          }}
          className="fixed p-4 z-20  max-w-[70%] bg-white hidden lg:block   cursor-pointer"
        >
          <div className="flex   flex-wrap gap-y-2">
            {profileLinks.map((linkObj, index) => (
              <Link key={index} to={`/settings${linkObj.link}`}>
                {index === 0 ? (
                  <CustomButton
                    variant="outlined"
                    customColor={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#159AA8"
                        : "#fff"
                    }
                    borderColor={"#D0D5DD"}
                    children={linkObj.name}
                    width="130px"
                    height="40px"
                    padding="10px"
                    custumStyle={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderRightWidth: "0px",
                      fontSize: "12px",
                    }}
                    color={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#fff"
                        : "#000"
                    }
                  />
                ) : index === 4 ? (
                  <CustomButton
                    variant="outlined"
                    customColor={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#159AA8"
                        : "#fff"
                    }
                    children={linkObj.name}
                    width="140px"
                    height="40px"
                    padding="10px"
                    custumStyle={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeftWidth: 0,
                      fontSize: "12px",
                    }}
                    color={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#fff"
                        : "#000"
                    }
                    borderColor={"#D0D5DD"}
                  />
                ) : (
                  <CustomButton
                    variant="outlined"
                    customColor={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#159AA8"
                        : "#fff"
                    }
                    children={linkObj.name}
                    width="155px"
                    height="40px"
                    padding="10px"
                    custumStyle={{
                      fontSize: "12px",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderLeftWidth: index % 2 === 0 ? "0px" : "1px",
                      borderRightWidth: index % 2 !== 0 ? "1px" : "0px",
                    }}
                    color={
                      type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                        ? "#fff"
                        : "#000"
                    }
                    borderColor={"#D0D5DD"}
                  />
                )}
              </Link>
            ))}
          </div>
        </div>
        <div
          className={` ${
            showInnerSideBar ? "" : "hidden"
          } w-1/2 p-4 lg:p-0 lg:mt-4 fixed   z-20 bg-white lg:hidden `}
        >
          {profileLinks.map((linkObj) => (
            <Link
              to={`/settings${linkObj.link}`}
              onClick={() => {
                setShowInnerSideBar(false);
              }}
              key={linkObj.id}
              className={`mb-0 `}
            >
              <div
                className="text-sm lg:text-base"
                style={{
                  fontWeight: "600",
                  padding: "10px",
                  color:
                    type &&
                    linkObj.name &&
                    type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                      ? "#344054"
                      : "#101828",
                  backgroundColor:
                    type &&
                    linkObj.name &&
                    type.replace(/\s+/g, "").toLowerCase() ===
                      linkObj.name.replace(/\s+/g, "").toLowerCase()
                      ? "#F9FAFB"
                      : "transparent",
                }}
              >
                {linkObj.name}
              </div>
            </Link>
          ))}
        </div>

        <div
          className="flex  w-full p-4 pt-20 lg:pt-20 "
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="w-full ">
            {type === undefined || (type === "profile" && <Profile />)}
            {type === undefined || (type === "security" && <Security />)}
            {type === undefined ||
              (type === "bankaccounts" && <BankAccounts />)}
            {type === undefined ||
              (type === "accountstatement" && <AccountStatement />)}

            {type === undefined || (type === "membership" && <Membership />)}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Settings;
