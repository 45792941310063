import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_REFRESH_TOKEN, API_SECURITY_PIN_OTP } from "../../../config/api";

export const getNewToken = createAsyncThunk(
  "opportunities/getNewToken",
  async () => {
    try {
      const { data } = await Client({
        method: "POST",

        path: API_REFRESH_TOKEN,
      });
      const token = data?.data?.access_token;
      if (token) {
        localStorage.setItem("accessToken", data?.data?.access_token);
      }
      return data?.data?.access_token;
    } catch (error) {}
  }
);
