import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

export default class DoughnutChart extends PureComponent {
  render() {
    const cellElements = this.props.insightItems.map((entry, index) => (
      <Cell key={`cell-${index}`} fill={entry.color} />
    ));

    return (
      <ResponsiveContainer width="100%">
        <PieChart>
          <Pie
            data={this.props.insightItems}
            cx="50%"
            cy="55%"
            innerRadius={75}
            outerRadius={100}
            fill="#8884d8"
          >
            {cellElements}
            <Label
              value="Total Investment"
              position="center"
              style={{
                fontSize: "12px",
                transform: "translateY(-10px)",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            />
            <Label
              value={`${this.props?._currency}${this.props?.getAmount(
                this.props?.insightItems?.reduce(
                  (sum, item) => sum + +item?.amount,
                  0
                ),
                2
              )}`}
              position="center"
              style={{ fontSize: "14px", transform: "translateY(5px)" }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
