import { createSlice } from "@reduxjs/toolkit";
import { fetchUnreadNotifications } from "../actions/notification/notification";
import { getAllNotifications } from "../actions/notification/getAllNotifications";
import { clearAllNotifications } from "../actions/notification/clearAllNotifications";
import { readNotification } from "../actions/notification/readNotification";
import { readNotifications } from "../actions/notification/readNotifications";
import { deleteNotifications } from "../actions/notification/deleteNotifications";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    error: null,
    pending: false,
    success: false,
    unreadNotifications: [],
    allNotifications: [],
    notifications: [],
  },

  reducers: {
    clearReadNotifications: (state, action) => {
      state.unreadNotifications = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearAllNotifications.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(clearAllNotifications.fulfilled, (state, action) => {
      state.pending = false;
      state.notifications = [];
      state.unreadNotifications = [];
      state.allNotifications = [];
    });
    builder.addCase(clearAllNotifications.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(fetchUnreadNotifications.pending, (state, action) => {
      state.pending = true;
      state.unreadNotifications = [];
    });
    builder.addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
      state.pending = false;
      state.unreadNotifications = action.payload || [];
    });
    builder.addCase(fetchUnreadNotifications.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getAllNotifications.pending, (state, action) => {
      state.pending = true;
      state.allNotifications = [];
    });
    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.pending = false;
      state.allNotifications = action.payload || [];
    });
    builder.addCase(getAllNotifications.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(readNotification.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(readNotification.fulfilled, (state, action) => {
      state.pending = false;
      state.unreadNotifications = state.unreadNotifications?.filter(
        (notification) => notification.id != action.payload
      );
    });
    builder.addCase(readNotification.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(deleteNotifications.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(deleteNotifications.fulfilled, (state, action) => {
      state.pending = false;
      state.unreadNotifications = state.unreadNotifications?.filter(
        (notification) => !action.payload.some((i) => i === notification.id)
      );
      state.allNotifications = state.allNotifications?.filter(
        (notification) => !action.payload.some((i) => i === notification.id)
      );

      builder.addCase(deleteNotifications.rejected, (state, action) => {
        state.pending = false;
      });
    });
    builder.addCase(readNotifications.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(readNotifications.fulfilled, (state, action) => {
      state.pending = false;
      state.unreadNotifications = state.unreadNotifications?.filter(
        (notification) => !action.payload.some((i) => i === notification.id)
      );
    });
    builder.addCase(readNotifications.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export const { clearReadNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
