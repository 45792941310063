import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CustomButton from "../buttons/Button";
import { MdClose } from "react-icons/md";

const ResponseMessageModal = ({
  open,
  onClose,
  closeIcon,
  type,
  mainText,
  width,
  subText,
  subTextType,
  handleContinue,
  btnTitle,
  closeButtonTitle,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: width || 480,
            // minHeight: 321,
            maxHeight: "70%",
            overflowY: "auto",
            maxWidth: "90%",
            bgcolor: "#FFF",
            position: "relative",
            borderRadius: "12px",
          }}
        >
          {closeIcon ? (
            <div
              onClick={onClose}
              className=" absolute cursor-pointer top-4 p-1 rounded-full  right-7"
            >
              <MdClose size={"26px"} color="#475467" />
            </div>
          ) : (
            <></>
          )}
          <div className="p-6 w-full pb-8 flex flex-col  items-center">
            {type === 3 ? (
              <></>
            ) : type === 0 ? (
              <img
                width="48px"
                height="48px"
                alt="Icon"
                src={"/Assets/free-membership-icon.png"}
              />
            ) : type === 1 ? (
              <img
                width="48px"
                height="48px"
                alt="Icon"
                src={"/Assets/paid-membership-icon.png"}
              />
            ) : null}
            <h4
              style={{ width: type === 1 || type === 3 ? "75%" : "50%" }}
              className={`font-[600] text-center  ${
                type === 3 ? "text-[24px] mb-5 mt-3" : "text-[18px] mb-2 mt-6"
              } leading-7 text-[#101828]`}
            >
              {mainText}
            </h4>
            <h5
              className={`font-[400]  leading-5 ${
                subTextType === "justify" ? "text-justify" : "text-center"
              } text-sm text-[#475467]`}
            >
              {subText}
            </h5>
          </div>
          <div className="flex flex-1  justify-center gap-9 w-full items-center border-t border-t-[#EAECF0] p-6">
            {closeButtonTitle ? (
              <CustomButton
                variant="outlined"
                customColor={"#FFF"}
                children={closeButtonTitle}
                width="100%"
                height="44px"
                padding="10px"
                className={"!border !border-[#159AA8]"}
                borderColor={"#D0D5DD"}
                onClick={onClose}
                color={"#159AA8"}
              />
            ) : (
              <></>
            )}
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children={btnTitle || "Continue"}
              width="100%"
              height="44px"
              onClick={handleContinue}
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResponseMessageModal;
