import React, { useEffect } from "react";
import { timeformatter } from "../../config/dateformatter";
import { formatAmount } from "../../config/utils";
import DataGrid from "../dataGrid";
// import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";

export default function MembershipTable() {
  // const [_currency] = useCurrency();

  const _currency = true === "NGR" ? "₦" : "$";

  const subscriptionHistory = useSelector(
    (state) => state.subscribe.subscriptionHistory
  );

  // const _currency = investment_product?.currency === "NGR" ? "₦" : "$";
  useEffect(() => {}, []);
  const { user, profile } = useSelector((state) => state.onboarding);

  const statuses = {
    paid: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
    pending: {
      textColor: "#B42318",
      borderColor: "#FECDCA",
      backgroundColor: "#FEF3F2",
    },
    expired: {
      textColor: "#B42318",
      borderColor: "#FECDCA",
      backgroundColor: "#FEF3F2",
    },
    success: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
    active: {
      textColor: "#067647",
      borderColor: "#ABEFC6",
      backgroundColor: "#ECFDF3",
    },
  };

  const transactions = [
    {
      plan: user?.selectedPlan,
      _id: "1",
    },
  ];

  const transactionColumns = () => {
    return [
      {
        field: "plan",
        headerClassName: "super-app-theme--header",

        headerName: "Plan",
        width: 225,
        sortable: false,
        renderHeader: ({ colDef, field }) => {
          return (
            <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
              {colDef.headerName}
            </h3>
          );
        },
        renderCell: (params) => (
          <div className="flex items-center">
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {params.row?.plan}
            </h3>
          </div>
        ),
      },
      {
        field: "amount",
        headerClassName: "super-app-theme--header",
        headerName: "Amount",
        width: 160,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {`${
                params.row?.currency === "NGR" ? "₦" : "$"
              }${" "}${formatAmount(params.row?.amount)}`}
            </h3>
          );
        },
      },

      {
        field: "date",
        headerClassName: "super-app-theme--header",
        headerName: "Next Renewal Date",
        width: 210,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          return (
            <h3 className="text-sm text-[#475467] font-[400] leading-5">
              {timeformatter(params.row?.nextDateRenew, "formal", false, true)}
            </h3>
          );
        },
      },
      {
        field: "status",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        width: 220,
        renderHeader: ({ colDef, field }) => (
          <h3 className="text-xs text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        ),
        renderCell: (params) => {
          const status =
            new Date(params.row?.nextDateRenew) < new Date()
              ? "Expired"
              : params.row.status;
          return (
            <div
              className=" h-6 px-[10px] border flex justify-center items-center rounded-full"
              style={{
                backgroundColor:
                  statuses[status?.toLowerCase()]?.backgroundColor || "#FEF3F2",
                borderColor:
                  statuses[status?.toLowerCase()]?.borderColor || "#FECDCA",
              }}
            >
              <h3
                style={{
                  color:
                    statuses[status?.toLowerCase()]?.textColor || "#B42318",
                }}
                className="text-sm font-[500] capitalize leading-5"
              >
                {status}
              </h3>
            </div>
          );
        },
      },
    ];
  };

  return (
    <div className="w-full border flex flex-col relative border-[#EAECF0] rounded-xl  h-[400px] sm:h-[540px] ">
      <div className="flex-1 overflow-auto">
        {subscriptionHistory && (
          <DataGrid
            columns={transactionColumns()}
            data={subscriptionHistory}
            loading={false}
          />
        )}
      </div>
    </div>
  );
}
