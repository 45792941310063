import React from "react";

function FundIcon() {
  return (
    <div>
      <img
        src={"/Assets/fund.png"}
        alt="Fund Icon"
        className={`w-4 h-4
         }`}
      />
    </div>
  );
}

export default FundIcon;
