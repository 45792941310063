import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_PAYMENT_DETAILS } from "../../../config/api";

export const checkPaymentStatus = createAsyncThunk(
  "packages/checkPaymentStatus",
  async ({ data, cb }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_GET_PAYMENT_DETAILS,
        data,
      });

      cb();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
