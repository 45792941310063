import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ALL_NOTIFICATIONS } from "../../../config/api";

export const clearAllNotifications = createAsyncThunk(
  "notifications/clearAllNotifications",
  async () => {
    try {
      // const { data } = await Client({
      //   method: "GET",
      //   path: API_ALL_NOTIFICATIONS,
      // });
      // return data;
    } catch (error) {
      throw error;
    }
  }
);
