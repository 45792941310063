import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../helper/proccessErrorMessage";
import { API_INVESTMENT_TRANSACTIONS } from "../../../config/api";
import mixpanel from "mixpanel-browser";
export const getInvestmentTransactions = createAsyncThunk(
  "portfolio/getInvestmentTransactions",
  async ({ currency, product_id }, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_INVESTMENT_TRANSACTIONS}${product_id}`,
      });

      return data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      mixpanel.track("Error", {
        type: "portfolio",
        subtype: "get-investment-transactions",
        message: msg,
      });
      toast.error(msg);
      throw error;
    }
  }
);
